export const DISPLAY_CUSTOMER_ALREADY_EXISTS =
  "DISPLAY_CUSTOMER_ALREADY_EXISTS";
export const HIDE_SIGN_IN_POPUP_MODAL = "HIDE_SIGN_IN_POPUP_MODAL";
export const SET_LOADER_FOR_SIGN_IN = "SET_LOADER_FOR_SIGN_IN";
export const HIDE_SIGNIN_PASS_ERROR = "HIDE_SIGNIN_PASS_ERROR";
export const SET_RECAPTCHA = "SET_RECAPTCHA";
export const RESET_RECAPTCHA = "RESET_RECAPTCHA";
export const DISPLAY_RECAPTCHA = "DISPLAY_RECAPTCHA";
export const SHOW_ACCOUNT_LOCKED = "SHOW_ACCOUNT_LOCKED";
export const DISPLAY_SIGNIN_PASS_ERROR = "DISPLAY_SIGNIN_PASS_ERROR";
export const SET_CUSTOMER_VERIFICATION_EMAIL =
  "SET_CUSTOMER_VERIFICATION_EMAIL";
export const SET_CUSTOMER_VERIFICATION_ID = "SET_CUSTOMER_VERIFICATION_ID";
