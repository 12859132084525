import React from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";

const DesktopP = styled.p`
  margin: 0;
  padding: 0;
  color: #ececec; // #f9fdff - would be more accessible
  font-weight: bold;
  & a {
    color: white;
    text-decoration: underline;
  }
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TopMessageDesktop = () => {
  const topMsg = useSelector(state => state.popups.topMsg);
  return (
    <DesktopP
      dangerouslySetInnerHTML={{
        __html: topMsg
      }}
    />
  );
};

export default TopMessageDesktop;
