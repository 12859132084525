import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { getSellMerchants } from "@actions/initApp";
import { getBuyMerchants } from "@actions/buyMerchants";
import { useDispatch, useSelector } from "react-redux";

const MainContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 300px;
  margin-bottom: 29px;

  @media (max-width: 575px) {
    width: 355px;
  }
  @media (max-width: 500px) {
    width: 315px;
  }
  @media (max-width: 350px) {
    width: 280px;
  }
  @media (max-width: 305px) {
    width: 90%;
  }
`;

const ButtonContainer = styled.div`
  height: 42px;
  border-radius: 21px;
  box-shadow: 0 20px 70px 0 rgba(31, 32, 33, 0.12);
  background-color: #f5f5f6;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #777984;
  align-items: center;

  @media (max-width: 575px) {
    background-color: #494168;
    font-weight: 600;
  }
  @media (max-width: 415px) {
    font-size: 13px;
  }
  @media (max-width: 345px) {
    font-size: 12px;
  }
  @media (max-width: 325px) {
    font-size: 11px;
  }
  @media (max-width: 305px) {
    font-size: 10px;
  }
  @media (max-width: 285px) {
    font-size: 9px;
  }
`;

const SellButton = styled.div`
  color: ${({ setColor }) => setColor};
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 21px 0px 0px 21px;

  @media (max-width: 575px) {
    color: ${props => props.mobileColor};
  }
`;

const BuyButton = styled.div`
  color: ${({ setColor }) => setColor};
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 0px 21px 21px 0px;

  @media (max-width: 575px) {
    color: ${props => props.mobileColor};
  }
`;

const AbsoluteButton = styled.div`
  position: absolute;
  pointer-events: none;
  border: ${props => props.border};
  border-radius: 21px;
  height: 100%;
  width: 50%;
  transform: ${props => `translateX(${props.transform}%)`};
  transition: transform 0.175s ease-out;
  background-color: ${props => props.backgroundColor};

  @media (max-width: 575px) {
    background-color: ${props => props.backgroundColorMobile};
    border: none;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: ${props => props.border};
  margin-right: 6px;
  background-color: #ffffff;

  @media (max-width: 575px) {
    background-color: ${props => props.mobileBackgroundColor};
    border: ${props => props.mobileBorder};
    margin-right: 8px;
  }
`;

const SliderHeaderButtons = ({ changeBuyAction, buyAction, reset }) => {
  const dispatch = useDispatch();
  const merchantsSellSorted = useSelector(
    state => state.merchantsSell?.sortedByName
  );
  const merchantsBuySorted = useSelector(
    state => state.merchantsBuy?.sortedByName || []
  );
  const [slider, changeSlider] = useState(buyAction);
  useEffect(() => {
    changeSlider(buyAction);
  }, [buyAction]);

  return (
    <MainContainer>
      <AbsoluteButton
        transform={slider ? "100" : "0"}
        backgroundColor={!slider ? "#f2fcf6" : "rgba(255, 156, 27, 0.1)"}
        backgroundColorMobile={!slider ? "#ffffff" : "#ffffff"}
        border={!slider ? "solid 2px #39c876" : "solid 2px #ff9c1b"}
      />
      <ButtonContainer>
        <SellButton
          onClick={() => {
            if (merchantsSellSorted.length < 1) {
              dispatch(getSellMerchants());
            }
            if (slider) {
              changeBuyAction(false);
              reset();
            }
          }}
          setColor={!slider ? "#39c876" : "#777984"}
          backgroundColor={!slider ? "" : "#f5f5f6"}
          mobileColor={!slider ? "#39c876" : "#ffffff"}
        >
          <Circle
            border={
              slider
                ? "solid 1px rgba(119, 121, 132, 0.24)"
                : "5px solid #39c876"
            }
            mobileBackgroundColor={
              slider ? "rgba(255, 255, 255, 0.08)" : "#ffffff"
            }
            mobileBorder={
              slider
                ? "solid 1px rgba(255, 255, 255, 0.2)"
                : "5px solid #39c876"
            }
          />
          SELL & EARN
        </SellButton>
        <BuyButton
          onClick={() => {
            if (merchantsBuySorted.length < 1) {
              dispatch(getBuyMerchants());
            }
            if (!slider) {
              changeBuyAction(true);
              reset();
            }
          }}
          setColor={slider ? "#ff9c1b" : "#777984"}
          backgroundColor={slider ? "" : "#fff9ef"}
          mobileColor={slider ? "#ff9c1b" : "#ffffff"}
        >
          <Circle
            border={
              !slider
                ? "solid 1px rgba(119, 121, 132, 0.24)"
                : "5px solid #ff9c1b"
            }
            mobileBorder={
              !slider
                ? "solid 1px rgba(255, 255, 255, 0.2)"
                : "5px solid #ff9c1b"
            }
            mobileBackgroundColor={
              !slider ? "rgba(255, 255, 255, 0.08)" : "#ffffff"
            }
          />
          BUY & SAVE
        </BuyButton>
      </ButtonContainer>
    </MainContainer>
  );
};

export default SliderHeaderButtons;
