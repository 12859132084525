import { Link as ReactRouterLink } from "react-router-dom";
import styled from "@emotion/styled";

const HomeSearch = styled.button`
  width: 295px;
  height: 72px;
  border-radius: 36px;
  box-shadow: 0 9px 18px 0 rgba(253, 165, 54, 0.3);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  background-color: #ff9c1b;
  border: 0;
  outline: none;
  &:hover,
  &:disabled,
  &:focus {
    background-color: #ff9c1b;
    border: 0;
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const QuickBuyResultsInitial = styled(HomeSearch)`
  &:hover {
    box-shadow: 0 9px 18px 0 rgba(253, 165, 54, 0.3);
    background-color: #f4961b;
  }
  width: 467px;
  @media (max-width: 900px) {
    width: 400px;
  }
  @media (max-width: 575px) {
    width: 300px;
  }

  @media (max-height: 880px) and (max-width: 900px) {
    width: 100%;
  }
  @media (max-height: 610px) and (max-width: 900px) {
    height: 60px;
  }
  @media (max-width: 375px) {
    font-size: 14px;
    height: 60px;
  }
`;

export const QuickBuyResultsSubmitting = styled(HomeSearch)`
  background-color: #777984 !important;
  width: 467px;
  box-shadow: none;
  @media (max-width: 900px) {
    width: 400px;
  }
  @media (max-width: 575px) {
    width: 300px;
  }

  @media (max-height: 880px) and (max-width: 900px) {
    width: 100%;
  }
  @media (max-height: 610px) and (max-width: 900px) {
    height: 60px;
  }
  @media (max-height: 610px) and (max-width: 375px) {
    font-size: 14px;
    height: 60px;
  }
`;

export const ShoppingCartCheckout = styled.button`
  width: 100%;
  border: none;
  outline: none;
  color: white;
  transition: border 0.2s, background-color 0.2s, color 0.2s;
  height: 50px;
  border-radius: 36px;
  box-shadow: 0 9px 18px 0 rgba(253, 165, 54, 0.3);
  background-color: #ff9c1b;
  font-weight: bold;
  &:hover,
  &:focus,
  &:active {
    border: 0;
    outline: none;
    background-color: #ff9c1b;
  }
`;

export const ClearedStyleButton = styled.div`
  &:hover,
  &:focus,
  &:disabled,
  &:active,
  &:visited {
    border: 0;
    outline: none;
  }
`;

export const ClearedStyleRealButton = styled.button`
  border: 0;

  &:hover,
  &:focus,
  &:disabled,
  &:active,
  &:visited {
    border: 0;
    outline: none;
  }
`;

export const ClearedStyleInput = styled.input`
  border: 0;
  outline: none;
  &:hover,
  &:focus,
  &:disabled,
  &:active,
  &:visited {
    border: 0;
    outline: none;
  }
`;

export const ClearedStyleTextArea = styled.textarea`
  border: 0;
  outline: none;
  &:hover,
  &:focus,
  &:disabled,
  &:active,
  &:visited {
    border: 0;
    outline: none;
  }
`;

export const ClearedStyleRouterLink = styled(ReactRouterLink)`
  border: 0;
  outline: none;
  color: inherit;
  text-decoration: none;
  &:hover,
  &:focus,
  &:disabled,
  &:active,
  &:visited {
    border: 0;
    outline: none;
    color: inherit;
    text-decoration: none;
  }
`;

export const ClearedStyleHref = styled.a`
  border: 0;
  outline: none;
  color: inherit;
  text-decoration: none;
  &:hover,
  &:focus,
  &:disabled,
  &:active,
  &:visited {
    border: 0;
    outline: none;
    color: inherit;
    text-decoration: none;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
