import React from "react";
import EachCategory from "./EachCategory";
import styled from "@emotion/styled";

const SmallBlueLine = styled.div`
  width: 36px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #12b2ea;
  margin: 0 auto;
`;

const CategoryHeader = styled.h2`
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  color: #1f2021;
  margin-top: 25px;
  margin-bottom: 75px;
  @media (max-width: 500px) {
    font-size: 30px;
  }
`;

const CategoryContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 150px;
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1750px) {
    padding-top: 0;
    width: 75%;
  }
  @media (max-width: 1600px) {
    width: 80%;
  }
  @media (max-width: 1250px) {
    width: 85%;
  }
  @media (max-width: 950px) {
    padding-top: 89px;
  }
  @media (max-width: 728px) {
    width: 90%;
  }
`;

const ContainerDiv = styled.div`
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  justify-content: center;
  @media (max-width: 950px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const categoryArray = [
  {
    index: "SPORTS & OUTDOORS",
    path: "/buy-gift-cards/discounted-sports-outdoors-cards/",
    pngImage: "https://cdn.cardcash.com/website/ui/Home/sports-1.png",
    overlayImage: "https://cdn.cardcash.com/website/ui/Home/hover-top1.png",
    name: "Sports"
  },
  {
    index: "APPAREL",
    path: "/buy-gift-cards/discounted-apparel-cards/",
    webPImage: "https://cdn.cardcash.com/website/ui/Home/Apparel-1.webp",
    pngImage: "https://cdn.cardcash.com/website/ui/Home/Apparel.png",
    overlayImage: "https://cdn.cardcash.com/website/ui/Home/hover-square.png",
    name: "Apparel"
  },
  {
    index: "FOOD & BEVERAGE",
    path: "/buy-gift-cards/discounted-food-beverage-cards/",
    webPImage: "https://cdn.cardcash.com/website/ui/Home/Food-1.webp",
    pngImage: "https://cdn.cardcash.com/website/ui/Home/Food.png",
    overlayImage: "https://cdn.cardcash.com/website/ui/Home/hover-square.png",
    name: "Food & Beverage"
  },
  {
    index: "HOME & GARDEN",
    path: "/buy-gift-cards/discounted-home-garden-cards/",
    pngImage: "https://cdn.cardcash.com/website/ui/Home/Home-1.png",
    overlayImage: "https://cdn.cardcash.com/website/ui/Home/hover-square.png",
    name: "Home & Garden"
  },
  {
    index: "SHOES",
    path: "/buy-gift-cards/discounted-shoes-cards/",
    pngImage: "https://cdn.cardcash.com/website/ui/Home/shoes-1.png",
    overlayImage: "https://cdn.cardcash.com/website/ui/Home/hover-square.png",
    name: "Shoes"
  },
  {
    index: "PETS",
    path: "/buy-gift-cards/discounted-pets-cards/",
    webPImage: "https://cdn.cardcash.com/website/ui/Home/pets-1.webp",
    pngImage: "https://cdn.cardcash.com/website/ui/Home/pets.png",
    overlayImage: "https://cdn.cardcash.com/website/ui/Home/hover-bg.png",
    name: "Pets"
  }
];

const CategoryGrid = () => (
  <CategoryContainer>
    <ContainerDiv>
      <SmallBlueLine></SmallBlueLine>
      <CategoryHeader>Stuff you actually want.</CategoryHeader>
    </ContainerDiv>

    <GridContainer>
      {categoryArray.map(item => (
        <EachCategory
          key={item.name}
          index={item.index}
          path={item.path}
          name={item.name}
          overlayImage={item.overlayImage}
          webPImage={item.webPImage}
          pngImage={item.pngImage}
        />
      ))}
    </GridContainer>
  </CategoryContainer>
);

export default CategoryGrid;
