import {
  SEND_ECOMMERCE_INFO,
  SET_BUY_ORDER_UID,
  PUT_BITCOIN_INFO_IN_REDUX,
  BITPAY_IFRAME_OPENED,
  ANYCARD_IFRAME_OPENED,
  SET_PHONE,
  DISPLAY_MAIN_PAYMENT_ERROR,
  HIDE_MAIN_PAYMENT_ERROR,
  SET_ORDER_ID,
  CLEAR_ORDER_ID,
  SET_CREDIT_CARD_TO_REMOVE,
  SET_CUSTOMER_PROFILE,
  CUSTOMER_PROFILE_THUNK_RUNNING,
  CUSTOMER_CALL_STATUS,
  LOGOUT,
  LOGOUT_MARK_THUNK_RUNNING,
  LOGOUT_CLEAR_THUNK_RUNNING,
  RESET,
  COMPLETE_ORDER_SELL,
  RESET_SELL,
  RESET_BUY,
  RESET_EXPIRE,
  SET_PAYPAL_INTERNAL_PAYMENT_ID,
  CONFIRM_END_BUY_TRANSACTION,
  SET_ATTEMPTED_PATH
} from "@constants/actionTypes";

import {
  HIDE_SIGNIN_PASS_ERROR,
  SET_RECAPTCHA,
  RESET_RECAPTCHA,
  DISPLAY_RECAPTCHA,
  DISPLAY_SIGNIN_PASS_ERROR
} from "@constants/accountTypes";

import { CLEAR_RECEIPT_BUY } from "@constants/buyReceipt";

const initialState = {
  firstname: "",
  lastname: "",
  street: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
  email: "",
  phone: "",
  mainPaymentError: false,
  bitcoinPaymentId: "",
  bitpayIframeOpened: false,
  anycardIframeOpened: false,
  invalidSignInPassword: false,
  orderID: null,
  orderUIDBuy: null,
  showRecaptcha: false,
  recaptchaCode: null,
  mainErrorMsg: "Please correct the errors below and try again.",
  passErrorMsg: "",
  ecommerceInfoSent: false,
  creditCardToRemove: null,
  _logoutThunkRunning: false,
  _customerInfoThunkRunning: true,
  customerGetStatus: "initial",
  encodedId: "",
  internalId: "",
  isNewBuyCustomer: "",
  isNewSellCustomer: "",
  rakutenData: {},
  confirmEndBuyTransaction: false,
  attemptedPath: "",
  riskConsignmentProposalStatus: ""
};

const customerInfo = (state = initialState, action) => {
  switch (action.type) {
    case SEND_ECOMMERCE_INFO:
      return {
        ...state,
        ecommerceInfoSent: true
      };

    case SET_BUY_ORDER_UID:
      return {
        ...state,
        orderUIDBuy: action.payload
      };

    case PUT_BITCOIN_INFO_IN_REDUX:
      return {
        ...state,
        orderUIDBuy: action.payload.orderUIDBuy,
        bitcoinPaymentId: action.payload.paymentId
      };

    case BITPAY_IFRAME_OPENED:
      return {
        ...state,
        bitpayIframeOpened: action.payload
      };

    case ANYCARD_IFRAME_OPENED:
      return {
        ...state,
        anycardIframeOpened: action.payload
      };

    case DISPLAY_RECAPTCHA:
      return {
        ...state,
        showRecaptcha: true
      };

    case SET_RECAPTCHA:
      return {
        ...state,
        recaptchaCode: action.payload
      };

    case RESET_RECAPTCHA:
      return {
        ...state,
        recaptchaCode: null
      };

    case SET_PHONE:
      return {
        ...state,
        phone: action.payload
      };

    case DISPLAY_SIGNIN_PASS_ERROR:
      return {
        ...state,
        invalidSignInPassword: true,
        passErrorMsg:
          action.payload === "reCaptcha required"
            ? "Login failed. Please check your information and try again"
            : action.payload.includes("instance.customer.email")
            ? "Please enter a valid email"
            : action.payload.includes("instance.")
            ? "Login failed. Please check your information and try again"
            : action.payload
      };

    case HIDE_SIGNIN_PASS_ERROR:
      return {
        ...state,
        invalidSignInPassword: false,
        passErrorMsg: ""
      };

    case DISPLAY_MAIN_PAYMENT_ERROR:
      return {
        ...state,
        mainPaymentError: true,
        mainErrorMsg: action.payload
      };

    case HIDE_MAIN_PAYMENT_ERROR:
      return {
        ...state,
        mainPaymentError: false
      };

    case SET_ORDER_ID:
      return {
        ...state,
        orderID: action.payload.id
      };

    case CLEAR_ORDER_ID:
      return {
        ...state,
        orderID: null
      };

    case SET_CREDIT_CARD_TO_REMOVE:
      return {
        ...state,
        creditCardToRemove: action.payload
      };

    case SET_CUSTOMER_PROFILE:
      let phoneExists = action.payload.customer.phone.length;
      let addressExists = action.payload.customer.address.length;
      return {
        ...state,
        id: action.payload.customer.id,
        firstname: action.payload.customer.firstname,
        lastname: action.payload.customer.lastname,
        email: action.payload.customer.email,
        phone: phoneExists
          ? action.payload.customer.phone[0].number.toString()
          : "",
        internalId: action.payload.customer.internalId,
        encodedId: action.payload.customer.referLinkHash,
        referredCoupon: action.payload.customer.referredCoupon
          ? action.payload.customer.referredCoupon
          : "",
        state: addressExists ? action.payload.customer.address[0].state : "",
        city: addressExists ? action.payload.customer.address[0].city : "",
        zip: addressExists ? action.payload.customer.address[0].postcode : "",
        street: addressExists ? action.payload.customer.address[0].street : "",
        street2: addressExists
          ? action.payload.customer.address[0].street2
          : "",
        missingInfo: action.payload.customer.missingInfo,
        isNewBuyCustomer: action.payload.customer.isNewBuyCustomer,
        isNewSellCustomer: action.payload.customer.isNewSellCustomer,
        rakutenData: action.payload.customer.rakutenData,
        riskConsignmentProposalStatus:
          action.payload.customer.riskConsignmentProposalStatus
      };

    case CUSTOMER_PROFILE_THUNK_RUNNING:
      return {
        ...state,
        _customerInfoThunkRunning: action.payload
      };

    case CUSTOMER_CALL_STATUS:
      return {
        ...state,
        customerGetStatus: action.payload
      };

    case LOGOUT:
      return {
        ...state,

        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        state: "",
        city: "",
        zip: "",
        street: "",
        customerGetStatus: "failed",
        showRecaptcha: false,
        recaptchaCode: null
      };

    case LOGOUT_MARK_THUNK_RUNNING:
      return {
        ...state,
        _logoutThunkRunning: true
      };

    case LOGOUT_CLEAR_THUNK_RUNNING:
      return {
        ...state,
        _logoutThunkRunning: false
      };

    case RESET:
      return {
        ...initialState,
        customerGetStatus: "failed"
      };

    case COMPLETE_ORDER_SELL:
    case RESET_SELL:
      return {
        ...state,
        orderID: null
      };

    case RESET_BUY:
      return {
        ...state,
        orderUIDBuy: null
      };

    case RESET_EXPIRE:
      return {
        ...initialState,
        customerGetStatus: "failed",
        anycardIframeOpened: state.anycardIframeOpened
      };

    case CLEAR_RECEIPT_BUY:
      return {
        ...state,
        ecommerceInfoSent: false
      };

    case SET_PAYPAL_INTERNAL_PAYMENT_ID:
      return {
        ...state,
        internalPaymentId: action.payload
      };

    case CONFIRM_END_BUY_TRANSACTION:
      return {
        ...state,
        confirmEndBuyTransaction: action.payload
      };
    case SET_ATTEMPTED_PATH:
      return {
        ...state,
        attemptedPath: action.payload
      };

    default:
      return state;
  }
};

export default customerInfo;
