import React from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";

const MobileP = styled.p`
  margin: 0;
  padding: 0;
  color: #ececec; // #f9fdff - would be more accessible
  font-weight: bold;
  & a {
    color: white;
    text-decoration: underline;
  }
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;
const TopMessageMobile = () => {
  const mobileMsg = useSelector(state => state.popups.mobileMsg);
  return (
    <MobileP
      dangerouslySetInnerHTML={{
        __html: mobileMsg
      }}
    />
  );
};

export default TopMessageMobile;
