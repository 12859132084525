export const getCardType = number => {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null) return "Visa";

  // Mastercard
  re = new RegExp("^5[1-5]");
  if (number.match(re) != null) return "Mastercard";

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "AMEX";

  // Discover
  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null) return "Discover";

  // Diners
  re = new RegExp("^36");
  if (number.match(re) != null) return "Diners";

  // Diners - Carte Blanche
  re = new RegExp("^30[0-5]");
  if (number.match(re) != null) return "Diners - Carte Blanche";

  // JCB
  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null) return "JCB";

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) return "Visa Electron";

  return "";
};

export const getBuyCards = (results, currentCards = {}) => {
  let allCardsInCart = results.cards.map(card => card.id);
  results.cards.forEach(value => {
    let {
      id,
      merchant,
      balance,
      cost,
      quantity,
      type,
      percentOff = null
    } = value;
    if (type === "newEcode") {
      let { from, to, message, deliveryDate } = value;
      currentCards[id] = {
        id,
        merchant,
        balance,
        cost,
        quantity,
        type,
        from,
        to,
        message,
        deliveryDate,
        percentOff
      };
    } else {
      currentCards[id] = {
        id,
        merchant,
        balance,
        cost,
        quantity,
        type,
        percentOff
      };
    }
  });
  return [currentCards, allCardsInCart];
};

export const getSellCards = (results, currentCards) => {
  currentCards = currentCards || {};
  let cartID;
  let allCardsInCart = [];

  if (results.cart && results.cart.sellCart) {
    cartID = results.cart.sellCart.cartId;
    if (results.cart.sellCart.cards) {
      allCardsInCart = results.cart.sellCart.cards.map(card => card.id);
      results.cart.sellCart.cards.forEach(value => {
        let cardID = value.id;
        let {
          enterValue,
          cashValue,
          merchant,
          pin,
          number,
          percentage,
          refId,
          merchantName
        } = value;
        if (!currentCards[cardID] && pin != null && number != null) {
          currentCards[cardID] = {
            ...currentCards[cardID],
            enterValue,
            cashValue,
            pin,
            number,
            refId,
            percentage,
            merchantName,
            // match: "PASSED",
            cardNumEnter: number,
            pinNumEnter: pin,
            pinError: "PASSED",
            cardError: "PASSED",
            id: merchant,
            balanceError: "PASSED"
          };
        } else if (!currentCards[cardID] && number != null) {
          currentCards[cardID] = {
            ...currentCards[cardID],
            enterValue,
            cashValue,
            pin,
            number,
            refId,
            percentage,
            merchantName,
            id: merchant,
            cardError: "PASSED",
            pinError: "",
            // match: "PASSED",
            cardNumEnter: number,
            pinNumEnter: "",
            balanceError: "PASSED"
            // msg: "Invalid Card Number"
          };
        } else if (!currentCards[cardID]) {
          currentCards[cardID] = {
            ...currentCards[cardID],
            enterValue,
            cashValue,
            pin,
            number,
            refId,
            percentage,
            id: merchant,
            merchantName,
            cardError: "",
            pinError: "",
            // match: "PASSED",
            cardNumEnter: "",
            pinNumEnter: "",
            balanceError: "PASSED"
            // msg: "Invalid Card Number"
          };
        } else {
          currentCards[cardID] = {
            ...currentCards[cardID],
            enterValue,
            cashValue,
            pin,
            number,
            refId,
            percentage,
            merchantName,
            // match: "PASSED",
            cardNumEnter: number,
            pinNumEnter: pin,
            id: merchant,
            balanceError: "PASSED"
          };
        }
      }); // forEach
    } // if cards in cart
  } else {
    allCardsInCart = results.cards.map(card => card.id);
    results.cards.forEach(value => {
      let cardID = value.id;
      let {
        enterValue,
        cashValue,
        merchant,
        pin,
        number,
        refId,
        merchantName,
        percentage
      } = value;
      if (!currentCards[cardID]) {
        currentCards[cardID] = {
          ...currentCards[cardID],
          enterValue,
          cashValue,
          pin,
          number,
          refId,
          percentage,
          id: merchant,
          merchantName,
          cardError: "",
          pinError: "",
          // match: "PASSED",
          cardNumEnter: number,
          pinNumEnter: pin,
          balanceError: "PASSED"
          // msg: "Invalid Card Number"
        };
      } else if (currentCards[cardID] && pin && number) {
        currentCards[cardID] = {
          ...currentCards[cardID],
          enterValue,
          cashValue,
          pin,
          refId,
          percentage,
          merchantName,
          number,
          cardNumEnter: number,
          pinNumEnter: pin,
          id: merchant,
          balanceError: "PASSED",
          pinError: "PASSED",
          cardError: "PASSED"
          // match: 'PASSED'
        };
      } else {
        currentCards[cardID] = {
          ...currentCards[cardID],
          enterValue,
          cashValue,
          pin,
          number,
          percentage,
          merchantName,
          refId,
          // match: "PASSED",
          cardNumEnter: number,
          pinNumEnter: pin,
          id: merchant,
          balanceError: "PASSED"
        };
      }
    });
  }

  return [currentCards, allCardsInCart, cartID];
};
