import React from "react";
const ShoppingCartImageSvg = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g
      id="Design"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.200000003"
    >
      <g
        id="Homepage"
        transform="translate(-1561.000000, -33.000000)"
        fill="#1F2021"
        fillRule="nonzero"
      >
        <g id="Menu" transform="translate(215.000000, 25.000000)">
          <g
            id="shopping-basket-2"
            transform="translate(1346.000000, 8.000000)"
          >
            <path
              d="M21.5,14.625 L2.5,14.625 C2.34983865,14.625 2.20761413,14.6924279 2.11263188,14.8087327 C2.01764964,14.9250375 1.97996773,15.0778725 2.01,15.225 L3.291,21.625 C3.67491406,22.6329809 4.69477662,23.2510795 5.766,23.125 L18.234,23.125 C19.3052234,23.2510795 20.3250859,22.6329809 20.709,21.625 L21.99,15.225 C22.0200323,15.0778725 21.9823504,14.9250375 21.8873681,14.8087327 C21.7923859,14.6924279 21.6501613,14.625 21.5,14.625 L21.5,14.625 Z M7.75,20.125 L7.75,17.625 C7.75,17.2107864 8.08578644,16.875 8.5,16.875 C8.91421356,16.875 9.25,17.2107864 9.25,17.625 L9.25,20.125 C9.25,20.5392136 8.91421356,20.875 8.5,20.875 C8.08578644,20.875 7.75,20.5392136 7.75,20.125 L7.75,20.125 Z M16.25,20.125 C16.25,20.5392136 15.9142136,20.875 15.5,20.875 C15.0857864,20.875 14.75,20.5392136 14.75,20.125 L14.75,17.625 C14.75,17.2107864 15.0857864,16.875 15.5,16.875 C15.9142136,16.875 16.25,17.2107864 16.25,17.625 L16.25,20.125 Z"
              id="Shape"
            ></path>
            <path
              d="M24,11.125 C24,10.0204305 23.1045695,9.125 22,9.125 L20.142,9.125 C20.0431142,9.12529825 19.9533452,9.0672816 19.913,8.977 L16.643,1.618 C16.3520575,1.00491624 15.6253713,0.735591322 15.0051438,1.01097667 C14.3849163,1.28636202 14.097347,2.00602347 14.357,2.633 L17.087,8.774 C17.1210352,8.85149033 17.1138982,8.94089091 17.068,9.012 C17.0217616,9.08291724 16.9426582,9.1254824 16.858,9.125 L6.981,9.125 C6.89557567,9.12485806 6.81613728,9.08110829 6.77035367,9.00898897 C6.72457005,8.93686964 6.71877992,8.84636564 6.755,8.769 L9.631,2.658 C9.92509161,2.03336595 9.65713403,1.28859165 9.03249999,0.994500024 C8.40786595,0.700408399 7.66309164,0.968365963 7.369,1.593 L3.892,8.982 C3.85102408,9.06968242 3.76278309,9.12551633 3.666,9.125 L2,9.125 C0.8954305,9.125 0,10.0204305 0,11.125 C0,12.2295695 0.8954305,13.125 2,13.125 L22,13.125 C23.1045695,13.125 24,12.2295695 24,11.125 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default ShoppingCartImageSvg;
