import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { mediaMLGDown, mediaSMDDown } from "@modules/Components/break";
import PreloadLink from "@components/PreloadLink";
import { useSelector, useDispatch } from "react-redux";

const compact =
  (a, b) =>
  ({ compact }) =>
    compact ? a : b;

const Button = styled.button`
  transition: background-color 0.2s, box-shadow 0.5s, height 0.2s;
  width: 132px;
  height: ${compact("40px", "60px")};
  border-radius: 36px;
  box-shadow: 0 9px 18px rgba(31, 32, 33, 0.05);
  background-color: #fff;
  border: none;
  color: #ff9c1b;
  font-weight: bold;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0 9px 18px 0 rgba(31, 32, 33, 0.1);
  }

  &:active {
    box-shadow: 0 0px 5px rgba(24, 32, 33, 0.1);
  }
  ${mediaMLGDown} {
    width: auto;
    border-radius: unset;
    box-shadow: none;
    padding: 0;
  }
`;

const AccountGroupNotSignedIn = ({ compact }) => {
  const location = useLocation();

  return (
    <PreloadLink page={"SignIn"}>
      <Link
        css={{
          [mediaSMDDown]: {
            display: "none"
          }
        }}
        to={{
          pathname: "/login/",
          state: { from: location.pathname }
        }}
      >
        <Button compact={compact}>Login</Button>
      </Link>
    </PreloadLink>
  );
};

export default AccountGroupNotSignedIn;
