import {
  SET_BUY_PAYMENT_METHODS,
  SET_SELL_PAYMENT_METHODS,
  SET_DEFAULT_SELL_ACH_ACCOUNT,
  SET_ALL_PAYMENT_METHODS,
  LOGOUT,
  SET_SELECTED_PAYMENT_ID,
  SET_ADDED_PAYMENT_METHOD_ID,
  SET_ANYCARD_TRANSACTION_ID,
  RESET_EXPIRE
} from "@constants/actionTypes";

const initialState = {
  paymentMethods: [],
  buyAndSellPaymentMethods: [],
  selectedId: "",
  addedPaymentMethodId: "",
  sellPaymentMethods: [],
  defaultSellBankAccount: {},
  anycardTransactionId: ""
};

const paymentMethods = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_PAYMENT_METHODS:
      return {
        ...state,
        buyAndSellPaymentMethods: action.payload
      };

    case SET_SELL_PAYMENT_METHODS:
      return {
        ...state,
        sellPaymentMethods: action.payload
      };

    case SET_DEFAULT_SELL_ACH_ACCOUNT:
      return {
        ...state,
        defaultSellBankAccount: action.payload
      };

    case SET_BUY_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload
      };

    case SET_ANYCARD_TRANSACTION_ID:
      return {
        ...state,
        anycardTransactionId: action.payload
      };

    case SET_SELECTED_PAYMENT_ID:
      return {
        ...state,
        selectedId: action.payload
      };
    case SET_ADDED_PAYMENT_METHOD_ID:
      return {
        ...state,
        addedPaymentMethodId: action.payload
      };

    case RESET_EXPIRE:
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default paymentMethods;
