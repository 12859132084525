export const CUSTOM_BUTTONS = {
  ORANGE_BUTTON: {
    default: {
      color: "#ffffff",
      boxShadow: "0 9px 18px 0 rgba(253, 165, 54, 0.3)",
      backgroundColor: "#ff9c1b"
    },
    hover: {
      color: "#ffffff",
      boxShadow: "0 9px 18px 0 rgba(253, 165, 54, 0.3)",
      backgroundColor: "#f4961b"
    },
    active: {
      color: "#ffffff",
      boxShadow: "0 9px 18px 0 rgba(253, 165, 54, 0.3)",
      backgroundColor: "#e9901b"
    }
  },
  GREEN_BUTTON: {
    default: {
      color: "#ffffff",
      boxShadow: "0 9px 18px 0 rgba(57, 200, 118, 0.3)",
      backgroundColor: "#39c876"
    },
    hover: {
      color: "#ffffff",
      boxShadow: "0 9px 18px 0 rgba(53, 186, 110, 0.3)",
      backgroundColor: "#35ba6e"
    },
    active: {
      color: "#ffffff",
      boxShadow: "0 9px 18px 0 rgba(53, 186, 110, 0.3)",
      backgroundColor: "#30a863"
    }
  },
  WHITE_BUTTON_ORANGE_COLOR: {
    default: {
      color: "#ff9c1b",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.05)",
      backgroundColor: "#ffffff"
    },
    hover: {
      color: "#e68c18",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.1)",
      backgroundColor: "#ffffff"
    },
    active: {
      color: "#e68c18",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.16)",
      backgroundColor: "#ffffff"
    }
  },
  WHITE_BUTTON_GREEN_COLOR: {
    default: {
      color: "#39c876",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.05)",
      backgroundColor: "#ffffff"
    },
    hover: {
      color: "#35ba6e",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.1)",
      backgroundColor: "#ffffff"
    },
    active: {
      color: "#30a863",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.16)",
      backgroundColor: "#ffffff"
    }
  },
  BLUE_BUTTON: {
    default: {
      color: "#ffffff",
      boxShadow: "0 9px 18px 0 rgba(11, 84, 189, 0.24)",
      backgroundColor: "#0b54bd"
    },
    hover: {
      color: "#ffffff",
      boxShadow: "0 9px 18px 0 rgba(11, 84, 189, 0.24)",
      backgroundColor: "#0a4ba8"
    },
    active: {
      color: "#ffffff",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.16)",
      backgroundColor: " #094294"
    }
  },
  WHITE_BUTTON_BLUE_COLOR: {
    default: {
      color: "#12b2ea",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.05)",
      backgroundColor: "#ffffff"
    },
    hover: {
      color: "#12b2ea",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.1)",
      backgroundColor: "#ffffff"
    },
    active: {
      color: "#12b2ea",
      boxShadow: "0 9px 18px 0 rgba(31, 32, 33, 0.16)",
      backgroundColor: "#ffffff"
    }
  }
};
