import React from "react";
import Resizer from "@hocs/Resizer";
import TabletSocial from "./TabletSocial";
import { useSelector } from "react-redux";

const Social = ({ minBreak, maxBreak }) => {
  const userMobile = useSelector(state => state.config.userAgent.isMobile);

  return (
    <Resizer
      components={[
        {
          component: () => <TabletSocial />,
          break: minBreak,
          maxBreak: maxBreak
        }
      ]}
      defaultTrue={userMobile ? true : false}
    />
  );
};

export default Social;
