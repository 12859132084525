import { ADD_TO_PRELOAD } from "@constants/actionTypes";
import { defaultAction } from "./defaultAction";
import loadable from "@loadable/component";

export const preloadLinks = page => async (dispatch, getState, api) => {
  if (!getState().preload.preloadArray.includes(page)) {
    dispatch(defaultAction(ADD_TO_PRELOAD, page));
    const Page = loadable(() => import(`../pages/${page}`));
    Page.preload();
  }
};
