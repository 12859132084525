import React from "react";
import styled from "@emotion/styled";
import SocialFooter from "./SocialFooter";
import { Link as ReactLink, useLocation } from "react-router-dom";
import HomeLogo from "@components/Logo";
import "../../../styles/footer.scss";
import PreloadLink from "@components/PreloadLink";
import Resizer from "@hocs/Resizer";
import { useSelector } from "react-redux";
import Terms from "@components/Terms";
import SignUpEmail from "./SignUpEmail";
import SiteMapGrid from "./SiteMapGrid";
import Social from "./Social";

const FooterMainContainer = styled.footer`
  padding-top: ${props => props.paddingTop};
  background-image: ${props => props.setGradient};
  margin: 0 auto;

  @media (max-width: 1100px) {
    padding-bottom: ${props => (props.cartPage ? "167px" : "")};
  }
  @media (max-width: 950px) {
    padding-bottom: ${props =>
      props.payoutPage || props.cartPage ? "167px" : ""};
  }
`;

const SolidLine = styled.div`
  height: 1px;
  opacity: 0.15;
  background-image: linear-gradient(
    to right,
    rgba(66, 67, 69, 0.3),
    #1f2021 51%,
    rgba(31, 32, 33, 0.3)
  );
  display: ${props => props.setDisplay};
`;

const FooterMainGrid = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 3fr;
  margin-top: 75px;
  margin-bottom: 90px;

  @media (max-width: 1350px) {
    grid-gap: 70px;
  }
  @media (max-width: 1040px) {
    display: initial;
  }
`;

const SocialGridItem = styled.div`
  width: 315px;

  @media (max-width: 1040px) {
    margin-top: 80px;
    width: 100%;
    margin-bottom: 40px;
  }
  @media (max-width: 821px) {
    grid-template-columns: 1.6fr 0.7fr;
    grid-gap: 20px;
  }
  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
  @media (max-width: 350px) {
    width: 295px;
  }
  @media (max-width: 315px) {
    width: 100%;
  }
`;

const LogoDiv = styled.div`
  height: 37px;
  margin-bottom: 25px;

  @media (max-width: 1040px) {
    display: grid;
    grid-template-columns: 1fr auto;
  }
`;

const Logo = styled.img`
  height: 37px;
  width: 155px;
  cursor: pointer;
`;

const TurnUnusedP = styled.p`
  font-size: 16px;
  line-height: 1.88;
  color: #777984;

  @media (max-width: 1040px) {
    font-size: 15px;
  }
  @media (max-width: 450px) {
    font-size: 14px;
    margin-bottom: 24px;
  }
`;

const FooterInnerContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1750px) {
    width: 75%;
  }
  @media (max-width: 1600px) {
    width: 80%;
  }
  @media (max-width: 1250px) {
    width: 85%;
  }
  @media (max-width: 1150px) {
    width: 95%;
  }
  @media (max-width: 999px) {
    width: 85%;
  }
  @media (max-width: 728px) {
    width: 90%;
  }
`;

const BottomFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 36px;
  align-items: center;

  @media (max-width: 1040px) {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 40px;
  }
  @media (max-width: 700px) {
    grid-gap: 25px;
  }
  @media (max-width: 675px) {
    grid-template-columns: 1fr;
  }
`;

const Copyright = styled.p`
  font-size: 16px;
  line-height: 1.88;
  color: #777984;
  @media (max-width: 700px) {
    font-size: 15px;
  }
  @media (max-width: 675px) {
    font-size: 16px;
  }
  @media (max-width: 375px) {
    font-size: 14px;
  }
`;

const GridForMedia = styled.div`
  margin-bottom: 30px;

  @media (max-width: 450px) {
    margin-bottom: 40px;
  }
`;

const LargeScreen = styled.div`
  @media (max-width: 1040px) {
    display: none;
  }
`;

const CardCashLink = styled(ReactLink)`
  display: flex;
  justify-content: flex-start;
  height: 37px;
  max-width: fit-content;
`;

const Tablet = styled.div`
  @media (min-width: 1041px) {
    display: none;
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

const MobileSocial = styled.div`
  @media (min-width: 451px) {
    display: none;
  }
`;

const LargeTerms = styled.div`
  @media (max-width: 675px) {
    display: none;
  }
`;

const Footer = () => {
  const cardsBuy = useSelector(state => state.cardsBuy);
  const mainLogo = useSelector(state => state.logos.mainLogo);
  const userMobile = useSelector(state => state.config.userAgent.isMobile);
  const location = useLocation();

  return (
    <FooterMainContainer
      className="footer-main-container"
      cartPage={
        (location.pathname.startsWith("/cart") ||
          location.pathname.startsWith("/checkout")) &&
        Object.values(cardsBuy).length > 0
      }
      payoutPage={location.pathname.startsWith("/payout")}
      setGradient={
        location.pathname.startsWith("/save-with-gift-cards") ||
        location.pathname.startsWith("/gift-card-statistics")
          ? "linear-gradient(to bottom,rgba(34, 176, 252, 0.081),rgba(34, 176, 252, 0))"
          : "linear-gradient(to bottom,rgba(34, 176, 252, 0),rgba(34, 176, 252, 0.05) 96%)"
      }
      paddingTop={
        location.pathname.startsWith("/save-with-gift-cards") ||
        location.pathname.startsWith("/gift-card-statistics")
          ? "120px"
          : ""
      }
    >
      <FooterInnerContainer>
        <SolidLine
          setDisplay={
            location.pathname.startsWith("/bulk-buy") ||
            location.pathname.startsWith("/refer") ||
            location.pathname.startsWith("/pass-assist") ||
            location.pathname.startsWith("/contact") ||
            location.pathname.startsWith("/save-with-gift-cards") ||
            location.pathname.startsWith("/gift-card-statistics")
              ? "none"
              : ""
          }
        />
        <FooterMainGrid>
          <SocialGridItem>
            <GridForMedia>
              <LogoDiv>
                <PreloadLink page={"Home"}>
                  <CardCashLink to="/" aria-label="Home page">
                    {mainLogo ? (
                      <Logo src={mainLogo} alt="cardcash" loading="lazy" />
                    ) : (
                      <HomeLogo />
                    )}
                  </CardCashLink>
                </PreloadLink>
                <Tablet>
                  <Social minBreak={450} maxBreak={1041} />
                </Tablet>
              </LogoDiv>
              <TurnUnusedP>
                Doing our part to turn the world's $140+ billion of unused gift
                cards into sweet, sweet discounts and usable cash.
              </TurnUnusedP>
              <MobileSocial>
                <Social minBreak={1} maxBreak={451} />
              </MobileSocial>
            </GridForMedia>
            <SignUpEmail />
          </SocialGridItem>
          <SiteMapGrid />
        </FooterMainGrid>
        <BottomFooter>
          <Copyright>
            &copy; {new Date().getFullYear()} CardCash. All rights reserved.
          </Copyright>
          <LargeScreen>
            <Resizer
              components={[
                {
                  component: () => <SocialFooter />,
                  break: 1040,
                  maxBreak: Number.MAX_SAFE_INTEGER
                }
              ]}
              defaultTrue={userMobile ? false : true}
            />
          </LargeScreen>
          <LargeTerms>
            <Resizer
              components={[
                {
                  component: () => <Terms />,
                  break: 675,
                  maxBreak: Number.MAX_SAFE_INTEGER
                }
              ]}
              defaultTrue={userMobile ? false : true}
            />
          </LargeTerms>
        </BottomFooter>
      </FooterInnerContainer>
    </FooterMainContainer>
  );
};

export default Footer;
