import React from "react";
import styled from "@emotion/styled";
import { ClearedStyleInput } from "@modules/Components";

const AmountInputDiv = styled.div`
  width: 100%;
  position: relative;
  padding-left: 25px;
  z-index: 2;
  height: 100%;
  border: ${props => props.amountBorder};
  border-left: ${props =>
    props.merchantFocused
      ? "none"
      : props.amountFocused
      ? props.amountBorder
      : "none"};
  border-right: ${props =>
    props.merchantFocused
      ? "none"
      : props.amountFocused
      ? props.amountBorder
      : "none"};

  @media (max-width: 400px) {
    padding-left: 19px;
  }

  @media (max-width: 330px) {
    padding-left: 10px;
  }
`;

const AmountInput = styled(ClearedStyleInput)`
  width: 100%;
  height: 100%;
`;

const Dollar = styled.div`
  position: absolute;
  height: 100%;
  left: 12px;
  display: flex;
  align-items: center;

  @media (max-width: 400px) {
    left: 7px;
    font-size: 13px;
  }
  @media (max-width: 330px) {
    display: none;
  }
`;

const NavSearchAmountInput = ({
  amountBorder,
  setAmountBorder,
  merchantFocused,
  amountRef,
  setSelectedAmount,
  selectedAmount,
  mobileView,
  setAmountFocused,
  showMenu,
  setShowMenu,
  amountFocused
}) => {
  const handleFocus = () => {
    setAmountFocused(true);
    setAmountBorder("2px solid black !important");
    if (showMenu) {
      setShowMenu(false);
    }
  };
  const handleBlur = () => {
    setAmountFocused(false);
    setAmountBorder("solid 2px rgba(31, 32, 33, 0.1)");
  };
  const handleChange = e => {
    setSelectedAmount(e.target.value);
  };
  return (
    <AmountInputDiv
      className="remove-number-input-arrows"
      amountBorder={amountBorder}
      merchantFocused={merchantFocused}
      amountFocused={amountFocused}
    >
      <Dollar>$</Dollar>
      <AmountInput
        ref={amountRef}
        onChange={handleChange}
        value={selectedAmount}
        type="number"
        placeholder={mobileView ? "Amount" : "Amount (optional)"}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </AmountInputDiv>
  );
};

export default NavSearchAmountInput;
