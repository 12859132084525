import React from "react";
import MastImage from "./MastImage";
import Resizer from "@hocs/Resizer";
import { useSelector } from "react-redux";

const LargeScreenMast = () => {
  const userMobile = useSelector(state => state.config.userAgent.isMobile);

  return (
    <Resizer
      components={[
        {
          component: () => <MastImage />,
          break: 575,
          maxBreak: Number.MAX_SAFE_INTEGER
        }
      ]}
      defaultTrue={userMobile ? false : true}
    />
  );
};

export default LargeScreenMast;
