import { ADD_TO_PRELOAD } from "@constants/actionTypes";

const initialState = {
  preloadArray: []
};

const preload = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_PRELOAD:
      return {
        ...state,
        preloadArray: [...state.preloadArray, action.payload]
      };

    default:
      return state;
  }
};
export default preload;
