import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { IoIosPricetag } from "react-icons/io";
import ReactLazy from "./ReactLazy";

const PercentSpan = styled.span`
  width: 131px;
  height: 36px;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media (max-width: 1430px) {
    width: 120px;
    font-size: 13px;
  }
  @media (max-width: 1375px) {
    height: 29px;
  }
`;

const PercentAmountSpan = styled.span`
  margin-left: 4px;
`;

const BrandInfo = styled.p`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  margin: 0 auto;
  @media (max-width: 1880px) {
    width: 100%;
  }
  @media (max-width: 1430px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr 1fr;
    width: 90%;
  }
`;

const CardDetailsDiv = styled.div`
  margin-top: 40px;
  @media (max-width: 1150px) {
    margin-top: 25px;
  }
`;

const AmountDisplayContainer = styled.span`
  display: grid;
  align-items: center;
  justify-self: end;
  @media (max-width: 1430px) {
    display: none;
  }
  @media (max-width: 575px) {
    display: initial;
    justify-self: center;
  }
`;

const AmountInnerSpan = styled.span`
  color: #777984;
  font-weight: bold;
  display: flex;
  font-size: 15px;
  @media (max-width: 1480px) {
    font-size: 13px;
  }
`;

const CardMainContainer = styled.div`
  box-shadow: 0 20px 80px 0 rgba(119, 121, 132, 0.1);
  background-color: #ffffff;
`;

const LineDiv = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 1px;
  opacity: 0.15;
  background-image: linear-gradient(
    to right,
    rgba(66, 67, 69, 0.3),
    #1f2021 51%,
    rgba(31, 32, 33, 0.3)
  );
`;

const BuyNowP = styled.p`
  width: 80%;
  height: 72px;
  border-radius: 36px;
  box-shadow: 0 9px 18px 0 rgba(253, 165, 54, 0.3);
  background-color: #ff9c1b;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  &:hover {
    box-shadow: 0 9px 18px 0 rgba(253, 165, 54, 0.3);
    background-color: #f4961b;
  }
  @media (max-width: 1600px) {
    margin-top: 24px;
    height: 62px;
  }
  @media (max-width: 1375px) {
    width: 72%;
    height: 55px;
    margin-top: 23px;
  }
  @media (max-width: 1150px) {
    width: 64%;
    height: 49px;
    margin-top: 19px;
    font-size: 14px;
  }
  @media (max-width: 950px) {
    margin-top: 30px;
    width: 80%;
    height: 72px;
    font-size: 16px;
  }
`;

const PopUpDiv = styled.div`
  transition: height 0.2s ease-in-out;
  max-height: 0px;
  height: 0px;
  overflow: hidden;
  @media (max-width: 950px) {
    position: absolute;
    z-index: 102;
    background-color: inherit;
    box-shadow: rgba(119, 121, 132, 0.1) 0px 20px 80px 0px;
    width: 100%;
  }
`;

const Circle = styled.div`
  width: 42px;
  height: 42px;
  opacity: 0.2;
  border: solid 1px #ff9c1b;
  position: absolute;
  border-radius: 50%;
  right: 83px;
  top: -18px;
  z-index: -1;
`;
const Flame = styled.img`
  position: absolute;
  right: 13px;
  top: -66px;
`;
const CardImage = styled.img`
  margin-bottom: -43px;
  width: 43%;
  height: auto;
  @media (max-width: 1600px) {
    width: 50%;
  }
  @media (max-width: 1375px) {
    width: 43%;
  }
  @media (max-width: 1150px) {
    width: 48%;
    margin-bottom: -31px;
  }
  @media (max-width: 950px) {
    margin-bottom: -43px;
    width: 43%;
  }
  @media (max-width: 575px) {
    margin-top: 20px;
    width: 166px;
    height: 107px;
  }
  @media (max-width: 500px) {
    width: 135px;
    height: 87px;
  }
  @media (max-width: 340px) {
    width: 126px;
    height: 81px;
  }
  @media (max-width: 300px) {
    width: 116px;
    height: 74px;
  }
  @media (max-width: 280px) {
    width: 43%;
    height: auto;
  }
`;

const CardInnerContainer = styled.div`
  cursor: pointer;
  padding: 10px 15px;
  height: 151px;
  display: flex;
  align-items: center;
  @media (max-width: 1600px) {
    height: 140px;
  }
  @media (max-width: 1375px) {
    height: 122px;
  }
  @media (max-width: 1150px) {
    padding-bottom: 0px;
    height: 111px;
  }
  @media (max-width: 950px) {
    height: 151px;
  }
  @media (max-width: 575px) {
    height: 161px;
  }
  @media (max-width: 450px) {
    height: 133px;
    padding: 0;
  }
`;

const RouterLink = styled(Link)`
  @media (max-width: 950px) {
    height: fit-content;
    position: relative;
  }
`;

const FeaturedCard = ({ img, notifyOfHover, card }) => {
  const { slug, hot, upToPercentage, minFaceValue, maxFaceValue, name } = card;
  const [hovering, changeHovering] = useState(false);
  const colorOfPercent =
    upToPercentage >= 10
      ? "rgba(255, 156, 27, 0.7)"
      : "rgba(18, 178, 234, 0.7)";
  const colorOfPercentBold = upToPercentage >= 10 ? "#ff9c1b" : "#12b2ea";
  const colorOfBackground =
    upToPercentage >= 10
      ? "rgba(255, 156, 27, 0.1)"
      : "rgba(18, 178, 234, 0.1)";

  const callFunc = () => {
    if (globalThis.ga) {
      ga(
        "send",
        "event",
        "HomePage",
        "PopularStores",
        `PopularMerchant_${slug}`
      );
    }
  };

  const setHoverState = bool => {
    changeHovering(bool);
    notifyOfHover(bool);
  };

  const getCardMaxAndMin = () => {
    if (minFaceValue && maxFaceValue) {
      const minimum = minFaceValue >= 1 ? Math.round(minFaceValue) : 1;
      const maximum = Math.round(maxFaceValue);
      return (
        <>
          <IoIosPricetag
            style={{
              marginRight: "9px",
              color: "rgba(119, 121, 132, 0.4)",
              fontSize: "19px"
            }}
          />
          ${minimum} - ${maximum}
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <RouterLink
      to={`/buy-gift-cards/${slug}/`}
      style={{
        height: "fit-content",
        position: "relative",
        textDecoration: "none"
      }}
    >
      <ReactLazy height={80} offset={200}>
        <CardImage
          src={img}
          alt={`buy ${name} cards`}
          onMouseEnter={() => setHoverState(true)}
          onMouseLeave={() => setHoverState(false)}
        />
      </ReactLazy>

      {hot && (
        <>
          <Circle />
          <Flame
            src="https://cdn.cardcash.com/website/ui/elements/flame.png"
            loading="lazy"
            alt="flame"
          ></Flame>
        </>
      )}

      <CardMainContainer
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
      >
        <CardInnerContainer className="shrink-trans" onClick={callFunc}>
          <CardDetailsDiv className="fp-card-details">
            <BrandInfo>
              <PercentSpan
                style={{
                  color: colorOfPercent,
                  backgroundColor: colorOfBackground
                }}
              >
                Up to{" "}
                <PercentAmountSpan style={{ color: colorOfPercentBold }}>
                  {upToPercentage}
                </PercentAmountSpan>
                % off
              </PercentSpan>
              <AmountDisplayContainer>
                <AmountInnerSpan>{getCardMaxAndMin()}</AmountInnerSpan>
              </AmountDisplayContainer>
            </BrandInfo>
          </CardDetailsDiv>
        </CardInnerContainer>

        <PopUpDiv
          style={{
            maxHeight: hovering ? "initial" : 0,
            height:
              hovering && global.innerWidth > 1600
                ? "133px"
                : hovering && global.innerWidth > 1375
                ? "113px"
                : hovering && global.innerWidth > 1150
                ? "105px"
                : hovering && global.innerWidth > 400
                ? "133px"
                : hovering
                ? "88px"
                : 0
          }}
        >
          <LineDiv />
          <BuyNowP className="button-transition-hover-bgColor-boxShadow">
            Buy now
          </BuyNowP>
        </PopUpDiv>
      </CardMainContainer>
    </RouterLink>
  );
};

export default FeaturedCard;
