import {
  ADD_LOGOS,
  ADD_BUY_LOGOS,
  ADD_BUY_NEW_LOGOS,
  ADD_EPIC_DISCOUNTS_BANNER
} from "@constants/actionTypes";

const initialState = {
  buyMainLogo: "",
  buyNewMainLogo: "",
  epicDiscountsBanner: "",
  mainLogo: ""
  // "https://cdn.cardcash.com/website/ui/nav/logo.svg"
};

const logos = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LOGOS:
      return {
        ...state,
        mainLogo: action.payload
      };
    case ADD_BUY_LOGOS:
      return {
        ...state,
        buyMainLogo: action.payload
      };
    case ADD_BUY_NEW_LOGOS:
      return {
        ...state,
        buyNewMainLogo: action.payload
      };
    case ADD_EPIC_DISCOUNTS_BANNER:
      return {
        ...state,
        epicDiscountsBanner: action.payload
      };

    default:
      return state;
  }
};

export default logos;
