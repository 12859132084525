import currentEnv, {
  DEVELOPMENT,
  INTEGRATION,
  set,
  get
} from "./utils/currentEnv";
import React from "react";
import { hydrate } from "react-dom";
import axios from "axios";
import Routes from "./Routes";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "./reduxMiddleware/thunk";
import promise from "./reduxMiddleware/promise";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import { BrowserRouter } from "react-router-dom";
import reducers from "./reducers";
// import { createLogger } from "redux-logger";
import PackageJson from "../../package.json";
import SessionState from "@helpers/session";
import Modal from "react-modal";
import { ServiceManager } from "./modules/ServiceManager";
import reduxHook from "./modules/ReduxHook";
// import { fireOneOff } from "./modules/Emission/action";
// set project wide environment variables.
import { getConfiguration } from "@helpers/site_configuration";
import { loadableReady } from "@loadable/component";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { defaultAction } from "./actions/defaultAction";
import { SET_GOOGLE_SDK_LOADED } from "./constants/actionTypes";

set("SSR", false);

const config = getConfiguration({
  host: window.location.hostname,
  env: process.env.NODE_ENV
});

const remote = axios.create({
  baseURL: `${config.baseURL}`,
  timeout: 80000, // one minute and 20 seconds, matching latest WebAPI timout
  headers: { "x-cc-app": config.x_cc_app }, //?? what case is this invoked and configureApiCall() hasn't been called?
  responseType: "json",
  withCredentials: true,
  ssr: get("SSR")
});

const serviceManager = new ServiceManager();

let middleware = [
  thunk.withExtraArguments(remote, serviceManager),
  promise,
  reduxHook
  // config.devMode !== "production"
  //   ? createLogger({
  //       collapsed: true
  //     })
  //   : false
].filter(Boolean);

// let devToolFunc =
//   "function" === typeof global.__REDUX_DEVTOOLS_EXTENSION__
//     ? global.__REDUX_DEVTOOLS_EXTENSION__()
//     : f => f;

const composeEnhancers =
  typeof window === "object" &&
  config.devMode !== "production" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
    : compose;

const store = createStore(
  reducers,
  window.INITIAL_STATE,
  composeEnhancers(applyMiddleware(...middleware))
);

// if (global.document) {
//   const matches = document.cookie.match(/vlid=(\w+)/);
//   if (matches) {
//     // NOTE: This is a temp fix.
//     setTimeout(() => {
//       store.dispatch(fireOneOff("SESSION_CONFIRMED", { variant: matches[1] }));
//     }, 1000);
//   }
// }

// Services need to have the appropriate configs loaded before the application starts.
store.dispatch({ type: "load_config", payload: config });

// Setup ServiceManager
serviceManager.setupStore(store);
serviceManager.initializeServices();

// Axios Session Interceptor.
const sessionState = new SessionState();
sessionState.attach({
  axios: remote,
  store
});
sessionState.initializeExpirationWarningMessage();
sessionState.initializeExpiredSessionMessage();
sessionState.initializeAddExpirationRenewOnActionMessage();
global.sessionState = sessionState;
// Setting some development globals.

switch (currentEnv()) {
  case DEVELOPMENT:
  case INTEGRATION:
    global.store = store;
    global.sessionState = sessionState;
    global.serviceManager = serviceManager;
    global.remote = remote;
    break;
}

console.info("Application Version: ", PackageJson.version);

// Here comes the DOM!
Modal.setAppElement("#root");
loadableReady(() => {
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <GoogleOAuthProvider
          clientId="186752924547-ukacunlg88a3m9s1i9p8flovg3lrql6i"
          onScriptLoadSuccess={() =>
            store.dispatch(defaultAction(SET_GOOGLE_SDK_LOADED))
          }
        >
          <div>{renderRoutes(Routes, { sessionState, store })}</div>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </Provider>,
    document.querySelector("#root")
  );
});
