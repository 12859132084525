/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React from "react";
import { toggle } from "./state";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { NAV_IMG } from "@modules/Images";

const NameSpan = styled.span`
  &:before {
    content: "Hello,";
  }
  @media (max-width: 950px) {
    &:before {
      content: "";
    }
  }
  @media (max-width: 999px) {
    &:before {
      content: "Hello,";
    }
  }
`;

const ArrowToggle = () => {
  const isOpen = useSelector(state => state.nav.accountGroup.isOpen);

  return <img src={NAV_IMG(`chevron-${isOpen ? "up" : "down"}.svg`)} alt="" />;
};

const AccountDropdown = () => {
  const dispatch = useDispatch();
  const firstname = useSelector(state => state.initAppReducer.firstname);

  return (
    <button
      type="button"
      onClick={() => dispatch(toggle())}
      css={{
        all: "unset",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "1rem",
        width: "187px",
        padding: "1rem",
        boxSizing: "border-box",
        ":focus": {
          outline: "none"
        }
      }}
    >
      <NameSpan>&nbsp;{firstname}</NameSpan>
      <ArrowToggle />
    </button>
  );
};

export default AccountDropdown;
