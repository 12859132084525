import React from "react";
import styled from "@emotion/styled";

const Link = styled.a`
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 1300px) {
    display: grid;
    align-content: center;
  }
  @media (max-width: 1300px) {
    justify-content: center;
  }
`;

const ImageLink = styled.img`
  height: ${props => props.setHeight};
  width: ${props => props.setWidth};

  @media (max-width: 1300px) {
    max-width: 100%;
    max-height: 30px;
  }
`;

const FeaturedIn = ({
  media,
  link,
  position,
  image,
  brand,
  classNames,
  height,
  width
}) => (
  <Link
    className={`featured-in-link-${media}`}
    target="_blank"
    rel="noreferrer"
    onClick={() => {
      if (link && position) {
        ga && ga("send", "event", "outboundLinks", "article", link, position);
      }
    }}
    href={link}
  >
    <ImageLink
      src={image}
      className={classNames}
      setHeight={height}
      setWidth={width}
      loading="lazy"
      alt={brand}
    />
  </Link>
);
export default FeaturedIn;
