import {
  SET_CARD_KNOX_FORM,
  CLEAR_CARD_KNOX,
  SET_CARD_KNOX_SUBMITTING,
  SET_CARD_KNOX_LOADED
} from "@constants/actionTypes";

const initialState = {
  "card-number": { initialTouch: true },
  cvv: { initialTouch: true },
  submitting: false,
  loaded: false
};

const cardKnox = (state = initialState, action) => {
  switch (action.type) {
    case SET_CARD_KNOX_FORM:
      return {
        ...state,
        ...action.payload
      };
    case SET_CARD_KNOX_SUBMITTING:
      return {
        ...state,
        submitting: action.payload
      };
    case SET_CARD_KNOX_LOADED:
      return {
        ...state,
        loaded: action.payload
      };

    case CLEAR_CARD_KNOX:
      return { ...initialState, loaded: state.loaded };
    default:
      return state;
  }
};

export default cardKnox;
