import {
  SET_BUY_PAYMENT_METHODS,
  SET_ALL_PAYMENT_METHODS,
  SET_SELL_PAYMENT_METHODS,
  SET_SELECTED_PAYMENT_ID
} from "@constants/actionTypes";
import { sendBugsnagError } from "@utils/Bugsnag";
import { uniqBy } from "lodash";
import { defaultAction } from "./defaultAction";

export const getPaymentMethods = () => async (dispatch, getState, api) => {
  try {
    const results = await api.get("v3/customers/payment-options");
    const dataArray = dispatch(storePaymentMethods(results));
    return dataArray;
  } catch (error) {
    sendBugsnagError(error, "actions/getPaymentMethods");
  }
};

export const storePaymentMethods = results => async (dispatch, getState) => {
  let dataArray = results.data.data.buy.items.map(item => {
    return {
      ...item,
      paymentMethod:
        item.paymentMethod === "BITCOIN" ? "CRYPTO" : item.paymentMethod
    };
  });
  const sellDataArray = results.data.data.sell.items;
  await dispatch(defaultAction(SET_SELL_PAYMENT_METHODS, sellDataArray));
  const totalMethodArray = uniqBy([...dataArray, ...sellDataArray], value =>
    [value.id].join()
  );
  await dispatch(defaultAction(SET_ALL_PAYMENT_METHODS, totalMethodArray));
  dataArray = filterAchAuthorized(
    dataArray,
    getState().initAppReducer.achAuthorized
  );
  await dispatch(defaultAction(SET_BUY_PAYMENT_METHODS, dataArray));
  const defaultPayment = dataArray.find(item => item.default === true);
  if (defaultPayment) {
    dispatch(defaultAction(SET_SELECTED_PAYMENT_ID, defaultPayment.id));
  }
  return dataArray;
};

const filterAchAuthorized = (dataArray, auth) => {
  return auth
    ? dataArray
    : dataArray.filter(
        item => !item.paymentMethod.toLowerCase().startsWith("ach")
      );
};
