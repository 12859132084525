import { defaultAction } from "./defaultAction";
import { SHOW_QUICK_BUY_RESULT_MODAL } from "@constants/actionTypes";

export const showQuickBuySearchResults = () => async dispatch => {
  dispatch(defaultAction(SHOW_QUICK_BUY_RESULT_MODAL, true));
};

export const getHomeSearchState = () => async (dispatch, getState) => {
  return getState().nav.initNav.homeSearchOpen;
};

export const getQuickSearchState = () => async (dispatch, getState) => {
  return getState().nav.initNav.quickSearchOpen;
};
