import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import AccountGroupNotAttempted from "./AccountGroupNotAttempted";
import AccountGroupNotSignedIn from "./AccountGroupNotSignedIn";
import AccountGroupSignedIn from "./AccountGroupSignedIn";

const AccountGroup = ({ compact, cartView, payoutView }) => {
  const signedIn = useSelector(state => state.initAppReducer.signedIn);
  const signInAttempted = useSelector(
    state => state.initAppReducer.signInAttempted
  );

  return (
    <Fragment>
      {!signInAttempted ? (
        <AccountGroupNotAttempted />
      ) : (
        <>
          {!signedIn && <AccountGroupNotSignedIn compact={compact} />}
          {signedIn && (
            <AccountGroupSignedIn
              cartView={cartView}
              payoutView={payoutView}
              compact={compact}
            />
          )}
        </>
      )}
    </Fragment>
  );
};

export default AccountGroup;
