import {
  SET_NEW_BUY_IS_LOADING,
  SET_DROPDOWN_STATE,
  RESET,
  RESET_EXPIRE
} from "@constants/actionTypes";

const initialState = {
  isLoading: false,
  dropdownState: false
};

const newGiftCardPartial = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_BUY_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case SET_DROPDOWN_STATE:
      return {
        ...state,
        dropdownState: action.payload
      };

    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};

export default newGiftCardPartial;
