import React from "react";
import loadable from "@loadable/component";
import { Redirect } from "react-router-dom";
import App from "./App";
import currentEnv, { PRODUCTION } from "@utils/currentEnv";

const Home = loadable(() =>
  import(/* webpackChunkName: "Home", webpackPrefetch: true */ "./pages/Home")
);
const AboutUs = loadable(() =>
  import(/* webpackChunkName: "AboutUs" */ "./pages/AboutUs")
);
const Unsubscribe = loadable(() =>
  import(/* webpackChunkName: "Unsubscribe" */ "./pages/Unsubscribe")
);
const TermsOfService = loadable(() =>
  import(/* webpackChunkName: "TermsOfService" */ "./pages/TermsOfService")
);
const FidelityTermsOfService = loadable(() =>
  import(
    /* webpackChunkName: "FidelityTermsOfService" */ "./pages/TermsOfServiceFidelity"
  )
);
const FAQs = loadable(() =>
  import(/* webpackChunkName: "FAQs" */ "./pages/FAQs")
);
const GivingFAQs = loadable(() =>
  import(/* webpackChunkName: "GivingFAQs" */ "./pages/GivingFAQs")
);
const GiftOfCollegeFAQs = loadable(() =>
  import(
    /* webpackChunkName: "GiftOfCollegeFAQs" */ "./pages/GiftOfCollegeFAQs"
  )
);
const Giving = loadable(() =>
  import(/* webpackChunkName: "Giving" */ "./pages/Giving")
);
const BulkBuy = loadable(() =>
  import(/* webpackChunkName: "Giving" */ "./pages/BulkBuy")
);
const BulkBuyThankYou = loadable(() =>
  import(/* webpackChunkName: "BulkBuyThankYou" */ "./pages/BulkBuyThankYou")
);
const ExchangeLp = loadable(() =>
  import(/* webpackChunkName: "ExchangeLp" */ "./pages/ExchangeLp")
);
const BulkSell = loadable(() =>
  import(/* webpackChunkName: "BulkSell" */ "./pages/BulkSell")
);
const WhyWeDenyFraud = loadable(() =>
  import(/* webpackChunkName: "WhyWeDenyFraud" */ "./pages/WhyWeDenyFraud")
);
const GiftCardStatistics = loadable(() =>
  import(
    /* webpackChunkName: "GiftCardStatistics" */ "./pages/GiftCardStatistics"
  )
);
const PrivacyPolicy = loadable(() =>
  import(/* webpackChunkName: "PrivacyPolicy" */ "./pages/PrivacyPolicy")
);
const NewBuy = loadable(() =>
  import(/* webpackChunkName: "NewBuy" */ "./pages/NewBuy")
);
const GiftOrderOrCategory = loadable(() =>
  import(
    /* webpackChunkName: "GiftOrderOrCategory" */ "./pages/GiftOrderOrCategory"
  )
);
const DiscountBuy = loadable(() =>
  import(
    /* webpackChunkName: "DiscountBuy", webpackPrefetch: true */ "./pages/DiscountBuy"
  )
);
const MerchantDetailOrCategory = loadable(() =>
  import(
    /* webpackChunkName: "MerchantDetailOrCategory" */ "./pages/MerchantDetailOrCategory"
  )
);
const SuperDiscountBrands = loadable(() =>
  import(
    /* webpackChunkName: "SuperDiscountBrands" */ "./pages/SuperDiscountBrands"
  )
);
const SignUp = loadable(() =>
  import(/* webpackChunkName: "SignUp" */ "./pages/SignUp")
);
const Guarentee = loadable(() =>
  import(/* webpackChunkName: "Guarentee" */ "./pages/Guarentee")
);
const ContactUs = loadable(() =>
  import(/* webpackChunkName: "ContactUs" */ "./pages/ContactUs")
);
const Accessibility = loadable(() =>
  import(/* webpackChunkName: "Accessibility" */ "./pages/Accessibility")
);
const FraudWarning = loadable(() =>
  import(/* webpackChunkName: "FraudWarning" */ "./pages/FraudWarning")
);
const OrderStatus = loadable(() =>
  import(/* webpackChunkName: "OrderStatus" */ "./pages/OrderStatus")
);
const SignIn = loadable(() =>
  import(/* webpackChunkName: "SignIn" */ "./pages/SignIn")
);
const Subscribe = loadable(() =>
  import(/* webpackChunkName: "Subscribe" */ "./pages/Subscribe")
);
const ForgotPassword = loadable(() =>
  import(/* webpackChunkName: "ForgotPassword" */ "./pages/ForgotPassword")
);
const StepOneSell = loadable(() =>
  import(/* webpackChunkName: "StepOneSell" */ "./pages/StepOneSell")
);
const StepTwoSell = loadable(() =>
  import(/* webpackChunkName: "StepTwoSell" */ "./pages/StepTwoSell")
);
const HowTo = loadable(() =>
  import(/* webpackChunkName: "HowTo" */ "./pages/HowTo")
);
const Tips = loadable(() =>
  import(/* webpackChunkName: "Tips" */ "./pages/Tips")
);
const Cart = loadable(() =>
  import(/* webpackChunkName: "Cart" */ "./pages/Cart")
);
const Payout = loadable(() =>
  import(/* webpackChunkName: "Payout" */ "./pages/Payout")
);
const Account = loadable(() =>
  import(/* webpackChunkName: "Account" */ "./pages/Account")
);
const ConsignmentAgreement = loadable(() =>
  import(
    /* webpackChunkName: "ConsignmentAgreement" */ "./pages/ConsignmentAgreement"
  )
);
const SellOrderComplete = loadable(() =>
  import(
    /* webpackChunkName: "SellOrderComplete" */ "./pages/SellOrderComplete"
  )
);
const Checkout = loadable(() =>
  import(/* webpackChunkName: "Checkout" */ "./pages/Checkout")
);
const Receipt = loadable(() =>
  import(/* webpackChunkName: "Receipt" */ "./pages/Receipt")
);
const CheckToken = loadable(() =>
  import(/* webpackChunkName: "CheckToken" */ "./pages/CheckToken")
);
const OrderVerification = loadable(() =>
  import(
    /* webpackChunkName: "OrderVerification" */ "./modules/OrderVerification/index"
  )
);
const ReferAFriend = loadable(() =>
  import(/* webpackChunkName: "ReferAFriend" */ "./pages/ReferAFriend")
);
const NotFound = loadable(() =>
  import(/* webpackChunkName: "NotFound" */ "./pages/NotFound")
);
const Wallet = loadable(() =>
  import(/* webpackChunkName: "Wallet" */ "./pages/Wallet")
);
const BuyOrders = loadable(() =>
  import(/* webpackChunkName: "BuyOrders" */ "./components/BuyOrders")
);
const SellOrders = loadable(() =>
  import(/* webpackChunkName: "SellOrders" */ "./components/SellOrders")
);
const ConsignmentOrders = loadable(() =>
  import(
    /* webpackChunkName: "ConsignmentOrders" */ "./components/ConsignmentOrders"
  )
);

const Routes = [
  {
    ...App,
    routes: [
      {
        component: Home,
        path: "/",
        exact: true,
        loadData: () => {
          return "home";
        }
      },
      {
        component: AboutUs,
        path: "/about/",
        exact: true
      },
      {
        component: Unsubscribe,
        path: "/unsubscribe/",
        exact: true
      },
      {
        component: TermsOfService,
        path: "/tos/"
      },
      {
        component: FidelityTermsOfService,
        path: "/f-tos/"
      },
      {
        component: FAQs,
        path: "/faqs/"
      },
      {
        component: GivingFAQs,
        path: "/giving/faqs/"
      },
      {
        component: GiftOfCollegeFAQs,
        path: "/giving/GiftOfCollege/"
      },
      {
        component: Giving,
        path: "/giving/"
      },
      {
        component: BulkBuy,
        path: "/bulk-buy-gift-cards/",
        exact: true
      },
      {
        component: BulkBuyThankYou,
        path: "/bulk-buy-gift-cards/thank-you/",
        exact: true
      },
      {
        component: ExchangeLp,
        path: "/partnerships/"
      },
      {
        component: BulkSell,
        path: "/bulk-sell-gift-cards/"
      },
      {
        component: WhyWeDenyFraud,
        path: "/fraud-denial/"
      },
      {
        component: GiftCardStatistics,
        path: "/gift-card-statistics/",
        loadData: () => {
          return "statistics";
        }
      },
      {
        component: PrivacyPolicy,
        path: "/privacy-policy/"
      },
      {
        component: Guarentee,
        path: "/guarantee/"
      },
      {
        component: ContactUs,
        path: "/contact/"
      },
      {
        component: Accessibility,
        path: "/accessibility-statement/"
      },
      {
        component: FraudWarning,
        path: "/gift-card-scams/"
      },
      {
        component: OrderStatus,
        path: "/order-status/:param?/"
      },
      {
        component: SignIn,
        path: "/login/"
      },
      {
        component: Subscribe,
        path: "/subscribe/"
      },
      {
        component: ForgotPassword,
        path: "/pass-assist/"
      },
      {
        component: SignUp,
        path: "/sign-up/",
        loadData: () => {
          return "";
        }
      },
      {
        component: GiftOrderOrCategory,
        path: "/buy-gift-cards/new/:id/",
        loadData: () => {
          return "newCategoryBuy";
        }
      },
      {
        component: NewBuy,
        path: "/buy-gift-cards/new/",
        loadData: () => {
          return "newBuy";
        }
      },
      {
        component: () =>
          (window.location = `https://${
            currentEnv() === PRODUCTION ? "" : "test-"
          }betmgm.cardcash.com`),
        path: "/buy-gift-cards/discount-betmgm-cards/",
        exact: true
      },
      {
        component: SuperDiscountBrands,
        path: "/buy-gift-cards/epic-discounts/",
        exact: true,
        loadData: () => {
          return "discountBuy";
        }
      },
      {
        component: MerchantDetailOrCategory,
        path: "/buy-gift-cards/:id/",
        loadData: () => {
          return "discountCategoryBuy";
        }
      },
      {
        component: DiscountBuy,
        path: "/buy-gift-cards/",
        loadData: () => {
          return "discountBuy";
        }
      },
      {
        component: StepOneSell,
        path: "/sell-gift-cards/"
      },
      {
        component: StepTwoSell,
        path: "/step-two/"
      },
      {
        component: HowTo,
        path: "/save-with-gift-cards/"
      },
      {
        component: Tips,
        path: "/savings-tips/"
      },
      {
        component: Cart,
        path: "/cart/"
      },
      {
        component: Payout,
        path: "/payout/"
      },
      {
        component: Account,
        path: "/account/",
        routes: [
          {
            path: "/account/buy/",
            component: BuyOrders
          },
          {
            path: "/account/sell/",
            component: SellOrders
          },
          {
            path: "/account/listed",
            component: ConsignmentOrders
          }
        ]
      },
      {
        component: ConsignmentAgreement,
        path: "/listing-agreement/"
      },
      {
        component: SellOrderComplete,
        path: "/order-complete/"
      },
      {
        component: Checkout,
        path: "/checkout/"
      },
      {
        component: Receipt,
        path: "/receipt/"
      },
      {
        component: CheckToken,
        path: "/forgot-password/:id/"
      },
      {
        component: OrderVerification,
        path: "/orders/validation/:token/"
      },
      {
        component: ReferAFriend,
        path: "/refer/"
      },
      {
        component: Wallet,
        path: "/wallet/"
      },
      {
        path: "/sell/",
        component: () => <Redirect to="/sell-gift-cards/" />
      },
      {
        path: "/buy/",
        component: () => <Redirect to="/buy-gift-cards/" />
      },
      {
        component: NotFound
      }
    ]
  }
];

export default Routes;
