import { RECEIVE_NEW_MERCHANTS } from "@constants/actionTypes";

const merchantsNew = (
  state = {
    sortedByName: []
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_NEW_MERCHANTS:
      let newState = { ...state, sortedByName: action.payload };
      return newState.sortedByName.reduce((obj, merchant) => {
        obj[merchant.id] = merchant;
        return obj;
      }, newState);

    default:
      return state;
  }
};

export default merchantsNew;
