const loadScripts = (
  tagSrc,
  tagNameId,
  callback,
  additionnalAttributes = []
) => {
  const existingScript = document.getElementById(tagNameId);
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = tagSrc;
    script.id = tagNameId;
    script.setAttribute("defer", "");
    if (additionnalAttributes.length > 0) {
      additionnalAttributes.forEach(att => {
        script.setAttribute(att.attName, att.attValue);
      });
    }
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback("existing");
};

const loadLinks = (tagHref, tagNameId, callback) => {
  const existingLink = document.getElementById(tagNameId);
  if (!existingLink) {
    const link = document.createElement("link");
    link.href = tagHref;
    link.id = tagNameId;
    link.rel = "stylesheet";
    link.type = "text/css";
    document.body.appendChild(link);
    link.onload = () => {
      if (callback) callback();
    };
  }
  if (existingLink && callback) callback();
};

const loadScriptsWithPromise = (tagSrc, tagNameId, callback) => {
  const scriptProm = new Promise(resolve => {
    const existingScript = document.getElementById(tagNameId);
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = tagSrc;
      script.id = tagNameId;
      script.setAttribute("defer", "");
      document.body.appendChild(script);
      script.onload = resolve;
    }
    if (existingScript) resolve();
  });
  return Promise.race([
    scriptProm,
    new Promise((res, rej) => setTimeout(rej, 5000))
  ]);
};
export { loadScripts, loadLinks, loadScriptsWithPromise };
