import { SET_COOKIES_ENABLED } from "@constants/actionTypes";

const initialState = {
  cookiesEnabled: true
};

const cookies = (state = initialState, action) => {
  switch (action.type) {
    case SET_COOKIES_ENABLED:
      return {
        ...state,
        cookiesEnabled: action.payload
      };
    default:
      return state;
  }
};

export default cookies;
