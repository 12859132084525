import {
  SET_CUSTOMER_PROFILE,
  RESET,
  RESET_EXPIRE
} from "@constants/actionTypes";

const initialState = {};

const shippingEdit = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_PROFILE:
      const { customer } = action.payload;
      let phoneExists = action.payload.customer.phone.length;
      let addressExists = action.payload.customer.address.length;
      if (addressExists && action.payload.customer.address[0].street2 != null) {
        return {
          ...state,
          confirmFirstname: customer.firstname,
          confirmLastname: customer.lastname,
          confirmEmail: customer.email,
          confirmPhone: phoneExists ? customer.phone[0].number.toString() : "",
          confirmState: addressExists ? customer.address[0].state : "",
          confirmCity: addressExists ? customer.address[0].city : "",
          confirmZip: addressExists ? customer.address[0].postcode : "",
          confirmStreet: addressExists ? customer.address[0].street : "",
          confirmStreet2: addressExists ? customer.address[0].street2 : ""
        };
      } else {
        return {
          ...state,
          confirmFirstname: customer.firstname,
          confirmLastname: customer.lastname,
          confirmEmail: customer.email,
          confirmPhone: phoneExists ? customer.phone[0].number.toString() : "",
          confirmState: addressExists ? customer.address[0].state : "",
          confirmCity: addressExists ? customer.address[0].city : "",
          confirmZip: addressExists ? customer.address[0].postcode : "",
          confirmStreet: addressExists ? customer.address[0].street : ""
        };
      }

    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};

export default shippingEdit;
