import {
  CHECKOUT_ORDER_SELL_MARKORDERLOADING,
  CHECKOUT_ORDER_SELL_SETORDER,
  CHECKOUT_ORDER_SELL_SETCARDS,
  RESET,
  RESET_EXPIRE,
  RESET_SELL,
  COMPLETE_ORDER_SELL
} from "@constants/actionTypes";

const initialState = {
  allSellCards: []
};

const checkoutOrdersSell = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case CHECKOUT_ORDER_SELL_MARKORDERLOADING:
      newState = { ...state };
      newState[action.payload] = {
        ...newState[action.payload],
        _thunkRunning: true
      };
      return newState; //?? consolidate after debugging

    case CHECKOUT_ORDER_SELL_SETORDER:
      // Used Object.assign() below because the spread operator did not function as
      // expected when transpiled by Babel on 2017-3-2.
      //
      // The new state for the specific order reference ID is the composition of (in
      // this order):
      // 1. whatever was found in state for this referenceID
      // 2. overlay the new data to add/overwrite
      // 3. force the object to contain _thunkRunning with value false
      return {
        ...state,
        [action.payload.sellOrder.id]: {
          ...state[action.payload.sellOrder.id],
          ...action.payload.sellOrder,
          _thunkRunning: false
        }
      };

    case CHECKOUT_ORDER_SELL_SETCARDS:
      let newState = { ...state };

      if (Array.isArray(action.payload)) {
        action.payload.map(sellCard => {
          let orderID = sellCard.order.id;
          if (sellCard.id) {
            // bogus return from WebAPI sometimes gives cards will null ID. Skip.
            if (!newState[orderID]) {
              // initilize a dummy order object with just enough properties so UI will not fail to render
              newState[orderID] = {
                _thunkRunning: false,
                date: {
                  placed: "0000-00-00 00:00:00"
                }
              };
            }
            if (!newState[orderID].cards) {
              newState[orderID].cards = [];
            }
            newState[orderID].cards.push(sellCard.id);
          }
        });
        newState.allSellCards = action.payload;
      }

      return newState;

    case RESET:
    case RESET_EXPIRE:
    case RESET_SELL:
    case COMPLETE_ORDER_SELL:
      return initialState;

    default:
      return state;
  }
};

export default checkoutOrdersSell;
