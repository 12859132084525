import { path as walk } from "ramda";

const DEFAULT_OPTIONS = {
  resolvePath: null
};

// NOTE: This code is a bit wet.
const pendingGet = (store, path, condition, options = DEFAULT_OPTIONS) => {
  options = { ...DEFAULT_OPTIONS, ...options };

  return new Promise(resolve => {
    const state = store.getState();
    const value = walk(path, state);

    if (condition(value)) {
      if (options.resolvePath) {
        return resolve(walk(options.resolvePath, state));
      }
      resolve(value);
    }

    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const value = walk(path, state);

      if (condition(value)) {
        unsubscribe();
        if (options.resolvePath) {
          return resolve(walk(options.resolvePath, state));
        }
        resolve(value);
      }
    });
  });
};
export default pendingGet;
