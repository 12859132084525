import React, { useState } from "react";
import styled from "@emotion/styled";
import { ClearedStyleInput } from "@modules/Components";
import { email } from "@components/validation";
import { useDispatch } from "react-redux";
import { subscribe } from "@actions/subscribe";
import { trimString } from "@utils";
import { FaCheck } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";

const SignUpEmailP = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #777984;
  margin-bottom: 24px;

  @media (max-width: 320px) {
    font-size: 13px;
  }
`;

const SignUpEmailInputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 768px;
`;

const Success = styled.div`
  width: 320px;
  height: 56px;
  border-radius: 5px;
  box-shadow: 0 20px 70px 0 rgba(34, 35, 36, 0.12);
  background-color: #ffffff;
  padding-left: 24px;
  color: rgba(31, 32, 33, 0.5);
  display: flex;
  align-items: center;

  @media (max-width: 1040px) {
    width: 100%;
  }
`;

const SignUpEmailInput = styled(ClearedStyleInput)`
  width: 320px;
  height: 56px;
  border-radius: 5px;
  box-shadow: 0 20px 70px 0 rgba(34, 35, 36, 0.12);
  background-color: #ffffff;
  padding-left: 24px;

  @media (max-width: 1040px) {
    width: 100%;
  }
`;

const SignUpEmailInputButton = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  box-shadow: ${props =>
    props.emailSubmitted ? "" : "0 9px 18px 0 rgba(255, 156, 27, 0.3)"};
  background-color: ${props =>
    props.emailSubmitted ? "rgba(2,163,132,0.1)" : "#ff9c1b"};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: ${props =>
      props.emailSubmitted ? "" : "0 9px 18px 0 rgba(253, 165, 54, 0.3)"};
    background-color: ${props =>
      props.emailSubmitted ? "rgba(2,163,132,0.1)" : "#f4961b"};
  }
`;

const SearchSpinner = styled.img`
  width: 20px;
  height: 20px;
`;

const ErrorContainer = styled.div`
  top: 3px;
  position: absolute;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  left: 24px;
  text-transform: uppercase;

  color: #ee5050;
`;

const SignUpEmail = () => {
  const dispatch = useDispatch();

  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [signupEmail, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleEmailClick = async () => {
    if (loading) return;
    const invalid = email(signupEmail);
    if (invalid) {
      setInvalidEmail(invalid);
    } else {
      setLoading(true);
      const serverResponse = await dispatch(
        subscribe({
          email: trimString(signupEmail)
        })
      );

      if (serverResponse === "success") {
        setEmailSubmitted(true);
        setInvalidEmail("");
      } else {
        setInvalidEmail("Something went wrong");
      }

      setLoading(false);
    }
  };

  return (
    <div>
      <SignUpEmailP>Sign up for our sales and savings emails</SignUpEmailP>
      <SignUpEmailInputContainer>
        {emailSubmitted ? (
          <Success>
            <div>You have been subscribed!</div>
          </Success>
        ) : (
          <SignUpEmailInput
            className="standard-placeholder"
            placeholder="Your email address..."
            value={signupEmail}
            onChange={e => setEmail(e.target.value)}
            type="email"
            aria-label="email signup"
          />
        )}

        <SignUpEmailInputButton
          onClick={handleEmailClick}
          emailSubmitted={emailSubmitted}
        >
          {loading ? (
            <SearchSpinner
              className="spinner-rotate"
              src="https://cdn.cardcash.com/website/ui/elements/spinner-white.png"
              alt=""
            />
          ) : emailSubmitted ? (
            <FaCheck
              style={{
                color: "rgb(2, 163, 132)",
                fontSize: "15px"
              }}
            />
          ) : (
            <FiArrowRight
              style={{
                color: "#ffffff",
                fontSize: "16px"
              }}
            />
          )}
        </SignUpEmailInputButton>
        {invalidEmail && (
          <ErrorContainer className={`input-header`}>
            <div>{invalidEmail}</div>
          </ErrorContainer>
        )}
      </SignUpEmailInputContainer>
    </div>
  );
};

export default SignUpEmail;
