import {
  ADD_CARD,
  DELETE_CARD,
  UPDATE_CARD_PIN,
  UPDATE_CARD_NUMBER,
  UPDATE_THE_CARD,
  DISPLAY_MAX_BALANCE_ERROR,
  DISPLAY_MIN_BALANCE_ERROR,
  DISPLAY_NO_BALANCE_ERROR,
  RESET,
  RESET_EXPIRE,
  RESET_SELL,
  CHECKOUT_ORDER_SELL_SETCARDS,
  COMPLETE_ORDER_SELL,
  CLEAR_UPDATE_CARD_SERVER_ERROR,
  SET_UPDATE_CARD_SERVER_ERROR,
  CLEAR_CARD_AND_PIN_ENTRY
} from "@constants/actionTypes";

const cards = (state = {}, action) => {
  switch (action.type) {
    case ADD_CARD:
      return { ...action.payload };

    case UPDATE_THE_CARD:
      return { ...action.payload.cartSell };

    case DELETE_CARD:
      const newStateObj = state;
      delete newStateObj[action.payload];
      return { ...newStateObj };

    case UPDATE_CARD_PIN:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          pinNumEnter: action.payload.pin
        }
      };

    case UPDATE_CARD_NUMBER:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          cardNumEnter: action.payload.number
        }
      };

    case COMPLETE_ORDER_SELL:
      return {};

    case DISPLAY_MAX_BALANCE_ERROR:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          balanceError: "MAX_BALANCE_ERROR"
        }
      };

    case DISPLAY_MIN_BALANCE_ERROR:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          balanceError: "MIN_BALANCE_ERROR"
        }
      };

    case DISPLAY_NO_BALANCE_ERROR:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          balanceError: "NO_BALANCE_ERROR"
        }
      };

    case CLEAR_UPDATE_CARD_SERVER_ERROR:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          msg: "",
          serverError: false
        }
      };

    case SET_UPDATE_CARD_SERVER_ERROR:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          msg: action.payload.msg,
          serverError: true
        }
      };

    case CLEAR_CARD_AND_PIN_ENTRY:
      return state[action.payload.cardID]
        ? {
            ...state,
            [action.payload.cardID]: {
              ...state[action.payload.cardID],
              cardNumEnter: "",
              pinNumEnter: ""
            }
          }
        : { ...state };

    case CHECKOUT_ORDER_SELL_SETCARDS:
      let newState = { ...state };

      if (Array.isArray(action.payload)) {
        action.payload.map(sellCard => {
          if (sellCard.id) {
            // bogus return from WebAPI sometimes gives cards will null ID. Skip.

            // ensure this card exists in Redux
            if (!newState[sellCard.id]) {
              newState[sellCard.id] = { id: sellCard.internalId };
            }

            // blend in
            newState[sellCard.id] = Object.assign(newState[sellCard.id], {
              enterValue: sellCard.enteredValue,
              newCard: sellCard.newCard,
              type: sellCard.type,
              cardNumLastFour: sellCard.number,
              merchantId: sellCard.merchantId
            });
          }
        });
      }

      return newState;

    // case COMPLETE_ORDER_SELL: //?? should uncomment as soon as Partner site uses checkOrdersSell rather than outdated date here
    case RESET:
    case RESET_EXPIRE:
    case RESET_SELL:
      return {};

    default:
      return state;
  }
};

export default cards;
