import { sendBugsnagError } from "@utils/Bugsnag";

export const quickBuySearch =
  (id, cardType, amount) => async (dispatch, getState, api) => {
    try {
      const { data: bundle } = await api.get(
        `/v3/merchants/${id}/bundle/?amount=${amount}&cardType=${cardType}`
      );
      return bundle;
    } catch (err) {
      sendBugsnagError(err, "actions/quickBuySearch");
      return null;
    }
  };
