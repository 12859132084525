const SET_QUERY_PARAMS = "set_query_params";
const SET_PATH_FOR_MARKETING = "set_path";

import {
  SET_UTM_CAMPAIGN_IS_ACTIVE,
  SALE_POPUP_STATUS,
  LOGOUT
} from "@constants/actionTypes";

const initialState = {
  utmCampaignIsActive: true,
  showPopup: false,
  queryParams: {},
  path: ""
};

const marketing = (state = initialState, action) => {
  switch (action.type) {
    case SET_UTM_CAMPAIGN_IS_ACTIVE:
      return {
        ...state,
        utmCampaignIsActive: action.payload
      };

    case SALE_POPUP_STATUS:
      return {
        ...state,
        showPopup: action.payload
      };

    case SET_QUERY_PARAMS:
      const queryObj = {};
      Object.keys(action.payload).forEach(item => {
        if (Array.isArray(action.payload[item])) {
          queryObj[item] =
            action.payload[item][action.payload[item].length - 1];
        } else {
          queryObj[item] = action.payload[item];
        }
      });
      return {
        ...state,
        queryParams: queryObj
      };

    case SET_PATH_FOR_MARKETING:
      return {
        ...state,
        path: action.payload
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default marketing;
