import React from "react";
import styled from "@emotion/styled";
import { ClearedStyleRouterLink } from "@modules/Components";
import { IoIosPricetag } from "react-icons/io";
import { addParamsToImage } from "@utils";

const CardLink = styled(ClearedStyleRouterLink)`
  @media (max-width: 1000px) {
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const IndividualCard = styled.div`
  box-shadow: 0 20px 80px 0 rgba(119, 121, 132, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  width: 233px;
  height: 186px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 1000px) {
    width: 315px;
    height: 147px;
  }
  @media (max-width: 450px) {
    margin: 0 auto;
  }
  @media (max-width: 350px) {
    width: 100%;
  }
  @media (max-width: 320px) {
    height: 158px;
  }
`;

const IndividualCardImageContainer = styled.div`
  text-align: center;
`;

const IndividualCardImage = styled.img`
  width: 139px;
  height: 90px;
  border-radius: 5px;
  margin-top: -55px;
`;

const CardInfo = styled.div`
  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100px;
    align-items: center;
  }
  @media (max-width: 320px) {
    display: block;
  }
`;

const Percent = styled.div`
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 139px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.7);
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 35px;
  margin-bottom: 26px;

  @media (max-width: 1000px) {
    margin: 0;
    justify-self: end;
  }
  @media (max-width: 320px) {
    margin: 0 auto;
    margin-top: 26px;
    margin-bottom: 16px;
  }
`;

const PercentBoldSpan = styled.span`
  color: #ffffff;
`;

const Price = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    justify-self: flex-start;
    margin-left: 24px;
  }
  @media (max-width: 320px) {
    margin: 0;
  }
`;

const HomeCategoryCard = ({ merchant, sliderClass }) => {
  const getCardMaxAndMin = (min, max) => {
    if (min && max) {
      const minimum = min >= 1 ? Math.round(min) : 1;
      const maximum = Math.round(max);
      return (
        <>
          <IoIosPricetag
            style={{
              marginRight: "9px",
              color: "rgba(119, 121, 132, 0.4)",
              fontSize: "19px"
            }}
          />
          ${minimum} - ${maximum}
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <CardLink
      to={`/buy-gift-cards/${merchant.slug}/`}
      className={`animated ${sliderClass}`}
    >
      <IndividualCard>
        <IndividualCardImageContainer>
          <IndividualCardImage
            src={addParamsToImage(merchant.image, "139x90")}
            alt={`${merchant.name}`}
            loading="lazy"
          />
          <CardInfo>
            <Percent>
              Up to&nbsp;
              <PercentBoldSpan>
                {merchant.upToPercentage}%&nbsp;
              </PercentBoldSpan>
              off
            </Percent>
            <Price>
              {getCardMaxAndMin(merchant.minFaceValue, merchant.maxFaceValue)}
            </Price>
          </CardInfo>
        </IndividualCardImageContainer>
      </IndividualCard>
    </CardLink>
  );
};

export default HomeCategoryCard;
