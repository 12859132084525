import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import TopMessageContent from "./TopMessageContent";
import TopMessageOpenOrder from "./TopMessageOpenOrder";
import TopMessageIpMessage from "./TopMessageIpMessage";

const compact =
  (a, b) =>
  ({ compact }) =>
    compact ? a : b;

const TopMessageV2 = styled.div`
  transition: opacity 0.1s, height 0.1s;
  height: ${compact("0px", "35px")};
  opacity: ${compact("0.0", "1.0")};
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  box-shadow: ${({ boxShadow }) => boxShadow};

  @media (max-width: 660px) {
    height: ${({ usAddress }) =>
      usAddress ? compact("0px", "35px") : compact("0px", "43px")};
  }
`;

const TopMessage = ({ scrolled }) => {
  const openOrders = useSelector(state => state.initAppReducer.openOrders);
  const country = useSelector(state => state.ipInfo.country);
  const cookiesEnabled = useSelector(state => state.cookies.cookiesEnabled);
  const usIpAddress = !country || country.toLowerCase() === "us" ? true : false;
  return (
    <>
      {openOrders.sell?.length > 0 ? (
        <TopMessageV2
          backgroundColor={"#ffffff"}
          compact={scrolled}
          boxShadow={"0 20px 70px 0 rgba(31, 32, 33, 0.12)"}
        >
          <TopMessageOpenOrder />
        </TopMessageV2>
      ) : !usIpAddress || !cookiesEnabled ? (
        <TopMessageV2
          backgroundColor={!usIpAddress ? "#cf4545" : "#777984"} // #72737C - would be more accessible
          compact={scrolled}
          usAddress={usIpAddress}
        >
          <TopMessageIpMessage />
        </TopMessageV2>
      ) : (
        <TopMessageV2
          backgroundColor={"#777984"} // #72737C - would be more accessible
          compact={scrolled}
          usAddress={usIpAddress}
        >
          <TopMessageContent />
        </TopMessageV2>
      )}
    </>
  );
};

export default TopMessage;
