/**
 * @typedef {Object} Options
 * @property {"redux" | "indexDB"} variant
 * @property {boolean} save
 *
 * @typedef {Object} AdapterParams
 * @property {Object} ctx
 * @property {string} namespace
 * @property {string} field
 * @property {boolean} save
 */

const GETTER_SETTERS_MAP = {
  redux: {
    /**
     * @param {AdapterParams} param1
     */
    getter:
      ({ ctx, namespace: _, field }) =>
      () => {
        if (ctx.store == null) throw Error("this.store must be defined.");

        // cache contains a more accurate representation of what will be in the store.
        return ctx.cache[field];
      },
    /**
     * @param {AdapterParams} param1
     */
    setter:
      ({ ctx, namespace: _, field, save = true }) =>
      value => {
        ctx.cache[field] = value;

        const creatorName = ctx.fieldName && ctx.fieldName(field);

        if (ctx.store == null || ctx.creators[creatorName] == null)
          throw Error(
            `this.store and this.creators.${creatorName} must be defined.`
          );

        if (save) {
          ctx.store.dispatch(ctx.creators[creatorName](value));
        }
      }
  }
};

const DEFAULT_OPTIONS = { variant: "redux", save: true };
export default class StoreFields {
  cache = {};

  fieldName(field) {
    return `set${field[0].toUpperCase() + field.slice(1)}`;
  }

  /**
   *
   * @param {string} namespace - The parent field in the store.
   * @param {Array<string>} fields
   * @param {Options} options
   */
  constructor(namespace, fields, options = DEFAULT_OPTIONS) {
    const { variant, save } = { ...DEFAULT_OPTIONS, ...options };

    this.namespace = namespace;
    this.fields = fields;

    const ctx = this;
    const newGettersSetters = {};
    const { getter, setter } = GETTER_SETTERS_MAP[variant];

    for (const field of fields) {
      newGettersSetters[field] = {
        get: getter({ ctx, namespace, field, save }),
        set: setter({ ctx, namespace, field, save })
      };
    }

    Object.defineProperties(this, {
      [namespace]: {
        get: () => this.store.getState()[namespace]
      },
      ...newGettersSetters
    });
  }

  initializeState() {
    const initialState = this.store.getState()[this.namespace];
    for (const field of this.fields) {
      this.cache[field] = initialState[field];
    }
  }
}
