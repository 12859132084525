import { defaultAction } from "./defaultAction";
import {
  RESET_COUPON,
  SET_BUY_CART_ID,
  UPDATE_COUPON_ERROR,
  ENABLE_COUPON,
  RESET_BUY_CART_COUPON,
  COUPON_LOADING,
  UPDATE_COUPON_ENTER
} from "@constants/actionTypes";
import { sendBugsnagError } from "@utils/Bugsnag";
import { errorResponseMessage } from "./errorResponseMessage";
import { isSchemaError } from "./isSchemaError";
const isMobileOnlyError = str => str === "coupon is mobile only";
import { setModal as setMessageModal } from "@modules/Messages/reducer";

export const deleteCoupon = () => async (dispatch, getState, api) => {
  try {
    const { cartBuy, coupon } = getState();
    const couponCode = coupon.coupon;
    await api.delete(`v3/carts/${cartBuy.cartId}/coupons/${couponCode}`);
    dispatch(defaultAction(RESET_COUPON));
    dispatch(defaultAction(UPDATE_COUPON_ERROR, ""));
    global.ga &&
      global.ga(
        "send",
        "event",
        "buyMicroConversions",
        "removeCoupon",
        couponCode
      );
  } catch (error) {
    sendBugsnagError(error, "actions/deleteCoupon");
  }
};

export const getCoupon = code => async (dispatch, getState, api) => {
  dispatch(defaultAction(COUPON_LOADING, true));
  const trimmedCode = code.trim();
  dispatch(defaultAction(UPDATE_COUPON_ENTER, trimmedCode));

  const { cartBuy } = getState();
  let cartId = cartBuy.cartId;
  try {
    if (!cartId) {
      const buyCart = await api.post("v3/carts", { action: "buy" });
      dispatch(defaultAction(SET_BUY_CART_ID, buyCart.data.cartId));
      cartId = buyCart.data.cartId;
    }
    const coupon = await api.post(`v3/carts/${cartId}/coupons/${trimmedCode}`);
    const numberOfCards = cartBuy.numCards;
    let warningMessage;
    if (numberOfCards && coupon.data.discount === 0) {
      dispatch(defaultAction(RESET_BUY_CART_COUPON));
      dispatch(defaultAction(RESET_COUPON));
      dispatch(defaultAction(COUPON_LOADING, false));

      if (coupon.data.warningMessage) {
        warningMessage = coupon.data.warningMessage;
      } else {
        warningMessage = `Coupon is valid, but does not apply to ${
          numberOfCards > 1 ? "cards" : "card"
        } in cart`;
      }
      global.ga &&
        global.ga(
          "send",
          "event",
          "buyMicroConversions",
          "errorCoupon",
          trimmedCode
        );
      return dispatch(defaultAction(UPDATE_COUPON_ERROR, warningMessage));
    }

    dispatch(defaultAction(ENABLE_COUPON, coupon.data));
    global.ga &&
      global.ga(
        "send",
        "event",
        "buyMicroConversions",
        "addCoupon",
        trimmedCode
      );
    dispatch(defaultAction(COUPON_LOADING, false));
  } catch (error) {
    dispatch(defaultAction(RESET_COUPON));
    let message = errorResponseMessage(error);
    if (isSchemaError(message)) {
      message = "Coupon Invalid";
    }

    if (isMobileOnlyError(message)) {
      dispatch(
        setMessageModal({
          title: "Hey! This coupon is only available on mobile.",
          content:
            "To use this coupon and save money now, proceed from the mobile app."
        })
      );
      global.ga &&
        global.ga(
          "send",
          "event",
          "buyMicroConversions",
          "errorCoupon",
          trimmedCode
        );
      dispatch(defaultAction(COUPON_LOADING, false));
      sendBugsnagError(error, "actions/getCoupon");
      return;
    }

    dispatch(defaultAction(UPDATE_COUPON_ERROR, message));
    global.ga &&
      global.ga(
        "send",
        "event",
        "buyMicroConversions",
        "errorCoupon",
        trimmedCode
      );
    dispatch(defaultAction(COUPON_LOADING, false));
    sendBugsnagError(error, "actions/getCoupon");
    return;
  }
};
