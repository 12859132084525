import React from "react";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { openModal } from "@modules/OrderVerification/action";
import PreloadLink from "../../../components/PreloadLink";
import { ClearedStyleRouterLink } from "@modules/Components";

const AccountOpenOrder = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  font-size: 14px;

  @media (max-width: 300px) {
    font-size: 12px;
  }
`;

const OpenOrderImage = styled.img`
  padding-bottom: 4px;
  height: 21px;
  width: auto;

  @media (max-width: 300px) {
    padding-bottom: 2px;
    height: 16px;
  }
`;

const OpenOrderText = styled.div`
  color: #1f2021;
  margin-right: 10px;
  margin-left: 10px;

  @media (max-width: 300px) {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const LinkContainer = styled.div`
  font-weight: bold;
  color: #12b2ea;
`;

const OpenOrderLink = styled(ClearedStyleRouterLink)`
  font-weight: bold;
  color: #12b2ea;
  cursor: pointer;
`;

const TopMessageOpenOrder = () => {
  const dispatch = useDispatch();

  return (
    <AccountOpenOrder>
      <OpenOrderImage
        src="https://cdn.cardcash.com/website/ui/elements/shopping-cart-empty-1.svg"
        alt=""
      />
      <OpenOrderText>You have a order pending</OpenOrderText>
      <LinkContainer onClick={() => dispatch(openModal())}>
        <PreloadLink page={"Account"}>
          <OpenOrderLink to="/account/sell/">Complete now</OpenOrderLink>
        </PreloadLink>
      </LinkContainer>
    </AccountOpenOrder>
  );
};

export default TopMessageOpenOrder;
