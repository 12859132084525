import React from "react";
import NavLinks from "./NavLinks";

const LargeNavLinks = ({ scrolled, loadablePage, route, linkText, type }) => {
  return (
    <NavLinks
      scrolled={scrolled}
      loadablePage={loadablePage}
      route={route}
      linkText={linkText}
      type={type}
    />
  );
};
export default LargeNavLinks;
