import axios from "axios";
import { Observable } from "rxjs";
import { both, complement, isEmpty, isNil, last } from "ramda";
import {
  setPending,
  setRenewalTime,
  setRenderAssets,
  setSessionId,
  setFailed
} from "@client/reducers/sessionReducer";
import StoreFields from "./StoreFields";
import { defaultAction } from "../../src/client/actions/defaultAction";
import {
  SET_SESSION_ID,
  RESET_EXPIRE,
  SET_SESSION_MESSAGE,
  ANYCARD_IFRAME_OPENED
} from "@client/constants/actionTypes";

import currentEnv, { DEVELOPMENT, get } from "@client/utils/currentEnv";
import queryParams from "@client/utils/queryParams";
import pendingGet from "@client/utils/pendingGet";

const THREE_MINUTES = 3 * 60;
const ONE_MINUTE = 60;

const secondsToMilliseconds = value => value * 1000;

// tools
const wait = time =>
  new Observable(observer => {
    setTimeout(() => {
      observer.next(time);
      observer.complete();
    }, time);
  });

// Legacy Action Creator.
const setSessionIdLegacy = sessionId => {
  console.warn("Deprecated: SET_SESSION_ID");
  return defaultAction(SET_SESSION_ID, sessionId);
};

class SessionState extends StoreFields {
  static TAG = "helpers.session";

  _callbacks = [];
  _callbacksExpired = [];
  _expirationRenewOnActionSubscriptioncallback = [];

  constructor() {
    // NOTE: the strings in connected fields are variables that reflect
    //       their state in the store respectively.
    const connectedFields = [
      "error",
      "failed",
      "pending",
      "renderAssets",
      "renewalTime",
      "sessionId",
      "sessionIdLegacy"
    ];
    super("session", connectedFields, {
      variant: "redux",
      save: !get("SSR")
    });

    this.creators = {
      setFailed,
      setPending,
      setRenderAssets,
      setRenewalTime,
      setSessionId,
      setSessionIdLegacy
    };

    this._axios = null;
    this._expirationSubscription = null;
    this._expirationRenewOnActionSubscription = null;
    this._expiredTimeSubscription = null;
    this._store = null; // redux-store
    this._cookieJar = null;
  }

  get axios() {
    if (!this._axios)
      throw new Error(
        "Can't get remote: SessionState#attach must be called before this and request can be made."
      );
    return this._axios;
  }

  get store() {
    if (!this._store)
      throw new Error(
        "Can't get store: SessionState#attach must be called before this and request can be made."
      );
    return this._store;
  }

  get cookieJar() {
    return this._cookieJar;
  }

  initializeExpirationWarningMessage() {
    this.addExpirationWarningHook(() => {
      const { initAppReducer, cards, cardsBuy } = this._store.getState();
      let message = "";
      if (initAppReducer.signedIn) {
        if (Object.keys(cards).length > 0 || Object.keys(cardsBuy).length > 0) {
          message =
            "Are you still here? Click anywhere to continue. Otherwise your session and cart will expire in";
        } else {
          message =
            "Are you still here? Click anywhere to continue. Otherwise your session will expire in";
        }

        this.store.dispatch({
          type: "session.expiration-warning"
        });
      } else {
        if (Object.keys(cards).length > 0 || Object.keys(cardsBuy).length > 0) {
          message =
            "Are you still here? Click anywhere to continue. Otherwise your cart will expire in";

          this.store.dispatch({
            type: "session.expiration-warning"
          });
        }
      }

      this.store.dispatch(defaultAction(SET_SESSION_MESSAGE, message));
    });
  }

  initializeExpiredSessionMessage() {
    this.addExpiredSessionHook(() => {
      const { initAppReducer, cards, cardsBuy, customerInfo } =
        this._store.getState();
      let message = "";
      if (initAppReducer.signedIn) {
        if (Object.keys(cards).length > 0 || Object.keys(cardsBuy).length > 0) {
          message =
            "You haven’t been active in a while, so your cart and session expired. But hey, you can still get some great deals";
        } else {
          message =
            "You haven’t been active in a while, so your session expired. But hey, you can still get some great deals";
        }
      } else {
        if (Object.keys(cards).length > 0 || Object.keys(cardsBuy).length > 0) {
          message =
            "You haven’t been active in a while, so your cart expired. But hey, you can still get some great deals";
        }
      }
      this.store.dispatch(defaultAction(SET_SESSION_MESSAGE, message));
      this.store.dispatch({
        type: "session.session-expired"
      });
      if (
        customerInfo.anycardIframeOpened &&
        typeof CardCash !== "undefined" &&
        CardCash.close
      ) {
        CardCash.close();
        this.store.dispatch(defaultAction(ANYCARD_IFRAME_OPENED, false));
      }
      this.store.dispatch(defaultAction(RESET_EXPIRE));
    });
  }

  initializeAddExpirationRenewOnActionMessage() {
    this.addExpirationRenewOnActionHook(() => {
      const theStore = this._store.getState();
      if (theStore.initAppReducer.signedIn) {
        this.store.dispatch({
          type: "session.renew-session-on-action"
        });
      } else {
        this.store.dispatch({
          type: "session.renew-session-on-action"
        });
      }
    });
  }

  get config() {
    return pendingGet(
      this.store,
      ["config"],
      both(complement(isEmpty), complement(isNil))
    );
  }

  get expired() {
    return this.renewalTime == null || this.renewalTime <= Date.now();
  }

  get sessionBody() {
    const query = queryParams();

    if (
      query.utm_medium &&
      ["rakuten", "shareasale"].includes(query.utm_medium)
    ) {
      if (query.utm_source) {
        return {
          type: "affiliate",
          value: Array.isArray(query.utm_source)
            ? last(query.utm_source)
            : query.utm_source
        };
      }
    } else if (query.utm_campaign) {
      return {
        type: "campaign",
        value: query.utm_campaign
      };
    }

    return {};
  }

  addExpirationWarningHook(callback) {
    this._callbacks.indexOf(callback) === -1 && this._callbacks.push(callback);
  }

  addExpiredSessionHook(callback) {
    this._callbacksExpired.indexOf(callback) === -1 &&
      this._callbacksExpired.push(callback);
  }

  addExpirationRenewOnActionHook(callback) {
    this._expirationRenewOnActionSubscriptioncallback.indexOf(callback) ===
      -1 && this._expirationRenewOnActionSubscriptioncallback.push(callback);
  }

  removeExpirationWarningHook(callback) {
    const index = this._callbacks.indexOf(callback);
    index !== -1 && this._callbacks.splice(index, 1);
  }

  set expirationTime(millisecondsUntilExpiration) {
    this.renewalTime = new Date().getTime() + millisecondsUntilExpiration;

    if (this._expiredTimeSubscription != null) {
      // Cancel Subscription and reset expiration handle
      this._expiredTimeSubscription.unsubscribe();
      this._expiredTimeSubscription = null;
    }
    const wait$ = wait(millisecondsUntilExpiration);
    this._expiredTimeSubscription = wait$.subscribe(() => {
      for (const callback of this._callbacksExpired) {
        callback(millisecondsUntilExpiration);
      }
    });
  }

  set expirationRenewOnAction(millisecondsUntilExpiration) {
    if (this._expirationRenewOnActionSubscription != null) {
      // Cancel Subscription and reset expiration handle
      this._expirationRenewOnActionSubscription.unsubscribe();
      this._expirationRenewOnActionSubscription = null;
    }

    const wait$ = wait(millisecondsUntilExpiration);

    this._expirationRenewOnActionSubscription = wait$.subscribe(() => {
      for (const callback of this
        ._expirationRenewOnActionSubscriptioncallback) {
        callback(millisecondsUntilExpiration);
      }
    });
  }

  set expirationWarningTime(millisecondsUntilExpiration) {
    if (this._expirationSubscription != null) {
      // Cancel Subscription and reset expiration handle
      this._expirationSubscription.unsubscribe();
      this._expirationSubscription = null;
    }

    const wait$ = wait(millisecondsUntilExpiration);

    this._expirationSubscription = wait$.subscribe(() => {
      for (const callback of this._callbacks) {
        callback(millisecondsUntilExpiration);
      }
    });
  }

  async renewSession(retry = 2) {
    if (retry === 0) throw Error("Session renewal retries exceeded.");

    try {
      this.pending = true; // Updates the redux store.

      const { data, headers } = await this.axios.post(
        "/v3/session",
        this.sessionBody
      );

      const { expiresInSeconds, sessionId, renderAssets } = data;

      // Updating the redux store.
      this.sessionId = sessionId;
      this.sessionIdLegacy = sessionId;
      this.renderAssets = renderAssets;
      this.pending = false;

      this.store.dispatch({
        type: "session.renew-session-on-action-reset"
      });
      this.store.dispatch({
        type: "session.session-expired-reset"
      });
      this.store.dispatch({
        type: "session.expiration-warning-reset"
      });
      this.expirationRenewOnAction = secondsToMilliseconds(
        expiresInSeconds - THREE_MINUTES
      );
      this.expirationWarningTime = secondsToMilliseconds(
        expiresInSeconds - ONE_MINUTE
      );
      this.expirationTime = secondsToMilliseconds(expiresInSeconds);

      return { expiresInSeconds, sessionId };
    } catch (error) {
      this.pending = false;
      console.log(error);
      return await this.renewSession(retry - 1);
    }
  }

  /**
   * Resolves a promise once a session has been established or has already been established.
   * @param {string} url - the url that requires there to be an existing session.
   */
  ensureSessionExists() {
    if (!this._sessionPromiseHandle || (this.expired && !this.pending))
      this._sessionPromiseHandle = this.renewSession(2);

    return this._sessionPromiseHandle;
  }

  /**
   * Request Interceptor that ensures a session exists before calling any api endpoint.
   * @param {AxiosRequestConfig} config - The configuration object passed into axios
   * @returns {AxiosRequestConfig}
   */
  async requestInterceptor(config) {
    // add an axios cancellation token.
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;

    // Check if remote has failed out.
    if (this.failed && !config.ssr) {
      console.warn("Session call tried out.");
      source.cancel("Session call tried out.");
      return config;
    }

    // Ensure Session Exists
    try {
      if (config.url.endsWith("v3/session")) return config;
      const response = await this.ensureSessionExists(config.url);
      this._insertCookie(config);
    } catch (e) {
      this.failed = true;
      source.cancel("Session has retried too many times.");
    } finally {
      return config;
    }
  }

  /**
   * Extract Cookie
   *
   * @param {string} x_cc_app
   * @param {string[]} cookies
   * @returns {string | void}
   */
  extractCookie(x_cc_app, cookies) {
    for (const cookie of cookies) {
      const [key, value] = cookie.split("=");
      if (key === x_cc_app) {
        return value.split(";")[0];
      }
    }

    return null;
  }

  /**
   * Response interceptor that checks if set-cookie is defined in
   * the header and updates the store appropriately.
   * @param {AxiosResponse} response - The response of api call
   * @returns {AxiosResponse}
   */
  async responseInterceptor(response) {
    if ("set-token-expire" in response.headers) {
      const tokenExpire = response.headers["set-token-expire"];
      if (!tokenExpire) return response;

      const expiresInSeconds = secondsToMilliseconds(+tokenExpire) - Date.now();
      this.expirationRenewOnAction =
        expiresInSeconds - secondsToMilliseconds(THREE_MINUTES);
      this.expirationWarningTime =
        expiresInSeconds - secondsToMilliseconds(ONE_MINUTE);
      this.expirationTime = expiresInSeconds;
    }

    return response;
  }

  attach({ axios, store, cookieJar }) {
    // checking parameters.
    if (!axios || !store)
      throw new Error("axios and store must be provided when attaching.");
    if (get("SSR") && !cookieJar)
      throw new Error("cookieJar must be provided when running in SSR");

    this._axios = axios;
    this._store = store;
    this._cookieJar = cookieJar;

    this.axios.interceptors.request.use(this.requestInterceptor.bind(this));
    this.axios.interceptors.response.use(this.responseInterceptor.bind(this));

    this.initializeState();
  }

  _insertCookie(config) {
    if (!get("SSR") || !this.cookieJar) return;

    const cookie = this.cookieJar.getCookieHeader(config.baseURL);

    if (cookie) config.headers.Cookie = cookie;
  }
}

// NOTE: For Development Purposes.
switch (currentEnv()) {
  case DEVELOPMENT:
    window.SessionState = SessionState;
    break;
}

export default SessionState;
