import configBuilder from "@client/utils/configBuilder";

export const layers = {
  production: {
    aOrAn: "a",
    x_cc_app: "q3vsT1zXO",
    baseURL: "https://production-api.cardcash.com/",
    anycardURL: "https://checkout.cardcash.com/checkout.js",
    baseServerURL: "http://api.production/",
    bulkSalesEmail: "bulksales@cardcash.com",
    supportEmail: "support@cardcash.com",
    supportPhone: "1-800-227-4214",
    devMode: "production",
    ga: "",
    imgPrefix: "https://cdn.cardcash.com/website/",
    siftScienceBeacon: "20a58582f6",
    resellerRatingsId: 39711,
    siteHref: "https://cardcash.com",
    siteEnv: "https://cardcash.com"
  },
  integration: {
    // test.cardcash.com
    devMode: "integration",
    ga: "",
    anycardURL: "https://test-checkout.cardcash.com/test-checkout.js",
    siftScienceBeacon: "cebece9feb",
    x_cc_app: "JQme7NhnvEf5ivAFiIuf",
    baseURL: "https://integration-api.cardcash.com/",
    baseServerURL: "http://api.integration/",
    siteHref: "https://test.cardcash.com",
    siteEnv: "https://test.cardcash.com"
  },
  development: {
    devMode: "development",
    ga: "",
    x_cc_app: "JQme7NhnvEf5ivAFiIuf",
    // anycardURL: 'http://localhost:9000/dist/widget.js',
    anycardURL: "https://test-checkout.cardcash.com/test-checkout.js",
    siftScienceBeacon: "cebece9feb",
    baseServerURL: "https://integration-api.cardcash.com/",
    // baseServerURL: "https://local.cardcash.com:8080/",
    baseURL: "https://integration-api.cardcash.com/",
    // baseURL: "https://lcapi.cardcash.com:8080/",
    whenHosts: ["local.cardcash.com"],
    siteHref: "https://test.cardcash.com",
    siteEnv: "https://local.cardcash.com:3000"
  }
};

export const getConfiguration = options =>
  configBuilder("cardCash", layers, options);
