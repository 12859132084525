import { options as optionsHandle } from "./constant";

/**
 * @typedef {(Action, Dispatch, GetState) => void} Handle
 */

const DEFAULT_OPTIONS = {
  runPreHooks: true,
  runPostHooks: true,
  runHooks: true
};

const identity = () => null;

class HookHandler {
  /**
   * @param {Handle} handle
   */
  constructor(handle) {
    this.handle = (handle || identity).bind(this);
  }
}

/**
 * @param {{Handle}} def
 * @returns {HookHandler}
 */
const hookHandler = def => [new HookHandler(def.handle)];

class Tappable {
  constructor() {
    this._handlers = [];
  }

  next(...values) {
    this._handlers.forEach(fn => fn(...values));
  }

  tap(handler) {
    this._handlers.push(handler);
    return () => {
      this._handlers.splice(this._handlers.indexOf(handler));
    };
  }
}

/** @type {{[name: string]: import("redux").Action[]}} */
const preHooks = {};
/** @type {{[name: string]: import("redux").Action[]}} */
const postHooks = {};

const reduxHook =
  ({ dispatch, getState }) =>
  next =>
  action => {
    let options = { ...DEFAULT_OPTIONS, ...(action[optionsHandle] || {}) };

    if (action.type in preHooks && options.runPreHooks && options.runHooks) {
      preHooks[action.type].forEach(a => {
        if (a instanceof Tappable) a.next(action, dispatch);
        else if (a instanceof HookHandler) a.handle(action, dispatch, getState);
        else dispatch(a);
      });
    }

    next(action);

    if (action.type in postHooks && options.runPostHooks && options.runHooks) {
      postHooks[action.type].forEach(a => {
        if (a instanceof Tappable) a.next(action, dispatch);
        else if (a instanceof HookHandler) a.handle(action, dispatch, getState);
        else dispatch(a);
      });
    }
  };

const registerHook = (targetActions = [], emittedActions = []) => {
  targetActions.forEach(targetAction => {
    preHooks[targetAction] = preHooks[targetAction] || [];
    preHooks[targetAction].push(...emittedActions);
  });
};

const registerPreHook = registerHook;

/**
 * @param {string[]} targetActions ActionTypes to respond to
 * @param {import("redux").Action[]} emittedActions Actions to dispatch.
 */
const registerPostHook = (targetActions, emittedActions = []) => {
  targetActions.forEach(targetAction => {
    postHooks[targetAction] = postHooks[targetAction] || [];
    postHooks[targetAction].push(...emittedActions);
  });
};

export default reduxHook;
export { registerPreHook, registerPostHook, hookHandler };
