import { clone } from "ramda";
import fuzzysort from "fuzzysort";

const FLOOR = -Infinity;

class NullMerchant extends Error {}
class NullMerchants extends Error {}

export function score(merchant, input) {
  if (merchant === null || typeof merchant === "undefined")
    throw new NullMerchant();

  let nameScore = FLOOR,
    aliasScore = FLOOR,
    scoreObject = null;

  aliasScore = merchant?.aliases
    ? merchant.aliases
        .map(alias => {
          const obj = fuzzysort.single(input, alias);
          return obj ? obj.score : FLOOR;
        })
        .reduce((acc, c) => Math.max(acc, c), FLOOR)
    : 0;

  scoreObject = fuzzysort.single(input, merchant.name);
  if (scoreObject) nameScore = scoreObject.score;

  return Math.max(nameScore, aliasScore);
}

export function filter({ merchants, pattern, threshold = -2500 }) {
  if (typeof merchants === "undefined" || merchants === null)
    throw new NullMerchants();
  merchants = clone(merchants);
  merchants.sort((a, b) => {
    const aAvailableScore = a.cardsAvailable ? 0.5 : 1.5;
    const bAvailableScore = b.cardsAvailable ? 0.5 : 1.5;
    return (
      (score(b, pattern) / b.popular) * bAvailableScore -
      (score(a, pattern) / a.popular) * aAvailableScore
    );
  });
  return merchants.filter(i => score(i, pattern) > threshold);
}

export function filterSell({ merchants, pattern, threshold = -2500 }) {
  if (typeof merchants === "undefined" || merchants === null)
    throw new NullMerchants();
  merchants = clone(merchants);
  let results = fuzzysort.go(pattern, merchants, {
    keys: ["name"],
    allowTypo: true,
    threshold
  });

  return results.map(item => item.obj);
}

export function go(value, arr) {
  return fuzzysort.go(value, arr, {
    key: "name"
  });
}

export function filterWallet({ merchants, pattern, threshold = -2500 }) {
  if (typeof merchants === "undefined" || merchants === null)
    throw new NullMerchants();
  merchants = clone(merchants);
  return merchants.filter(i => scoreWallet(i, pattern) > threshold);
}

export function scoreWallet(merchant, input) {
  let nameScore = FLOOR,
    scoreObject = null;

  scoreObject = fuzzysort.single(input, merchant.merchant.name);
  if (scoreObject) nameScore = scoreObject.score;

  return nameScore;
}
