import React from "react";
import styled from "@emotion/styled";
import { mediaSMDDown } from "@modules/Components/break";
import Resizer from "@hocs/Resizer";
import { useSelector } from "react-redux";
import BurgerMenu from "./BurgerMenu";
import ShoppingCart from "./ShoppingCart";
import MobileSearch from "./MobileSearch";
import loadable from "@loadable/component";
const AccountMain = loadable(() =>
  import(/* webpackChunkName: "AccountMain" */ "./AccountMain")
);
const SellFlowStatus = loadable(() =>
  import(/* webpackChunkName: "SellFlowStatus" */ "./SellFlowStatus")
);
const LargeNavLinks = loadable(() =>
  import(/* webpackChunkName: "LargeNavLinks" */ "./LargeNavLinks")
);

const RightSection = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  & > * {
    margin: 18px;
    white-space: nowrap;
  }
  & > *:last-child {
    margin-left: ${({ marginLeft }) => marginLeft};
  }
  & > *:first-child {
    margin-right: 0;
  }
  @media (max-width: 1250px) {
    & > * {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
  @media (max-width: 1030px) {
    & > *:last-child {
      margin-left: 0px;
    }
  }
  @media (max-width: 980px) {
    & > * {
      margin-left: 0px;
    }
  }
  @media (max-width: 999px) {
    z-index: 2;
    & > * {
      margin: 18px;
    }
    & > *:last-child {
      margin-left: 36px;
    }
  }
  @media (max-width: 767px) {
    height: 76px !important;
  }
  @media (max-width: 415px) {
    & > * {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
  @media (max-width: 300px) {
    & > * {
      margin-right: 4px;
      margin-left: 4px;
    }
  }
`;

const Mobile = styled.div`
  display: none;
  ${mediaSMDDown} {
    display: initial;
  }
`;

const NavLinkContainerLarge = styled.div`
  ${mediaSMDDown} {
    display: none;
  }
`;

const NavRightSection = ({ cartView, scrolled, payoutView }) => {
  const navSearchOpen = useSelector(state => state.nav.initNav.navSearchOpen);
  const userMobile = useSelector(state => state.config.userAgent.isMobile);

  return (
    <RightSection
      marginLeft={cartView ? "0px" : "36px"}
      style={{ height: scrolled ? "76px" : "96px" }}
    >
      <Mobile>
        <Resizer
          components={[
            {
              component: () => <BurgerMenu payoutView={payoutView} />,
              break: 1,
              maxBreak: 1000
            }
          ]}
          defaultTrue={true}
        />
      </Mobile>
      <ShoppingCart payoutView={payoutView} />

      {!cartView && !payoutView && <MobileSearch />}
      <NavLinkContainerLarge>
        <Resizer
          components={[
            {
              component: () => (
                <AccountMain
                  scrolled={scrolled}
                  cartView={cartView}
                  payoutView={payoutView}
                />
              ),
              break: 999,
              maxBreak: Number.MAX_SAFE_INTEGER
            }
          ]}
          defaultTrue={userMobile ? false : true}
        />
      </NavLinkContainerLarge>
      {!cartView && !payoutView && !navSearchOpen && (
        <>
          <NavLinkContainerLarge>
            <Resizer
              components={[
                {
                  component: () => (
                    <LargeNavLinks
                      scrolled={scrolled}
                      loadablePage={"DiscountBuy"}
                      route={"/buy-gift-cards/"}
                      linkText={"Buy Gift Cards"}
                      type={"buy"}
                    />
                  ),
                  break: 999,
                  maxBreak: Number.MAX_SAFE_INTEGER
                }
              ]}
              defaultTrue={userMobile ? false : true}
            />
          </NavLinkContainerLarge>
          <NavLinkContainerLarge>
            <Resizer
              components={[
                {
                  component: () => (
                    <LargeNavLinks
                      scrolled={scrolled}
                      loadablePage={"StepOneSell"}
                      route={"/sell-gift-cards/"}
                      linkText={"Sell Gift Cards"}
                      type={"sell"}
                    />
                  ),
                  break: 999,
                  maxBreak: Number.MAX_SAFE_INTEGER
                }
              ]}
              defaultTrue={userMobile ? false : true}
            />
          </NavLinkContainerLarge>
        </>
      )}
      {payoutView && <SellFlowStatus />}
    </RightSection>
  );
};

export default NavRightSection;
