import React from "react";
import styled from "@emotion/styled";
import SocialFooter from "./SocialFooter";

const TabletSocialMain = styled.div`
  max-width: 200px;

  @media (max-width: 450px) {
    max-width: 100%;
    justify-content: flex-start;
    display: flex;
  }
`;

const TabletSocial = () => {
  return (
    <TabletSocialMain>
      <SocialFooter />
    </TabletSocialMain>
  );
};

export default TabletSocial;
