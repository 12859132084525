import Service from "../Service";

export default class RiskifiedService extends Service {
  constructor() {
    super("riskified");
  }

  buildRiskifiedURL(storeDomain, sessionId) {
    return (
      this.protocol +
      "beacon.riskified.com?shop=" +
      encodeURIComponent(storeDomain) +
      "&sid=" +
      encodeURIComponent(sessionId)
    );
  }

  afterScript({ session }) {
    this.script.src = this.buildRiskifiedURL("cardcash.com", session.sessionId);
  }
}
