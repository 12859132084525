import React from "react";
import styled from "@emotion/styled";
import { mediaXSUp, mediaSMDown } from "@modules/Components/break";
import HomeSearchBox from "../modules/QuickBuy/HomeSearchBox";
import LargeScreenMast from "./LargeScreenMast";

const InnerContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 1200px;
  background-image: linear-gradient(
    to bottom,
    rgba(34, 172, 256, 0.167),
    rgba(34, 172, 256, 0)
  );

  @media (max-width: 1400px) {
    height: 1075px;
  }
  @media (max-width: 1300px) {
    height: 1000px;
  }
  @media (max-width: 1000px) {
    height: 970px;
  }
  @media (max-width: 660px) {
    height: 928px;
  }
  @media (max-width: 620px) {
    height: 888px;
  }
`;

const MastContainer = styled.div`
  ${mediaXSUp} {
    margin-top: 125px;
  }
  ${mediaSMDown} {
    margin-top: 85px;
  }
`;

const LargeScreen = styled.div`
  @media (max-width: 575px) {
    display: none;
  }
`;

const Mast = () => {
  return (
    <MastContainer>
      <div>
        <InnerContainer>
          <LargeScreen>
            <LargeScreenMast />
          </LargeScreen>
          <div>
            <HomeSearchBox />
          </div>
        </InnerContainer>
      </div>
    </MastContainer>
  );
};

export default Mast;
