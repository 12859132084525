import {
  ADD_CARD_TO_BUY_CART,
  UPDATE_BUY_CART_ERROR_MSG,
  DELETE_BUY_CARD,
  RECEIVE_BUY_CART,
  SET_BUY_CART_ID,
  UPDATE_NUMBER_OF_CARDS_IN_BUY,
  ENABLE_COUPON,
  RESET_BUY_CART_COUPON,
  UPDATE_COUPON_ENTER,
  UPDATE_COUPON_ERROR,
  UPDATE_COUPON_WARNING,
  RESET,
  RESET_EXPIRE,
  RESET_BUY,
  CART_BUY_SHOW_POPUP_CART,
  UPDATE_BUY_ORDER_TOTAL,
  UPDATE_CART_BUY_POPUP_CART_ERROR,
  CLEAR_CART_BUY_POPUP_CART_ERROR,
  POPUP_CART_LOADING,
  CART_LOADING,
  POPUP_CART_CONFIRM_DELETE,
  CART_CONFIRM_DELETE,
  POPUP_CART_CLEAR_CONFIRM_DELETE,
  CART_CLEAR_CONFIRM_DELETE,
  CLEAR_CART_BUY_CART_ERROR,
  UPDATE_CURRENT_MERCHANT_SLUG_POPUP_CART,
  CART_BUY_SHOW_LOADING,
  UPDATE_CART_BUY_ERROR
} from "@constants/actionTypes";

const initialState = {
  cardIds: [],
  cartId: null,
  cartErrorMsg: "",
  scrollToTop: false,
  numCards: 0,
  couponEnter: "",
  couponDiscount: 0,
  couponError: "",
  warningMessage: "",
  orderTotal: "",
  popupCartError: "",
  cartError: "",
  popupCartLoading: { status: false },
  popupCartOpenConfirmModal: { status: false },
  cartOpenConfirmModal: { status: false },
  cartLoading: { status: false },
  merchantPageSlug: "",
  cardLoading: []
};

const cartBuy = (state = initialState, action) => {
  switch (action.type) {
    case CART_BUY_SHOW_POPUP_CART:
      return {
        ...state,
        popupCartError: "",
        popupCartOpenConfirmModal: { status: false }
      };

    case CART_BUY_SHOW_LOADING:
      const findCardInLoadingArray = state.cardLoading.find(
        card =>
          card.merchId === action.payload.merchId &&
          card.value === action.payload.value &&
          card.percent === action.payload.percent &&
          card.type === action.payload.type
      );

      const filterCardLodingArray = state.cardLoading.filter(
        card =>
          card.merchId !== action.payload.merchId ||
          card.value !== action.payload.value ||
          card.percent !== action.payload.percent ||
          card.type !== action.payload.type
      );

      if (!findCardInLoadingArray) {
        if (action.payload.state) {
          filterCardLodingArray.push(action.payload);
        }
      }
      return {
        ...state,
        cardLoading: [...filterCardLodingArray]
      };

    case DELETE_BUY_CARD:
      let newIds = state.cardIds.filter(card => {
        return card !== action.payload;
      });
      return {
        ...state,
        cardIds: newIds
      };

    case RECEIVE_BUY_CART:
      return {
        ...state,
        cartId: action.payload.cartId,
        cardIds: action.payload.cards
      };

    case SET_BUY_CART_ID:
      return {
        ...state,
        cartId: action.payload
      };

    case ADD_CARD_TO_BUY_CART:
      return {
        ...state,
        cardIds: action.payload
      };

    case UPDATE_BUY_CART_ERROR_MSG:
      return {
        ...state,
        cartErrorMsg: action.payload,
        scrollToTop: action.payload ? true : false
      };

    case UPDATE_NUMBER_OF_CARDS_IN_BUY:
      return {
        ...state,
        numCards: action.payload
      };

    case ENABLE_COUPON:
      return {
        ...state,
        couponDiscount: action.payload.discount || 0,
        couponError: action.payload.errorMsg || "",
        warningMessage: action.payload.warningMessage
      };

    case RESET_BUY_CART_COUPON:
      return {
        ...state,
        couponDiscount: 0,
        couponError: "",
        warningMessage: ""
      };

    case UPDATE_COUPON_ENTER:
      return {
        ...state,
        couponEnter: action.payload
      };

    case UPDATE_COUPON_WARNING:
      return {
        ...state,
        warningMessage: action.payload
      };

    case UPDATE_COUPON_ERROR:
      return {
        ...state,
        couponError: action.payload
      };

    case UPDATE_CART_BUY_POPUP_CART_ERROR:
      return {
        ...state,
        popupCartError: action.payload
      };

    case UPDATE_CART_BUY_ERROR:
      return {
        ...state,
        cartError: action.payload
      };

    case CLEAR_CART_BUY_POPUP_CART_ERROR:
      return {
        ...state,
        popupCartError: ""
      };

    case CLEAR_CART_BUY_CART_ERROR:
      return {
        ...state,
        cartError: ""
      };

    case POPUP_CART_LOADING:
      return {
        ...state,
        popupCartLoading: action.payload
      };

    case CART_LOADING:
      return {
        ...state,
        cartLoading: action.payload
      };

    case POPUP_CART_CONFIRM_DELETE:
      return {
        ...state,
        popupCartOpenConfirmModal: action.payload
      };

    case POPUP_CART_CLEAR_CONFIRM_DELETE:
      return {
        ...state,
        popupCartOpenConfirmModal: { status: false }
      };

    case CART_CONFIRM_DELETE:
      return {
        ...state,
        cartOpenConfirmModal: action.payload
      };

    case CART_CLEAR_CONFIRM_DELETE:
      return {
        ...state,
        cartOpenConfirmModal: { status: false }
      };

    case UPDATE_CURRENT_MERCHANT_SLUG_POPUP_CART:
      return {
        ...state,
        merchantPageSlug: action.payload
      };
    case UPDATE_BUY_ORDER_TOTAL:
      return {
        ...state,
        orderTotal: action.payload
      };

    case RESET:
    case RESET_EXPIRE:
    case RESET_BUY:
      return initialState;

    default:
      return state;
  }
};

export default cartBuy;
