import React from "react";
import styled from "@emotion/styled";
import "../../styles/reactSelect.scss";

const MerchantOptions = styled.div`
  position: absolute;
  width: 100%;
  max-height: 326px;
  overflow: scroll;
  top: 36px;
  background-color: #ffffff;
  border-radius: 0px 0px 30px 30px;
  padding-top: 40px;
`;

const InnerOption = styled.div`
  width: 95%;
  margin: 0 auto;
  border-bottom: 1px rgba(119, 121, 132, 0.15) solid;
  display: grid;
  grid-template-columns: auto 1fr 135px;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: pointer;
`;

const OptionImage = styled.img`
  width: 40px;
  height: 29px;
  margin-right: 10px;
  margin-left: 10px;
  opacity: ${props => props.setOpacity};
`;

const Label = styled.span`
  opacity: ${props => props.setOpacity};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: nowrap;

  @media (max-width: 370px) {
    grid-column: ${props => props.gridColumnMobile};
  }
`;

const AvailableContainer = styled.div`
  @media (max-width: 370px) {
    justify-self: flex-end;
  }
`;

const SoldOutSpan = styled.span`
  width: 135px;
  height: 36px;
  opacity: 0.5;
  border-radius: 5px;
  background-color: rgba(119, 121, 132, 0.1);
  color: #777984;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: end;
  justify-self: end;

  @media (max-width: 370px) {
    width: 84px;
  }
`;

const PercentSpan = styled.span`
  border-radius: 18px;
  background-color: rgba(18, 178, 234, 0.1);
  font-size: 14px;
  font-weight: bold;
  color: rgba(18, 178, 234, 0.7);
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;

  @media (max-width: 370px) {
    display: none;
  }
`;

const PercentSpanBold = styled.span`
  color: #12b2ea;
`;

const NoResults = styled.div`
  text-align: center;
  color: #777984;
`;

const NavSearchMerchantOptions = ({ sortedMerchants, handleMerchantClick }) => {
  return (
    <MerchantOptions className="nav-merchant-options">
      <div>
        {sortedMerchants.map(mer => (
          <InnerOption
            onClick={() => {
              handleMerchantClick(mer);
            }}
            key={`mer${mer.id}`}
          >
            <OptionImage
              src={mer.image}
              setOpacity={mer.cardsAvailable ? 1 : 0.5}
              alt="merchant"
            />
            <Label
              setOpacity={mer.cardsAvailable ? 1 : 0.5}
              gridColumnMobile={mer.cardsAvailable ? "2/4" : "2/3"}
            >
              {mer.name}
            </Label>
            <AvailableContainer>
              {!mer.cardsAvailable ? (
                <SoldOutSpan>Sold out</SoldOutSpan>
              ) : (
                <PercentSpan>
                  Up to&nbsp;
                  <PercentSpanBold>{mer.upToPercentage}%</PercentSpanBold>
                  &nbsp;off
                </PercentSpan>
              )}
            </AvailableContainer>
          </InnerOption>
        ))}

        {sortedMerchants.length < 1 && <NoResults>No results found</NoResults>}
      </div>
    </MerchantOptions>
  );
};

export default NavSearchMerchantOptions;
