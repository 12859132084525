import {
  FILL_SELL_ORDERS,
  FILL_SELL_ORDER,
  FILL_SELL_ORDER_CARDS,
  RESET,
  RESET_EXPIRE,
  SET_SELL_ORDER_TO_CANCEL,
  SELL_ORDERS_THUNK_RUNNING
} from "@constants/actionTypes";

const initialState = {
  sellOrders: [],
  sellOrder: {},
  sellOrderCards: [],
  sellOrderToCancel: null,
  sellOrderTotal: 0,
  _thunkRunning: false,
  sellOrdersByYear: {}
};

const ordersSell = (state = initialState, action) => {
  switch (action.type) {
    case FILL_SELL_ORDERS:
      const sellOrderYear = {};
      action.payload.forEach(order => {
        const year = new Date(order.date.placed).getFullYear();
        if (sellOrderYear[year]) {
          sellOrderYear[year].push(order);
        } else {
          sellOrderYear[year] = [order];
        }
      });
      return {
        ...state,
        sellOrders: action.payload,
        sellOrdersByYear: sellOrderYear
      };

    case FILL_SELL_ORDER:
      return {
        ...state,
        sellOrder: action.payload.sellOrder
      };

    case FILL_SELL_ORDER_CARDS:
      return {
        ...state,
        sellOrderCards: action.payload.sellCards
      };

    case SET_SELL_ORDER_TO_CANCEL:
      return {
        ...state,
        sellOrderToCancel: action.payload
      };

    case SELL_ORDERS_THUNK_RUNNING:
      return {
        ...state,
        _thunkRunning: action.payload
      };

    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};

export default ordersSell;
