import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import CategoryGrid from "@components/CategoryGrid";
import PressRecognition from "@components/PressRecognition";
import { Link } from "react-router-dom";
import { signal } from "@modules/Signal/action";
import Mast from "@components/Mast";
import DownloadApp from "@components/DownloadApp";
import HomeCategories from "@components/HomeCategories";
import "../styles/home.scss";
import { initAppBrowser } from "@actions/initApp";
import { useDispatch } from "react-redux";
import MostPopularCards from "@components/MostPopularCards";
import { format } from "date-fns";

const HomeMain = styled.div`
  margin-top: -5px !important;
  @media (max-width: 767px) {
    margin-top: 110px !important;
  }
`;

const DownloadOurApp = styled.div`
  height: 703px;
  background-color: #0d0236;
  display: grid;
  grid-template-rows: 150px 10px 543px;
  @media (max-width: 1150px) {
    grid-template-rows: 150px 10px 507px;
    height: 667px;
  }
  @media (max-width: 950px) {
    display: block;
    height: auto;
  }
`;

const SmallWhiteLine = styled.div`
  width: 36px;
  height: 3px;
  border-radius: 1.5px;
  background-color: rgba(250, 250, 250, 0.3);
  @media (max-width: 950px) {
    margin: 0 auto;
  }
`;

const LeftGrid = styled.div`
  @media (max-width: 950px) {
    margin-top: 32px;
  }
  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

const SeperationLine = styled.div`
  width: 80%;
  height: 1px;
  opacity: 0.2;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.3),
    #ffffff 51%,
    rgba(255, 255, 255, 0.3)
  );
  margin: 0 auto;
`;

const SellGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 100px;
  grid-gap: 165px;
  @media (max-width: 1700px) {
    margin-top: 60px;
  }
  @media (max-width: 1450px) {
    margin-top: 33px;
  }
  @media (max-width: 1400px) {
    grid-gap: 12px;
  }
  @media (max-width: 1275px) {
    margin-top: 18px;
  }
  @media (max-width: 1040px) {
    grid-gap: 112px;
    margin-top: 0px;
  }
  @media (max-width: 950px) {
    display: block;
  }
  @media (max-width: 575px) {
    width: 343px;
    margin: 0 auto;
    line-height: 1.2;
  }
  @media (max-width: 350px) {
    width: 305px;
  }
  @media (max-width: 305px) {
    width: 100%;
  }
`;

const SellGiftP = styled.h2`
  font-size: 36px;
  font-weight: normal;
  color: #ffffff;
  text-align: left;
  margin-top: 30px;
  max-width: 500px;

  @media (max-width: 1275px) {
    font-size: 32px;
    margin-top: 25px;
  }
  @media (max-width: 1150px) {
    font-size: 27px;
  }
  @media (max-width: 957px) {
    font-size: 26px;
  }
  @media (max-width: 950px) {
    text-align: center;
    padding: 0;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 48px;
    font-size: 36px;
    width: 538px;
  }
  @media (max-width: 700px) {
    width: 100%;
    font-size: 33px;
  }
  @media (max-width: 575px) {
    font-size: 30px;
  }
  @media (max-width: 350px) {
    font-size: 28px;
  }
`;

const SellGiftButton = styled.div`
  width: 320px;
  height: 72px;
  border-radius: 36px;
  box-shadow: 0 9px 18px 0 rgba(31, 32, 33, 0.05);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #ff9c1b;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #fff9ef;
  }
  @media (max-width: 1275px) {
    width: 276px;
    height: 63px;
  }
`;

const MiddleContainer = styled.div`
  position: relative;
  min-height: 800px;
  border-radius: 0px 460px 0px 0px;
  background-image: linear-gradient(
    to top,
    rgba(34, 176, 252, 0) 67%,
    rgba(34, 176, 252, 0.09)
  );
  padding-top: 336px;

  @media (max-width: 1575px) {
    border-radius: 0px 370px 0px 0px;
  }
  @media (max-width: 1400px) {
    padding-top: 292px;
  }
  @media (max-width: 1150px) {
    border-radius: 0px 260px 0px 0px;
  }
  @media (max-width: 950px) {
    background-image: none;
    padding-top: 35px;
  }

  @media (max-width: 575px) {
    padding-top: 150px;
  }
`;

const TopImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 1400px) {
    width: 190px;
  }
  @media (max-width: 950px) {
    display: none;
  }
`;

const SellGiftCardMain = styled.div`
  background-image: ${({ imageSrc }) => `url("${imageSrc}")`};
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  font-weight: 300;
  margin-bottom: 30px;
  height: 438px;
  background-size: 100%;
  @media (max-width: 1450px) {
    margin-bottom: 0px;
    height: 402px;
  }
  @media (max-width: 1175px) {
    height: 356px;
  }
  @media (max-width: 1040px) {
    height: 307px;
  }
  @media (max-width: 950px) {
    background-position: 73%;
    background-size: auto 100%;
  }
  @media (max-width: 950px) {
    height: 481px;
    border-radius: 0px 0px 160px 0px;
  }
  @media (max-width: 700px) {
    border-radius: 0px 0px 130px 0px;
  }
  @media (max-width: 575px) {
    background-image: none;
    position: relative;
    overflow: hidden;
  }
  @media (max-width: 550px) {
    border-radius: 0px 0px 110px 0px;
  }
`;

const MobileImage = styled.img`
  @media (min-width: 576px) {
    display: none;
  }
  position: absolute;
  pointer-events: none;
  top: 0;
  right: -367px;
  bottom: 0;
  z-index: -1;
  height: 100%;
  width: auto;

  @media (max-width: 450px) {
    right: -580px;
  }
  @media (max-width: 350px) {
    right: -680px;
  }
`;

const SellButtonLinkDiv = styled.div`
  height: 72px;
  align-self: center;
  > :hover {
    text-decoration: none;
  }
  @media (max-width: 1400px) {
    justify-self: center;
  }
  @media (max-width: 1275px) {
    height: 63px;
    align-self: center;
    margin-top: 25px;
  }
  @media (max-width: 950px) {
    display: flex;
    justify-content: center;
  }
`;

const SellGiftCardMainInner = styled.div`
  width: auto;
  color: white;
  text-align: center;
  padding: 50px;
  font-weight: 300;
  @media (max-width: 950px) {
    padding-top: 100px;
  }
  @media (max-width: 655px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
    padding-top: 100px;
  }
`;

const meta = [
  {
    name: "description",
    content:
      "CardCash has been helping businesses save money by taking advantage of unwanted gift cards. Start saving money with our discounted gift cards, and become a Premier Buyer today!"
  },
  {
    metaProperty: "og:title",
    metaContent: "CardCash | Gift Card Exchange"
  },
  {
    metaProperty: "og:image",
    metaContent: "https://cdn.cardcash.com/images/sharing/refer-a-friend.png"
  },
  {
    metaProperty: "og:image:width",
    metaContent: "1200"
  },
  {
    metaProperty: "og:image:height",
    metaContent: "630"
  },
  {
    name: "twitter:card",
    content: "summary_large_image"
  },
  {
    name: "twitter:site",
    content: "@CardCash"
  },
  {
    metaProperty: "og:url",
    metaContent: "https://cardcash.com/"
  },
  {
    metaProperty: "og:description",
    metaContent:
      "Invite a friend to CardCash and you'll both get rewarded with $5 when they create an account and make a purchase."
  }
];
const Home = ({ windowLoaded }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initAppBrowser(["messages", "home-categories"]));
    dispatch(signal("order-reset"));
  }, []);

  return (
    <HomeMain className="home-page-main-container">
      <div>
        <Helmet
          title={`CardCash | Gift Card Exchange - Buy, Sell and Trade Gift Cards`}
          meta={meta}
        />
        <div>
          <Mast />
        </div>
        <MiddleContainer>
          <TopImage
            src="https://cdn.cardcash.com/website/ui/elements/homepage-elements-featured.svg"
            loading="lazy"
            alt="design"
          />
          <MostPopularCards
            title={`Shop ${format(new Date(), "MMMM")}'s most popular brands.`}
            classFromParent={"home-page-popular-cards"}
          />
          <HomeCategories />
        </MiddleContainer>
        <DownloadOurApp>
          <PressRecognition />
          <SeperationLine />
          <DownloadApp />
        </DownloadOurApp>
        <SellGiftCardMain
          imageSrc={
            windowLoaded
              ? "https://cdn.cardcash.com/website/ui/Home/sell-bg.png"
              : ""
          }
        >
          <MobileImage
            height="2137"
            width="481"
            src="https://cdn.cardcash.com/website/ui/Home/sell-bg.png"
            loading="lazy"
            alt="sell gift cards"
          />
          <SellGiftCardMainInner>
            <SellGridContainer>
              <LeftGrid>
                <SmallWhiteLine></SmallWhiteLine>
                <SellGiftP>
                  Exchange your unused gift card, and trade it for something you
                  really want
                </SellGiftP>
              </LeftGrid>
              <SellButtonLinkDiv>
                <Link to="/sell-gift-cards/">
                  <SellGiftButton
                    className="button-transition-hover-bgColor"
                    onClick={() =>
                      ga && ga("send", "event", "HomePage", "SellSection")
                    }
                  >
                    Trade your gift card
                  </SellGiftButton>{" "}
                </Link>
              </SellButtonLinkDiv>
            </SellGridContainer>
          </SellGiftCardMainInner>
        </SellGiftCardMain>

        <CategoryGrid />
      </div>
    </HomeMain>
  );
};

export default Home;
