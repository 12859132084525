import Service from "../Service";
import dlog from "../../../utils/dlog";

export default class MaxMindService extends Service {
  constructor() {
    super("maxmind");
  }

  afterScript() {
    this.script.src = `${this.protocol}device.maxmind.com/js/device.js`;
  }
}
