export const minWidth = w => `(min-width: ${w.toString()})`;
export const maxWidth = w => `(max-width: ${w.toString()})`;
export const screen = () => "screen";
export const and = (...args) => args.join(" and ");
export const or = (...args) => args.join(" or ");
export const media = (...args) => `@media ${args.join(" ")}`;

class Breakpoint {
  constructor(v) {
    this._value = v;
    this._unit = "px";
  }

  set unit(v) {
    this._unit = v;
  }

  get unit() {
    return this._unit;
  }

  get upTo() {
    return new Breakpoint(this._value - 1);
  }

  get downTo() {
    return new Breakpoint(this._value + 1);
  }

  toString() {
    return `${this._value}${this._unit}`;
  }
}

const bp = d => new Breakpoint(d);

const XS = new Breakpoint(767);
const SM = new Breakpoint(768);
const SMD = new Breakpoint(1000);
const MD = new Breakpoint(992);
const MLG = new Breakpoint(1030);
const LG = new Breakpoint(1200);
const XL = new Breakpoint(1800);

export const mediaXS = media(maxWidth(XS));
export const mediaSM = media(minWidth(SM));
export const mediaSMD = media(minWidth(SMD));
export const mediaMD = media(minWidth(MD));
export const mediaMLG = media(minWidth(MLG));
export const mediaLG = media(minWidth(LG));
export const mediaXL = media(minWidth(XL));

export const mediaSMDown = media(and(maxWidth(SM.upTo)));
export const mediaSMDDown = media(and(maxWidth(SMD.upTo)));
export const mediaMDDown = media(and(maxWidth(MD.upTo)));
export const mediaMLGDown = media(and(maxWidth(MLG.upTo)));
export const mediaLGDown = media(and(maxWidth(LG.upTo)));
export const mediaXLDown = media(and(maxWidth(XL.upTo)));

export const mediaXSUp = media(and(minWidth(XS.downTo)));
export const mediaSMUp = media(and(minWidth(SM.downTo)));
export const mediaSMDUp = media(and(minWidth(SMD.downTo)));
export const mediaMDUp = media(and(minWidth(MD.downTo)));
export const mediaMLGUp = media(and(minWidth(MLG.downTo)));
export const mediaLGUp = media(and(minWidth(LG.downTo)));
export const mediaXLUp = media(and(minWidth(XL.downTo)));

// platform information
export const reallyTinySmallPhone = and(screen(), maxWidth(bp(295)));
export const tinySmallPhone = and(screen(), maxWidth(bp(320)));
export const verySmallPhone = and(screen(), maxWidth(bp(340)));
export const smallerPhone = and(screen(), maxWidth(bp(375)));
export const smallPhone = and(screen(), maxWidth(bp(425)));
export const phone = and(screen(), maxWidth(bp(500)));

export const tablet = and(screen(), maxWidth(bp(980).upTo));
export const smallScreen = and(screen(), minWidth(bp(616)));
export const mediumScreen = and(screen(), minWidth(bp(1000)));

export const desktop = and(screen(), minWidth(bp(980)));
