import { SET_MESSAGE, CLEAR_MESSAGE } from "./constant";
import { createReducer, createAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

export const setModal = createAction("messages/setModal");
export const clearModal = createAction("messages/clearModal");

const open = createReducer(false, {
  [setModal]() {
    return true;
  },
  [clearModal]() {
    return false;
  }
});

const title = createReducer("", {
  [SET_MESSAGE](state, action) {
    return action.title;
  },
  [CLEAR_MESSAGE]() {
    return "";
  },
  [clearModal]() {
    return "";
  },
  [setModal](state, action) {
    return action.payload.title;
  }
});

const content = createReducer("", {
  [setModal](state, action) {
    return action.payload.content;
  },
  [clearModal]() {
    return "";
  },
  [SET_MESSAGE](state, action) {
    return action.content;
  },
  [CLEAR_MESSAGE]() {
    return "";
  }
});

export default combineReducers({
  title,
  content,
  open
});
