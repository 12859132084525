class ISet {
  static load(plainJS = {}) {
    return new ISet(plainJS._data || {});
  }

  constructor(data = {}) {
    // In case someone explicitly provides something other than an object.
    if (typeof data !== "object") throw Error("Data must be an object");

    this._data = {
      ...data
    };
  }

  add(value) {
    return new ISet({
      ...this._data,
      [value]: (this._data[value] && this._data[value] + 1) || 1
    });
  }

  remove(value) {
    return new ISet({
      ...this._data,
      [value]: (this._data[value] && this._data[value] - 1) || 0
    });
  }

  has(value) {
    return !!this._data[value];
  }

  get size() {
    let size = 0;

    for (const key in this._data) {
      if (this.has(key)) size++;
    }

    return size;
  }
}

export default ISet;
