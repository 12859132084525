import {
  FILL_INVENTORY,
  FILL_INVENTORY_LOWEST_PERCENT,
  FILL_INVENTORY_HIGHEST_QTY,
  FILL_INVENTORY_LOWEST_QTY,
  FILL_INVENTORY_HIGHEST_VALUE,
  FILL_INVENTORY_LOWEST_VALUE,
  FILL_INVENTORY_PHYSICAL,
  FILL_INVENTORY_ECODE,
  FILL_INVENTORY_HIGHEST_YOU_PAY,
  FILL_INVENTORY_LOWEST_YOU_PAY,
  FILL_INVENTORY_THUNK_RUNNING
} from "@constants/actionTypes";

const initialState = {
  inventory: null,
  sortFactor: "HIGHEST_PERCENT",
  thunkRunning: false
};

const inventory = (state = initialState, action) => {
  switch (action.type) {
    case FILL_INVENTORY_THUNK_RUNNING:
      return {
        ...state,
        thunkRunning: true
      };

    case FILL_INVENTORY:
      return {
        ...state,
        thunkRunning: false,
        buyMessage: action.payload?.buyMessage,
        merchantID: action.payload?.merchantID,
        description: action.payload?.description,
        image: action.payload?.image,
        inventory: action.payload.inventory.sort(
          (item, nextItem) => nextItem.percentOff - item.percentOff
        ),
        sortFactor: "FILL_INVENTORY"
      };

    case FILL_INVENTORY_LOWEST_PERCENT:
      return {
        ...state,
        thunkRunning: false,
        inventory: action.payload.inventory.sort(
          (item, nextItem) => item.percentOff - nextItem.percentOff
        ),
        sortFactor: "FILL_INVENTORY_LOWEST_PERCENT"
      };

    case FILL_INVENTORY_HIGHEST_QTY:
      return {
        ...state,
        thunkRunning: false,
        inventory: action.payload.inventory.sort(
          (item, nextItem) => Number(nextItem.qty) - Number(item.qty)
        ),
        sortFactor: "FILL_INVENTORY_HIGHEST_QTY"
      };

    case FILL_INVENTORY_LOWEST_QTY:
      return {
        ...state,
        thunkRunning: false,
        inventory: action.payload.inventory.sort(
          (item, nextItem) => Number(item.qty) - Number(nextItem.qty)
        ),
        sortFactor: "FILL_INVENTORY_LOWEST_QTY"
      };

    case FILL_INVENTORY_HIGHEST_VALUE:
      return {
        ...state,
        thunkRunning: false,
        inventory: action.payload.inventory.sort(
          (item, nextItem) => nextItem.faceValue - item.faceValue
        ),
        sortFactor: "FILL_INVENTORY_HIGHEST_VALUE"
      };

    case FILL_INVENTORY_LOWEST_VALUE:
      return {
        ...state,
        thunkRunning: false,
        inventory: action.payload.inventory.sort(
          (item, nextItem) => item.faceValue - nextItem.faceValue
        ),
        sortFactor: "FILL_INVENTORY_LOWEST_VALUE"
      };

    case FILL_INVENTORY_HIGHEST_YOU_PAY:
      return {
        ...state,
        thunkRunning: false,
        inventory: action.payload.inventory.sort(
          (item, nextItem) => nextItem.cost - item.cost
        ),
        sortFactor: "FILL_INVENTORY_HIGHEST_YOU_PAY"
      };

    case FILL_INVENTORY_LOWEST_YOU_PAY:
      return {
        ...state,
        thunkRunning: false,
        inventory: action.payload.inventory.sort(
          (item, nextItem) => item.cost - nextItem.cost
        ),
        sortFactor: "FILL_INVENTORY_LOWEST_YOU_PAY"
      };

    case FILL_INVENTORY_PHYSICAL:
      return {
        ...state,
        thunkRunning: false,
        sortFactor: "FILL_INVENTORY_PHYSICAL",
        inventory: action.payload.inventory.sort((a, b) => {
          if (a.type > b.type) return -1;
          if (a.type < b.type) return 1;
          return 0;
        })
      };

    case FILL_INVENTORY_ECODE:
      return {
        ...state,
        thunkRunning: false,
        sortFactor: "FILL_INVENTORY_ECODE",
        inventory: action.payload.inventory.sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        })
      };

    default:
      return state;
  }
};

export default inventory;
