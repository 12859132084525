//PASSWORD RESET
export const VALID_TOKEN = "VALID_TOKEN";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const PASSWORD_TOKEN_RUNNING = "PASSWORD_TOKEN_RUNNING";
export const CART_BUY_SHOW_POPUP_CART = "CART_BUY_SHOW_POPUP_CART";
export const CART_BUY_SHOW_LOADING = "CART_BUY_SHOW_LOADING";
export const CART_BUY_HIDE_POPUP_CART = "CART_BUY_HIDE_POPUP_CART";
export const UPDATE_CART_BUY_POPUP_CART_ERROR =
  "UPDATE_CART_BUY_POPUP_CART_ERROR";
export const UPDATE_CURRENT_MERCHANT_SLUG_POPUP_CART =
  "UPDATE_CURRENT_MERCHANT_SLUG_POPUP_CART";
export const CLEAR_CART_BUY_POPUP_CART_ERROR =
  "CLEAR_CART_BUY_POPUP_CART_ERROR";
export const POPUP_CART_LOADING = "POPUP_CART_LOADING";
export const POPUP_CART_CONFIRM_DELETE = "POPUP_CART_CONFIRM_DELETE";
export const POPUP_CART_CLEAR_CONFIRM_DELETE =
  "POPUP_CART_CLEAR_CONFIRM_DELETE";
export const CART_LOADING = "CART_LOADING";
export const CART_CONFIRM_DELETE = "CART_CONFIRM_DELETE";
export const CART_CLEAR_CONFIRM_DELETE = "CART_CLEAR_CONFIRM_DELETE";
export const CLEAR_CART_BUY_CART_ERROR = "CLEAR_CART_BUY_CART_ERROR";
export const UPDATE_CART_BUY_ERROR = "UPDATE_CART_BUY_ERROR";
export const SET_PAYPAL_INTERNAL_PAYMENT_ID = "SET_PAYPAL_INTERNAL_PAYMENT_ID";
export const CONFIRM_END_BUY_TRANSACTION = "CONFIRM_END_BUY_TRANSACTION";
export const SET_ATTEMPTED_PATH = "SET_ATTEMPTED_PATH";

export const SET_BUY_ORDER_UID = "SET_BUY_ORDER_UID";
export const PUT_BITCOIN_INFO_IN_REDUX = "PUT_BITCOIN_INFO_IN_REDUX";
export const BITPAY_IFRAME_OPENED = "BITPAY_IFRAME_OPENED";
export const ANYCARD_IFRAME_OPENED = "ANYCARD_IFRAME_OPENED";

export const UPDATE_BUY_CART_ERROR_MSG = "UPDATE_BUY_CART_ERROR_MSG";
export const HIDE_CARDS_UNDER_CONSIDERATION_POPUP =
  "HIDE_CARDS_UNDER_CONSIDERATION_POPUP";
export const SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID =
  "SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID";
export const HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID =
  "HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID";
export const SEND_ECOMMERCE_INFO = "SEND_ECOMMERCE_INFO";
export const SET_OPEN_ORDERS = "SET_OPEN_ORDERS";
export const ADD_MESSAGES = "ADD_MESSAGES";
export const ADD_BANNERS = "ADD_BANNERS";
export const ADD_LOGOS = "ADD_LOGOS";
export const ADD_BUY_LOGOS = "ADD_BUY_LOGOS";
export const ADD_BUY_NEW_LOGOS = "ADD_BUY_NEW_LOGOS";
export const ADD_EPIC_DISCOUNTS_BANNER = "ADD_EPIC_DISCOUNTS_BANNER";
export const RESET_BUY = "RESET_BUY";
export const RESET_SELL = "RESET_SELL";
export const SET_CARDS_UNDER_CONSIDERATION = "SET_CARDS_UNDER_CONSIDERATION";
export const SET_CARD_KNOX_FORM = "SET_CARD_KNOX_FORM";
export const CLEAR_CARD_KNOX = "CLEAR_CARD_KNOX";
export const SET_CARD_KNOX_SUBMITTING = "SET_CARD_KNOX_SUBMITTING";
export const SET_CARD_KNOX_LOADED = "SET_CARD_KNOX_LOADED";
//MERCHANTS & PAYMENT OPTIONS
export const PAYMENT_OPTIONS_RECIEVED = "PAYMENT_OPTIONS_RECIEVED";
export const RECEIVE_SELL_MERCHANTS = "RECEIVE_SELL_MERCHANTS";
export const RECEIVE_NEW_MERCHANTS = "RECEIVE_NEW_MERCHANTS";

//SORTING MERCHANTS
export const LOAD_BUY_MERCHANTS = "LOAD_BUY_MERCHANTS";
export const LOAD_POPULAR_BUY_MERCHANTS = "LOAD_POPULAR_BUY_MERCHANTS";
export const LOAD_ALL_BUY_MERCHANTS = "LOAD_ALL_BUY_MERCHANTS";
export const LOAD_LARGEST_DISCOUNT_BUY_MERCHANTS =
  "LOAD_LARGEST_DISCOUNT_BUY_MERCHANTS";

export const FILTER_BY_LETTER = "FILTER_BY_LETTER";
export const SET_CATEGORY = "SET_CATEGORY";
export const SHOW_ALL_MERCHANTS = "SHOW_ALL_MERCHANTS";
export const SET_SIMILAR_MERCHANTS = "SIMILAR_MERCHANTS";
//INVENTORY
export const FILL_INVENTORY = "FILL_INVENTORY";
export const FILL_INVENTORY_LOWEST_PERCENT = "FILL_INVENTORY_LOWEST_PERCENT";
export const FILL_INVENTORY_HIGHEST_QTY = "FILL_INVENTORY_HIGHEST_QTY";
export const FILL_INVENTORY_LOWEST_QTY = "FILL_INVENTORY_LOWEST_QTY";
export const FILL_INVENTORY_HIGHEST_VALUE = "FILL_INVENTORY_HIGHEST_VALUE";
export const FILL_INVENTORY_LOWEST_VALUE = "FILL_INVENTORY_LOWEST_VALUE";
export const FILL_INVENTORY_HIGHEST_YOU_PAY = "FILL_INVENTORY_HIGHEST_YOU_PAY";
export const FILL_INVENTORY_LOWEST_YOU_PAY = "FILL_INVENTORY_LOWEST_YOU_PAY";
export const FILL_INVENTORY_PHYSICAL = "FILL_INVENTORY_PHYSICAL";
export const FILL_INVENTORY_ECODE = "FILL_INVENTORY_ECODE";

//USER INFO
export const UPDATE_NUMBER_OF_CARDS_IN_BUY = "UPDATE_NUMBER_OF_CARDS_IN_BUY";

//USER ACTIONS
export const SET_ACTIVE_PANEL = "SET_ACTIVE_PANEL";
export const RESET_ACTIVE_PANEL = "RESET_ACTIVE_PANEL";
export const REMOVE_CREDIT_CARD_MODAL_OPEN = "REMOVE_CREDIT_CARD_MODAL_OPEN";
export const PASSWORD_MODAL_OPEN = "PASSWORD_MODAL_OPEN";
export const ADD_CARD_BOX_OPEN = "ADD_CARD_BOX_OPEN";
export const KEEP_ADD_CARD_BOX_CLOSED = "KEEP_ADD_CARD_BOX_CLOSED";

//CATEGORIES
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const SET_HOME_CATEGORIES = "SET_HOME_CATEGORIES";

export const ENABLE_COUPON = "ENABLE_COUPON";
export const RESET_COUPON = "RESET_COUPON";
export const COUPON_LOADING = "COUPON_LOADING";

//BUY CART
export const ADD_CARD_TO_BUY_CART = "ADD_CARD_TO_BUY_CART";
export const DELETE_BUY_CARD = "DELETE_BUY_CARD";
export const RECEIVE_BUY_CART = "RECEIVE_BUY_CART";
export const SET_BUY_CART_ID = "SET_BUY_CART_ID";
export const ADD_BUY_CARD = "ADD_BUY_CARD";
export const RESET_BUY_CART_COUPON = "RESET_BUY_CART_COUPON";
export const INITIAL_CART_LOADING = "INITIAL_CART_LOADING";
export const INITIAL_SELL_CART_LOADING = "INITIAL_SELL_CART_LOADING";

//INIT
export const LOAD_CONFIG = "LOAD_CONFIG";
export const RESET = "RESET";

//CARDS
export const ADD_CARD = "ADD_CARD";
export const ADD_CARD_TO_CART = "ADD_CARD_TO_CART";
export const SET_SELL_CARD_OFFER_LOADER = "SET_SELL_CARD_OFFER_LOADER";
export const DELETE_CARD = "DELETE_CARD";
export const DELETE_CARD_RUNNING = "DELETE_CARD_RUNNING";
export const CARD_PARTIAL_MERCHANT = "CARD_PARTIAL_MERCHANT";
export const CARD_PARTIAL_BALANCE = "CARD_PARTIAL_BALANCE";

export const UPDATE_CARD_PIN = "UPDATE_CARD_PIN";
export const UPDATE_CARD_NUMBER = "UPDATE_CARD_NUMBER";
export const UPDATE_THE_CARD = "UPDATE_THE_CARD";
export const CARD_PARTIAL_CLEAR = "CARD_PARTIAL_CLEAR";
export const SET_SELL_CART_ID = "SET_SELL_CART_ID";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const CLEAR_ORDER_ID = "CLEAR_ORDER_ID";
export const COMPLETE_ORDER_SELL = "COMPLETE_ORDER_SELL";
export const SORT_DROP_DOWN = "SORT_DROP_DOWN";
export const CHANGE_BUY_SEARCH_TEXT = "CHANGE_BUY_SEARCH_TEXT";

//PAYMENT
export const SET_PHONE = "SET_PHONE";
export const SET_CUSTOMER_PROFILE = "SET_CUSTOMER_PROFILE";
export const SET_SIGN_IN_ATTEMPTED = "SET_SIGN_IN_ATTEMPTED";

export const CUSTOMER_PROFILE_THUNK_RUNNING = "CUSTOMER_PROFILE_THUNK_RUNNING";
export const CUSTOMER_CALL_STATUS = "CUSTOMER_CALL_STATUS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_MARK_THUNK_RUNNING = "LOGOUT_MARK_THUNK_RUNNING";
export const LOGOUT_CLEAR_THUNK_RUNNING = "LOGOUT_CLEAR_THUNK_RUNNING";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const SET_SESSION_MESSAGE = "SET_SESSION_MESSAGE";
export const RESET_EXPIRE = "RESET_EXPIRE";

//ERRORS
export const DISPLAY_CARD_ERROR_PARTIAL = "DISPLAY_CARD_ERROR_PARTIAL";
export const DISPLAY_MAX_ERROR_PARTIAL = "DISPLAY_MAX_ERROR_PARTIAL";
export const DISPLAY_MIN_ERROR_PARTIAL = "DISPLAY_MIN_ERROR_PARTIAL";
export const DISPLAY_MERCHANT_ERROR_PARTIAL = "DISPLAY_MERCHANT_ERROR_PARTIAL";
export const DISPLAY_NO_ERROR_PARTIAL = "DISPLAY_NO_ERROR_PARTIAL";
export const DISPLAY_MAIN_PAYMENT_ERROR = "DISPLAY_MAIN_PAYMENT_ERROR";
export const DISPLAY_MAX_BALANCE_ERROR = "DISPLAY_MAX_BALANCE_ERROR";
export const DISPLAY_MIN_BALANCE_ERROR = "DISPLAY_MIN_BALANCE_ERROR";
export const DISPLAY_NO_BALANCE_ERROR = "DISPLAY_NO_BALANCE_ERROR";
export const DISPLAY_CARD_BALANCE_FORMAT_ERROR =
  "DISPLAY_CARD_BALANCE_FORMAT_ERROR";
export const CLEAR_UPDATE_CARD_SERVER_ERROR = "CLEAR_UPDATE_CARD_SERVER_ERROR";
export const SET_UPDATE_CARD_SERVER_ERROR = "SET_UPDATE_CARD_SERVER_ERROR";
export const CLEAR_CARD_AND_PIN_ENTRY = "CLEAR_CARD_AND_PIN_ENTRY";

//CONTACT INFO
export const HIDE_MAIN_PAYMENT_ERROR = "HIDE_MAIN_PAYMENT_ERROR";

//POPUPS
export const TOGGLE_VERIFICATION_BOX = "TOGGLE_VERIFICATION_BOX";
export const TOGGLE_ACCOUNT_HELP_BOX = "TOGGLE_ACCOUNT_HELP_BOX";
export const OPEN_ADD_A_CREDIT_CARD = "OPEN_ADD_A_CREDIT_CARD";
export const OPEN_ERROR_BOX = "OPEN_ERROR_BOX";
export const CLOSE_ERROR_BOX = "TOGGLE_ERROR_BOX";
export const SHOW_TWILIO_POPUP = "SHOW_TWILIO_POPUP";
export const SET_CLAIM_TYPE = "SET_CLAIM_TYPE";
export const SET_CLAIM_AMOUNT_TO_REFUND = "SET_CLAIM_AMOUNT_TO_REFUND";
export const SET_ORDER_SUPPORT_INFO = "SET_ORDER_SUPPORT_INFO";
export const OPEN_EDIT_PROFILE_MODAL = "OPEN_EDIT_PROFILE_MODAL";
export const OPEN_CANCEL_SELL_ORDER_MODAL = "OPEN_CANCEL_SELL_ORDER_MODAL";
export const OPEN_PAYMENT_DETAILS_MODAL = "OPEN_PAYMENT_DETAILS_MODAL";
export const CLOSE_SHARE_A_SALE_MODAL = "CLOSE_SHARE_A_SALE_MODAL";
export const SHOW_YOU_TUBE_POPUP = "SHOW_YOU_TUBE_POPUP";
export const SHOW_WALLET_CARD_POPUP = "SHOW_WALLET_CARD_POPUP";

//MISC
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const SET_SLICK_DEALS_SDTID = "SET_SLICK_DEALS_SDTID";
export const SET_WALLET_CARD_ID = "SET_WALLET_CARD_ID";
export const SET_VIEWED_WALLET_CARDS = "SET_VIEWED_WALLET_CARDS";

export const WALLET_OVER_THE_LIMIT = "WALLET_OVER_THE_LIMIT";
export const WALLET_EMAIL_OPENED_FROM_FAILED_CALL =
  "WALLET_EMAIL_OPENED_FROM_FAILED_CALL";
export const WALLET_CARDS = "WALLET_CARDS";

export const FILL_SELL_ORDER = "FILL_SELL_ORDER";
export const FILL_SELL_ORDER_CARDS = "FILL_SELL_ORDER_CARDS";
export const FILL_SELL_ORDERS = "FILL_SELL_ORDERS";
export const FILL_SELL_PAYMENTS = "FILL_SELL_PAYMENTS";
export const FILL_SELL_PAYMENT = "FILL_SELL_PAYMENT";
export const SET_SELL_ORDER_TO_CANCEL = "SET_SELL_ORDER_TO_CANCEL";

export const FILL_BUY_ORDER = "FILL_BUY_ORDER";
export const FILL_BUY_ORDERS = "FILL_BUY_ORDERS";
export const BUY_ORDERS_THUNK_RUNNING = "BUY_ORDERS_THUNK_RUNNING";
export const SELL_ORDERS_THUNK_RUNNING = "SELL_ORDERS_THUNK_RUNNING";
export const SET_ACH_TOOLTIP_POSITION = "SET_ACH_TOOLTIP_POSITION";
export const SET_CREDIT_CARD_TO_REMOVE = "SET_CREDIT_CARD_TO_REMOVE";
export const SET_GOOGLE_SDK_LOADED = "SET_GOOGLE_SDK_LOADED";
export const SET_FACEBOOK_SDK_LOADED = "SET_FACEBOOK_SDK_LOADED";
export const SHOW_DEVICE_VERIFY_POPUP_MODAL = "SHOW_DEVICE_VERIFY_POPUP_MODAL";

//New Gifting
export const SET_NEW_BUY_IS_LOADING = "SET_NEW_BUY_IS_LOADING";
export const SET_DROPDOWN_STATE = "SET_DROPDOWN_STATE";

export const SET_PAYOUT = "SET_PAYOUT";
export const UPDATE_COUPON_ENTER = "UPDATE_COUPON_ENTER";
export const UPDATE_COUPON_ERROR = "UPDATE_COUPON_ERROR";
export const UPDATE_COUPON_WARNING = "UPDATE_COUPON_WARNING";
export const CLOSE_ALL_SEARCH_DROPDOWN = "CLOSE_ALL_SEARCH_DROPDOWN";
export const SET_SELECT_SEARCH_VALUES = "SET_SELECT_SEARCH_VALUES";
export const SET_NAV_SEARCH_OPEN = "SET_NAV_SEARCH_OPEN";
export const SET_HOME_SEARCH_OPEN = "SET_HOME_SEARCH_OPEN";
export const SET_QUICK_SEARCH_OPEN = "SET_QUICK_SEARCH_OPEN";

export const UPDATE_ORDER_STATUS_LAST_NAME = "UPDATE_ORDER_STATUS_LAST_NAME";
export const UPDATE_ORDER_STATUS_ID = "UPDATE_ORDER_STATUS_ID";
export const GET_ORDER_INFORMATION = "GET_ORDER_INFORMATION";
export const SHOW_ERROR_ORDER_DETAILS = "SHOW_ERROR_ORDER_DETAILS";

export const HIDE_SELL_RECEIPT_MODAL = "HIDE_SELL_RECEIPT_MODAL";
export const SHOW_SELL_RECEIPT_MODAL = "SHOW_SELL_RECEIPT_MODAL";

export const SHOW_RECEIPT_MODAL = "SHOW_RECEIPT_MODAL";
export const SHOW_SIGN_IN_POPUP_MODAL = "SHOW_SIGN_IN_POPUP_MODAL";

export const HIDE_GLOBAL_LOADER_POPUP_MODAL = "HIDE_GLOBAL_LOADER_POPUP_MODAL";
export const SHOW_GLOBAL_LOADER_POPUP_MODAL = "SHOW_GLOBAL_LOADER_POPUP_MODAL";
export const SELL_ORDER_PROCESS_RUNNING = "SELL_ORDER_PROCESS_RUNNING";
export const SHOW_QUICK_BUY_POP_UP_MODAL = "SHOW_QUICK_BUY_POP_UP_MODAL";
export const SHOW_QUICK_BUY_RESULT_MODAL = "SHOW_QUICK_BUY_RESULT_MODAL";
export const SHOW_NEW_BUY_MODAL = "SHOW_NEW_BUY_MODAL";
export const SET_SLUG_NEW_BUY = "SET_SLUG_NEW_BUY";
export const SET_HISTORY_FOR_QUICK_BUY = "SET_HISTORY_FOR_QUICK_BUY";
export const SET_GLOBAL_WIDTH = "SET_GLOBAL_WIDTH";

export const SHOW_GUARANTEE_MODAL = "SHOW_GUARANTEE_MODAL";
export const HIDE_GUARANTEE_MODAL = "HIDE_GUARANTEE_MODAL";
export const SHOW_ORDER_DECLINED = "SHOW_ORDER_DECLINED";
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";

// CHECKOUT ORDERS SELL
export const CHECKOUT_ORDER_SELL_MARKORDERLOADING =
  "CHECKOUT_ORDER_SELL_MARKORDERLOADING";
export const CHECKOUT_ORDER_SELL_SETORDER = "CHECKOUT_ORDER_SELL_SETORDER";
export const CHECKOUT_ORDER_SELL_SETCARDS = "CHECKOUT_ORDER_SELL_SETCARDS";

export const FILL_INVENTORY_THUNK_RUNNING = "FILL_INVENTORY_THUNK_RUNNING";
export const UPDATE_BUY_ORDER_TOTAL = "UPDATE_BUY_ORDER_TOTAL";

// missing actions.
// NOTE: This can potensially be dangerous.
export const CC_API_ERR_RESPONSE = "CC_API_ERR_RESPONSE";

// actions to close account info dropdown
export const CLOSE_ACCOUNT_DROPDOWN = "accountGroup/close";

// actions to close mobile burgermenu
export const SET_BURGER_MENU = "SET_BURGER_MENU";

// marketing actions
export const SET_UTM_CAMPAIGN_IS_ACTIVE = "SET_UTM_CAMPAIGN_IS_ACTIVE";
export const SALE_POPUP_STATUS = "SALE_POPUP_STATUS";

//payment method actions
export const SET_BUY_PAYMENT_METHODS = "SET_BUY_PAYMENT_METHODS";
export const SET_SELL_PAYMENT_METHODS = "SET_SELL_PAYMENT_METHODS";
export const SET_DEFAULT_SELL_ACH_ACCOUNT = "SET_DEFAULT_SELL_ACH_ACCOUNT";
export const SET_ALL_PAYMENT_METHODS = "SET_ALL_PAYMENT_METHODS";
export const SET_SELECTED_PAYMENT_ID = "SET_SELECTED_PAYMENT_ID";
export const SET_ADDED_PAYMENT_METHOD_ID = "SET_ADDED_PAYMENT_METHOD_ID";

export const SET_ANYCARD_TRANSACTION_ID = "SET_ANYCARD_TRANSACTION_ID";

//cookies
export const SET_COOKIES_ENABLED = "SET_COOKIES_ENABLED";

//subscriptions
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS";

// preload
export const ADD_TO_PRELOAD = "ADD_TO_PRELOAD";
export const SET_INITIAL_LOAD = "SET_INITIAL_LOAD";
export const SET_SIGNED_IN = "SET_SIGNED_IN";
