import { ADD_MESSAGES } from "@constants/actionTypes";

const initialState = {
  topMsg: "",
  sellMsg: "",
  buyMsg: "",
  mobileMsg: "",
  customerReviews: ""
};

const popups = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGES:
      return {
        ...state,
        topMsg: action.payload.top,
        buyMsg: action.payload.buy,
        sellMsg: action.payload.sell,
        mobileMsg: action.payload.mobile,
        customerReviews: action.payload.customerReviews
      };

    default:
      return state;
  }
};

export default popups;
