import {
  GET_ALL_CATEGORIES,
  SET_HOME_CATEGORIES
} from "@constants/actionTypes";

const initialState = {
  allCategories: [],
  homeCategories: []
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return { ...state, allCategories: action.payload };
    case SET_HOME_CATEGORIES:
      return { ...state, homeCategories: action.payload };
    default:
      return state;
  }
};

export default categories;
