import React from "react";
import Resizer from "@hocs/Resizer";
import TopMessage from "./TopMessage";
import loadable from "@loadable/component";
const SessionExpire = loadable(
  () => import(/* webpackChunkName: "SessionExpire" */ "./SessionExpire"),
  { ssr: false }
);
const MobileShade = loadable(
  () => import(/* webpackChunkName: "MobileShade" */ "./MobileShade"),
  { ssr: false }
);
import { useSelector } from "react-redux";

const NavTop = ({ sessionState, scrolled }) => {
  const showSessionExpireWarning = useSelector(
    state => state.session.showSessionExpireWarning
  );
  const anycardIframeOpened = useSelector(
    state => state.customerInfo.anycardIframeOpened
  );
  const isOpen = useSelector(state => state.burgerMenu.isOpen);

  return (
    <>
      {showSessionExpireWarning && !anycardIframeOpened && (
        <SessionExpire sessionState={sessionState} />
      )}
      {isOpen && (
        <div className="mobile-menu">
          <Resizer
            components={[
              {
                component: () => <MobileShade compact={scrolled} />,
                break: 1,
                maxBreak: 1000
              }
            ]}
            defaultTrue={true}
          />
        </div>
      )}
      <TopMessage scrolled={scrolled} />
    </>
  );
};

export default NavTop;
