import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import DefaultCustomButton from "@modules/Components/DefaultCustomButton";
import { ClearedStyleRouterLink } from "@modules/Components";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import HomeCategoryCard from "./HomeCategoryCard";

const MainContainer = styled.div`
  position: relative;
  margin-top: 75px;

  @media (max-width: 1000px) {
    margin-top: 40px;
  }
`;

const CatGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  grid-column-gap: 100px;

  @media (max-width: 1550px) {
    grid-column-gap: 90px;
    grid-template-columns: ${props => props.gridTemplateColumnsSmallPc};
  }

  @media (max-width: 1400px) {
    grid-template-columns: ${props => props.gridTemplateColumnsBigTablet};
  }
  @media (max-width: 1300px) {
    grid-column-gap: 80px;
    grid-template-columns: ${props => props.gridTemplateColumnsTablet};
  }
  @media (max-width: 1000px) {
    grid-column-gap: 0px;
    grid-template-columns: 1fr;
    max-width: 556px;
    grid-row-gap: 40px;
  }
`;

const ImageContainer = styled.div`
  grid-column: ${props => props.gridColumn};
  width: 467px;
  height: 543px;
  grid-row: 1/2;
  position: relative;

  @media (max-width: 1550px) {
    width: 100%;
    height: fit-content;
  }
  @media (max-width: 1000px) {
    grid-row: 2/3;
    grid-column: 1/3;
  }
`;

const ImageAbsoluteText = styled.div`
  position: absolute;
  font-size: 24px;
  font-style: italic;
  line-height: 1.75;
  color: #ffffff;

  @media (max-width: 1550px) {
    font-size: 22px;
  }
  @media (max-width: 365px) {
    font-size: 18px;
  }
`;

const ImageTextName = styled.div`
  opacity: 0.6;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  margin-top: 20px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${props => props.borderRadius};

  @media (max-width: 1550px) {
    width: 100%;
    height: auto;
    border-radius: ${props => props.borderRadiusSmallPc};
  }
  @media (max-width: 1000px) {
    border-radius: 0px 0px 0px 55%;
  }
  @media (max-width: 450px) {
    border-radius: 0px 0px 0px 140px;
  }
`;

const CardsContainer = styled.div`
  grid-column: ${props => props.gridColumn};
  display: grid;
  grid-template-rows: 100px 1fr 60px;
  position: relative;
  padding-top: 35px;
  min-height: 530px;

  @media (max-width: 1000px) {
    grid-row: 1/2;
    grid-column: 1/3;
    min-height: 466px;
  }
  @media (max-width: 600px) {
    grid-row: 1/2;
    grid-column: 1/3;
    min-height: 495px;
  }
`;
const Border = styled.div`
  display: ${props => props.display};
  margin-top: 75px;
  grid-column: 1/3;
  height: 1px;
  width: 100%;
  opacity: 0.2;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.3),
    #ffffff 51%,
    rgba(255, 255, 255, 0.3)
  );

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
  align-items: center;
  margin-top: 18px;
  grid-gap: 20px;

  @media (max-width: 1000px) {
    display: block;
    margin-top: 26px;
    text-align: center;
  }
`;

const ArrowButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: 134px;
  position: relative;
  grid-row: 3/4;

  @media (max-width: 1000px) {
    margin: 0 auto;
  }
`;

const ArrowButton = styled.div`
  border-radius: 50%;
  border: solid 2px rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: ${props => props.cursor};

  &:hover {
    background-color: ${props => props.hoverBG};
    border: ${props => props.hoverBorder};
  }
`;

const SmallBlueLine = styled.div`
  background-color: #12b2ea;
  width: 36px;
  height: 3px;
  border-radius: 1.5px;

  @media (max-width: 1000px) {
    margin: 0 auto;
  }
`;

const SaveOn = styled.h3`
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;

  @media (max-width: 1675px) {
    font-size: 34px;
  }
  @media (max-width: 1400px) {
    font-size: 30px;
  }
  @media (max-width: 600px) {
    font-size: 30px;
  }
  @media (max-width: 340px) {
    font-size: 26px;
  }
`;

const Br = styled.br`
  @media (min-width: 601px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  width: 176px;
  justify-self: flex-end;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const ButtonContainerDesktop = styled(ButtonContainer)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const ButtonContainerMobile = styled(ButtonContainer)`
  @media (min-width: 1001px) {
    display: none;
  }
`;

const CardsMain = styled.div`
  grid-row: 2/3;
  position: absolute;
  right: ${props => props.right};
  top: 18px;

  @media (max-width: 1000px) {
    position: static;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  margin-top: 100px;
  text-align: center;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    margin-top: 85px;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-top: 115px;
  }
`;

const IndividualCard = styled.div`
  box-shadow: 0 20px 80px 0 rgba(119, 121, 132, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  width: 233px;
  height: 186px;
  @media (max-width: 1000px) {
    width: 315px;
    height: 147px;
  }
  @media (max-width: 350px) {
    width: 100%;
  }
  @media (max-width: 320px) {
    height: 158px;
  }
`;

const BlankIndividualCard = styled(IndividualCard)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const categoryText = [
  {
    text: "Love to eat out, love to save money while doing so",
    name: "Gregg Boury"
  },
  {
    text: "SUPER easy way to save money on things I’m already going to buy",
    name: "Lisa Marie"
  },
  {
    text: "I’ve saved literally hundreds of dollars",
    name: "Debbie Miller"
  }
];

const HomeCategory = ({ category, index }) => {
  const [amountOfMerchantsToDisplay, setAmountOfMerchantsToDisplay] =
    useState(1);

  const oddPattern = index % 2 ? false : true;
  const currentCategories = category.merchants;
  const categoryLength = currentCategories.length;
  const [sliceStart, setSliceStart] = useState(0);
  const [currentDisplay, changeCurrentDisplay] = useState(
    oddPattern
      ? currentCategories.slice(
          sliceStart,
          sliceStart + amountOfMerchantsToDisplay
        )
      : currentCategories
          .slice(sliceStart, sliceStart + amountOfMerchantsToDisplay)
          .reverse()
  );
  const [extraDisplay, setExtraDisplay] = useState(true);
  const [sliderClass, setSliderClass] = useState("");
  const [leftArrowColor, setLeftArrowColor] = useState("#ffffff");
  const [rightArrowColor, setRightArrowColor] = useState("#ffffff");

  useEffect(() => {
    handleResize();
  }, [globalThis?.innerWidth]);

  const handleResize = () => {
    if (globalThis?.innerWidth) {
      getLeftArrowColor(sliceStart, extraDisplay);
      getRightArrowColor(sliceStart, extraDisplay);
      const theWidth = globalThis.innerWidth;
      let current = 1;
      if (theWidth > 1400) {
        current = 3;
      } else if (theWidth > 1000) {
        current = 2;
      }
      setAmountOfMerchantsToDisplay(current);
      changeCurrentDisplay(
        oddPattern
          ? currentCategories.slice(sliceStart, sliceStart + current)
          : currentCategories.slice(sliceStart, sliceStart + current).reverse()
      );
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const forwardArrow = () => {
    if (categoryLength > sliceStart + amountOfMerchantsToDisplay) {
      let extraDisplayVar = extraDisplay;
      if (!(categoryLength > sliceStart + amountOfMerchantsToDisplay + 1)) {
        extraDisplayVar = false;
        setExtraDisplay(false);
      }
      const newSlice = sliceStart + 1;
      setSliceStart(newSlice);
      if (globalThis.innerWidth > 999) {
        changeCurrentDisplay(
          currentCategories.slice(
            newSlice,
            newSlice + amountOfMerchantsToDisplay
          )
        );
      } else {
        setSliderClass("slideOutLeft");
        setTimeout(() => {
          changeCurrentDisplay(
            currentCategories.slice(
              newSlice,
              newSlice + amountOfMerchantsToDisplay
            )
          );
          setSliderClass("slideInRight very-fast");
        }, 200);
      }
      getLeftArrowColor(newSlice, extraDisplayVar);
      getRightArrowColor(newSlice, extraDisplayVar);
    }
  };

  const backArrow = () => {
    if (sliceStart > 0) {
      setExtraDisplay(true);
      const newSlice = sliceStart - 1;
      setSliceStart(newSlice);

      if (globalThis.innerWidth > 999) {
        changeCurrentDisplay(
          currentCategories.slice(
            newSlice,
            newSlice + amountOfMerchantsToDisplay
          )
        );
      } else {
        setSliderClass("slideOutRight");
        setTimeout(() => {
          changeCurrentDisplay(
            currentCategories.slice(
              newSlice,
              newSlice + amountOfMerchantsToDisplay
            )
          );
          setSliderClass("slideInLeft medium-fast");
        }, 100);
      }
      getLeftArrowColor(newSlice, true);
      getRightArrowColor(newSlice, true);
    }
  };

  const forwardArrowAlt = () => {
    if (sliceStart > 0) {
      setExtraDisplay(true);
      const newSlice = sliceStart - 1;
      setSliceStart(newSlice);
      changeCurrentDisplay(
        currentCategories
          .slice(newSlice, newSlice + amountOfMerchantsToDisplay)
          .reverse()
      );
      getLeftArrowColor(newSlice, true);
      getRightArrowColor(newSlice, true);
    }
  };

  const backArrowAlt = () => {
    if (categoryLength > sliceStart + 3) {
      let extraDisplayVar = extraDisplay;

      if (!(categoryLength > sliceStart + 3 + 1)) {
        extraDisplayVar = false;
        setExtraDisplay(false);
      }
      const newSlice = sliceStart + 1;
      setSliceStart(newSlice);
      changeCurrentDisplay(
        currentCategories
          .slice(newSlice, newSlice + amountOfMerchantsToDisplay)
          .reverse()
      );
      getLeftArrowColor(newSlice, extraDisplayVar);
      getRightArrowColor(newSlice, extraDisplayVar);
    }
  };

  const getLeftArrowColor = (slice, bool) => {
    let color = "#ffffff";
    if (index === 1 && globalThis.innerWidth && globalThis.innerWidth > 999) {
      if (!bool) {
        color = "rgba(255, 255, 255, 0.4)";
      }
    } else {
      if (slice < 1) {
        color = "rgba(255, 255, 255, 0.4)";
      }
    }
    setLeftArrowColor(color);
  };

  const getRightArrowColor = (slice, bool) => {
    let color = "#ffffff";
    if (index === 1 && globalThis.innerWidth > 999) {
      if (slice < 1) {
        color = "rgba(255, 255, 255, 0.4)";
      }
    } else {
      if (!bool) {
        color = "rgba(255, 255, 255, 0.4)";
      }
    }
    setRightArrowColor(color);
  };

  return (
    <MainContainer
      key={index}
      className={`main-container-home-category-${index}`}
    >
      <CatGrid
        className={"main-container-page-width"}
        gridTemplateColumns={index % 2 ? "2fr 1fr" : "1fr 2fr"}
        gridTemplateColumnsSmallPc={index % 2 ? "2fr 1.2fr" : "1.2fr 2fr"}
        gridTemplateColumnsBigTablet={index % 2 ? "2fr 1.4fr" : "1.4fr 2fr"}
        gridTemplateColumnsTablet={index % 2 ? "2fr 1.6fr" : "1.6fr 2fr"}
      >
        <ImageContainer gridColumn={index % 2 ? "2/3" : "1/2"}>
          <ImageAbsoluteText className={`absolute-image-category-${index}`}>
            <div className={"absolute-image-category-text"}>
              &quot;{categoryText[index]?.text}&quot;
            </div>
            <ImageTextName className={`absolute-image-category-name`}>
              {categoryText[index]?.name}
            </ImageTextName>
          </ImageAbsoluteText>
          <ClearedStyleRouterLink to={`/buy-gift-cards/${category.url}/`}>
            <picture>
              <source
                srcSet={`${category.images.icon}.webp`}
                type="image/webp"
              />
              <source srcSet={`${category.images.icon}.png`} type="image/png" />
              <Image
                width="467"
                height="544"
                src={`${category.images.icon}.png`}
                alt={`${category.categoryName} image`}
                borderRadius={
                  index % 2 ? "0px 0px 279.3px 0px" : "0px 0px 0px 279.3px"
                }
                borderRadiusSmallPc={
                  index % 2 ? "0px 0px 55% 0px" : "0px 0px 0px 55%"
                }
                loading="lazy"
              />
            </picture>
          </ClearedStyleRouterLink>
        </ImageContainer>
        <CardsContainer gridColumn={index % 2 ? "1/2" : "2/3"}>
          <div className={"home-category-header-container"}>
            <SmallBlueLine />
            <Header>
              <div>
                <SaveOn>
                  Save on all your&nbsp;
                  <Br />
                  {category.categoryName}
                </SaveOn>
              </div>

              <ButtonContainerDesktop>
                <ClearedStyleRouterLink to={`/buy-gift-cards/${category.url}/`}>
                  <DefaultCustomButton
                    handleClick={f => f}
                    classes={"main-default-orange-button hover-white-button"}
                    type="button"
                    disabled={false}
                    buttonType={"WHITE_BUTTON_ORANGE_COLOR"}
                  >
                    <span>Shop all</span>
                  </DefaultCustomButton>
                </ClearedStyleRouterLink>
              </ButtonContainerDesktop>
            </Header>
          </div>
          <CardsMain right={index % 2 ? "0px" : ""}>
            <Cards>
              {index % 2 > 0 && extraDisplay ? <BlankIndividualCard /> : null}
              {currentDisplay.map((item, index) => (
                <HomeCategoryCard
                  merchant={item}
                  key={index}
                  sliderClass={sliderClass}
                />
              ))}
              {index % 2 === 0 && extraDisplay ? <BlankIndividualCard /> : null}
            </Cards>
          </CardsMain>
          <ArrowButtonContainer>
            <ArrowButton
              cursor={leftArrowColor === "#ffffff" ? "pointer" : "not-allowed"}
              hoverBorder={
                leftArrowColor === "#ffffff"
                  ? "none"
                  : "solid 2px rgba(255, 255, 255, 0.1)"
              }
              hoverBG={
                leftArrowColor === "#ffffff" ? "rgba(255, 255, 255, 0.1)" : ""
              }
              onClick={() => {
                if (index % 2 && globalThis.innerWidth > 999) {
                  backArrowAlt();
                } else {
                  backArrow();
                }
              }}
            >
              <FaArrowLeft style={{ color: leftArrowColor }} />
            </ArrowButton>
            <ArrowButton
              cursor={rightArrowColor === "#ffffff" ? "pointer" : "not-allowed"}
              hoverBorder={
                rightArrowColor === "#ffffff"
                  ? "none"
                  : "solid 2px rgba(255, 255, 255, 0.1)"
              }
              hoverBG={
                rightArrowColor === "#ffffff" ? "rgba(255, 255, 255, 0.1)" : ""
              }
              onClick={() => {
                if (index % 2 && globalThis.innerWidth > 999) {
                  forwardArrowAlt();
                } else {
                  forwardArrow();
                }
              }}
            >
              <FaArrowRight style={{ color: rightArrowColor }} />
            </ArrowButton>
          </ArrowButtonContainer>
        </CardsContainer>
        <Border display={index === 2 ? "none" : ""} />
        <ButtonContainerMobile>
          <ClearedStyleRouterLink to={`/buy-gift-cards/${category.url}/`}>
            <DefaultCustomButton
              handleClick={f => f}
              classes={"main-default-orange-button hover-white-button"}
              type="button"
              disabled={false}
              buttonType={"WHITE_BUTTON_ORANGE_COLOR"}
            >
              <span>Shop all</span>
            </DefaultCustomButton>
          </ClearedStyleRouterLink>
        </ButtonContainerMobile>
      </CatGrid>
    </MainContainer>
  );
};

export default HomeCategory;
