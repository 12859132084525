import { FILL_SELL_PAYMENTS, FILL_SELL_PAYMENT } from "@constants/actionTypes";

const initialState = {
  payments: {},
  payment: {}
};

const paymentsSell = (state = initialState, action) => {
  switch (action.type) {
    case FILL_SELL_PAYMENTS:
      return {
        ...state,
        payments: { ...state.payments, ...action.payload }
      };

    case FILL_SELL_PAYMENT:
      return {
        ...state,
        payment: action.payload.payment
      };

    default:
      return state;
  }
};

export default paymentsSell;
