const DEVELOPMENT = "currentEnv.development";
const INTEGRATION = "currentEnv.integration";
const PRODUCTION = "currentEnv.production";
const SSR = "currentEnv.serverSideRendering";

const LOCAL_NETWORK = new RegExp(
  [
    "^(",
    [
      "127\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}",
      "192\\.168\\.\\d{1,3}\\.\\d{1,3}",
      ".+\\.local",
      "localhost",
      "local.cardcash.com"
    ].join("|"),
    ")$"
  ].join("")
);

const _state = {};

export default function () {
  if (!global.location) return null;

  const hostname = global.location.hostname;

  if (LOCAL_NETWORK.test(hostname)) return DEVELOPMENT;

  if (hostname === "test.cardcash.com") return INTEGRATION;

  return PRODUCTION;
}

/**
 * Store value as key in store
 * Throws if:
 *  key is not a string
 *  value is nully
 * @params{String} key
 * @params{Any} value
 */
function set(key, value) {
  if (typeof key !== "string")
    throw new Error(`${value} must not be null or undefined`);
  if (value == null) throw new Error(`${key} cannot be set to a null value`);

  _state[key] = value;
}

/**
 * A strict get from the store.
 * Throws if:
 *  key is not in store.
 * @params{String} key
 * @returns{Any}
 */
function get(key) {
  if (_state[key] == null)
    throw new Error(`${key} is not defined in currentEnv.state`);

  return _state[key];
}

// Set variables provided from webpack build.
set("env", process.env.NODE_ENV);

export { DEVELOPMENT, INTEGRATION, PRODUCTION, SSR, set, get };
