import {
  SHOW_ALL_MERCHANTS,
  FILTER_BY_LETTER,
  SET_CATEGORY,
  SORT_DROP_DOWN,
  UPDATE_ORDER_STATUS_ID,
  UPDATE_ORDER_STATUS_LAST_NAME,
  CHANGE_PAYMENT_METHOD,
  RESET,
  RESET_EXPIRE,
  SET_ACTIVE_PANEL,
  RESET_ACTIVE_PANEL,
  REMOVE_CREDIT_CARD_MODAL_OPEN,
  PASSWORD_MODAL_OPEN,
  ADD_CARD_BOX_OPEN,
  KEEP_ADD_CARD_BOX_CLOSED,
  SHOW_QUICK_BUY_POP_UP_MODAL,
  SHOW_QUICK_BUY_RESULT_MODAL,
  SHOW_GUARANTEE_MODAL,
  HIDE_GUARANTEE_MODAL,
  SHOW_ORDER_DECLINED,
  SET_HISTORY_FOR_QUICK_BUY,
  CHANGE_BUY_SEARCH_TEXT,
  SHOW_NEW_BUY_MODAL,
  SET_SLUG_NEW_BUY,
  HIDE_SELL_RECEIPT_MODAL,
  SHOW_RECEIPT_MODAL,
  SHOW_SELL_RECEIPT_MODAL,
  SHOW_SIGN_IN_POPUP_MODAL,
  SHOW_GLOBAL_LOADER_POPUP_MODAL,
  HIDE_GLOBAL_LOADER_POPUP_MODAL,
  SHOW_DEVICE_VERIFY_POPUP_MODAL
} from "@constants/actionTypes";
import {
  HIDE_SIGN_IN_POPUP_MODAL,
  SET_LOADER_FOR_SIGN_IN
} from "@constants/accountTypes";

const initialState = {
  filterCards: "POPULARITY",
  selectedCategory: "",
  filterDropDown: "Most Popular",
  orderStatusId: "",
  orderStatusLastName: "",
  buySearchText: "",
  paymentType: "",
  activePanel: null,
  showEditProfileModal: false,
  showRemoveCreditCardModal: false,
  showChangePasswordModal: false,
  displayAddCardBox: true,
  showQuickBuyPopUpModal: false,
  showQuickBuyResultModal: false,
  showNewBuyPopUp: false,
  slugForNewPopUp: "",
  showGuaranteeModal: false,
  showReceiptModal: false,
  showSellReceiptModal: false,
  showSignInPopUpModal: false,
  showDeviceVerifyPopUpModal: false,
  orderDeclined: false,
  showGlobalLoaderPopup: false,
  globalPopupInitiator: "",
  signInPopUpModalInitiator: "",
  historyForQuickBuy: {},
  loaderForSignin: "",
};

const userActions = (state = initialState, action) => {
  switch (action.type) {
   

    case SET_LOADER_FOR_SIGN_IN:
      return {
        ...state,
        loaderForSignin: action.payload
      };

    case CHANGE_BUY_SEARCH_TEXT:
      return {
        ...state,
        buySearchText: action.payload
      };

    case SHOW_ALL_MERCHANTS:
      return {
        ...state,
        filterCards: "ALL"
      };

    case SORT_DROP_DOWN:
      return {
        ...state,
        filterDropDown: action.payload
      };

    case SET_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
        filterDropDown: "Most Popular",
        filterCards: "POPULARITY"
      };

    case FILTER_BY_LETTER:
      if (
        /(POPULARITY|ALL|HIGHEST_DISCOUNT|LOWEST_DISCOUNT|MOST_CARDS|LEAST_CARDS)/.test(
          action.payload
        )
      ) {
        return {
          ...state,
          filterCards: action.payload
        };
      } else {
        return {
          ...state,
          filterCards: action.payload,
          filterDropDown: "Select"
        };
      }

    case UPDATE_ORDER_STATUS_ID:
      return {
        ...state,
        orderStatusId: action.payload
      };

    case UPDATE_ORDER_STATUS_LAST_NAME:
      return {
        ...state,
        orderStatusLastName: action.payload
      };

    case CHANGE_PAYMENT_METHOD:
      return {
        ...state,
        paymentType: action.payload
      };

    case SET_ACTIVE_PANEL:
      return {
        ...state,
        activePanel: action.payload
      };

    case RESET_ACTIVE_PANEL:
      return {
        ...state,
        activePanel: null
      };

    case REMOVE_CREDIT_CARD_MODAL_OPEN:
      return {
        ...state,
        showRemoveCreditCardModal: action.payload
      };

    case PASSWORD_MODAL_OPEN:
      return {
        ...state,
        showChangePasswordModal: action.payload
      };

    case ADD_CARD_BOX_OPEN:
      return {
        ...state,
        displayAddCardBox: action.payload
      };

    case KEEP_ADD_CARD_BOX_CLOSED:
      return {
        ...state,
        keepAddCardBoxClosed: action.payload
      };

    case SHOW_QUICK_BUY_POP_UP_MODAL:
      return {
        ...state,
        showQuickBuyPopUpModal: action.payload
      };

    case SHOW_NEW_BUY_MODAL:
      return {
        ...state,
        showNewBuyPopUp: action.payload
      };

    case SET_SLUG_NEW_BUY:
      return {
        ...state,
        slugForNewPopUp: action.payload
      };

    case SHOW_QUICK_BUY_RESULT_MODAL:
      return {
        ...state,
        showQuickBuyResultModal: action.payload
      };

    case SHOW_GUARANTEE_MODAL:
      return {
        ...state,
        showGuaranteeModal: true,
        guaranteeForever: action.payload.foreverGuarantee
      };

    case HIDE_GUARANTEE_MODAL:
      return {
        ...state,
        showGuaranteeModal: false
      };

    case SHOW_ORDER_DECLINED:
      return {
        ...state,
        orderDeclined: action.payload
      };

    case SHOW_RECEIPT_MODAL:
      return {
        ...state,
        showReceiptModal: action.payload
      };

    case SHOW_SELL_RECEIPT_MODAL:
      return {
        ...state,
        showSellReceiptModal: true,
        resourceId: action.payload
      };

    case HIDE_SELL_RECEIPT_MODAL:
      return {
        ...state,
        showSellReceiptModal: false
      };

    case SHOW_SIGN_IN_POPUP_MODAL:
      return {
        ...state,
        showSignInPopUpModal: true,
        signInPopUpModalInitiator: action.payload
      };

    case HIDE_SIGN_IN_POPUP_MODAL:
      return {
        ...state,
        showSignInPopUpModal: false
      };

    case SHOW_DEVICE_VERIFY_POPUP_MODAL:
      return {
        ...state,
        showDeviceVerifyPopUpModal: action.payload
      };

    case HIDE_GLOBAL_LOADER_POPUP_MODAL:
      return {
        ...state,
        showGlobalLoaderPopup: false,
        globalPopupInitiator: ""
      };

    case SHOW_GLOBAL_LOADER_POPUP_MODAL:
      return {
        ...state,
        showGlobalLoaderPopup: true,
        globalPopupInitiator: action.payload
      };

    case SET_HISTORY_FOR_QUICK_BUY:
      return {
        ...state,
        historyForQuickBuy: action.payload
      };
    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};

export default userActions;
