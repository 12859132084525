import React, { useState } from "react";
import FeaturedCard from "./FeaturedCard";
import Link from "@modules/Components/Link";
import styled from "@emotion/styled";
import "../styles/mostPopularCard.scss";
import { useSelector } from "react-redux";

const HeaderLine = styled.div`
  width: 36px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #12b2ea;
  margin: 0 auto;
`;

const CardHeader = styled.h2`
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: #1f2021;
  margin-top: 70px;
  margin-bottom: 50px;
  @media (max-width: 1175px) {
    font-size: 33px;
    margin-top: 44px;
  }
  @media (max-width: 575px) {
    width: 222px;
    font-size: 30px;
    margin: 44px auto 30px auto;
  }
`;

const PopularCardsDiv = styled.div`
  z-index: 10;
  position: relative;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  height: 328px;
  @media (max-width: 1650px) {
    height: 296px;
  }
  @media (max-width: 950px) {
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 50px;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    width: 75%;
    margin: 0 auto;
  }
  @media (max-width: 500px) {
    width: 315px;
    grid-row-gap: 35px;
  }
  @media (max-width: 350px) {
    width: 295px;
  }
  @media (max-width: 330px) {
    width: 100%;
  }
`;

const ButtonDiv = styled.div`
  margin-top: -64px;

  @media (max-width: 1650px) {
    margin-top: -40px;
  }
  @media (max-width: 1400px) {
    margin-top: -55px;
  }
  @media (max-width: 1375px) {
    margin-top: -90px;
  }
  @media (max-width: 950px) {
    margin-top: 88px;
  }
`;

const CardContainer = styled.div`
  max-width: 70%;
  margin: 0 auto;
  @media (max-width: 1750px) {
    max-width: 75%;
  }
  @media (max-width: 1600px) {
    max-width: 80%;
  }
  @media (max-width: 1375px) {
    max-width: 70%;
  }
  @media (max-width: 1250px) {
    max-width: 85%;
  }
  @media (max-width: 950px) {
    height: auto;
    max-width: 100%;
  }
  @media (max-width: 450px) {
    padding-bottom: 0px;
  }
`;

const BrowseButton = styled.p`
  width: 218px;
  height: 72px;
  border-radius: 36px;
  box-shadow: 0 9px 18px 0 rgba(31, 32, 33, 0.05);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #fff9ef;
  }
  @media (max-width: 1400px) {
    width: 200px;
    height: 62px;
  }
  @media (max-width: 990px) {
    width: 151px;
    height: 50px;
    font-size: 14px;
  }
  @media (max-width: 950px) {
    width: 218px;
    height: 72px;
    font-size: 16px;
  }
  @media (max-width: 500px) {
    width: 315px;
  }
  @media (max-width: 350px) {
    width: 295px;
  }
  @media (max-width: 330px) {
    width: 100%;
  }
`;

const CardContainerInnerMain = styled.div`
  @media (max-width: 950px) {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
  @media (max-width: 728px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const MostPopularCards = ({ title, classFromParent }) => {
  const popularMerchants = useSelector(
    state => state.merchantsBuy?.popularMerchants || []
  );

  const [hoverState, changehoverState] = useState(11);

  const changeHovering = bool => {
    const zIndex = bool ? 0 : 11;
    changehoverState(zIndex);
  };

  if (popularMerchants.length === 0) {
    return <div />;
  }

  return (
    <section className={`content ${classFromParent}`}>
      <CardContainer className="card-container">
        <CardContainerInnerMain className="card-container-inner-main">
          <HeaderLine />
          <CardHeader>{title}</CardHeader>
          <PopularCardsDiv className={"popular-cards-div"}>
            {popularMerchants.map((card, index) => (
              <FeaturedCard
                notifyOfHover={changeHovering}
                key={index}
                img={card.image.replace(
                  "https://s3.amazonaws.com/new-cardcash-images/images/merchants/",
                  "https://cdn.cardcash.com/images/merchants/175x113/"
                )}
                card={card}
              />
            ))}
          </PopularCardsDiv>
          <ButtonDiv>
            <BrowseButton
              className="button-transition-hover-bgColor"
              onClick={() => {
                ga &&
                  ga(
                    "send",
                    "event",
                    "HomePage",
                    "PopularStores",
                    "PopularStoresButton"
                  );
              }}
            >
              <Link
                style={{
                  cursor: "pointer",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  color: "#ff9c1b",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "36px",
                  textDecoration: "none",
                  zIndex: hoverState
                }}
                to="/buy-gift-cards/"
              >
                {" "}
                Browse All Brands{" "}
              </Link>
            </BrowseButton>
          </ButtonDiv>
        </CardContainerInnerMain>
      </CardContainer>
    </section>
  );
};

export default MostPopularCards;
