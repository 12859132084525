import {
  ADD_BUY_CARD,
  DELETE_BUY_CARD,
  RESET,
  RESET_EXPIRE,
  RESET_BUY
} from "@constants/actionTypes";

const cardsBuy = (state = {}, action) => {
  switch (action.type) {
    case ADD_BUY_CARD:
      return { ...action.payload };

    case DELETE_BUY_CARD:
      delete state[action.payload];
      return { ...state };

    case RESET:
    case RESET_EXPIRE:
    case RESET_BUY:
      return {};

    default:
      return state;
  }
};

export default cardsBuy;
