import React from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
const BannerViewExtraHeader = loadable(() =>
  import(
    /* webpackChunkName: "BannerViewExtraHeader" */ "./BannerViewExtraHeader"
  )
);
const MobileMainSearch = loadable(() =>
  import(/* webpackChunkName: "MobileMainSearch" */ "./MobileMainSearch")
);

const NavBottom = ({ navSearchOpen }) => {
  const bannersList = useSelector(state => state.banners.bannersList);
  const userMobile = useSelector(state => state.config.userAgent.isMobile);

  return (
    <>
      {bannersList.length > 0 &&
        (!navSearchOpen || (navSearchOpen && !userMobile)) && (
          <BannerViewExtraHeader />
        )}

      {navSearchOpen && <MobileMainSearch />}
    </>
  );
};

export default NavBottom;
