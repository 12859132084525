import React, { useState } from "react";
import styled from "@emotion/styled";

const AnchorTag = styled.a`
  @media (max-width: 950px) {
    width: 100%;
    min-width: 40px;
  }
`;

const Image = styled.img`
  margin-right: 8px;
  width: 36px;
  height: 36px;
`;

const SocialImage = ({ src, hoverImage, name, href }) => {
  const [imageSrc, changeImageSrc] = useState(src);
  return (
    <AnchorTag
      onMouseEnter={() => changeImageSrc(hoverImage)}
      onMouseLeave={() => changeImageSrc(src)}
      onClick={() =>
        ga && ga("send", "event", "outboundLinks", "socialFollow", name)
      }
      target="_blank"
      href={href}
      rel="noreferrer"
    >
      <Image
        src={imageSrc}
        alt={name}
        className="social-icon-image"
        loading="lazy"
      />
    </AnchorTag>
  );
};

export default SocialImage;
