import {
  SET_CUSTOMER_PROFILE,
  CUSTOMER_PROFILE_THUNK_RUNNING,
  CUSTOMER_CALL_STATUS,
  SET_SIGN_IN_ATTEMPTED,
  SET_OPEN_ORDERS,
  SET_SIGNED_IN,
  LOGOUT,
  ANYCARD_IFRAME_OPENED
} from "@constants/actionTypes";
import { sendBugsnagError } from "@utils/Bugsnag";
import { errorResponseMessage } from "./errorResponseMessage";
import { defaultAction } from "./defaultAction";
import { isSchemaError } from "./isSchemaError";
import { SET_REFER_COUPON } from "@constants/refer";

export const getCustomerProfile =
  actionType => async (dispatch, getState, api) => {
    if (actionType !== "no-actions") {
      dispatch(defaultAction(CUSTOMER_PROFILE_THUNK_RUNNING, true));
    }
    try {
      const customerProfile = await api.get("v3/customers");
      dispatch({ type: SET_CUSTOMER_PROFILE, payload: customerProfile.data });
      dispatch({ type: SET_REFER_COUPON, payload: customerProfile.data });
      dispatch({
        type: SET_OPEN_ORDERS,
        payload: customerProfile.data.customer.openOrders
      });
      dispatch(defaultAction(SET_SIGNED_IN));
      if (actionType !== "no-actions") {
        dispatch(defaultAction(CUSTOMER_PROFILE_THUNK_RUNNING, false));
        dispatch(defaultAction(CUSTOMER_CALL_STATUS, "success"));
        dispatch(defaultAction(SET_SIGN_IN_ATTEMPTED));
      }
      return "success";
    } catch (error) {
      if (
        getState().customerInfo.anycardIframeOpened &&
        typeof CardCash !== "undefined" &&
        CardCash.close
      ) {
        CardCash.close();
        dispatch(defaultAction(ANYCARD_IFRAME_OPENED, false));
      }
      if (getState().initAppReducer.signedIn) {
        dispatch(defaultAction(LOGOUT));
      }

      if (actionType !== "no-actions") {
        dispatch(defaultAction(CUSTOMER_CALL_STATUS, "failed"));
        dispatch(defaultAction(CUSTOMER_PROFILE_THUNK_RUNNING, false));
        dispatch(defaultAction(SET_SIGN_IN_ATTEMPTED));
      }

      if (error?.response?.status && error.response.status !== 401) {
        sendBugsnagError(error, "actions/getCustomerProfile");
      }
      let errorMessage = errorResponseMessage(error);
      if (!errorMessage || isSchemaError(errorMessage)) {
        errorMessage =
          "Something went wrong, please check your information and try again.";
      }
      return { status: error?.response?.status || null, error: errorMessage };
    }
  };
