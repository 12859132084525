import Service, { globalLinker } from "../Service";

export default class SiftScienceService extends Service {
  constructor() {
    super("sift-science");
    this.forceLoad = false;
  }

  get variables() {
    return ["_sift"].reduce(globalLinker, {});
  }

  afterScript({ config, session }) {
    if (!config.siftScienceBeacon) return; //if Sift beacon is not set up in config, do not load the script
    const _session_id = session.sessionId; // Set to a unique session ID for the visitor's current browsing session.
    const _sift = (global._sift = global._sift || []);
    _sift.push(["_setAccount", config.siftScienceBeacon]);
    _sift.push(["_setSessionId", _session_id]);
    _sift.push(["_setUserId", ""]);
    _sift.push(["_trackPageview"]);
    this.script.src = `${this.protocol}cdn.sift.com/s.js`;
  }
}
