import {
  FIND_TRACKING_DETAILS,
  LOAD_BUY_RECEIPT,
  FILL_BUY_RECEIPT_INFO,
  RESET,
  RESET_EXPIRE,
  CLEAR_RECEIPT_BUY
} from "@constants/buyReceipt";

const initialState = {
  name: "",
  lastName: "",
  street: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
  email: "",
  purchasedCards: {},
  orderId: "",
  paymentType: "",
  coupon: "",
  couponDiscount: "",
  paymentStatus: "",
  trackingData: {},
  uuid: ""
};

const buyReceipt = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BUY_RECEIPT:
      let street2 = action.payload.editShipping.confirmStreet2 || "";
      return {
        ...state,
        name:
          action.payload.editShipping.confirmFirstname +
          " " +
          action.payload.editShipping.confirmLastname,
        city: action.payload.editShipping.confirmCity,
        state: action.payload.editShipping.confirmState,
        zip: action.payload.editShipping.confirmZip,
        email: action.payload.customerInfo.email,
        street: action.payload.editShipping.confirmStreet + " " + street2,
        orderId: action.payload.orderId,
        purchasedCards: action.payload.cardsBuy,
        paymentType: action.payload.userActions.paymentType.toLowerCase(),
        coupon: action.payload.coupon.coupon,
        couponDiscount: action.payload.coupon.discount,
        lastName: action.payload.editShipping.confirmLastname
      };

    case FIND_TRACKING_DETAILS:
      return {
        ...state,
        trackingData: action.payload
      };

    case FILL_BUY_RECEIPT_INFO:
      return {
        ...state,
        name: action.payload.shippingDetails.name,
        city: action.payload.shippingDetails.city,
        street: action.payload.shippingDetails.address,
        zip: action.payload.shippingDetails.zip,
        state: action.payload.shippingDetails.state,
        paymentType: action.payload.paymentDetails.method,
        paymentDetails: action.payload.paymentDetails,
        total: action.payload.total,
        uuid: action.payload.id
      };

    case RESET:
    case RESET_EXPIRE:
    case CLEAR_RECEIPT_BUY:
      return initialState;

    default:
      return state;
  }
};

export default buyReceipt;
