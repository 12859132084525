import { createSelector } from "reselect";

export const data = state => state.messages;

/**
 * @typedef {Object} Messages
 *
 * @typedef {Object} Message
 * @property {string} title
 * @property {string} message
 */

/**
 * @param {Messages} state
 * @return {Message}
 */
export const message = ({ messages: { title, content } }) =>
  title && content ? { title, content } : null;

export const isOpen = createSelector(data, data => data?.open);

export const title = createSelector(data, data => data?.title);

export const content = createSelector(data, data => data?.content);
