import { path } from "ramda";

export const errorResponseMessage = error => {
  if (typeof error == "string") {
    return error;
  }
  return (
    path(["response", "data", "message", 0], error) ||
    path(["message"], error) ||
    null
  );
};
