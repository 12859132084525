import React from "react";
import styled from "@emotion/styled";

const SearchSpinner = styled.img`
  width: 20px;
  height: 20px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SmallButtonSpinner = ({ img }) => (
  <SpinnerContainer>
    <SearchSpinner className="spinner-rotate" src={img} alt="" />
  </SpinnerContainer>
);
export default SmallButtonSpinner;
