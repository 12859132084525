import { SET_SIMILAR_MERCHANTS } from "@constants/actionTypes";

const initialState = {
  similarMerchants: []
};

const similarMerchants = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIMILAR_MERCHANTS:
      return {
        ...state,
        similarMerchants: action.payload
      };

    default:
      return state;
  }
};

export default similarMerchants;
