import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import sessionReducer from "./sessionReducer";
import merchantsBuy from "./merchantsBuy";
import config from "./config";
import customerInfo from "./customerInfo";
import orderStatus from "./orderStatus";
import categories from "./categories";
import cardPartial from "./cardPartial";
import cartBuy from "./cartBuy";
import userActions from "./userActions";
import userInfo from "./userInfo";
import popups from "./popups";
import refer from "./refer";
import popupsActions from "./popupsActions";
import offers from "./offers";
import cartSell from "./cartSell";
import cards from "./cards";
import merchantsSell from "./merchantsSell";
import merchantsNew from "./merchantsNew";
import cardsBuy from "./cardsBuy";
import inventory from "./inventory";
import preload from "./preload";
import coupon from "./coupon";
import orderAssistance from "./orderAssistance";
import paymentsSell from "./paymentsSell";
import ach from "./ach";
import editShipping from "./shippingEdit";
import checkoutOrdersSell from "./checkoutOrdersSell";
import receiptBuy from "./buyReceipt";
import ordersBuy from "./ordersBuy";
import paymentMethods from "./paymentMethods";
import ordersSell from "./ordersSell";
import banners from "./banners";
import logos from "./logos";
import wallets from "./wallets";
import similarMerchants from "./similarMerchants";
import ipInfo from "./ipInfo";
import marketing from "./marketing";
import newGiftCardPartial from "./newGiftCardPartial";
import cookies from "./cookies";
import subscriptions from "./subscriptions";
import emissionReducer from "@modules/Emission";
import orderVerificationReducer from "@modules/OrderVerification/reducer";
import checkoutReducer from "@modules/Checkout/reducer";
import messagesReducer from "@modules/Messages/reducer";
import quick from "@modules/QuickBuy/reducer";
import nav from "@modules/UI/Nav/state";
import initAppReducer from "./initAppReducer";
import burgerMenu from "./burgerMenu";
import cardKnox from "./cardKnox";

export default combineReducers({
  merchantsBuy,
  config,
  customerInfo,
  orderStatus,
  ipInfo,
  categories,
  initAppReducer,
  cartBuy,
  userActions,
  userInfo,
  popups,
  popupsActions,
  cardPartial,
  offers,
  cartSell,
  cards,
  cardsBuy,
  banners,
  cookies,
  logos,
  wallets,
  preload,
  merchantsSell,
  merchantsNew,
  inventory,
  refer,
  coupon,
  orderAssistance,
  paymentsSell,
  ach,
  editShipping,
  checkoutOrdersSell,
  receiptBuy,
  ordersBuy,
  paymentMethods,
  ordersSell,
  newGiftCardPartial,
  marketing,
  burgerMenu,
  subscriptions,
  session: sessionReducer,
  form: formReducer,
  emission: emissionReducer,
  orderVerification: orderVerificationReducer,
  checkout: checkoutReducer,
  messages: messagesReducer,
  quick,
  nav,
  similarMerchants,
  cardKnox
});
