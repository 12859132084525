import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const IpErrorText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 660px) {
    display: block;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 8px;
  }
  @media (max-width: 341px) {
    font-size: 13px;
  }
`;

const IpErrorImageSpan = styled.span`
  margin-right: 6px;
`;

const IpErrorBold = styled.span`
  font-weight: bold;
`;

const IpSpan = styled.span`
  @media (max-width: 660px) {
    display: block;
  }
`;

const TopMessageIpMessage = () => {
  const country = useSelector(state => state.ipInfo.country);

  const usIpAddress = !country || country.toLowerCase() === "us" ? true : false;

  return (
    <IpErrorText>
      <IpErrorImageSpan>
        <img
          src={
            !usIpAddress
              ? "https://cdn.cardcash.com/website/ui/elements/world-icon.svg"
              : "https://cdn.cardcash.com/website/ui/elements/cookie-icon.svg"
          }
          alt=""
        />
      </IpErrorImageSpan>
      <IpErrorBold>
        {!usIpAddress
          ? "Hey, it looks like you’re outside of the US."
          : "Please enable cookies and refresh your page"}
      </IpErrorBold>
      <IpSpan>
        &nbsp;
        {!usIpAddress
          ? "Some of the features may not work in your country."
          : "to continue"}
      </IpSpan>
    </IpErrorText>
  );
};

export default TopMessageIpMessage;
