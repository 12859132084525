import {
  WALLET_CARDS,
  SET_WALLET_CARD_ID,
  WALLET_OVER_THE_LIMIT,
  SET_VIEWED_WALLET_CARDS,
  WALLET_EMAIL_OPENED_FROM_FAILED_CALL,
  RESET,
  RESET_EXPIRE,
  LOGOUT,
  SHOW_WALLET_CARD_POPUP
} from "@constants/actionTypes";

const initialState = {
  walletCardId: "",
  walletOverTheLimit: false,
  emailCodeOpenedFromFailedCall: false,
  showWalletCardPopUp: false,
  viewedWalletCards: [],
  walletCards: []
};

const wallets = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLET_CARD_ID:
      return {
        ...state,
        walletCardId: action.payload
      };
    case SET_VIEWED_WALLET_CARDS:
      let newViewedArray = [...state.viewedWalletCards];
      const findIt = state.viewedWalletCards.find(i => i === action.payload);

      if (!findIt) {
        newViewedArray.push(action.payload);
      }
      return {
        ...state,
        viewedWalletCards: [...newViewedArray]
      };

    case WALLET_OVER_THE_LIMIT:
      return {
        ...state,
        walletOverTheLimit: action.payload
      };

    case WALLET_EMAIL_OPENED_FROM_FAILED_CALL:
      return {
        ...state,
        emailCodeOpenedFromFailedCall: action.payload
      };

    case SHOW_WALLET_CARD_POPUP:
      return {
        ...state,
        showWalletCardPopUp: action.payload
      };

    case WALLET_CARDS:
      return {
        ...state,
        walletCards: action.payload
      };

    case RESET_EXPIRE:
    case LOGOUT:
    case RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
export default wallets;
