import { debounce } from "lodash";

class MotionSensor {
  constructor({ threshold = 60, delay = 200 } = {}) {
    this.threshold = threshold;
    this.delay = delay;
    this.watchers = [];
    this._onMotion = debounce(this._onMotion.bind(this), delay);
  }

  _onMotion(e) {
    this.watchers.forEach(watcher => watcher(e.type));
  }

  watch(fn) {
    this.watchers.push(fn);
    return () => {
      this.watchers.splice(this.watchers.indexOf(fn), 1);
    };
  }

  sense() {
    window.addEventListener("scroll", this._onMotion);
    window.addEventListener("resize", this._onMotion);
    return this;
  }

  ignore() {
    window.removeEventListener("scroll", this._onMotion);
    window.removeEventListener("resize", this._onMotion);
    return this;
  }

  dull() {
    this.ignore();
  }

  drop() {
    this.ignore();
  }
}

export default MotionSensor;
