import {
  ADD_VERIFICATION_ATTEMPT,
  IS_LOADING,
  NOT_LOADING,
  SET_EXPIRED,
  SET_LAST_ATTEMPT,
  SET_ORDER_ID,
  SET_ORDER_UUID_AND_TYPE,
  SET_PENDING,
  SET_PHONE_NUMBERS,
  SET_SELECTED_PHONE_NUMBER_ID,
  SET_STATUS,
  SET_VALIDATION_TOKEN,
  SET_FLOW,
  FLOW_NEXT,
  FLOW_RESET,
  FLOW_GO,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_PHONE_TYPE,
  SET_SUBMIT_ATTEMPTS,
  RESET
} from "./constant";

export const setPhoneType = phoneType => ({
  type: SET_PHONE_TYPE,
  phoneType
});

export const setSubmitAttempts = submitAttempts => ({
  type: SET_SUBMIT_ATTEMPTS,
  submitAttempts
});

export const setPhoneNumbers = phoneNumbers => ({
  type: SET_PHONE_NUMBERS,
  phoneNumbers
});

export const selectPhoneNumber = phoneNumber => ({
  type: SET_SELECTED_PHONE_NUMBER_ID,
  phoneNumber
});

export const setLastAttempt = (validationType, phoneUUID, validationToken) => ({
  type: SET_LAST_ATTEMPT,
  validationType,
  phoneUUID,
  validationToken
});

export const setOrderId = orderId => ({
  type: SET_ORDER_ID,
  orderId
});

export const setOrderUUIDAndType = ({ orderUUID, orderType }) => ({
  type: SET_ORDER_UUID_AND_TYPE,
  orderUUID,
  orderType
});

export const isLoading = () => ({ type: IS_LOADING });
export const notLoading = () => ({ type: NOT_LOADING });

export const setExpired = () => ({
  type: SET_EXPIRED
});

export const setPending = () => ({
  type: SET_PENDING
});

export const setStatus = status => ({
  type: SET_STATUS,
  status
});

export const addVerificationAttempt = payload => ({
  type: ADD_VERIFICATION_ATTEMPT,
  payload
});

export const setValidationToken = token => ({
  type: SET_VALIDATION_TOKEN,
  token
});

export const setFlow = flow => ({
  type: SET_FLOW,
  flow
});

export const flowNext = () => ({
  type: FLOW_NEXT
});

export const flowReset = () => ({
  type: FLOW_RESET
});

export const flowGo = step => ({
  type: FLOW_GO,
  step
});

export const openModal = () => ({
  type: OPEN_MODAL
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});

export const reset = () => ({
  type: RESET
});
