import {
  SET_SUBSCRIPTIONS,
  SET_SUBSCRIPTION_STATUS
} from "@constants/actionTypes";

const initialState = {
  customerId: "",
  email: "",
  firstName: "",
  isSubscribed: null,
  lastName: "",
  subscriberId: null
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        ...action.payload
      };

    case SET_SUBSCRIPTION_STATUS:
      return {
        ...state,
        isSubscribed: action.payload
      };

    default:
      return state;
  }
};
export default subscriptions;
