import { errorResponseMessage } from "./errorResponseMessage";
import { defaultAction } from "./defaultAction";
import { SET_COOKIES_ENABLED } from "@constants/actionTypes";
import { sendBugsnagError } from "@utils/Bugsnag";

export const handleError = (error, source) => (dispatch, getState) => {
  const message = errorResponseMessage(error) || "Something went wrong";

  const { cookies, config } = getState();
  const { x_cc_app } = config;
  let iterations = 0;
  const itrvl = setInterval(() => {
    if (
      iterations++ === 60 &&
      !globalThis?.document?.cookie?.includes(x_cc_app)
    ) {
      dispatch(defaultAction(SET_COOKIES_ENABLED, false));
      sendBugsnagError(new Error("x_cc_app cookie not found"), source, {
        cookie: globalThis?.document?.cookie,
        cookieEnabled: globalThis?.navigator?.cookieEnabled
      });
      clearInterval(itrvl);
    } else if (globalThis?.document?.cookie?.includes(x_cc_app)) {
      clearInterval(itrvl);
      if (!cookies?.cookiesEnabled) {
        dispatch(defaultAction(SET_COOKIES_ENABLED, true));
      }
    }
  }, 400);
  sendBugsnagError(message, source, {
    cookie: globalThis?.document?.cookie,
    cookieEnabled: globalThis?.navigator?.cookieEnabled
  });
};
