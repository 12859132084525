import React from "react";
import styled from "@emotion/styled";
import classNames from "classnames";

const OrangeStyled = styled.div`
  width: 278px;
  height: 60px;
  border-radius: 36px;
  box-shadow: ${props =>
    props.disabled ? "none" : "0 9px 18px 0 rgba(253, 165, 54, 0.3)"};
  background-color: ${props => (props.disabled ? "#f2f2f3" : "#ff9c1b")};
  font-size: 16px;
  font-weight: bold;
  color: ${props => (props.disabled ? "rgba(119, 121, 132, 0.5)" : "#ffffff")};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    box-shadow: ${props =>
      props.disabled ? "none" : "0 9px 18px 0 rgba(253, 165, 54, 0.3)"};
    background-color: ${props => (props.disabled ? "#f2f2f3" : "#f4961b")};
  }
  &:active {
    box-shadow: ${props =>
      props.disabled ? "none" : "0 9px 18px 0 rgba(253, 165, 54, 0.3)"};
    background-color: ${props => (props.disabled ? "#f2f2f3" : "#e9901b")};
  }
`;

const DefaultOrangeButton = ({
  children,
  disabled,
  handleClick = f => f,
  classes,
  setId = ""
}) => (
  <OrangeStyled
    onClick={e => handleClick(disabled, e)}
    disabled={disabled}
    className={classNames(
      disabled ? "" : "button-transition-hover-bgColor-boxShadow",
      classes
    )}
    id={setId}
  >
    {children}
  </OrangeStyled>
);

export default DefaultOrangeButton;
