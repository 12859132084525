import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import "../../styles/reactSelect.scss";
import { SET_NAV_SEARCH_OPEN } from "@constants/actionTypes";
import { defaultAction } from "@actions/defaultAction";
import { filter } from "@utils/fuzzySearch";
import NavSearchInputContainer from "./NavSearchInputContainer";
import NavSearchAmountInput from "./NavSearchAmountInput";
import NavSearchButtonDiv from "./NavSearchButtonDiv";
import NavSearchMainButtonContainer from "./NavSearchMainButtonContainer";
import NavSearchMerchantOptions from "./NavSearchMerchantOptions";

const MainSearch = styled.div`
  display: grid;
  grid-template-columns: 292px auto 108px;
  font-size: 14px;
  color: rgb(51, 51, 51);
  position: relative;
  @media (max-width: 999px) {
    grid-template-columns: 248px auto auto;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1.4fr 1fr auto;
  }
  @media (max-width: 400px) {
    grid-template-columns: 1.7fr 1fr auto;
  }
  @media (max-width: 330px) {
    grid-template-columns: 1.8fr 1fr auto;
  }
`;

const NavSearch = ({ view }) => {
  const mobileView = view === "mobileView";
  const dispatch = useDispatch();
  const amountRef = useRef(null);

  const sortedByName = useSelector(
    state => state.merchantsBuy?.sortedByName || []
  );
  const [sortedMerchants, setSortedMerchants] = useState(
    sortedByName.filter(item => item.cardsAvailable > 0)
  );
  const navSearchOpen = useSelector(state => state.nav.initNav.navSearchOpen);

  const [amountBorder, setAmountBorder] = useState(
    "solid 2px rgba(31, 32, 33, 0.1)"
  );
  const [merchantFocused, setMerchantFocused] = useState(false);
  const [amountFocused, setAmountFocused] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState();
  const [selectedAmount, setSelectedAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [pattern, setPattern] = useState("");
  const [inputValue, changeInputValue] = useState("");
  useEffect(() => {
    if (!navSearchOpen) {
      setShowButtons(true);
    }
  }, [navSearchOpen]);

  useEffect(() => {
    window.addEventListener("blur", handleWindowBlur);

    return () => window.removeEventListener("blur", handleWindowBlur);
  }, []);

  useEffect(() => {
    if (pattern && sortedByName.length > 0) {
      setSortedMerchants(
        filter({ merchants: sortedByName, pattern, threshold: -1500 })
      );
    } else {
      setSortedMerchants(sortedByName.filter(item => item.cardsAvailable > 0));
    }
  }, [pattern]);

  const handleWindowBlur = () => {
    if (navSearchOpen) {
      dispatch(defaultAction(SET_NAV_SEARCH_OPEN, false));
    }
  };

  const reset = () => {
    setAmountBorder("solid 2px rgba(31, 32, 33, 0.1)");
    setMerchantFocused(false);
    setAmountFocused(false);
    setSelectedMerchant();
    setSelectedAmount("");
    setLoading(false);
    setShowMenu(false);
    setShowButtons(true);
    setPattern("");
    changeInputValue("");
    dispatch(defaultAction(SET_NAV_SEARCH_OPEN, false));
  };

  const handleMerchantClick = mer => {
    setSelectedMerchant(mer);
    setShowMenu(false);
    setShowButtons(false);
    changeInputValue("");
    if (mer.cardsAvailable > 0) {
      amountRef.current.focus();
    }
  };

  return (
    <MainSearch
      className={`main-search-select ${
        navSearchOpen ? "main-search-select-open" : "main-search-select-close"
      }`}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <NavSearchInputContainer
        selectedMerchant={selectedMerchant}
        inputValue={inputValue}
        changeInputValue={changeInputValue}
        merchantFocused={merchantFocused}
        setMerchantFocused={setMerchantFocused}
        mobileView={mobileView}
        reset={reset}
        setPattern={setPattern}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showButtons={showButtons}
        setShowButtons={setShowButtons}
      />

      {navSearchOpen && (
        <NavSearchAmountInput
          amountBorder={amountBorder}
          setAmountBorder={setAmountBorder}
          merchantFocused={merchantFocused}
          amountFocused={amountFocused}
          amountRef={amountRef}
          setSelectedAmount={setSelectedAmount}
          selectedAmount={selectedAmount}
          mobileView={mobileView}
          setAmountFocused={setAmountFocused}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      )}
      {navSearchOpen && (
        <NavSearchButtonDiv
          loading={loading}
          setLoading={setLoading}
          selectedMerchant={selectedMerchant}
          selectedAmount={selectedAmount}
          setSelectedMerchant={setSelectedMerchant}
          mobileView={mobileView}
          reset={reset}
        />
      )}
      {showButtons && !showMenu && navSearchOpen && (
        <NavSearchMainButtonContainer />
      )}
      {showMenu && navSearchOpen && (
        <NavSearchMerchantOptions
          sortedMerchants={sortedMerchants}
          handleMerchantClick={handleMerchantClick}
        />
      )}
    </MainSearch>
  );
};

export default NavSearch;
