import { SET_SESSION_MESSAGE } from "@client/constants/actionTypes";

const SET_RENEWAL_TIME = "session.setRenewalTime";
const SET_PENDING = "session.setPending";
const SET_SESSION_ID = "session.setSessionId";
const SET_FAILED = "session.setFailed";
const SET_RENDER_ASSETS = "session.setRenderAssets";

const initialState = {
  renewalTime: null,
  failed: false,
  renderAssets: null,
  sessionId: null,
  pending: false,
  error: null,
  session_expire_message: "",
  renewSessionOnAction: false,
  sessionExpired: false,
  showSessionExpireWarning: false
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAILED:
      return {
        ...state,
        failed: action.failed
      };

    case SET_RENEWAL_TIME:
      return {
        ...state,
        renewalTime: action.renewalTime
      };

    case SET_PENDING:
      return {
        ...state,
        pending: action.pending
      };

    case SET_SESSION_MESSAGE:
      return {
        ...state,
        session_expire_message: action.payload
      };

    case SET_RENDER_ASSETS:
      return {
        ...state,
        renderAssets: action.assets
      };

    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.sessionId
      };
    case "session.renew-session-on-action":
      return {
        ...state,
        renewSessionOnAction: true
      };
    case "session.renew-session-on-action-reset":
      return {
        ...state,
        renewSessionOnAction: false
      };
    case "session.session-expired":
      return {
        ...state,
        sessionExpired: true
      };
    case "session.session-expired-reset":
      return {
        ...state,
        sessionExpired: false
      };
    case "session.expiration-warning":
      return {
        ...state,
        showSessionExpireWarning: true
      };

    case "session.expiration-warning-reset":
      return {
        ...state,
        showSessionExpireWarning: false
      };
    default:
      return state;
  }
};

export const setPending = value => ({
  type: SET_PENDING,
  pending: value
});

export const setFailed = failed => ({
  type: SET_FAILED,
  failed
});

export const setRenewalTime = renewalTime => {
  return {
    type: SET_RENEWAL_TIME,
    renewalTime
  };
};

export const setSessionId = sessionId => ({
  type: SET_SESSION_ID,
  sessionId
});

export const setRenderAssets = assets => ({
  type: SET_RENDER_ASSETS,
  assets
});

export default sessionReducer;
