import React from "react";
import styled from "@emotion/styled";
import Resizer from "@hocs/Resizer";
import NavSearch from "../../QuickBuy/NavSearch";
import { useSelector } from "react-redux";

const NavbarSearch = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const LargeNavSearch = () => {
  const userMobile = useSelector(state => state.config.userAgent.isMobile);

  return (
    <NavbarSearch>
      <Resizer
        components={[
          {
            component: () => <NavSearch />,
            break: 999,
            maxBreak: Number.MAX_SAFE_INTEGER
          }
        ]}
        defaultTrue={userMobile ? false : true}
      />
    </NavbarSearch>
  );
};

export default LargeNavSearch;
