import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import CategoryImage from "./CategoryImage";

const SmallLine = styled.div`
  width: 36px;
  height: 3px;
  border-radius: 1.5px;
  margin: 0 auto;
  background-color: ${props => props.bgColor};
`;

const InnerContainer = styled.div`
  position: relative;
`;

const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

const NameDiv = styled.div`
  margin-top: 30px;
`;

const CaptionDiv = styled.div`
  pointer-events: none;
  position: absolute;
  top: 76%;
  right: 0;
  width: 100%;
  z-index: 102;

  @media (max-width: 950px) {
    top: 69%;
  }
`;

const EachCategory = ({
  path,
  name,
  index,
  overlayImage,
  webPImage,
  pngImage
}) => {
  const [lineColor, changeLineColor] = useState("rgba(250, 250, 250, 0.3)");
  const [display, changeDisplay] = useState("none");

  return (
    <div
      onMouseEnter={() => {
        changeLineColor("#12b2ea");
        changeDisplay("initial");
      }}
      onMouseLeave={() => {
        changeLineColor("rgba(250, 250, 250, 0.3)");
        changeDisplay("none");
      }}
    >
      <Link to={path}>
        <ContainerDiv
          onClick={() =>
            ga && ga("send", "event", "HomePage", "Category", index)
          }
        >
          <InnerContainer>
            <CategoryImage
              webPImage={webPImage}
              pngImage={pngImage}
              display={display}
              overlayImage={overlayImage}
              name={name}
            />

            <CaptionDiv>
              <SmallLine bgColor={lineColor} />
              <NameDiv>{name}</NameDiv>
            </CaptionDiv>
          </InnerContainer>
        </ContainerDiv>
      </Link>
    </div>
  );
};

export default EachCategory;
