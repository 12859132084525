import { defaultAction } from "./defaultAction";
import {
  RECEIVE_SELL_MERCHANTS,
  SET_SELL_CART_ID,
  ADD_CARD_TO_CART,
  ADD_CARD,
  UPDATE_NUMBER_OF_CARDS_IN_BUY,
  RECEIVE_BUY_CART,
  ADD_BUY_CARD,
  RESET_BUY,
  INITIAL_CART_LOADING,
  INITIAL_SELL_CART_LOADING,
  ENABLE_COUPON,
  OPEN_ERROR_BOX,
  SET_CARDS_UNDER_CONSIDERATION,
  UPDATE_COUPON_ENTER
} from "@constants/actionTypes";
import { getSellCards } from "@utils/validators/card";
import { sendBugsnagError } from "@utils/Bugsnag";
import { setUpBuyCardsForRedux, getCartIds } from "./buyMerchants";

export const getCartsSignIn = () => async (dispatch, getState, api) => {
  dispatch(defaultAction(INITIAL_CART_LOADING, true));
  dispatch(defaultAction(INITIAL_SELL_CART_LOADING, true));
  try {
    const { cards } = getState();
    const results = await api.get("v3/carts");
    const buyCartApi = results.data.cart.buyCart;
    const sellCartApi = results.data.cart.sellCart;
    if (Object.keys(buyCartApi).length) {
      let allCardsInCart = getCartIds(buyCartApi);
      let currentCards = setUpBuyCardsForRedux(buyCartApi);
      let qty = 0;
      buyCartApi.cards.forEach(card => {
        qty += card.quantity;
      });
      dispatch(defaultAction(UPDATE_NUMBER_OF_CARDS_IN_BUY, qty));
      dispatch(
        defaultAction(RECEIVE_BUY_CART, {
          cartId: buyCartApi.cartId,
          cards: allCardsInCart
        })
      );
      dispatch(defaultAction(ADD_BUY_CARD, currentCards));

      if (buyCartApi.couponData) {
        dispatch(defaultAction(ENABLE_COUPON, buyCartApi.couponData));
        if (buyCartApi.couponData.errorMsg) {
          await api.delete(
            `v3/carts/${buyCartApi.cartId}/coupons/${buyCartApi.couponData.couponCode}`
          );
        } else {
          if (buyCartApi.couponData.discount > 0) {
            dispatch(
              defaultAction(UPDATE_COUPON_ENTER, buyCartApi.couponData.coupon)
            );
          }
        }
      }
    } else {
      dispatch(defaultAction(RESET_BUY, null));
      dispatch(defaultAction(UPDATE_NUMBER_OF_CARDS_IN_BUY, 0));
    }

    if (
      sellCartApi &&
      Object.keys(sellCartApi).length &&
      Object.keys(sellCartApi.cards).length
    ) {
      let sellCart = getSellCards(results.data, {});
      if (Object.keys(cards).length === Object.keys(sellCart[0]).length) {
        dispatch(defaultAction(ADD_CARD, sellCart[0]));
        dispatch(defaultAction(ADD_CARD_TO_CART, sellCart[1]));
        dispatch(defaultAction(SET_SELL_CART_ID, sellCart[2]));
      } else {
        let cardsUnderConsideration = {};
        Object.keys(sellCart[0]).forEach(card => {
          if (!cards[card]) {
            cardsUnderConsideration[card] = sellCart[0][card];
          }
        });

        dispatch(defaultAction(ADD_CARD, sellCart[0]));
        dispatch(defaultAction(ADD_CARD_TO_CART, sellCart[1]));
        dispatch(defaultAction(SET_SELL_CART_ID, sellCart[2]));
        await dispatch(getSellMerch(cardsUnderConsideration));
      }
    } else {
    }
  } catch (error) {
    sendBugsnagError(error, "actions/getCartsSignIn");
    dispatch(
      defaultAction(OPEN_ERROR_BOX, {
        title: "Sorry, something went wrong.",
        message:
          "We are sorry. There was a problem. Please refresh the page and try again. Thank you."
      })
    );
  } finally {
    dispatch(defaultAction(INITIAL_CART_LOADING, false));
    dispatch(defaultAction(INITIAL_SELL_CART_LOADING, false));
  }
};

const getSellMerch =
  cardsUnderConsideration => async (dispatch, getState, api) => {
    try {
      const merchants = await api.get("v3/merchants/sell?cache=bust");
      dispatch(defaultAction(RECEIVE_SELL_MERCHANTS, merchants.data));
      if (cardsUnderConsideration) {
        dispatch(
          defaultAction(SET_CARDS_UNDER_CONSIDERATION, cardsUnderConsideration)
        );
      }
    } catch (error) {
      throw `Could not get sell merchants ${error}`;
    }
  };
