import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

const queryParams = () => {
  if (!global.location) return {};

  var query = {};
  let str = global.location.search.slice(1);
  str.replace(/([^=&]+)=([^&]*)/g, function (_, key, value) {
    query[decodeURIComponent(key)] = decodeURIComponent(value);
  });
  return query;
};
export default queryParams;

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => queryParams(search), [search]);
};
