const LOAD_CONFIG = "load_config";

const initialConfig = {
  aOrAn: "a",
  bulkSalesEmail: "bulksales@cardcash.com",
  supportEmail: "support@cardcash.com",
  supportPhone: "1-800-227-4214",
  imgPrefix: "https://cdn.cardcash.com/website/",
  resellerRatingsId: 39711,
  npm_package_version: ""
};

const config = (state = initialConfig, action) => {
  switch (action.type) {
    case LOAD_CONFIG:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

export default config;
