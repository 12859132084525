import {
  ADD_CARD_TO_CART,
  RESET_SELL,
  DELETE_CARD,
  DELETE_CARD_RUNNING,
  SET_SELL_CART_ID,
  RESET,
  RESET_EXPIRE,
  SET_PAYOUT,
  SET_CARDS_UNDER_CONSIDERATION,
  COMPLETE_ORDER_SELL,
  LOGOUT,
  SET_SELL_CARD_OFFER_LOADER
} from "@constants/actionTypes";

const initCartSellState = {
  cardIds: [],
  cartId: null,
  payoutType: null,
  payoutMethod: null,
  tradeBonus: 0,
  cardsUnderConsideration: {},
  sellCardOfferLoader: false,
  deleteCardRunning: false
};

const cartSell = (state = initCartSellState, action) => {
  switch (action.type) {
    case DELETE_CARD:
      let newIds = state.cardIds.filter(card => {
        return card !== action.payload;
      });
      const newStateObj = state;
      delete newStateObj.cardsUnderConsideration[action.payload];

      return {
        ...newStateObj,
        cardIds: newIds
      };

    case DELETE_CARD_RUNNING:
      return {
        ...state,
        deleteCardRunning: action.payload
      };

    case SET_SELL_CARD_OFFER_LOADER:
      return {
        ...state,
        sellCardOfferLoader: action.payload
      };

    case SET_SELL_CART_ID:
      return {
        ...state,
        cartId: action.payload
      };

    case ADD_CARD_TO_CART:
      return {
        ...state,
        cardIds: action.payload
      };

    case SET_PAYOUT:
      return {
        ...state,
        payoutType: action.payload.payoutType,
        payoutMethod: action.payload.payoutMethod,
        payoutMethodTradeID: action.payload.payoutMethodTradeID,
        tradeBonus: action.payload.tradeBonus
      };

    case SET_CARDS_UNDER_CONSIDERATION:
      return {
        ...state,
        cardsUnderConsideration: action.payload
      };

    case LOGOUT:
    case COMPLETE_ORDER_SELL:
    case RESET:
    case RESET_EXPIRE:
    case RESET_SELL:
      return initCartSellState;

    default:
      return state;
  }
};

export default cartSell;
