import React from "react";
import styled from "@emotion/styled";

const Image = styled.img`
  width: 100%;
`;

const OverlayImage = styled.img`
  position: absolute;
  top: 0px;
  z-index: 1;
  left: 0px;
  width: 100%;
  display: ${props => props.imageDisplay};
  opacity: 0;
  transition: opacity 200ms;
  &:hover {
    opacity: 1;
  }
`;

const CategoryImage = ({
  display,
  overlayImage,
  webPImage,
  pngImage,
  name
}) => (
  <>
    <picture>
      {webPImage && <source srcSet={webPImage} type="image/webp" />}
      <source srcSet={pngImage} type="image/png" />
      <Image src={pngImage} loading="lazy" alt={name} />
    </picture>

    <OverlayImage
      imageDisplay={display}
      src={overlayImage}
      overlayImage={overlayImage}
      loading="lazy"
      alt={`${name} overlay}`}
    />
  </>
);
export default CategoryImage;
