import React from "react";
import styled from "@emotion/styled";

const SelectedValue = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  pointer-events: none;
  padding-left: 30px;
  grid-gap: 10px;
`;

const InnerSingleValue = styled.div`
  height: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`;

const ValueImageContainer = styled.div`
  position: relative;
  margin-right: 8px;

  @media (max-width: 400px) {
    display: none;
  }
`;

const ValueSpan = styled.div`
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: nowrap;
`;

const ValueImage = styled.img`
  width: 40px;
  height: 29px;
`;

const NavSearchSelectedValue = ({ selectedMerchant }) => {
  return (
    <SelectedValue>
      <InnerSingleValue>
        <ValueImageContainer>
          <ValueImage src={selectedMerchant?.image} alt="merchant" />
        </ValueImageContainer>
        <ValueSpan>{selectedMerchant?.name}</ValueSpan>
      </InnerSingleValue>
    </SelectedValue>
  );
};

export default NavSearchSelectedValue;
