import { defaultAction } from "./defaultAction";
import {
  RESET_BUY,
  RESET_SELL,
  SET_SESSION_ID,
  OPEN_ERROR_BOX,
  LOGOUT_MARK_THUNK_RUNNING,
  LOGOUT_CLEAR_THUNK_RUNNING
} from "@constants/actionTypes";
import { getCartsSignIn } from "./getCartsSignIn";
import { sendBugsnagError } from "@utils/Bugsnag";
import { registerPostHook } from "@modules/ReduxHook";
import { signal } from "@modules/Signal/action";
import { reset as checkoutReset } from "@modules/Checkout/constant";

export const resetSessionFromLogout = () => async (dispatch, getState, api) => {
  try {
    const session = await api.post("v3/session");
    dispatch(defaultAction(SET_SESSION_ID, session.data.sessionId));
    dispatch(getCartsSignIn());
  } catch (error) {
    sendBugsnagError(error, "actions/resetSessionFromLogout");
  }
};

export const logoutUser = () => async (dispatch, getState, api) => {
  dispatch(defaultAction(LOGOUT_MARK_THUNK_RUNNING));
  try {
    await api.delete("v3/customers/logout");
    dispatch(resetSessionFromLogout());
    dispatch({ type: "LOGOUT", payload: { isLoggedIn: false } });
    dispatch(defaultAction(RESET_BUY));
    dispatch(defaultAction(checkoutReset));
    dispatch(defaultAction(RESET_SELL));
    dispatch(defaultAction(LOGOUT_CLEAR_THUNK_RUNNING));
    return "success";
  } catch (error) {
    sendBugsnagError(error, "actions/logoutUser");
    dispatch(defaultAction(LOGOUT_CLEAR_THUNK_RUNNING));
    dispatch(
      defaultAction(
        OPEN_ERROR_BOX,
        "Something went wrong. Please refresh the page. Thank you."
      )
    );
    return { error: error };
  }
};
registerPostHook([signal("logout").type], [logoutUser()]);
