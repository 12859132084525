function createPromiseMiddleware() {
  return () => next => action => {
    if (action instanceof Promise) {
      return action;
    }

    return next(action);
  };
}

const promise = createPromiseMiddleware();
promise.withExtraArguments = createPromiseMiddleware;

export default promise;
