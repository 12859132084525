import { contains } from "./utils";
import ISet from "./ISet";
import { mergeDeepRight } from "ramda";

export const REGISTERING_EVENT = "emission.registeringEvent";
export const UNREGISTERING_EVENT = "emission.unregisteringEvent";
export const FIRING_EVENT = "emission.firingEvent";
export const ENABLE_EVENT = "emission.enableEvent";
export const DISABLE_EVENT = "emission.disableEvent";
import { sendBugsnagError } from "@utils/Bugsnag";

/**
 * Transforms simplified representation into complex version of the payload
 * needed by the events endpoint.
 * @param {Object} values - the simplified payload.
 * @returns {Object} - complex payload.
 */
function toValuesObject(values, options = {}) {
  if (!values) return null;
  const { upperCaseValues } = mergeDeepRight(
    toValuesObject.defaultOptions,
    options
  );

  let output = { values: [] };

  for (let id in values) {
    if (values[id] == null) continue;

    let value = values[id].toString();

    if (upperCaseValues) {
      id = id.toUpperCase();
      value = value.toUpperCase();
    }

    output.values.push({ id, value });
  }

  return output;
}

toValuesObject.defaultOptions = {
  upperCaseValues: false
};

export const registeringEvent = event => ({
  type: REGISTERING_EVENT,
  event
});

export const unregisteringEvent = event => ({
  type: UNREGISTERING_EVENT,
  event
});

const firingEvent = event => ({
  type: FIRING_EVENT,
  event
});

export const registeringNewEvent = eventName => (dispatch, getState) => {
  const { emission } = getState();
  if (contains(eventName, emission)) return;

  dispatch(registeringEvent(eventName));
};

// NOTE: I don't like this function anymore. Need to tweak a little.
export const fireEvent =
  (event, payload = {}) =>
  async (dispatch, getState, api) => {
    try {
      const { emission } = getState();

      if (
        !ISet.load(emission.primedEvents).has(event) ||
        ISet.load(emission.disabledEvents).has(event)
      )
        return;

      dispatch(firingEvent(event));

      const [cleanedEvent, tag] = event.split(":");

      if (tag) payload.tag = tag;

      return await api.post(
        `/v3/events/${cleanedEvent}`,
        toValuesObject(payload)
      );
    } catch (error) {
      sendBugsnagError(error, "Emission/action/fireEvent");
      dispatch(registeringEvent(event));
    }
  };

export const fireOneOff =
  (event, payload = {}, options = {}) =>
  async (dispatch, _, api) => {
    const { attempts, upperCaseValues } = mergeDeepRight(
      fireOneOff.defaultOptions,
      options
    );

    if (attempts === 0) {
      return console.warn(`Emission: Failed to fire event=${event}`);
    }

    try {
      const [cleanedEvent, tag] = event.split(":");

      if (tag) payload.tag = tag;

      await api.post(
        `/v3/events/${cleanedEvent}`,
        toValuesObject(payload, {
          upperCaseValues
        })
      );
    } catch (error) {
      sendBugsnagError(error, "Emission/action/fireOneOff");
      dispatch(fireOneOff(event, payload, { attempts: attempts - 1 }));
    }
  };

fireOneOff.defaultOptions = {
  attempts: 3,
  upperCaseValues: false
};
