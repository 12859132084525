import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { mediaSMDown } from "@modules/Components/break";
import { useSelector } from "react-redux";
import AccountDropdown from "./AccountDropdown";
import AccountGroupMenu from "./AccountGroupMenu";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @media (max-width: 980px) {
    margin-right: ${props => (props.cartView ? "33px" : "")};
  }
  @media (max-width: 980px) {
    display: ${props => (props.cartView || props.payoutView ? "none" : "")};
  }
  ${mediaSMDown} {
    display: none;
  }
`;

const AccountGroupSignedIn = ({ cartView, payoutView, compact }) => {
  const isOpen = useSelector(state => state.nav.accountGroup.isOpen);
  return (
    <Fragment>
      <Container cartView={cartView} payoutView={payoutView}>
        <AccountDropdown />

        {isOpen && <AccountGroupMenu compact={compact} />}
      </Container>
    </Fragment>
  );
};

export default AccountGroupSignedIn;
