import {
  SET_BANK_ACCOUNT_TO_REMOVE,
  SET_OPEN_REMOVE_BANK_ACCT_MODAL,
  SET_OPEN_ADD_BANK_ACCT_MODAL
} from "@constants/achActionTypes";
import {
  RESET,
  RESET_EXPIRE,
  RESET_BUY,
  SET_ACH_TOOLTIP_POSITION
} from "@constants/actionTypes";

const intitialState = {
  bankAccountToRemove: null,
  showRemoveBankAccountModal: false,
  showAddBankAccountModal: false,
  achTooltipPosition: "hidden"
};

const ach = (state = intitialState, action) => {
  switch (action.type) {
    case SET_BANK_ACCOUNT_TO_REMOVE:
      return {
        ...state,
        bankAccountToRemove: action.payload
      };

    case SET_OPEN_REMOVE_BANK_ACCT_MODAL:
      return {
        ...state,
        showRemoveBankAccountModal: action.payload
      };

    case SET_OPEN_ADD_BANK_ACCT_MODAL:
      return {
        ...state,
        showAddBankAccountModal: action.payload
      };

    case SET_ACH_TOOLTIP_POSITION:
      return {
        ...state,
        achTooltipPosition: action.payload
      };

    case RESET:
    case RESET_EXPIRE:
    case RESET_BUY:
      return intitialState;

    default:
      return state;
  }
};
export default ach;
