import React, { createContext, useState, useEffect } from "react";
import { mergeDeepRight } from "ramda";
import styled from "@emotion/styled";
import MotionSensor from "@modules/Common/MotionSensor";
import loadable from "@loadable/component";
import Nav from "../Nav";
import Footer from "../Footer";
const Popups = loadable(
  () => import(/* webpackChunkName: "Popups" */ "../Popups"),
  { ssr: false }
);
import { useSelector } from "react-redux";
import { isOpen } from "../../Messages/selector";

const Container = styled.div`
  position: relative;
`;

const LayoutContext = createContext({});

const useLayoutInfo = () => {
  const [info, setInfo] = useState({
    scrolled: global.scrollY > 60
  });

  useEffect(() => {
    const sensor = new MotionSensor({ delay: 0 }).sense();

    sensor.watch(() => {
      setInfo(
        mergeDeepRight(info, {
          scrolled: window.scrollY > 60
        })
      );
    });

    return () => {
      sensor.dull();
    };
  }, []);

  return info;
};

const Layout = ({ children, sessionState }) => {
  const info = useLayoutInfo();
  const showPopupCart = useSelector(state => state.nav.initNav.showPopupCart);
  const displayLoading = useSelector(
    state => state.initAppReducer.displayLoading
  );
  const errorMsg = useSelector(state => state.initAppReducer.errorMsg);
  const mobileOpen = useSelector(state => isOpen(state));

  return (
    <LayoutContext.Provider value={info}>
      <Container>
        <Nav sessionState={sessionState} />
        {children}
        {(mobileOpen || displayLoading || errorMsg || showPopupCart) && (
          <Popups />
        )}

        <Footer />
      </Container>
    </LayoutContext.Provider>
  );
};

export default Layout;
