/** @jsx jsx */
import React, { Fragment } from "react";
import { jsx, css } from "@emotion/react";
import { mediaSMDUp } from "@modules/Components/break";
import { SET_NAV_SEARCH_OPEN } from "@constants/actionTypes";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { defaultAction } from "@actions/defaultAction";

const MobileSearchButton = () => {
  const dispatch = useDispatch();
  const navSearchOpen = useSelector(state => state.nav.initNav.navSearchOpen);

  const onClick = () => {
    dispatch(defaultAction(SET_NAV_SEARCH_OPEN, true));
  };

  return (
    <Fragment>
      <FaSearch
        onClick={onClick}
        css={{
          transition: "color 0.2s",
          fontSize: "22px",
          color: navSearchOpen ? "#1f2021" : "rgba(31, 32, 33, 0.2)",
          padding: 0,
          margin: "5px 0px 0px 0px",
          "&:hover": {
            cursor: "pointer"
          },
          [mediaSMDUp]: {
            display: "none"
          }
        }}
      />
    </Fragment>
  );
};

export default MobileSearchButton;
