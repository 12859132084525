import currentEnv, { INTEGRATION, DEVELOPMENT } from "./currentEnv";

const dlog = (...args) => {
  switch (currentEnv()) {
    case DEVELOPMENT:
    case INTEGRATION:
      console.group("DEBUG");
      console.log.apply(console, [...args]);
      console.groupEnd();
      return;
  }
};

export default dlog;
