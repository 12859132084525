export const IS_LOADING = "orderVerification.isLoading";
export const NOT_LOADING = "orderVerification.notLoading";
export const SET_LAST_ATTEMPT = "orderVerification.setLastAttempt";
export const SET_ORDER_ID = "orderVerification.setOrderId";
export const SET_ORDER_UUID_AND_TYPE = "orderVerification.setOrderUUIDAndType";
export const SET_PHONE_NUMBERS = "orderVerification.setPhoneNumbers";
export const SET_SELECTED_PHONE_NUMBER_ID =
  "orderVerification.setSelectedPhoneNumberId";
export const SET_EXPIRED = "orderVerification.setExpired";
export const ADD_VERIFICATION_ATTEMPT =
  "orderVerification.addVerificationAttempt";
export const SET_VALIDATION_TOKEN = "orderVerification.setValidationToken";
export const SET_PENDING = "orderVerification.setPending";
export const SET_STATUS = "orderVerification.setStatus";
export const FLOW_RESET = "orderVerification.flowReset";
export const FLOW_NEXT = "orderVerification.flowNext";
export const FLOW_GO = "orderVerification.flowGo";
export const SET_FLOW = "orderVerification.setFlow";
export const OPEN_MODAL = "orderVerification.openModal";
export const CLOSE_MODAL = "orderVerification.closeModal";
export const RESET = "orderVerification.reset";
export const SET_PHONE_TYPE = "orderVerification.setPhoneType";
export const SET_SUBMIT_ATTEMPTS = "orderVerification.setSubmitAttempts";
