import { createReducer, createAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

export const addResult = createAction("quickBuy/addResult");
export const clearResults = createAction("quickBuy/clear");
export const addErrorMessage = createAction("quickBuy/addErrorMessage");
export const clearErrorMessage = createAction("quickBuy/clearErrorMessage");

const times = createReducer([], {
  [addResult](state) {
    return state.concat(Date.now());
  },
  [clearResults]() {
    return [];
  }
});

const errorMessage = createReducer(
  {},
  {
    [addErrorMessage](state, action) {
      return {
        ...state,
        error: action.payload.error,
        brand: action.payload.brand
      };
    },
    [clearErrorMessage]() {
      return {};
    }
  }
);

const results = createReducer([], {
  [addResult](state, action) {
    return state.concat(action.payload).filter(Boolean).slice(-10);
  },
  [clearResults]() {
    return [];
  }
});

export default combineReducers({
  results,
  times,
  errorMessage
});
