import { defaultAction } from "./defaultAction";
import {
  SET_SUBSCRIPTIONS,
  SET_SUBSCRIPTION_STATUS
} from "@constants/actionTypes";
import { sendBugsnagError } from "@utils/Bugsnag";

export const getSubscriber =
  ({ id }) =>
  async (dispatch, getState, api) => {
    try {
      const response = await api.get(`/v3/marketing/emails/subscribers/${id}`);
      if (response.data) {
        dispatch(defaultAction(SET_SUBSCRIPTIONS, response.data));
      }
    } catch (error) {
      sendBugsnagError(error, "actions/getSubscriber");
    }
  };

export const unsubscribe =
  ({ id }) =>
  async (dispatch, getState, api) => {
    try {
      const response = await api.delete(
        `/v3/marketing/emails/subscribers/${id}`
      );
      if (response.data) {
        if (response.data.updated) {
          dispatch(defaultAction(SET_SUBSCRIPTION_STATUS, 0));
        }
        return "success";
      }
    } catch (error) {
      sendBugsnagError(error, "actions/unsubscribe");
    }
  };

export const subscribe = obj => async (dispatch, getState, api) => {
  try {
    const response = await api.post("/v3/marketing/emails/subscribers", {
      email: obj.email,
      firstName: obj.firstName, //optional
      lastName: obj.lastName //optional
    });
    if (response.data) {
      return "success";
    }
  } catch (error) {
    sendBugsnagError(error, "actions/subscribe");
    return { error: "Sorry there was an error please try again." };
  }
};

export const subscribePut = id => async (dispatch, getState, api) => {
  try {
    const response = await api.put(`/v3/marketing/emails/subscribers/${id}`);
    if (response.data) {
      if (response.data.updated) {
        dispatch(defaultAction(SET_SUBSCRIPTION_STATUS, 1));
      }
      return "success";
    }
  } catch (error) {
    sendBugsnagError(error, "actions/subscribe");
    return { error: "Sorry there was an error please try again." };
  }
};
