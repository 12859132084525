import { RECEIVE_SELL_MERCHANTS } from "@constants/actionTypes";

const initialState = { sortedByName: [] };

const merchantsSell = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SELL_MERCHANTS:
      // State has two levels
      //   Level One: object properties that are the merchantId, allowing quick access by ID.
      //   Level Two: array of merchants, sorted by merchant name
      let newState = {
        ...state,
        sortedByName: action.payload.sellMerchants.map(merchant => {
          return {
            value: merchant.id,
            label: merchant.name,
            ...merchant
          };
        })
      };
      return newState.sortedByName.reduce((obj, merchant) => {
        obj[merchant.id] = merchant;
        return obj;
      }, newState);

    default:
      return state;
  }
};

export default merchantsSell;
