import React from "react";
import styled from "@emotion/styled";
import { mediumScreen } from "@modules/Components/break";
import MobileSearchButton from "./MobileSearchButton";
import { getBuyMerchants } from "@actions/buyMerchants";
import { useDispatch, useSelector } from "react-redux";
import { defaultAction } from "@actions/defaultAction";
import { SET_BURGER_MENU } from "@constants/actionTypes";

const MobileSearchContainer = styled.div`
  margin: 0;
  @media ${mediumScreen} {
    display: none;
  }
`;

const MobileNavSearchContainer = () => {
  const dispatch = useDispatch();
  const burgerIsOpen = useSelector(state => state.burgerMenu.isOpen);
  const merchantsBuySorted = useSelector(
    state => state.merchantsBuy.sortedByName
  );
  const handleClick = () => {
    if (merchantsBuySorted.length < 1) {
      dispatch(getBuyMerchants());
    }
    if (burgerIsOpen) dispatch(defaultAction(SET_BURGER_MENU, false));
  };

  return (
    <MobileSearchContainer onClick={handleClick}>
      <MobileSearchButton />
    </MobileSearchContainer>
  );
};
export default MobileNavSearchContainer;
