import React from "react";
import styled from "@emotion/styled";
import { ClearedStyleInput } from "@modules/Components";
import { FaSearch } from "react-icons/fa";

const SearchInput = styled(ClearedStyleInput)`
  border-radius: ${({ navSearch, searchOpen }) => {
    return navSearch ? (searchOpen ? "30px 0px 0px 30px" : "30px") : "30px";
  }};
  width: 100%;
  height: 100%;
  padding-left: 56px;
  border: ${({ navSearch, border }) =>
    navSearch ? border : "solid 2px rgba(31, 32, 33, 0.1)"};
  border-right: ${({ navSearch, borderRight }) =>
    navSearch ? borderRight : ""};
  &:hover {
    border: ${({ navSearch, searchOpen }) =>
      navSearch
        ? searchOpen
          ? "solid 2px rgba(31, 32, 33, 0.1)"
          : "none"
        : "solid 2px rgba(31, 32, 33, 0.4)"};
  }
  &:focus {
    border: ${({ navSearch }) =>
      navSearch ? "2px solid black" : "2px solid black"};
    border-radius: ${({ navSearch }) =>
      navSearch ? "30px 0px 0px 30px" : "30px"};
  }

  @media (max-width: 330px) {
    padding-left: 26px;
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  height: 100%;
  background-color: transparent;
`;

const IconDiv = styled.div`
  position: absolute;
  left: 27px;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 330px) {
    display: none;
  }
`;

const SearchCards = ({
  children,
  selectedMerchant,
  inputValue,
  merchantFocused,
  mobileView,
  handleMerchantFocus,
  handleMerchantBlur,
  searchOpen,
  handleChange,
  navSearch
}) => {
  return (
    <InputContainer>
      {(!selectedMerchant?.id || inputValue) && (
        <IconDiv>
          <FaSearch
            style={{
              color: merchantFocused ? "#1f2021" : "rgba(31, 32, 33, 0.3)",
              fontSize: "16px"
            }}
          />
        </IconDiv>
      )}
      <SearchInput
        className="nav-search-input"
        placeholder={
          selectedMerchant?.id
            ? ""
            : mobileView || !navSearch
            ? "Search..."
            : "Search Gift Cards"
        }
        onFocus={handleMerchantFocus}
        onBlur={handleMerchantBlur}
        border={!searchOpen ? "none" : "solid 2px rgba(31, 32, 33, 0.1)"}
        borderRight={!searchOpen ? "none" : "solid 1px rgba(31, 32, 33, 0.1)"}
        searchOpen={searchOpen}
        onChange={handleChange}
        value={inputValue}
        navSearch={navSearch}
        aria-label="Search gift cards"
        aria-autocomplete="both"
      />
      {selectedMerchant?.id && !inputValue && children && <>{children}</>}
    </InputContainer>
  );
};

export default SearchCards;
