import { defaultAction } from "./defaultAction";
import {
  OPEN_ERROR_BOX,
  CHECKOUT_ORDER_SELL_MARKORDERLOADING,
  CHECKOUT_ORDER_SELL_SETORDER,
  CHECKOUT_ORDER_SELL_SETCARDS,
  FILL_SELL_ORDERS,
  GET_ORDER_INFORMATION,
  FILL_SELL_ORDER,
  FILL_SELL_ORDER_CARDS,
  FILL_SELL_PAYMENT,
  FILL_SELL_PAYMENTS,
  SELL_ORDERS_THUNK_RUNNING
} from "@constants/actionTypes";
import { sendBugsnagError } from "@utils/Bugsnag";

export const getOrderSell = resourceID => async (dispatch, getState, api) => {
  dispatch(defaultAction(CHECKOUT_ORDER_SELL_MARKORDERLOADING, resourceID));
  const sellOrder = await api.get(`v3/orders/${resourceID}`);
  const sellCards = await api.get(`v3/cards/sell/?orderId=${resourceID}`);
  dispatch(defaultAction(CHECKOUT_ORDER_SELL_SETORDER, sellOrder.data));
  if (
    Array.isArray(sellCards.data.sellCards) &&
    0 < sellCards.data.sellCards.length
  ) {
    dispatch(
      defaultAction(CHECKOUT_ORDER_SELL_SETCARDS, sellCards.data.sellCards)
    );
  } else {
    dispatch(
      defaultAction(OPEN_ERROR_BOX, {
        title: "Sell order does not contain any cards",
        message: "Error. This sell order does not include any cards."
      })
    );
  }
};

export const getOrderInformation =
  (orderId, lastName) => async (dispatch, getState, api) => {
    try {
      const apiCall = lastName
        ? `v3/orders/status/${orderId}/${lastName}`
        : `v3/orders/status/${orderId}`;
      const orderInformation = await api.get(apiCall);
      await dispatch({
        type: GET_ORDER_INFORMATION,
        payload: orderInformation.data.order
      });
      return "success";
    } catch (error) {
      throw `getOrderInformation: ${error}`;
    }
  };

export const getSellOrder = id => async (dispatch, getState, api) => {
  try {
    const results = await api.get(`v3/orders/${id}`);
    await dispatch(defaultAction(FILL_SELL_ORDER, results.data));
    await dispatch(getSellOrderCards(id));
  } catch (error) {
    sendBugsnagError(error, "actions/getSellOrder");
  }
};

const getSellOrderCards = orderId => async (dispatch, getState, api) => {
  try {
    const results = await api.get(`v3/cards/sell?orderId=${orderId}`);
    dispatch(defaultAction(FILL_SELL_ORDER_CARDS, results.data));
  } catch (error) {
    sendBugsnagError(error, "actions/getSellOrderCards");
  }
};

export const getSellPayment = paymentId => async (dispatch, getState, api) => {
  let { paymentsSell } = getState();
  if (!paymentsSell[paymentId] || !paymentsSell[paymentId].requestStatus) {
    let payment = paymentsSell[paymentId]
      ? JSON.parse(JSON.stringify(paymentsSell[paymentId]))
      : null;

    if (payment) {
      payment.requestStatus = "PENDING";
    } else {
      payment = { id: paymentId, requestStatus: "PENDING" };
    }

    dispatch(defaultAction(FILL_SELL_PAYMENT, { payment: payment }));

    try {
      const results = await api.get(`v3/payments/sell/${paymentId}`);
      dispatch(defaultAction(FILL_SELL_PAYMENT, results.data));
      dispatch(
        defaultAction(FILL_SELL_PAYMENTS, { [paymentId]: results.data })
      );
    } catch (error) {
      sendBugsnagError(error, "actions/getSellPayment");
    }
  }
};

export const getSellOrders = noRefresh => async (dispatch, getState, api) => {
  if (!noRefresh) {
    dispatch(defaultAction(SELL_ORDERS_THUNK_RUNNING, true));
  }

  try {
    const results = await api.get("v3/orders/sell");
    const sorted = results?.data?.sellOrders?.sort(
      (a, b) => new Date(b.date.placed) - new Date(b.date.placed)
    );
    dispatch(defaultAction(FILL_SELL_ORDERS, sorted || []));
    if (!noRefresh) {
      dispatch(defaultAction(SELL_ORDERS_THUNK_RUNNING, false));
    }
    return sorted;
  } catch (error) {
    sendBugsnagError(error, "actions/getSellOrders");
    if (!noRefresh) {
      dispatch(defaultAction(SELL_ORDERS_THUNK_RUNNING, false));
    }
  }
};
