const replaceValue = (val, number) => {
  let returnValue = val;
  if (val.length >= number) {
    const valueToChange = val.slice(0, val.length - number);
    let xString = "";
    for (const element of valueToChange) {
      if (element === " ") {
        xString = `${xString}${element}`;
      } else {
        xString = `${xString}X`;
      }
    }
    returnValue = `${xString}${val.slice(val.length - number, val.length)}`;
  }
  return returnValue;
};

const hideData = (val, number) => {
  if (typeof val === "object") {
    const newObject = {};
    Object.keys(val).forEach(key => {
      newObject[key] = hideData(val[key], 2);
    });
    return newObject;
  } else if (typeof val === "string") {
    return replaceValue(val, number);
  } else {
    return "not a string or object";
  }
};

const suppressErrorsList = [
  "customer account is locked",
  "reCaptcha required",
  "Invalid Email/Password",
  "The credit card you entered was declined. Please use a different card or contact your bank",
  "Expired Card",
  "Insufficient Funds",
  "SUSPECTED FRAUD",
  "Pick Up Card",
  "Invalid Coupon",
  "coupon is mobile only",
  "The credit card you entered was declined. Please use a different card or contact your bank",
  "Sorry, the credit card you provided is a prepaid card and cannot be accepted.",
  "Error adding credit card: Verification was declined",
  "entered value is greater than the maximum value allowed",
  "The billing address you entered does not match the one on file with your credit card company. Please try again.",
  "no Authorization token was found",
  "Error, insufficient inventory",
  "Error, This card is ineligible for sale at this time.",
  "Requested amount would exceed customer's purchase limit",
  "Processor Declined - Fraud Suspected",
  "Exceeds purchase limit",
  "OVER_THE_LIMIT",
  "Invalid Zip Code."
];

export const sendBugsnagError = (error, context, additionalInfo) => {
  if (suppressErrorsList.includes(error?.response?.data?.message?.[0])) {
    return;
  }
  let errorObj = {
    errorMessage:
      error && typeof error === "string"
        ? error
        : error?.message
        ? error.message
        : "unknown error",
    error: error?.response ? error.response : "unknown"
  };
  if (errorObj.error?.config?.data) {
    const data = JSON.parse(errorObj.error.config.data);
    if (data.card) {
      const { number, pin } = data.card;
      if (number) {
        data.card.number = hideData(number, 4);
      }
      if (pin) {
        data.card.pin = hideData(pin, 2);
      }
    }

    if (data.creditCardData) {
      const { nonce, billing } = data.creditCardData;
      if (nonce) {
        data.creditCardData.nonce = hideData(nonce, 4);
      }
      if (billing && Object.keys(billing).length > 0) {
        data.creditCardData.billing = hideData(billing, 2);
      }
    }

    if (data.customer) {
      data.customer = hideData(data.customer, 4);
    }

    if (data.creditCardDetails) {
      data.creditCardDetails = hideData(data.creditCardDetails, 2);
    }

    if (data.billingDetails) {
      data.billingDetails = hideData(data.billingDetails, 2);
    }

    if (data.shippingDetails) {
      data.shippingDetails = hideData(data.shippingDetails, 2);
    }

    if (data.access_token) {
      data.access_token = hideData(data.access_token, 2);
    }

    if (data.bankAccount) {
      if (data.bankAccount.accountNumber) {
        data.bankAccount.accountNumber = hideData(
          data.bankAccount.accountNumber,
          2
        );
      }
    }

    if (data.internalPaymentId) {
      data.internalPaymentId = hideData(data.internalPaymentId, 4);
    }

    if (data.paymentId) {
      data.paymentId = hideData(data.paymentId, 4);
    }
    if (data.payerId) {
      data.payerId = hideData(data.payerId, 4);
    }

    errorObj.error.config.data = JSON.stringify(data);
  }

  if (additionalInfo) {
    errorObj.cookie = additionalInfo.cookie;
    errorObj.cookieEnabled = additionalInfo.cookieEnabled;
  }
  errorObj = JSON.stringify(errorObj);
  const theError = new Error(errorObj);

  if (typeof Bugsnag !== "undefined") {
    Bugsnag.notify(theError, event => {
      event.context = context;
    });
  }
};
