import {
  FIRING_EVENT,
  REGISTERING_EVENT,
  UNREGISTERING_EVENT,
  DISABLE_EVENT,
  ENABLE_EVENT
} from "./action";
import ISet from "./ISet";

const initialState = {
  primedEvents: new ISet(),
  firedEvents: new ISet(),
  disabledEvents: new ISet()
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case REGISTERING_EVENT:
      return {
        ...state,
        disabledEvents: ISet.load(state.disabledEvents).remove(action.event),
        primedEvents: ISet.load(state.primedEvents).add(action.event)
      };
    case UNREGISTERING_EVENT:
      return {
        ...state,
        disabledEvents: ISet.load(state.disabledEvents).remove(action.event),
        firedEvents: ISet.load(state.firedEvents).remove(action.event),
        primedEvents: ISet.load(state.primedEvents).remove(action.event)
      };
    case FIRING_EVENT:
      return {
        ...state,
        primedEvents: ISet.load(state.primedEvents).remove(action.event),
        firedEvents: ISet.load(state.firedEvents).add(action.event)
      };
    case DISABLE_EVENT:
      return {
        ...state,
        primedEvents: ISet.load(state.primedEvents).remove(action.event),
        firedEvents: ISet.load(state.firedEvents).remove(action.event),
        disabledEvents: ISet.load(state.disabledEvents).add(action.event)
      };
    case ENABLE_EVENT:
      return {
        ...state,
        primedEvents: ISet.load(state.primedEvents).add(action.event),
        firedEvents: ISet.load(state.firedEvents).remove(action.event),
        disabledEvents: ISet.load(state.disabledEvents).remove(action.event)
      };
    default:
      return state;
  }
}
