import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Stars from "@components/Stars";
import { useSelector } from "react-redux";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 64%;
  margin: 0 auto;
  margin-top: 90px;
  align-self: flex-end;
  @media (max-width: 1750px) {
    width: 66%;
  }
  @media (max-width: 1600px) {
    grid-template-columns: 45% 55%;
  }
  @media (max-width: 1350px) {
    width: 73%;
  }
  @media (max-width: 1150px) {
    width: 80%;
    grid-template-columns: 45% 65%;
  }
  @media (max-width: 950px) {
    display: initial;
  }
`;

const DownloadDiv = styled.div`
  padding-top: 41px;
  width: 90%;
  position: relative;
  @media (max-width: 1700px) {
    display: grid;
    grid-template-rows: 20px 99px 194px 73px;
    width: 100%;
  }
  @media (max-width: 1600px) {
    grid-template-rows: 20px 94px 214px 73px;
  }
  @media (max-width: 975px) {
    grid-template-rows: 20px 84px 212px 79px;
  }
  @media (max-width: 950px) {
    grid-template-rows: auto;
    margin-bottom: 50px;
  }
  @media (max-width: 500px) {
    display: initial;
  }
`;

const SmallBlueLineCenteredDownloadDiv = styled.div`
  width: 36px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #12b2ea;

  @media (max-width: 950px) {
    margin: 0 auto;
    margin-top: 80px;
  }
`;

const DownloadHeader = styled.h2`
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  color: ${props => props.color};
  margin-bottom: 28px;
  margin-top: 48px;
  @media (max-width: 1700px) {
    margin-top: 38px;
  }
  @media (max-width: 1600px) {
    margin-top: 32px;
  }
  @media (max-width: 1350px) {
    margin-bottom: 0px;
    margin-top: 35px;
  }
  @media (max-width: 975px) {
    margin-top: 27px;
  }
  @media (max-width: 950px) {
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 36px;
  }
  @media (max-width: 575px) {
    width: 281px;
    font-size: 30px;
  }
  @media (max-width: 310px) {
    font-size: 27px;
    text-align: center;
    width: 100%;
  }
`;

const QuoteDiv = styled.div`
  display: grid;
  height: 178px;
  grid-template-columns: 2px 1fr;
  @media (max-width: 950px) {
    margin: 0 auto;
    width: 60%;
    text-align: center;
    height: 163px;
    overflow: hidden;
  }
  @media (max-width: 700px) {
    height: 200px;
  }
  @media (max-width: 500px) {
    height: 200px;
    width: 315px;
  }
  @media (max-width: 350px) {
    width: 295px;
  }
  @media (max-width: 305px) {
    width: 100%;
  }
`;

const BigQuote = styled.img`
  margin-left: -80px;
  width: 99px;
  height: 82px;
  @media (max-width: 950px) {
    margin-left: -63px;
  }
`;

const InnerTextDiv = styled.div`
  opacity: 0.8;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  font-style: italic;
  color: #ffffff;
  margin-bottom: 37px;
`;

const SaveUpToP = styled.p`
  max-height: 150px;
  overflow: hidden;
  @media (max-width: 1600px) {
    font-size: 17px;
  }
  @media (max-width: 1500px) {
    font-size: 16px;
  }
`;

const ReviewByDiv = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
  @media (max-width: 950px) {
    display: flex;
    justify-content: center;
  }
`;

const ReviewSpan = styled.span`
  opacity: 0.6;
  margin-right: 15px;
`;

const StarSpan = styled.span`
  margin-left: 8px;
`;

const ImagesGrid = styled.div`
  display: grid;
  position: relative;
  grid-gap: 5%;
  width: 100%;
  bottom: -8%;
  @media (max-width: 1700px) {
    align-self: flex-start;
  }
  @media (max-width: 1450px) {
  }
  @media (max-width: 1150px) {
    grid-gap: 2%;
  }
  @media (max-width: 950px) {
    grid-gap: 5%;
    justify-items: center;
  }
`;

const DownloadLinkGrid = styled.div`
  display: grid;
  max-width: 400px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 450px) {
    grid-gap: 15px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

const DownloadLink = styled.a`
  display: grid;
  align-content: center;
  margin-right: 15px;
  @media (max-width: 450px) {
    margin: 0;
  }
`;

const DownloadLinkImage = styled.img`
  max-width: 100%;
  background-color: ${props => props.bgColor};
  border-radius: ${props => props.radius};
`;

const DownloadImageDiv = styled.div`
  @media (max-width: 1150px) {
    display: grid;
  }
  @media (max-width: 950px) {
    justify-items: center;
  }
  @media (max-width: 500px) {
    margin-top: 25px;
  }
`;

const DownloadImage = styled.img`
  height: 465px;
  width: auto;

  @media (max-width: 1150px) {
    max-width: 100%;
    height: initial;
    align-self: flex-end;
  }
`;

const SaveUpTo = styled.div`
  font-size: 16px;
  line-height: 1.88;
  color: #777984;

  @media (max-width: 950px) {
    text-align: center;
    margin-bottom: 38px;
  }
  @media (max-width: 750px) {
    max-width: 344px;
    margin: 0 auto;
    margin-bottom: 38px;
  }
  @media (max-width: 375px) {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

const DownloadApp = ({ receiptPage }) => {
  const customerReviews = useSelector(state => state.popups.customerReviews);

  const [review, changeReview] = useState(
    "The service is amazing. A great way to save money, and it's convenient too! I've been using CardCash for years and have saved a ton on places we already shop and eat anyway!"
  );
  const [username, changeUserName] = useState("Eoobro");

  useEffect(() => {
    let interval;
    if (customerReviews && customerReviews.length > 0) {
      let customerReviewsFilter = customerReviews.filter(
        item => item.type === 2
      );

      let counter = 0;
      interval = setInterval(() => {
        const newUsername = customerReviewsFilter[counter].username;
        const newReview = customerReviewsFilter[counter].review;

        changeReview(newReview);
        changeUserName(newUsername);

        counter =
          counter === customerReviewsFilter.length - 1 ? 0 : counter + 1;
      }, 5000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [customerReviews]);

  const renderReview = () => {
    return review.length > 165 ? `${review.slice(0, 165)}...` : review;
  };

  return (
    <GridContainer>
      <DownloadDiv>
        <SmallBlueLineCenteredDownloadDiv />
        <DownloadHeader color={receiptPage ? "#1f2021" : "#ffffff"}>
          Live app-ily ever after.
        </DownloadHeader>
        {receiptPage ? (
          <SaveUpTo>
            Save up to 35% when you shop by paying with discounted gift cards
            from CardCash.
          </SaveUpTo>
        ) : (
          <QuoteDiv>
            <BigQuote
              src="https://cdn.cardcash.com/website/ui/elements/quote.svg"
              alt="quote"
              loading="lazy"
            />
            <InnerTextDiv>
              <SaveUpToP>&ldquo;{renderReview()}&rdquo;</SaveUpToP>
              <ReviewByDiv>
                <ReviewSpan>by {username}</ReviewSpan>

                <StarSpan>
                  <Stars amount={5} />
                </StarSpan>
              </ReviewByDiv>
            </InnerTextDiv>
          </QuoteDiv>
        )}
        <ImagesGrid>
          <DownloadLinkGrid>
            <DownloadLink
              onClick={() =>
                ga && ga("send", "event", "outboundLinks", "iosapp")
              }
              href="https://apps.apple.com/us/app/cardcash-discount-gift-cards/id1447403101"
              target="_blank"
              rel="noreferrer"
            >
              <DownloadLinkImage
                bgColor={receiptPage ? "#1f2021" : ""}
                radius={receiptPage ? "5px" : ""}
                src="https://cdn.cardcash.com/website/ui/elements/app-store.png"
                alt="apple app store"
                loading="lazy"
              />
            </DownloadLink>
            <DownloadLink
              onClick={() =>
                ga && ga("send", "event", "outboundLinks", "androidapp")
              }
              href="https://play.google.com/store/apps/details?id=com.cardcash.discounted_gift_cards"
              target="_blank"
              rel="noreferrer"
            >
              <DownloadLinkImage
                bgColor={receiptPage ? "#1f2021" : ""}
                radius={receiptPage ? "5px" : ""}
                src="https://cdn.cardcash.com/website/ui/elements/google-play.png"
                alt="google play"
                loading="lazy"
              />
            </DownloadLink>
          </DownloadLinkGrid>
        </ImagesGrid>
      </DownloadDiv>
      <DownloadImageDiv>
        <picture>
          <source
            srcSet="https://cdn.cardcash.com/website/ui/Home/mobileapp-8.webp"
            type="image/webp"
          />
          <source
            srcSet="https://cdn.cardcash.com/website/ui/Home/mobileapp-7.png"
            type="image/png"
          />
          <DownloadImage
            src="https://cdn.cardcash.com/website/ui/Home/mobileapp-7.png"
            loading="lazy"
            alt="mobile app image"
          />
        </picture>
      </DownloadImageDiv>
    </GridContainer>
  );
};

export default DownloadApp;
