import React from "react";
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "@actions/logout";
import PreloadLink from "@components/PreloadLink";
import { ClearedStyleRouterLink } from "@modules/Components";
import { defaultAction } from "@actions/defaultAction";
import { SHOW_REFER_POPUP_MODAL } from "@constants/refer";
import { SHOW_GLOBAL_LOADER_POPUP_MODAL } from "@constants/actionTypes";
import Resizer from "@hocs/Resizer";
import Terms from "@components/Terms";

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1350px) {
    grid-gap: 40px;
    grid-template-columns: auto auto auto auto;
  }
  @media (max-width: 1040px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0px;
    margin-bottom: 50px;
  }
  @media (max-width: 925px) {
    justify-self: flex-end;
    grid-gap: 20px;
  }
  @media (max-width: 850px) {
    grid-template-columns: auto auto auto auto;
  }
  @media (max-width: 750px) {
    grid-gap: 40px;
    grid-template-columns: auto auto auto auto;
  }
  @media (max-width: 675px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
    grid-column-gap: 0px;
  }
`;

const Header = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #777984;
  height: 37px;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const LinkItem = styled.div`
  display: ${props => props.display};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  color: #1f2021;

  @media (max-width: 1450px) {
    font-size: 15px;
  }
  @media (max-width: 675px) {
    font-size: 16px;
  }
  @media (max-width: 375px) {
    font-size: 14px;
  }
  @media (max-width: 315px) {
    font-size: 12px;
  }
`;

const PageLink = styled(ClearedStyleRouterLink)`
  cursor: pointer;
  color: #1f2021;
`;

const HrefLink = styled.a`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  color: #1f2021;
  text-decoration: none;
  outline: none;
  &:hover {
    color: #1f2021;
    text-decoration: none;
    outline: none;
  }
  &:active {
    color: #1f2021;
    text-decoration: none;
    outline: none;
  }
  &:visited {
    color: #1f2021;
    text-decoration: none;
    outline: none;
  }
  &:focus {
    color: #1f2021;
    text-decoration: none;
    outline: none;
  }
`;

const ReferSpan = styled.span`
  cursor: pointer;
`;

const MediaSmallTerms = styled.div`
  @media (min-width: 676px) {
    display: none;
  }
`;

const sitemap = [
  {
    header: "About",
    mainGaText: "about",
    itemArray: [
      {
        linkName: "Our Story",
        gaText: "ourstory",
        link: "/about/",
        linkType: "local",
        loadableComponent: "AboutUs"
      },
      {
        linkName: "How it Works",
        gaText: "howitworks",
        link: "/save-with-gift-cards/",
        linkType: "local",
        loadableComponent: "HowTo"
      },
      {
        linkName: "Blog",
        gaText: "blog",
        link: "https://www.cardcash.com/blog/",
        linkType: "href"
      },
      {
        linkName: "Gift Card Statistics",
        gaText: "giftcardstatistics",
        link: "/gift-card-statistics/",
        linkType: "local",
        loadableComponent: "GiftCardStatistics"
      },
      {
        linkName: "Accessibility",
        gaText: "accessibility",
        link: "/accessibility-statement/",
        linkType: "local",
        loadableComponent: "Accessibility"
      }
    ]
  },
  {
    header: "Enterprise",
    mainGaText: "bulk",
    itemArray: [
      {
        linkName: "Bulk Buy Program",
        gaText: "bulkBuy",
        link: "/bulk-buy-gift-cards/",
        linkType: "local",
        loadableComponent: "BulkBuy"
      },
      {
        linkName: "Bulk Sell Program",
        gaText: "bulkSell",
        link: "/bulk-sell-gift-cards/",
        linkType: "local",
        loadableComponent: "BulkSell"
      },
      {
        linkName: "Exchange Program",
        gaText: "exchange",
        link: "/partnerships/",
        linkType: "local",
        loadableComponent: "ExchangeLp"
      },
      {
        linkName: "CardCash Incentives",
        gaText: "Incentives",
        link: "https://incentives.cardcash.com/",
        linkType: "href"
      },
      {
        linkName: "uChoose by CardCash",
        gaText: "uChoose",
        link: "https://uchoose.cardcash.com/",
        linkType: "href"
      }
    ]
  },
  {
    header: "Support",
    mainGaText: "support",
    itemArray: [
      {
        linkName: "FAQ",
        gaText: "faqs",
        link: "/faqs/",
        linkType: "local",
        loadableComponent: "FAQs"
      },
      {
        linkName: "Guarantee",
        gaText: "guarantee",
        link: "/guarantee/",
        linkType: "local",
        loadableComponent: "Guarentee"
      },
      {
        linkName: "Contact Us",
        gaText: "contact",
        link: "/contact/",
        linkType: "local",
        loadableComponent: "ContactUs"
      }
    ]
  },
  {
    header: "Account",
    mainGaText: "account",
    itemArray: [
      {
        linkName: "Profile",
        gaText: "account",
        link: "/account/",
        linkType: "local",
        loadableComponent: "Account"
      },
      {
        linkName: "Order Status",
        gaText: "order-status",
        link: "/order-status/",
        linkType: "local",
        loadableComponent: "OrderStatus"
      },
      {
        linkName: "Login",
        gaText: "login",
        link: "/login/",
        linkType: "local",
        loadableComponent: "SignIn"
      },
      {
        linkName: "Refer a friend",
        gaText: "refer",
        link: "//",
        linkType: "openReferAFriend",
        id: ""
      }
    ]
  }
];

const SiteMapGrid = () => {
  const dispatch = useDispatch();
  const signedIn = useSelector(state => state.initAppReducer.signedIn);
  const userMobile = useSelector(state => state.config.userAgent.isMobile);

  const linkItemClick = (shouldUserLogout, mainGaText, gaText) => {
    shouldUserLogout && dispatch(logoutUser());

    ga && ga("send", "event", "navFooter", mainGaText, gaText);
  };

  const handleReferAFriend = () => {
    window.scrollTo(0, 0);
    if (signedIn) {
      dispatch(defaultAction(SHOW_REFER_POPUP_MODAL, true));
    } else {
      dispatch(defaultAction(SHOW_GLOBAL_LOADER_POPUP_MODAL, "footerRefer"));
    }
  };

  return (
    <Main>
      {sitemap.map(item => (
        <div key={item.header}>
          <Header>{item.header}</Header>

          {item.itemArray.map(linkItem => {
            let { linkName, link, gaText, linkType } = linkItem;
            let shouldUserLogout = false;
            let display = "block";

            if (linkName === "Login" && signedIn) {
              linkName = "Logout";
              gaText = "logout";
              link = "/";
              shouldUserLogout = true;
            }
            if (linkName === "Profile" && !signedIn) {
              display = "none";
            }

            return (
              <LinkItem
                display={display}
                key={linkName}
                onClick={() =>
                  linkItemClick(shouldUserLogout, item.mainGaText, gaText)
                }
              >
                {linkType === "local" ? (
                  <PreloadLink page={linkItem.loadableComponent}>
                    <PageLink to={link}>{linkName}</PageLink>
                  </PreloadLink>
                ) : linkType === "href" ? (
                  <HrefLink target="_blank" href={link}>
                    {linkName}
                  </HrefLink>
                ) : linkType === "openReferAFriend" ? (
                  <ReferSpan onClick={handleReferAFriend}>{linkName}</ReferSpan>
                ) : (
                  <span>{linkName}</span>
                )}
              </LinkItem>
            );
          })}
        </div>
      ))}

      <MediaSmallTerms>
        <Resizer
          components={[
            {
              component: () => <Terms />,
              break: 1,
              maxBreak: 676
            }
          ]}
          defaultTrue={userMobile ? true : false}
        />
      </MediaSmallTerms>
    </Main>
  );
};

export default SiteMapGrid;
