import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MotionSensor from "@modules/Common/MotionSensor";
import "../../../styles/nav.scss";
import loadable from "@loadable/component";
const CartProgress = loadable(() =>
  import(/* webpackChunkName: "CartProgress" */ "./CartProgress")
);
import NavRightSection from "./NavRightSection";
import NavLeftSection from "./NavLeftSection";
import NavBottom from "./NavBottom";
import NavTop from "./NavTop";

const Nav = styled.nav`
  transition: background-color 0.2s, box-shadow 0.5s, height 0.2s;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  max-height: 100%;
  z-index: 107;
  box-shadow: 0 0 5px rgb(31 32 33 / 15%);

  @media (min-width: 2500px) {
    max-width: 2500px;
    margin: 0 auto;
  }

  border-radius: ${props => props.borderRadius};

  @media (max-width: 1100px) {
    border-radius: ${props => props.mobileBorderRadius};
  }
  @media (max-width: 851px) {
    border-radius: 0px;
  }
  @media (max-width: 500px) {
    border-radius: ${props => props.mobileBorderRadius};
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 70%;

  @media (max-width: 500px) {
    max-width: 100%;
  }
  @media (max-width: 1700px) {
    width: 75%;
  }
  @media (max-width: 1600px) {
    width: 80%;
  }
  @media (max-width: 1250px) {
    width: 85%;
  }
  @media (max-width: 1150px) {
    width: 95%;
    margin: 0 auto;
  }
  @media (max-width: 999px) {
    width: 85%;
  }
  @media (max-width: 728px) {
    width: 90%;
  }
  @media (min-width: 980px) {
    margin: 0 auto;
  }
  height: 100%;
  max-height: 100%;
`;

const isScrolled = () => global.scrollY;

const NavV2 = ({ sessionState }) => {
  const bannersList = useSelector(state => state.banners.bannersList);

  const userMobile = useSelector(state => state.config.userAgent.isMobile);
  const navSearchOpen = useSelector(state => state.nav.initNav.navSearchOpen);
  const location = useLocation();

  const [setTheScroll, setScrolled] = useState(isScrolled());

  useEffect(() => {
    const sensor = new MotionSensor({ delay: 0 }).sense();

    sensor.watch(() => {
      setScrolled(isScrolled());
    });

    return () => {
      sensor.ignore();
    };
  }, []);

  const cartView =
    location.pathname.startsWith("/cart") ||
    location.pathname.startsWith("/checkout");
  const payoutView = location.pathname.startsWith("/payout");

  const scrolled = setTheScroll > 125;

  return (
    <Nav
      id="navigation-container"
      borderRadius={
        (navSearchOpen && userMobile) || bannersList.length > 0
          ? "0px 0px 30px 30px"
          : ""
      }
      mobileBorderRadius={
        bannersList.length > 0 || navSearchOpen ? "0px 0px 30px 30px" : "0px"
      }
    >
      <NavTop sessionState={sessionState} scrolled={scrolled} />

      <NavContainer className={`${scrolled ? " nav-container-scrolled" : ""}`}>
        <NavLeftSection payoutView={payoutView} cartView={cartView} />
        {cartView && <CartProgress scrolled={scrolled} />}
        <NavRightSection
          cartView={cartView}
          scrolled={scrolled}
          payoutView={payoutView}
        />
      </NavContainer>
      <NavBottom navSearchOpen={navSearchOpen} />
    </Nav>
  );
};

export default NavV2;
