import React, { useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import styled from "@emotion/styled";
import { defaultAction } from "./actions/defaultAction";
import currentEnv, { PRODUCTION } from "./utils/currentEnv";
import "./styles/all.scss";
import "./styles/_reactSelect.scss";
import {
  CART_BUY_HIDE_POPUP_CART,
  CLOSE_ACCOUNT_DROPDOWN,
  CLOSE_ALL_SEARCH_DROPDOWN,
  SET_GLOBAL_WIDTH,
  SET_NAV_SEARCH_OPEN,
  SET_HOME_SEARCH_OPEN,
  SET_QUICK_SEARCH_OPEN
} from "./constants/actionTypes";
import { getCurrentDropdown } from "@modules/SearchSelect/selector";
import { loadLinks } from "@helpers/loadScripts";
import loadable from "@loadable/component";
const GlobalPopupMain = loadable(
  () =>
    import(
      /* webpackChunkName: "GlobalPopupMain" */ "./modules/UI/Popups/GlobalPopupMain"
    ),
  { ssr: false }
);
const InitialNonEssenScripts = loadable(() =>
  import(
    /* webpackChunkName: "InitialNonEssenScripts"  */ "./InitialNonEssenScripts"
  )
);
import ErrorBoundary from "@components/ErrorBoundary";
import Layout from "./modules/UI/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const AppMain = styled.div`
  padding-top: 1px;
  position: relative;

  @media (min-width: 2500px) {
    max-width: 2500px;
    margin: 0 auto;
  }
`;

const MainContentContainer = styled.div`
  min-height: 700px;
  margin-top: ${props => props.setMarginTop};

  @media (max-width: 767px) {
    margin-top: ${props => props.setMarginMobile};
  }
`;

const App = ({ sessionState, route, store }) => {
  const dispatch = useDispatch();
  const renewSessionOnAction = useSelector(
    state => state.session.renewSessionOnAction
  );
  const sessionExpired = useSelector(state => state.session.sessionExpired);
  const showPopupCart = useSelector(state => state.nav.initNav.showPopupCart);
  const accountTabIsOpen = useSelector(
    state => state.nav?.accountGroup?.isOpen
  );
  const currentOpenDropdowns = useSelector(state =>
    getCurrentDropdown({
      searchArray: state.nav.initNav.selectSearchValues
    })
  );
  const bannersList = useSelector(state => state.banners.bannersList);

  const navSearchOpen = useSelector(state => state.nav.initNav.navSearchOpen);
  const homeSearchOpen = useSelector(state => state.nav.initNav.homeSearchOpen);
  const quickSearchOpen = useSelector(
    state => state.nav.initNav.quickSearchOpen
  );

  const [windowLoaded, setWindowLoaded] = useState(false);
  const [listenerLoaded, setListenerLoaded] = useState(false);

  const handleLoad = () => {
    setWindowLoaded(true);
  };

  const handleResize = () => {
    dispatch(defaultAction(SET_GLOBAL_WIDTH, globalThis.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    loadLinks(
      "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
      "fontAwesomeTag"
    );
  }, []);

  useEffect(() => {
    if (window.document.readyState !== "complete") {
      if (!listenerLoaded) {
        window.addEventListener("load", handleLoad);
        setListenerLoaded(true);
      }
    } else {
      setWindowLoaded(true);
    }
  }, [globalThis?.document]);

  const closeBuyCartModal = () => {
    if (showPopupCart) dispatch(defaultAction(CART_BUY_HIDE_POPUP_CART));

    if (accountTabIsOpen) dispatch(defaultAction(CLOSE_ACCOUNT_DROPDOWN));

    if (currentOpenDropdowns.length > 0)
      dispatch(defaultAction(CLOSE_ALL_SEARCH_DROPDOWN));

    if (navSearchOpen) dispatch(defaultAction(SET_NAV_SEARCH_OPEN, false));
    if (homeSearchOpen) dispatch(defaultAction(SET_HOME_SEARCH_OPEN, false));
    if (quickSearchOpen) dispatch(defaultAction(SET_QUICK_SEARCH_OPEN, false));
  };

  const handleMainClick = async () => {
    closeBuyCartModal();

    if (renewSessionOnAction) {
      if (!sessionExpired) {
        await sessionState.renewSession();
      }
    }
  };

  return (
    <ErrorBoundary>
      {currentEnv() !== PRODUCTION && (
        <Helmet>
          <link
            href="https://cdn.cardcash.com/images/favicons/test-cardcash.ico"
            type="image/x-icon"
            rel="icon"
          />
        </Helmet>
      )}
      <AppMain onClick={handleMainClick} id="app-main">
        {windowLoaded && <InitialNonEssenScripts />}
        <GlobalPopupMain />
        <Layout sessionState={sessionState}>
          <ErrorBoundary>
            <MainContentContainer
              setMarginTop={bannersList.length > 0 ? "205px" : "0px"}
              setMarginMobile={bannersList.length > 0 ? "178px" : "0px"}
            >
              {renderRoutes(route.routes, { windowLoaded })}
            </MainContentContainer>
          </ErrorBoundary>
        </Layout>
      </AppMain>
    </ErrorBoundary>
  );
};

export default {
  component: App
};
