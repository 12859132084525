import React from "react";
import SocialImage from "@components/SocialImage";

const socialIconsArray = [
  {
    name: "facebook",
    href: "https://www.facebook.com/CardCash/",
    src: "https://cdn.cardcash.com/website/ui/Footer/facebook.svg",
    hoverImage: "https://cdn.cardcash.com/website/ui/Footer/facebook-hover.svg"
  },
  {
    name: "instagram",
    href: "https://www.instagram.com/cardcash/?hl=en",
    src: "https://cdn.cardcash.com/website/ui/Footer/Instagram.svg",
    hoverImage: "https://cdn.cardcash.com/website/ui/Footer/instagram-hover.svg"
  },
  {
    name: "telegram",
    href: "https://t.me/cardcashtelegram",
    src: "https://cdn.cardcash.com/website/ui/Footer/telegram.svg",
    hoverImage: "https://cdn.cardcash.com/website/ui/Footer/telegram-hover.svg"
  },
  {
    name: "linkedin",
    href: "https://www.linkedin.com/company/cardcash-com",
    src: "https://cdn.cardcash.com/website/ui/Footer/linkedin.svg",
    hoverImage: "https://cdn.cardcash.com/website/ui/Footer/linkedin-hover.svg"
  },
  {
    name: "twitter",
    href: "https://twitter.com/CardCash?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
    src: "https://cdn.cardcash.com/website/ui/Footer/twitter.svg",
    hoverImage: "https://cdn.cardcash.com/website/ui/Footer/twitter-hover.svg"
  }
];

const SocialIcons = ({ filterObject }) => {
  let theArray = socialIconsArray;
  if (filterObject) {
    theArray = socialIconsArray.filter(item =>
      filterObject.includes(item.name)
    );
  }
  return (
    <div className="social-icon-container">
      {theArray.map(item => (
        <SocialImage
          key={item.name}
          name={item.name}
          href={item.href}
          src={item.src}
          hoverImage={item.hoverImage}
        />
      ))}
    </div>
  );
};
export default SocialIcons;
