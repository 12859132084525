import { ADD_BANNERS } from "@constants/actionTypes";

const initialState = {
  bannersList: []
};

const banners = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BANNERS:
      return {
        ...state,
        bannersList: action.payload
      };

    default:
      return state;
  }
};

export default banners;
