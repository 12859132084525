import React from "react";
import styled from "@emotion/styled";
import Link from "@modules/Components/Link";

const TermsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 16px;
  line-height: 1.88;
  color: #1f2021;
  @media (max-width: 700px) {
    font-size: 15px;
  }
  @media (max-width: 675px) {
    grid-template-columns: 1fr;
    font-size: 16px;
  }
  @media (max-width: 375px) {
    grid-template-columns: 1fr;
    font-size: 14px;
  }
`;

const TermsOfService = styled.p`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 675px) {
    height: 48px;
    margin: 0;
    justify-content: flex-start;
  }
`;

const PrivacyPolicy = styled.p`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 675px) {
    height: 48px;
    margin: 0;
    justify-content: flex-start;
  }
`;

const StyledLink = styled(Link)`
  color: #1f2021;
  :hover {
    color: #1f2021;
    text-decoration: none;
  }
  @media (max-width: 675px) {
    display: flex;
    align-items: center;
    height: 48px;
  }
`;

const Terms = () => {
  return (
    <TermsDiv>
      <TermsOfService
        onClick={() => ga && ga("send", "event", "navFooter", "support", "tos")}
      >
        <StyledLink style={{ cursor: "pointer" }} to="/tos/">
          Terms of Service
        </StyledLink>
      </TermsOfService>
      <PrivacyPolicy
        onClick={() =>
          ga && ga("send", "event", "navFooter", "support", "privacy-policy")
        }
      >
        {" "}
        <StyledLink style={{ cursor: "pointer" }} to="/privacy-policy/">
          Privacy Policy
        </StyledLink>
      </PrivacyPolicy>
    </TermsDiv>
  );
};

export default Terms;
