import React from "react";
import { signal } from "@modules/Signal/action";
import { useDispatch } from "react-redux";
import { Link as ReactRouterLink } from "react-router-dom";

const Link = ({ actions = [], mouseOverActions = [], css, ...props }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(signal("page-change"));
    actions.forEach(action => dispatch(action));
  };

  const onMouseOver = () => {
    mouseOverActions.forEach(action => dispatch(action));
  };

  return (
    <ReactRouterLink onClick={onClick} onMouseOver={onMouseOver} {...props} />
  );
};

export default Link;
