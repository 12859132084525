/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import styled from "@emotion/styled";

const StarsContainer = styled.div`
  margin-right: 12px;
  opacity: 1;
`;
const times = (n, fn) => new Array(n).fill(null).map((_, i) => fn(i));

const Stars = ({ amount }) => (
  <StarsContainer aria-label={`${amount} stars`}>
    {times(amount, i => (
      <img
        key={i}
        css={{
          height: "10px",
          width: "10px",
          margin: "1px",
          "&:first-of-type": {
            marginLeft: 0
          },
          "&:last-of-type": {
            marginRight: 0
          }
        }}
        src="https://cdn.cardcash.com/website/ui/nav/star.svg"
        loading="lazy"
        alt=""
      />
    ))}
  </StarsContainer>
);

export default Stars;
