import { defaultAction } from "./defaultAction";
import {
  PAYMENT_OPTIONS_RECIEVED,
  SET_SELL_CART_ID,
  ADD_CARD_TO_CART,
  ADD_CARD,
  SET_SELL_CARD_OFFER_LOADER,
  OPEN_ERROR_BOX,
  HIDE_LOADING,
  DISPLAY_CARD_ERROR_PARTIAL,
  DISPLAY_MAX_ERROR_PARTIAL,
  DISPLAY_MIN_ERROR_PARTIAL,
  DISPLAY_NO_ERROR_PARTIAL,
  ADD_CARD_BOX_OPEN,
  DISPLAY_MERCHANT_ERROR_PARTIAL,
  DISPLAY_CARD_BALANCE_FORMAT_ERROR,
  CARD_PARTIAL_CLEAR,
  DELETE_CARD_RUNNING,
  DELETE_CARD,
  OPEN_CANCEL_SELL_ORDER_MODAL,
  FILL_SELL_ORDERS,
  CLEAR_UPDATE_CARD_SERVER_ERROR,
  SET_UPDATE_CARD_SERVER_ERROR,
  UPDATE_THE_CARD
} from "@constants/actionTypes";
import { sendBugsnagError } from "@utils/Bugsnag";
import { getSellCards } from "@utils/validators/card";
import { removeEmpty } from "@utils";
import { errorResponseMessage } from "./errorResponseMessage";
import { getOrderInformation } from "./sellOrderInfo";

export const getPaymentOptions = () => async (dispatch, getState, api) => {
  try {
    const paymentOptions = await api.get("v3/payments/options?cache=bust");
    dispatch(defaultAction(PAYMENT_OPTIONS_RECIEVED, paymentOptions.data));
  } catch (error) {
    sendBugsnagError(error, "actions/getPaymentOptions");
  }
};

export const addSellCard = initiator => async (dispatch, getState, api) => {
  const { cardPartial, cartSell } = getState();
  let currentCardPartialBalance = cardPartial.cardBalance;

  let okToSend;

  if (cardPartial.selectedMerchant == null) {
    dispatch(defaultAction(DISPLAY_MERCHANT_ERROR_PARTIAL));
  } else {
    if (/^\d+(,\d{3})*(\.\d*)?$/.test(cardPartial.cardBalance)) {
      currentCardPartialBalance = Number(
        cardPartial.cardBalance.split(",").join("")
      );
    } else {
      if (currentCardPartialBalance) {
        return dispatch(defaultAction(DISPLAY_CARD_BALANCE_FORMAT_ERROR));
      }
    }

    okToSend = await dispatch(checkBalance());
  }
  if (okToSend) {
    if (
      cardPartial.displayMinError ||
      cardPartial.displayMaxError ||
      cardPartial.showMerchantError ||
      cardPartial.showCardBalanceError ||
      cardPartial.showCardBalanceFormatError
    ) {
      dispatch(defaultAction(CARD_PARTIAL_CLEAR));
      return;
    }

    if (!cartSell.cartId) {
      await dispatch(createSellCart(currentCardPartialBalance, initiator));
    } else {
      try {
        await dispatch(
          sendCardToSellCart(
            currentCardPartialBalance,
            cartSell.cartId,
            initiator
          )
        );
      } catch (error) {
        sendBugsnagError(error, "actions/addSellCard");

        dispatch(
          defaultAction(OPEN_ERROR_BOX, {
            title: "Sorry, something went wrong.",
            message:
              "We are sorry. Please refresh the page and try again. Thank you."
          })
        );
      }
    }
    dispatch(defaultAction(CARD_PARTIAL_CLEAR));
  }
};

export const checkBalance = () => async (dispatch, getState, api) => {
  const { cardPartial, userActions } = getState();
  const { selectedMerchant, cardBalance } = cardPartial;

  if (cardBalance == null) {
    dispatch(defaultAction(DISPLAY_CARD_ERROR_PARTIAL));
    return;
  } else if (
    selectedMerchant &&
    selectedMerchant.limit &&
    selectedMerchant.limit.max < cardBalance
  ) {
    dispatch(defaultAction(DISPLAY_MAX_ERROR_PARTIAL));
    return;
  } else if (
    selectedMerchant &&
    selectedMerchant.limit &&
    selectedMerchant.limit.min > cardBalance
  ) {
    dispatch(defaultAction(DISPLAY_MIN_ERROR_PARTIAL));
    return;
  } else {
    dispatch(defaultAction(DISPLAY_NO_ERROR_PARTIAL));

    if (!userActions.keepAddCardBoxClosed) {
      dispatch(defaultAction(ADD_CARD_BOX_OPEN, true));
    }

    return true;
  }
};

const createSellCart =
  (amount, initiator) => async (dispatch, getState, api) => {
    try {
      const cartSell = await api.post("v3/carts", { action: "sell" });
      dispatch(defaultAction(SET_SELL_CART_ID, cartSell.data.cartId));
      dispatch(defaultAction(ADD_CARD_TO_CART, cartSell.data.cards));
      await dispatch(
        sendCardToSellCart(amount, cartSell.data.cartId, initiator)
      );
    } catch (error) {
      sendBugsnagError(error, "actions/createSellCart");

      dispatch(
        defaultAction(OPEN_ERROR_BOX, {
          title: "Sorry, something went wrong.",
          message:
            "We are sorry. Please refresh the page and try again. Thank you."
        })
      );
    }
  };

const sendCardToSellCart =
  (cardBalance, cartSellId, initiator) => async (dispatch, getState, api) => {
    try {
      const { cards, cardPartial } = getState();
      const sellCart = await api.post(`v3/carts/${cartSellId}/cards`, {
        card: {
          merchantId: cardPartial.selectedMerchant.id,
          enterValue: Number(cardBalance)
        }
      });
      let validatedCards = getSellCards(sellCart.data, cards);
      await dispatch(defaultAction(ADD_CARD, validatedCards[0]));
      await dispatch(defaultAction(ADD_CARD_TO_CART, validatedCards[1]));
      if (initiator !== "quickSell") {
        dispatch(defaultAction(SET_SELL_CARD_OFFER_LOADER, true));
        setTimeout(() => {
          dispatch(defaultAction(SET_SELL_CARD_OFFER_LOADER, false));
        }, 2000);
      }
    } catch (error) {
      throw `failed while calling card to sell cart ${error}`;
    }
  };

export const deleteSellCardInCart =
  cardID => async (dispatch, getState, api) => {
    dispatch(defaultAction(DELETE_CARD_RUNNING, true));
    try {
      const { cartSell } = getState();
      await api.delete(`v3/carts/${cartSell.cartId}/cards/${cardID}`);
      await dispatch(defaultAction(DELETE_CARD, cardID));
      dispatch(defaultAction(DELETE_CARD_RUNNING, false));
    } catch (error) {
      sendBugsnagError(error, "actions/deleteSellCardInCart");
      dispatch(
        defaultAction(OPEN_ERROR_BOX, {
          title: "Sorry, something went wrong.",
          message:
            "We are sorry. Please refresh the page and try again. Thank you."
        })
      );
      dispatch(defaultAction(DELETE_CARD_RUNNING, false));
    }
  };

export const cancelSellOrder = id => async (dispatch, getState, api) => {
  try {
    await api.put(`v3/orders/${id}`, { cancel: true });
    dispatch(defaultAction(OPEN_CANCEL_SELL_ORDER_MODAL, false));
    await dispatch(refreshSellOrders());
  } catch (error) {
    sendBugsnagError(error, "actions/cancelSellOrder");
    dispatch(
      defaultAction(OPEN_ERROR_BOX, {
        title: "Cancel order error",
        message:
          "We are sorry. Please refresh the page and try again. Thank you."
      })
    );
    dispatch(defaultAction(HIDE_LOADING));
    dispatch(defaultAction(OPEN_CANCEL_SELL_ORDER_MODAL, false));
  }
};

const refreshSellOrders = () => async (dispatch, getState, api) => {
  try {
    const results = await api.get("v3/orders/sell");
    const sorted = results?.data?.sellOrders?.sort(
      (a, b) => new Date(b.date.placed) - new Date(b.date.placed)
    );
    dispatch(defaultAction(FILL_SELL_ORDERS, sorted));
  } catch (error) {
    throw `refreshSellOrders: ${error}`;
  }
};

export const cancelSellOrderWithReload =
  id => async (dispatch, getState, api) => {
    try {
      await api.put(`v3/orders/${id}`, { cancel: true });
      dispatch(defaultAction(OPEN_CANCEL_SELL_ORDER_MODAL, false));
      await dispatch(getOrderInformation(id));
    } catch (error) {
      sendBugsnagError(error, "actions/cancelSellOrderWithReload");
      dispatch(
        defaultAction(OPEN_ERROR_BOX, {
          title: "Cancel order error",
          message:
            "We are sorry. Please refresh the page and try again. Thank you."
        })
      );
      dispatch(defaultAction(HIDE_LOADING));
      dispatch(defaultAction(OPEN_CANCEL_SELL_ORDER_MODAL, false));
    }
  };

export const sendCardAndPinToServer =
  ({ cardID, number, pin }) =>
  async (dispatch, getState, api) => {
    const { cards, cartSell } = getState();
    try {
      let bodyToSendToServer = { number, pin };
      removeEmpty(bodyToSendToServer);
      const results = await api.put(
        `v3/carts/${cartSell.cartId}/cards/${cardID}`,
        {
          card: bodyToSendToServer
        }
      );
      let allCards = getSellCards(results.data, cards);
      dispatch(updateTheCard(allCards[0], cardID));
      dispatch(defaultAction(ADD_CARD_TO_CART, allCards[1]));
      dispatch(defaultAction(CLEAR_UPDATE_CARD_SERVER_ERROR, { cardID }));
    } catch (error) {
      sendBugsnagError(error, "actions/sendCardAndPinToServer");
      let errorMessage = errorResponseMessage(error);

      if (
        errorMessage &&
        (errorMessage.includes("It seems like the card you entered") ||
          errorMessage.includes("The card number you entered appears"))
      ) {
        dispatch(
          defaultAction(SET_UPDATE_CARD_SERVER_ERROR, {
            cardID,
            msg: errorMessage
          })
        );
      } else {
        dispatch(
          defaultAction(SET_UPDATE_CARD_SERVER_ERROR, {
            cardID,
            msg: "This card is ineligible for sale at this time."
          })
        );
      }
    }
  };

const updateTheCard = (cartSell, cardID) => {
  cartSell[cardID].cardError = "PASSED";
  return {
    type: UPDATE_THE_CARD,
    payload: {
      cartSell,
      cardID
    }
  };
};

export const updateStepOneFields =
  (cardID, merchantId, amount) => async (dispatch, getState, api) => {
    if (amount === "" || amount === ".") {
      amount = 0;
    }
    try {
      const { cards, cartSell } = getState();
      const results = await api.put(
        `v3/carts/${cartSell.cartId}/cards/${cardID}`,
        {
          card: { merchantId: Number(merchantId), enterValue: Number(amount) }
        }
      );
      let sellCart = getSellCards(results.data, cards);
      dispatch(defaultAction(ADD_CARD, sellCart[0]));
      dispatch(defaultAction(ADD_CARD_TO_CART, sellCart[1]));
    } catch (error) {
      sendBugsnagError(error, "actions/updateStepOneFields");
      const errorMessage = errorResponseMessage(error);
      let title = "";
      let message = "";

      if (
        errorMessage ===
        "Whoops! $200 is the maximum amount a customer may exchange."
      ) {
        title = "Trouble updating card";
        message =
          "Whoops! $200 is the total maximum amount a customer may exchange.";
      } else if (
        errorMessage ===
        "Error, card number or pin does not conform to validation for card"
      ) {
        title = "Sorry";
        message =
          "The merchant you have entered does not conform with the card number and pin. If you wish to change this merchant please delete this card.";
      } else if (
        errorMessage === "entered value does not meet minimum value required"
      ) {
        title = "Trouble updating card";
        message =
          "The merchant you have entered has a minimum value required. If you wish to change this merchant please delete this card and re-enter the merchant and value on a new line.";
      } else {
        title = "Sorry, something went wrong.";
        message =
          "We are sorry. Please refresh the page and try again. Thank you.";
      }

      dispatch(
        defaultAction(OPEN_ERROR_BOX, {
          title,
          message
        })
      );
    }
  };
