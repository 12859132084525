import dlog from "../../utils/dlog";

export function globalLinker(rest, variable) {
  return {
    ...rest,
    [variable]() {
      if (global[variable] != null) return global[variable];
      throw new Error(`${variable} variable is not defined`);
    }
  };
}

/**
 * How we represent an External web service (e.g. GoogleAnalytics, Riskified)
 */
export default class Service {
  constructor(name) {
    this.name = name;
    this.forceLoad = false;
  }

  // Override
  beforeScript() {}

  // Override
  afterScript() {}

  // Override
  get variables() {
    return {};
  }

  get protocol() {
    return "https:" == document.location.protocol ? "https://" : "http://";
  }

  get script() {
    if (this._script == null)
      throw new Error(
        `${this.name} service must be setup before getting a reference to a script.`
      );

    return this._script;
  }

  setup(deps, resolve) {
    dlog(`Setting up ${this.name}.`);

    this.beforeScript(deps);
    this._script = document.createElement("script");
    this._script.async = true;
    this._script.type = "application/javascript";
    this.afterScript(deps, resolve);

    return this._script;
  }

  reload(deps) {
    let parent = this.script.parentElement;
    this.script.parentElement.removeChild(this.script);
    parent.appendChild(this.setup(deps));
  }
}
