import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PreloadLink from "../../../components/PreloadLink";
import Link from "@modules/Components/Link";
import { useLocation } from "react-router-dom";

const NavLinkMain = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin: 0;
`;

const NavLinkItem = styled.div`
  height: 100%;
  display: grid;
  text-align: center;
  grid-template-rows: auto 1fr;
`;

const NavLinkBar = styled.div`
  width: 127.1px;
  height: 3px;
  border-radius: 1.5px;
`;

const NavLinks = ({ scrolled, loadablePage, route, linkText, type }) => {
  const location = useLocation();

  const [currentPage, changeCurrentPage] = useState("");
  useEffect(() => {
    handleLocationUrl();
  }, [location.pathname]);

  const handleLocationUrl = () => {
    const pathname = location.pathname;
    if (
      pathname.startsWith("/buy") ||
      pathname.startsWith("/cart") ||
      pathname.startsWith("/checkout") ||
      pathname.startsWith("/receipt")
    ) {
      changeCurrentPage("buy");
    } else if (
      pathname.startsWith("/sell") ||
      pathname.startsWith("/step-two") ||
      pathname.startsWith("/payout") ||
      pathname.startsWith("/order-complete")
    ) {
      changeCurrentPage("sell");
    } else {
      changeCurrentPage("");
    }
  };
  return (
    <NavLinkMain style={{ height: scrolled ? "76px" : "96px" }}>
      <NavLinkItem>
        <NavLinkBar
          style={{
            backgroundColor: currentPage === type ? "#ff9c1b" : "transparent"
          }}
        />
        <PreloadLink page={loadablePage}>
          <Link className="nav-links-link" to={route}>
            {linkText}
          </Link>
        </PreloadLink>
      </NavLinkItem>
    </NavLinkMain>
  );
};
export default NavLinks;
