import {
  SET_SIMILAR_MERCHANTS,
  LOAD_BUY_MERCHANTS,
  RECEIVE_NEW_MERCHANTS,
  OPEN_ERROR_BOX
} from "@constants/actionTypes";
import { handleError } from "./initAppError";
import { sendBugsnagError } from "@utils/Bugsnag";
import { defaultAction } from "./defaultAction";

export const getBuyMerchants = () => async (dispatch, getState, api) => {
  try {
    const buyMerchants = await api.get("v3/merchants/buy?cache=bust");
    dispatch({ type: LOAD_BUY_MERCHANTS, payload: buyMerchants });
    return buyMerchants?.data?.buyMerchants || [];
  } catch (error) {
    dispatch(handleError(error, "initApp/getBuyMerchants"));
  }
};

export const getNewMerch = () => async (dispatch, getState, api) => {
  try {
    const merchants = await api.get("v3/merchants/new");
    dispatch(defaultAction(RECEIVE_NEW_MERCHANTS, merchants.data.newMerchants));
  } catch (error) {
    sendBugsnagError(error, "initApp/getNewMerch", {
      cookie: globalThis?.document?.cookie,
      cookieEnabled: globalThis?.navigator?.cookieEnabled
    });

    dispatch(
      defaultAction(OPEN_ERROR_BOX, {
        title: "Sorry, something went wrong.",
        message:
          "We are sorry. Please check your internet connection and refresh the page. Thank you."
      })
    );
  }
};

export const setSimilarMerch = data => async (dispatch, getState, api) => {
  dispatch({ type: SET_SIMILAR_MERCHANTS, payload: data });
};

export const returnBuyMerchants = () => async (dispatch, getState, api) => {
  return getState().merchantsBuy;
};

export const setUpBuyCardsForRedux = cart => {
  let currentCards = {};
  cart.cards.forEach(value => {
    currentCards[value.id] = {
      ...value
    };
  });
  return currentCards;
};

export const getCartIds = cart => cart.cards.map(card => card.id);
