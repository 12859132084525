import {
  LOAD_BUY_MERCHANTS,
  LOAD_ALL_BUY_MERCHANTS,
  LOAD_POPULAR_BUY_MERCHANTS,
  LOAD_LARGEST_DISCOUNT_BUY_MERCHANTS
} from "@constants/actionTypes";

const merchantsBuy = (
  state = {
    sortedByName: [],
    popularMerchants: [],
    largestDiscountMerchants: []
  },
  action
) => {
  let newState;

  switch (action.type) {
    case LOAD_BUY_MERCHANTS:
      newState = {
        ...state,
        sortedByName: action.payload.data.buyMerchants,
        popularMerchants: action.payload.data.buyMerchants
          .filter(card => card.cardsAvailable && card.popular)
          .sort((a, b) => b.popular - a.popular)
          .slice(0, 4),
        largestDiscountMerchants: action.payload.data.buyMerchants
          .filter(card => card.upToPercentage > 0)
          .sort((a, b) => b.upToPercentage - a.upToPercentage)
          .slice(0, 6)
      };
      return {
        ...state,
        ...newState.sortedByName.reduce((obj, merchant) => {
          obj[merchant.id] = merchant;
          return obj;
        }, newState)
      };

    case LOAD_LARGEST_DISCOUNT_BUY_MERCHANTS:
      return { ...state, largestDiscountMerchants: action.payload };

    case LOAD_POPULAR_BUY_MERCHANTS:
      return { ...state, popularMerchants: action.payload };

    case LOAD_ALL_BUY_MERCHANTS:
      return { ...state, sortedByName: action.payload };

    default:
      return state;
  }
};
export default merchantsBuy;
