import React, { useRef, useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled";
import HomeCategory from "./HomeCategory";
import { getAllCategories } from "@actions/initApp";
import { getBuyMerchants } from "@actions/buyMerchants";
import { returnBuyMerchants } from "@actions/buyMerchants";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

const Main = styled.div`
  border-radius: 0px 460px 0px 0px;
  background-color: #0d0236;
  padding-top: 10px;
  overflow: hidden;

  @media (max-width: 1575px) {
    border-radius: 0px 370px 0px 0px;
  }
  @media (max-width: 1150px) {
    border-radius: 0px 260px 0px 0px;
  }
  @media (max-width: 950px) {
    margin-top: -220px;
    z-index: 1;
    position: relative;
  }
  @media (max-width: 575px) {
    border-radius: 0px 200px 0px 0px;
  }
  @media (max-width: 400px) {
    border-radius: 0px 140px 0px 0px;
  }
`;

const HomeCategories = () => {
  const [loading, setLoading] = useState(false);
  const categoryRef = useRef();
  const dispatch = useDispatch();
  const categories = useSelector(
    state => state.categories?.homeCategories || []
  );
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const debounceSetSearch = useCallback(debounce(setFunction, 100), []);
  async function setFunction() {
    const buyMerch = await dispatch(getBuyMerchants());
    dispatch(getAllCategories(buyMerch));
  }
  const scrollHandler = async () => {
    const merchantsBuy = await dispatch(returnBuyMerchants());

    if (
      categoryRef?.current?.getBoundingClientRect &&
      window.pageYOffset + window.innerHeight >= categoryRef.current.offsetTop
    )
      if (categoryRef.current.getBoundingClientRect().y < window.pageYOffset) {
        if (merchantsBuy.sortedByName.length < 1 && !loading) {
          setLoading(true);
          debounceSetSearch();
        }
        window.removeEventListener("scroll", scrollHandler);
      }
  };
  return (
    <Main ref={categoryRef}>
      {categories.map((item, index) => (
        <HomeCategory category={item} index={index} key={index} />
      ))}
    </Main>
  );
};

export default HomeCategories;
