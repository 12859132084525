export function round(value, decimals = 2) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals) || 0;
}

export const trimString = v => (v && typeof v === "string" ? v.trim() : v);

export const stripNonDigits = phone => phone.replace(/[^\d]/g, "");

export const currencyFormatter = (num, noSymbol) => {
  num = Number(num);
  const dollarSymbol = noSymbol === "no-symbol" ? "" : "$";
  return (
    dollarSymbol +
    `${round(num, 2).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};

export const covertSecondsToTimeClock = time => {
  let minutes = Math.floor(time / 60);
  let seconds = time - minutes * 60;
  let hours = Math.floor(time / 3600);
  time = time - hours * 3600;
  let str_pad_left = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  };

  return {
    minutes: str_pad_left(minutes, "0", 2),
    seconds: str_pad_left(seconds, "0", 2)
  };
};

export const capitalizeFirstLetter = string => {
  if (string == null || string == null || string.length === 0) {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isVowel = letter => {
  return ["a", "e", "i", "o", "u"].indexOf(letter.toLowerCase()) !== -1;
};

export const calculateDiscount = (cost, balance) => {
  const discount = ((cost - balance) / balance) * 100;
  return parseFloat(Math.abs(discount)).toFixed(2);
};

export const thisIsTheBrowser = typeof window !== "undefined";

const formatCCName = str => {
  if (str && str === "master-card") {
    return "MasterCard";
  }
  if (str) {
    return str
      .split("-")
      .map(v => capitalizeFirstLetter(v))
      .join(" ");
  }
  return str;
};

export const checkLuhn = input => {
  let sum = 0;
  let numdigits = input.length;
  let parity = numdigits % 2;
  for (let i = 0; i < numdigits; i++) {
    let digit = parseInt(input.charAt(i));
    if (i % 2 == parity) digit *= 2;
    if (digit > 9) digit -= 9;
    sum += digit;
  }
  return sum % 10 == 0;
};

export const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] == "" || obj[key] == null) {
      delete obj[key];
    }
  });
  return obj;
};

export const trimObject = obj => {
  Object.keys(obj).forEach(key => {
    obj[key] = obj[key].trim();
  });
  return obj;
};

export const getSrcImage = name => {
  switch (formatCCName(name).toLowerCase()) {
    case "american express":
      return "https://cdn.cardcash.com/website/ui/elements/amex.svg";
    case "mastercard":
      return "https://cdn.cardcash.com/website/ui/elements/mastercard.svg";
    case "visa":
      return "https://cdn.cardcash.com/website/ui/elements/visa.svg";
    case "discover":
      return "https://cdn.cardcash.com/website/ui/elements/discover.svg";
    default:
      return "https://cdn.cardcash.com/website/ui/elements/physical-card.svg";
  }
};

export const parseDate = date => {
  const split = date.split("-");
  const third = split[2].slice(0, 2);
  return `${split[0]}/${split[1]}/${third}`;
};

export const shuffle = arr => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
};

export const addParamsToImage = (img, size) => {
  const split = img.split("/");
  const pop = split.pop();
  return `${split.join("/")}/${size}/${pop}`;
};
