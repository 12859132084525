import React from "react";
import styled from "@emotion/styled";
import HomeSearchHeader from "./HomeSearchHeader";
import { useDispatch, useSelector } from "react-redux";
import { defaultAction } from "../../actions/defaultAction";
import { SHOW_REFER_POPUP_MODAL } from "@constants/refer";
import { SHOW_GLOBAL_LOADER_POPUP_MODAL } from "@constants/actionTypes";
import HomeSearchForm from "./HomeSearchForm";
import ReactLazy from "../../components/ReactLazy";

const QuickSearchMainContainer = styled.div`
  max-width: 70%;
  padding: 0;
  position: absolute;
  top: 7%;
  left: 15%;
  @media (max-width: 1700px) {
    left: 12.5%;
  }
  @media (max-width: 1600px) {
    left: 10%;
  }
  @media (max-width: 140px) {
    top: 6.5%;
  }
  @media (max-width: 1250px) {
    top: 5%;
    left: 7.5%;
  }

  @media (max-width: 1150px) {
    top: 6%;
  }
  @media (max-width: 950px) {
    top: 9%;
  }
  @media (max-width: 728px) {
    left: 5%;
  }
  @media (max-width: 685px) {
    top: 5%;
  }
  @media (max-width: 645px) {
    top: 3%;
  }
  @media (max-width: 620px) {
    top: 2%;
  }
  @media (max-width: 605px) {
    top: 1%;
  }
  @media (max-width: 575px) {
    position: initial;
    width: 100%;
    max-width: 100%;
  }
`;

const QuickSearchContainer = styled.div`
  padding: 0;
`;

const SearchFormMainContainer = styled.div`
  width: 422px;
  box-shadow: 0 50px 70px 0 rgba(31, 32, 33, 0.2);
  background-color: #ffffff;
  z-index: -3;
  position: relative;
  padding-top: 6px;
  z-index: 1;

  @media (max-width: 1400px) {
    width: 390px;
  }
  @media (max-width: 575px) {
    width: 100%;
    display: grid;
    box-shadow: none;
    overflow: hidden;
    min-height: 639px;
  }
`;

const SearchFormContainer = styled.div`
  padding-bottom: 40px;
  @media (max-width: 1400px) {
    padding-bottom: 16px;
  }
  @media (max-width: 575px) {
    z-index: 1;
  }
`;

const ReferLink = styled.div`
  background-color: #494168;
  min-height: 46px;
  text-align: center;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;

  @media (max-width: 1150px) {
    min-height: 36px;
    text-align: center;
  }

  @media (max-width: 575px) {
    position: relative;
    z-index: 2;
  }
`;

const IconSpan = styled.span`
  margin-right: 10px;
`;

const IconImage = styled.img`
  width: 18px;
  height: 18px;
`;

const MobileImage = styled.img`
  @media (min-width: 576px) {
    display: none;
  }

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: auto;

  @media (max-width: 450px) {
    width: 120%;
  }
  @media (max-width: 325px) {
    width: 130%;
  }
  @media (max-width: 295px) {
    width: 140%;
  }
`;

const HomeSearchBox = () => {
  const signedIn = useSelector(state => state.initAppReducer.signedIn);
  const dispatch = useDispatch();

  const handleRefer = () => {
    window.scrollTo(0, 0);
    if (signedIn) {
      dispatch(defaultAction(SHOW_REFER_POPUP_MODAL, true));
    } else {
      dispatch(defaultAction(SHOW_GLOBAL_LOADER_POPUP_MODAL, "homeRefer"));
    }
  };
  return (
    <QuickSearchMainContainer>
      <QuickSearchContainer>
        <SearchFormMainContainer className="homePageSearchContainer">
          <MobileImage
            src="https://cdn.cardcash.com/website/ui/Home/hero-mobile.png"
            alt=""
          />
          <HomeSearchHeader />
          <SearchFormContainer>
            <HomeSearchForm />
          </SearchFormContainer>
          <ReferLink onClick={handleRefer}>
            <IconSpan>
              <ReactLazy height={80} offset={200}>
                <IconImage
                  src="https://cdn.cardcash.com/website/ui/elements/chat.svg"
                  alt="refer icon"
                  loading="lazy"
                />
              </ReactLazy>
            </IconSpan>
            Invite Friends, Earn Rewards!
          </ReferLink>
        </SearchFormMainContainer>
      </QuickSearchContainer>
    </QuickSearchMainContainer>
  );
};

export default HomeSearchBox;
