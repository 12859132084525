import React from "react";
import { useSelector } from "react-redux";
import Resizer from "@hocs/Resizer";
import TopMessageDesktop from "./TopMessageDesktop";
import TopMessageMobile from "./TopMessageMobile";
import styled from "@emotion/styled";

const TopMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media (min-width: 500px) {
    max-width: 100%;
  }
  @media (min-width: 979px) {
    max-width: 900px;
  }
  @media (min-width: 980px) {
    max-width: 1080px;
  }
  height: 100%;
  color: white;
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const TopMessageContent = () => {
  const userMobile = useSelector(state => state.config.userAgent.isMobile);
  return (
    <TopMessageContainer>
      <CenterColumn>
        <CenterRow>
          <Resizer
            components={[
              {
                component: () => <TopMessageMobile />,
                break: 1,
                maxBreak: 769
              },
              {
                component: () => <TopMessageDesktop />,
                break: 768,
                maxBreak: Number.MAX_SAFE_INTEGER
              }
            ]}
            defaultComponent={() =>
              userMobile ? <TopMessageMobile /> : <TopMessageDesktop />
            }
          />
        </CenterRow>
      </CenterColumn>
    </TopMessageContainer>
  );
};

export default TopMessageContent;
