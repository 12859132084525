import {
  GET_ORDER_INFORMATION,
  SHOW_ERROR_ORDER_DETAILS,
  RESET,
  RESET_EXPIRE
} from "@constants/actionTypes";

const initialState = {
  details: {
    orderId: "",
    amount: "",
    paymentType: "",
    paymentStatus: "",
    customer: {
      name: "",
      street: "",
      city: ""
    }
  },
  showError: ""
};

const orderStatus = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_INFORMATION:
      return {
        ...state,
        details: action.payload
      };

    case SHOW_ERROR_ORDER_DETAILS:
      return {
        ...state,
        showError: action.payload
      };

    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};

export default orderStatus;
