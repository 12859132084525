import {
  SHOW_REFER_POPUP_MODAL,
  SHOW_REFER_SIGN_UP_STATUS_POPUP_MODAL,
  SET_REFERRAL_DATA,
  SET_REFER_COUPON,
  SHOW_REFER_SIGN_UP_TO_GET_REWARDS,
  SET_REFER_FIRST_NAME
} from "@constants/refer";

const initialState = {
  showReferPopUpModal: false,
  showReferSignInStatus: false,
  referralStatus: "",
  referredCoupon: "",
  showReferSignUpToGetReward: false,
  referrerFirstName: ""
};

const refer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_REFER_POPUP_MODAL:
      return {
        ...state,
        showReferPopUpModal: action.payload
      };
    case SHOW_REFER_SIGN_UP_STATUS_POPUP_MODAL:
      return {
        ...state,
        showReferSignInStatus: action.payload
      };
    case SET_REFERRAL_DATA:
      return {
        ...state,
        referralStatus: action.payload.referralStatus
          ? action.payload.referralStatus
          : "",
        referredCoupon: action.payload.referredCoupon
          ? action.payload.referredCoupon
          : ""
      };
    case SET_REFER_COUPON:
      return {
        ...state,
        referredCoupon: action.payload.customer.referredCoupon
          ? action.payload.customer.referredCoupon
          : ""
      };

    case SHOW_REFER_SIGN_UP_TO_GET_REWARDS:
      return {
        ...state,
        showReferSignUpToGetReward: action.payload
      };

    case SET_REFER_FIRST_NAME:
      return {
        ...state,
        referrerFirstName: action.payload
      };
    default:
      return state;
  }
};
export default refer;
