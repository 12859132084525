import { mergeDeepRight, append } from "ramda";
import {
  ADD_VERIFICATION_ATTEMPT,
  CLOSE_MODAL,
  FLOW_NEXT,
  FLOW_RESET,
  FLOW_GO,
  IS_LOADING,
  NOT_LOADING,
  OPEN_MODAL,
  SET_EXPIRED,
  SET_FLOW,
  SET_LAST_ATTEMPT,
  SET_ORDER_ID,
  SET_ORDER_UUID_AND_TYPE,
  SET_PENDING,
  SET_PHONE_NUMBERS,
  SET_SELECTED_PHONE_NUMBER_ID,
  SET_STATUS,
  SET_PHONE_TYPE,
  SET_SUBMIT_ATTEMPTS,
  SET_VALIDATION_TOKEN,
  RESET
} from "./constant";
import { RESET_EXPIRE } from "@constants/actionTypes";

// NOTE: Don't abuse.
const foundOrFirst = idx => (idx === -1 ? 0 : idx);

const initialState = {
  flow: [],
  flowCursor: -1,
  loading: false,
  isModalOpen: false,
  orderId: null,
  phoneNumbers: [],
  phoneValidationToken: null,
  selectedPhoneNumberId: "",
  status: null,
  attempt: {},
  verificationAttempts: [],
  submitAttempts: 0,
  phoneType: "",
  orderType: "",
  orderUUID: ""
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_LOADING:
      return mergeDeepRight(state, {
        loading: true
      });
    case NOT_LOADING:
      return mergeDeepRight(state, {
        loading: false
      });
    case SET_VALIDATION_TOKEN:
      return mergeDeepRight(state, {
        phoneValidationToken: action.token
      });
    case ADD_VERIFICATION_ATTEMPT:
      return mergeDeepRight(state, {
        verificationAttempts: append(action.payload, state.verificationAttempts)
      });
    case SET_PHONE_TYPE:
      return { ...state, phoneType: action.phoneType };

    case SET_SUBMIT_ATTEMPTS:
      return { ...state, submitAttempts: action.submitAttempts };

    case SET_ORDER_ID:
      return {
        ...state,
        orderId: action.orderId
      };
    case SET_ORDER_UUID_AND_TYPE:
      return {
        ...state,
        orderType: action.orderType,
        orderUUID: action.orderUUID
      };
    case SET_LAST_ATTEMPT:
      return {
        ...state,
        attempt: {
          validationType: action.validationType,
          phoneUUID: action.phoneUUID,
          validationToken: action.validationToken
        }
      };
    case SET_SELECTED_PHONE_NUMBER_ID:
      return {
        ...state,
        selectedPhoneNumberId: action.phoneNumber
      };
    case SET_PHONE_NUMBERS:
      return {
        ...state,
        phoneNumbers: action.phoneNumbers
      };
    case SET_STATUS:
      return {
        ...state,
        status: action.status
      };
    case SET_PENDING:
      return {
        ...state,
        status: "pending"
      };
    case SET_EXPIRED:
      return {
        ...state,
        status: "expired"
      };
    case SET_FLOW:
      return {
        ...state,
        flow: action.flow,
        flowCursor: action.flow.length === 0 ? -1 : 0
      };
    case FLOW_NEXT:
      return {
        ...state,
        flowCursor: (state.flowCursor + 1) % state.flow.length
      };
    case FLOW_GO:
      return {
        ...state,
        flowCursor: foundOrFirst(state.flow.indexOf(action.step))
      };
    case FLOW_RESET:
      return {
        ...state,
        flowCursor: state.flow.length === 0 ? -1 : 0
      };
    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false
      };
    case RESET_EXPIRE:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
