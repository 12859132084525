import React from "react";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { SET_NAV_SEARCH_OPEN } from "@constants/actionTypes";
import { defaultAction } from "@actions/defaultAction";
import DefaultOrangeButton from "@modules/Components/DefaultOrangeButton";
import SmallButtonSpinner from "@components/SmallButtonSpinner";
import { useHistory } from "react-router-dom";
import { quickBuySearch } from "@actions/quickBuy";
import { addResult, addErrorMessage } from "./reducer";
import { showQuickBuySearchResults } from "../../actions/search";
import { FaSearch } from "react-icons/fa";

const ButtonDiv = styled.div`
  border: solid 2px rgba(31, 32, 33, 0.1);
  border-left: none;
  border-radius: 0px 30px 30px 0px;
  width: 108px;
  display: flex;
  align-items: center;
  z-index: 2;

  @media (max-width: 999px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const NavSearchButtonDiv = ({
  loading,
  setLoading,
  selectedMerchant,
  selectedAmount,
  setSelectedMerchant,
  mobileView,
  reset
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleButtonClick = async disabled => {
    if (disabled || loading) {
      return;
    } else {
      setLoading(true);
      if (
        selectedMerchant?.id &&
        selectedMerchant?.cardsAvailable &&
        selectedAmount
      ) {
        const result = await dispatch(
          quickBuySearch(
            selectedMerchant?.id,
            selectedMerchant?.cardType,
            selectedAmount
          )
        );
        if (result) {
          dispatch(addResult(result));
          dispatch(showQuickBuySearchResults());
        } else {
          dispatch(
            addErrorMessage({
              error:
                "Sorry, we could not find a package of cards that meets your search.",
              brand: selectedMerchant
            })
          );
        }
        history.push(`/buy-gift-cards/${selectedMerchant?.slug}/`);
        setLoading(false);
      } else {
        setSelectedMerchant();
        dispatch(defaultAction(SET_NAV_SEARCH_OPEN, false));
        history.push(`/buy-gift-cards/${selectedMerchant?.slug}/`);
        setLoading(false);
      }
    }
    reset();
  };
  return (
    <ButtonDiv>
      <DefaultOrangeButton
        handleClick={(disabled, e) => {
          e.stopPropagation();
          if (!loading) handleButtonClick(disabled);
        }}
        disabled={!selectedMerchant}
        classes={"main-default-orange-button"}
      >
        {loading ? (
          <SmallButtonSpinner img="https://cdn.cardcash.com/website/ui/elements/spinner-white.png" />
        ) : (
          <>{mobileView ? <FaSearch /> : <span>Search</span>}</>
        )}
      </DefaultOrangeButton>
    </ButtonDiv>
  );
};

export default NavSearchButtonDiv;
