import {
  SET_CLAIM_TYPE,
  SET_CLAIM_AMOUNT_TO_REFUND,
  SET_ORDER_SUPPORT_INFO
} from "@constants/actionTypes";

const initialState = {
  name: "",
  email: "",
  orderId: "",
  orderType: "",
  merchantName: "",
  cardId: "",
  faceValue: "",
  cost: "",
  cardType: "",
  claimType: "",
  refundAmount: 0,
  fulfilledId: null,
  prevClaimAmount: 0
};

const orderAssistance = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLAIM_TYPE:
      return {
        ...state,
        claimType: action.payload
      };

    case SET_ORDER_SUPPORT_INFO:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        orderId: action.payload.orderId,
        orderType: action.payload.orderType,
        merchantName: action.payload.merchantName,
        cardId: action.payload.cardId,
        faceValue: action.payload.faceValue,
        cost: action.payload.cost,
        cardType: action.payload.cardType,
        fulfilledId: action.payload.fulfilledId,
        prevClaimAmount: action.payload.prevClaimAmount,
        orderUUID: action.payload.orderUUID
      };

    case SET_CLAIM_AMOUNT_TO_REFUND:
      return {
        ...state,
        refundAmount: action.payload
      };

    default:
      return state;
  }
};

export default orderAssistance;
