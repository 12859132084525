export const CITYREGEX = new RegExp("^[a-zA-Z]+(?:(?:\\s+|-)[a-zA-Z]+)*$");
export const PHONEREGEX = new RegExp(
  "^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)" +
    "|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*" +
    "(?:[.-]\\s*)?([0-9]{4})(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+))?$"
);
// export const PASSREGEX = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{8,}$")
export const PASSREGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@$!%*?&#]).{8,}$/;
export const STREETREGEX = new RegExp("^[Aa-zA-Z\\d\\s-.,#'/]*$");
export const NUMSREGEX = new RegExp("^[0-9]+$");
export const ZIPREGEX = /^\d{5}(?:[-\s]\d{4})?$/;
export const EMAILREGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAMEREGEX =
  /^[A-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\\'-]+$/;
export const FULLNAMEREGEX =
  /^[A-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\\'-]+[ ][A-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\\'-]+$/;

export const BALANCECHECK = new RegExp(
  "^[+-]?[0-9]{1,3}(:?[0-9]{3})*(?:.[0-9]{2})?$"
);
export const MONEYREGEX = /^\d{1,100}(\.\d{0,2})?$/;
export const CARD_NUMBER_REGEX = /^[A-Za-z0-9]{3,30}?$/;
export const CARD_PIN_REGEX = /^([A-z0-9]|[\*]){0,24}$/;
export const ACCOUNT_NUMBER = /^[0-9]{4,17}$/;
export const ROUTING_NUMBER = /^[0-9]{9}$/;
export const DOLLAR = /^\d{1,100}(\.\d{0,2})?$/;
