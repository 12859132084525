export const FEATURED_BRANDS = [
  {
    brand: "abc",
    link: "http://abcnews.go.com/Lifestyle/holiday-savings-101-discount-holiday-gift-cards/story?id=35364111",
    width: "23px",
    height: "23px"
  },
  {
    brand: "forbes",
    link: "https://www.forbes.com/sites/karstenstrauss/2014/07/30/your-unwanted-gift-cards-are-worth-millions-these-guys-are-building-an-empire-with-them/",
    width: "95px",
    height: "23px"
  },
  {
    brand: "fox",
    link: "http://www.foxbusiness.com/features/2013/11/18/new-ways-to-sell-unwanted-gift-cards.html",
    width: "55px",
    height: "23px"
  },
  {
    brand: "cnn",
    link: "http://money.cnn.com/2011/11/18/pf/holiday_money_gift_cards/",
    width: "49px",
    height: "23px"
  },
  {
    brand: "cbs",
    link: "https://www.cbsnews.com/essentials/gift-cards-shipping-delays-holiday-shopping/",
    width: "76px",
    height: "23px"
  },
  {
    brand: "aol",
    link: "https://www.aol.com/article/2013/02/15/unwanted-gift-cards-5-ways-cash-in/20460744/",
    width: "60px",
    height: "23px"
  },
  {
    brand: "bloomberg",
    link: "http://www.bloomberg.com/research/stocks/private/snapshot.asp?privcapId=249977568",
    width: "73px",
    height: "23px"
  },
  {
    brand: "ey",
    link: "http://www.ey.com/us/en/newsroom/news-releases/news-ey-announces-the-ey-entrepreneur-of-the-year-2015-award-winners-in-new-jersey",
    width: "27px",
    height: "23px"
  },
  {
    brand: "today",
    link: "http://www.today.com/money/make-millennial-smile-christmas-give-digital-gift-card-1D80266453",
    width: "29px",
    height: "23px"
  },
  {
    brand: "yahoo",
    link: "http://finance.yahoo.com/news/ways-sell-unwanted-gift-cards-100000869.html",
    width: "99px",
    height: "23px"
  },
  {
    brand: "time",
    link: "http://time.com/money/3025192/movie-tickets-prices-deals-savings/",
    width: "74px",
    height: "23px"
  },
  {
    brand: "marketwatch",
    link: "http://www.marketwatch.com/story/1-billion-in-gift-cards-go-unredeemed-2013-11-27",
    width: "165px",
    height: "23px"
  },
  {
    brand: "inc500",
    link: "http://www.inc.com/profile/cardcashcom",
    width: "29px",
    height: "23px"
  }
];
