import { combineReducers } from "redux";
import {
  disable,
  enable,
  setFormsCompleted,
  reset,
  agreeToTerms
} from "./constant";

function submitDisabled(state = false, action) {
  switch (action.type) {
    case disable:
      return true;
    case enable:
      return false;
    default:
      return state;
  }
}
const initialState = {
  formsCompleted: false,
  agreeToTerms: false
};

function handlePaymentMethods(state = initialState, action) {
  switch (action.type) {
    case setFormsCompleted:
      return { ...state, formsCompleted: action.payload };
    case reset:
      return {
        ...state,
        formsCompleted: false
      };
    default:
      return state;
  }
}

function handleAgreeToTerms(state = initialState, action) {
  switch (action.type) {
    case agreeToTerms:
      return {
        ...state,
        agreeToTerms: action.payload
      };
    default:
      return state;
  }
}

export default combineReducers({
  submitDisabled,
  handlePaymentMethods,
  handleAgreeToTerms
});
