import {
  CARD_PARTIAL_MERCHANT,
  CARD_PARTIAL_CLEAR,
  CARD_PARTIAL_BALANCE,
  DISPLAY_CARD_ERROR_PARTIAL,
  DISPLAY_MAX_ERROR_PARTIAL,
  DISPLAY_MIN_ERROR_PARTIAL,
  DISPLAY_MERCHANT_ERROR_PARTIAL,
  DISPLAY_NO_ERROR_PARTIAL,
  DISPLAY_CARD_BALANCE_FORMAT_ERROR,
  COMPLETE_ORDER_SELL,
  RESET,
  RESET_EXPIRE,
  RESET_SELL
} from "@constants/actionTypes";

const initialState = {
  cardBalance: "",
  selectedMerchant: null,
  displayBalanceError: false,
  displayMaxError: false,
  displayMinError: false,
  displayMerchantError: false
};

const cardPartial = (state = initialState, action) => {
  switch (action.type) {
    case CARD_PARTIAL_MERCHANT:
      return {
        ...state,
        selectedMerchant: action.payload,
        displayMerchantError: false
      };

    case CARD_PARTIAL_BALANCE:
      return {
        ...state,
        cardBalance: action.payload,
        displayMinError: false
      };

    case DISPLAY_CARD_ERROR_PARTIAL:
      return {
        ...state,
        displayCardBalanceFormatError: false,
        displayMinError: false,
        displayMaxError: false,
        displayBalanceError: true
      };

    case DISPLAY_MAX_ERROR_PARTIAL:
      return {
        ...state,
        displayCardBalanceFormatError: false,
        displayBalanceError: false,
        displayMinError: false,
        displayMaxError: true
      };

    case DISPLAY_MIN_ERROR_PARTIAL:
      return {
        ...state,
        displayCardBalanceFormatError: false,
        displayBalanceError: false,
        displayMaxError: false,
        displayMinError: true
      };

    case DISPLAY_NO_ERROR_PARTIAL:
      return {
        ...state,
        displayCardBalanceFormatError: false,
        displayBalanceError: false,
        displayMaxError: false,
        displayMinError: false,
        displayMerchantError: false
      };

    case DISPLAY_MERCHANT_ERROR_PARTIAL:
      return {
        ...state,
        displayMerchantError: true
      };

    case DISPLAY_CARD_BALANCE_FORMAT_ERROR:
      return {
        ...state,
        displayCardBalanceFormatError: true,
        displayBalanceError: false,
        displayMinError: false,
        displayMaxError: false
      };

    case COMPLETE_ORDER_SELL:
    case CARD_PARTIAL_CLEAR:
    case RESET:
    case RESET_EXPIRE:
    case RESET_SELL:
      return initialState;

    default:
      return state;
  }
};

export default cardPartial;
