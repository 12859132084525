import React from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { getBuyMerchants, getNewMerch } from "@actions/buyMerchants";
import { defaultAction } from "@actions/defaultAction";
import {
  CART_BUY_SHOW_POPUP_CART,
  CART_BUY_HIDE_POPUP_CART,
  SET_BURGER_MENU
} from "@constants/actionTypes";
import ShoppingCartLight from "./ShoppingCartLight";
import ShoppingCartDark from "./ShoppingCartDark";
import loadable from "@loadable/component";
const ShoppingCartCircle = loadable(() =>
  import(/* webpackChunkName: "ShoppingCartCircle" */ "./ShoppingCartCircle")
);

const ShoppingCartDiv = styled.div`
  position: relative;
  text-align: center;
  cursor: pointer;

  @media (max-width: 635px) {
    display: ${props => (props.payoutView ? "none" : "block")};
  }
`;

const ShoppingCartImage = styled.button`
  background: none;
  border-radius: 30rem;
  border: none;
  &:focus {
    outline: none;
  }
`;

const ShoppingCart = ({ payoutView }) => {
  const dispatch = useDispatch();
  const merchantsBuy = useSelector(state => state.merchantsBuy);
  const merchantsNew = useSelector(state => state.merchantsNew);
  const showPopupCart = useSelector(state => state.nav.initNav.showPopupCart);
  const burgerIsOpen = useSelector(state => state.burgerMenu.isOpen);
  const cardsBuy = useSelector(state => state.cardsBuy);

  const openOrCloseCart = () => {
    if (showPopupCart) {
      dispatch(defaultAction(CART_BUY_HIDE_POPUP_CART));
    } else {
      dispatch(defaultAction(CART_BUY_SHOW_POPUP_CART));
    }
    if (burgerIsOpen) dispatch(defaultAction(SET_BURGER_MENU, false));
  };

  const shoppingCartClick = () => {
    if (Object.keys(merchantsBuy).length < 4) {
      dispatch(getBuyMerchants());
    }
    if (Object.keys(merchantsNew).length < 2) {
      dispatch(getNewMerch());
    }
    openOrCloseCart();
  };

  return (
    <ShoppingCartDiv
      payoutView={payoutView}
      onClick={shoppingCartClick}
      id="shopping-cart-div"
    >
      <ShoppingCartImage id="shopping-button" aria-label="shopping cart">
        {cardsBuy && Object.keys(cardsBuy).length > 0 ? (
          <ShoppingCartDark />
        ) : (
          <ShoppingCartLight />
        )}
      </ShoppingCartImage>
      {cardsBuy && Object.keys(cardsBuy).length > 0 && <ShoppingCartCircle />}
    </ShoppingCartDiv>
  );
};
export default ShoppingCart;
