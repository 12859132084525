/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React from "react";
import DLink from "@modules/Components/Link";

const MenuItem = props => {
  return (
    <DLink
      css={css`
        padding: 0.5rem;
        display: grid;
        grid-template-columns: 28px 1fr;
        width: fit-content;
        padding-top: 5px;
        padding-bottom: 5px;
        align-items: center;
        color: #777984;
        &:hover {
          color: #1f2021;
          text-decoration: none;
        }
      `}
      {...props}
    />
  );
};

export default MenuItem;
