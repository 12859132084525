import { createSelector } from "reselect";

const searchArray = ({ searchArray }) =>
  searchArray.filter(item => item.displayDropdown);

const currentItem = ({ searchArray, name }) =>
  searchArray.find(item => item.name === name);

export const getCurrentDropdown = createSelector(searchArray, item => item);
export const getCurrentItem = createSelector(currentItem, item => item);
