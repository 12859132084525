import React from "react";
import styled from "@emotion/styled";
import SocialIcons from "@components/SocialIcons";

const SocialIconGrid = styled.div`
  display: flex;
  justify-content: center;
`;

const SocialFooter = () => (
  <SocialIconGrid>
    <SocialIcons />
  </SocialIconGrid>
);

export default SocialFooter;
