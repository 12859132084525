import React from "react";
import styled from "@emotion/styled";
import { mediaSMDDown } from "@modules/Components/break";
import { useDispatch, useSelector } from "react-redux";
import { SET_BURGER_MENU } from "@constants/actionTypes";
import { defaultAction } from "@actions/defaultAction";

const Bar = styled.div`
  transition: transform 0.3s, opacity 0.3s, top 0.3s;
  width: 100%;
  height: 15%;
  position: absolute;
  top: ${props => props.setTop}%;
  opacity: ${props => props.setHidden};
  transform: rotate(${props => props.setRotate});
  background: #6b6b6b;
  user-select: none;
`;

const Wrapper = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  display: none;
  width: 1.5rem;
  height: 1rem;
  margin-left: 2px;
  ${mediaSMDDown} {
    display: flex;
    min-width: 21px;
  }
  @media (max-width: 575px) {
    display: ${props => (props.payoutView ? "none" : "")};
  }
`;

const BurgerMenu = ({ payoutView }) => {
  const isOpen = useSelector(state => state.burgerMenu.isOpen);
  const dispatch = useDispatch();

  const handleClick = () => {
    const bool = !isOpen;
    dispatch(defaultAction(SET_BURGER_MENU, bool));
  };

  return (
    <Container payoutView={payoutView}>
      <Wrapper onClick={handleClick}>
        <Bar
          setRotate={isOpen ? "45deg" : "0deg"}
          setTop={isOpen ? 50 : 0}
          style={{
            backgroundColor: isOpen ? "#1f2021" : "rgba(31, 32, 33, 0.2)"
          }}
        >
          &nbsp;
        </Bar>
        <Bar
          setHidden={isOpen ? 0 : 1}
          setTop={50}
          style={{
            backgroundColor: isOpen ? "#1f2021" : "rgba(31, 32, 33, 0.2)"
          }}
        >
          &nbsp;
        </Bar>
        <Bar
          setRotate={isOpen ? "-45deg" : "0deg"}
          setTop={isOpen ? 50 : 100}
          style={{
            backgroundColor: isOpen ? "#1f2021" : "rgba(31, 32, 33, 0.2)"
          }}
        >
          &nbsp;
        </Bar>
      </Wrapper>
    </Container>
  );
};

export default BurgerMenu;
