import React from "react";
import styled from "@emotion/styled";

const HeaderDiv = styled.div`
  display: grid;
  padding-left: 10px;
  padding-right: 10px;
  grid-template-columns: 60px 2fr;
  height: 62px;
  align-items: center;
  align-content: center;
  padding-bottom: 0px;

  @media (max-width: 575px) {
    grid-row: 1/2;
    margin: 0 auto;
    grid-gap: 24px;
    grid-template-columns: 57px 168px;
    width: auto;
    margin-top: 35px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 57px 168px;
    width: auto;
  }
`;

const LineDiv = styled.div`
  width: 90%;
  margin: 0 auto;
  height: 1px;
  opacity: 0.15;
  background-image: linear-gradient(
    to right,
    rgba(66, 67, 69, 0.3),
    #1f2021 51%,
    rgba(31, 32, 33, 0.3)
  );

  @media (max-width: 575px) {
    display: none;
  }
`;

const IconDiv = styled.div`
  text-align: center;
  height: 62px;
  display: flex;
  justify-content: center;

  @media (max-width: 575px) {
    align-items: center;
  }
`;

const TopHeader = styled.p`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #777984;
  margin: 0;
  opacity: 0.5;

  @media (max-width: 1400px) {
    font-size: 13px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    color: #ffffff;
    opacity: 0.8;
  }
`;

const TurnGiftCardHeader = styled.h1`
  font-size: 36px;
  font-weight: 400;
  line-height: 1.33;
  text-align: center;
  color: #1f2021;
  width: 65%;
  margin: 0 auto;
  margin-top: 31px;
  margin-bottom: 23px;

  @media (max-width: 1400px) {
    margin-top: 23px;
    font-size: 34px;
  }
  @media (max-width: 595px) {
    font-size: 27px;
  }
  @media (max-width: 575px) {
    color: #ffffff;
    font-size: 36px;
    margin-bottom: 30px;
    width: 300px;
    margin-top: 18px;
    z-index: 1;
  }
  @media (max-width: 305px) {
    width: 100%;
  }
`;

const FoxImage = styled.div`
  content: url("https://cdn.cardcash.com/website/ui/elements/foxnews1.png");
  width: 41px;
  height: 32px;
  align-self: center;
  margin-top: -11px;
  opacity: 0.4;

  @media (max-width: 575px) {
    content: url("https://cdn.cardcash.com/website/ui/Home/foxnewsmobile.png");
    margin: 0;
    opacity: 0.7;
  }
`;

const HomeSearchHeader = () => {
  return (
    <>
      <HeaderDiv>
        <IconDiv>
          <FoxImage alt="fox news image" />
        </IconDiv>
        <TopHeader>
          &ldquo;CardCash.com is the StubHub for Gift Cards&rdquo;
        </TopHeader>
      </HeaderDiv>
      <LineDiv></LineDiv>
      <TurnGiftCardHeader>Turn gift cards into discounts</TurnGiftCardHeader>
    </>
  );
};

export default HomeSearchHeader;
