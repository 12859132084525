import React from "react";
import styled from "@emotion/styled";
import FeaturedIn from "@components/FeaturedIn";
import { FEATURED_BRANDS } from "@constants/consumerFrontPage";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  height: 100%;
  align-items: center;
  margin-bottom: 0;
  @media (max-width: 1500px) {
    display: grid;
    grid-template-columns: repeat(13, auto);
    grid-gap: 5px;
    width: 85%;
  }
  @media (max-width: 1400px) {
    grid-template-columns: repeat(10, auto);
    grid-gap: 5px;
    width: 90%;
  }
  @media (max-width: 950px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 14px;
  }
  @media (max-width: 390px) {
    width: 100%;
  }
`;

const MainContainer = styled.div`
  margin-top: 0px;
  height: 100%;
  @media (max-width: 1150px) {
    margin-top: 0px;
  }
  @media (max-width: 950px) {
    margin-top: 0px;
    height: 150px;
  }
`;

const PressRecognition = () => {
  let mappedCardsArray = FEATURED_BRANDS.map((brand, key) => {
    return { ...brand, position: key };
  });

  return (
    <MainContainer>
      <Container>
        {mappedCardsArray.map((logo, index) => {
          return (
            <FeaturedIn
              key={logo.brand}
              width={logo.width}
              height={logo.height}
              image={`https://cdn.cardcash.com/website/ui/Home/${logo.brand}.png`}
              brand={logo.brand}
              link={logo.link}
              position={logo.position}
              media={
                index < 4
                  ? "small"
                  : index < 6
                  ? "medium-small"
                  : index < 8
                  ? "medium"
                  : index < 10
                  ? "large"
                  : "xtraLarge"
              }
              classNames={"pointer featured-press"}
            />
          );
        })}
      </Container>
    </MainContainer>
  );
};
export default PressRecognition;
