import React, { useEffect, useState } from "react";
import { get } from "@utils/currentEnv";
import { useSelector } from "react-redux";

const Resizer = ({ components, defaultComponent, defaultTrue }) => {
  const globalWidth = useSelector(state => state.initAppReducer.globalWidth);
  const [widthLength, setWidthLength] = useState(
    globalThis?.innerWidth ? globalThis.innerWidth : 0
  );
  useEffect(() => {
    if (globalWidth) {
      setWidthLength(globalWidth);
    }
  }, [globalWidth]);

  return (
    <div>
      {components.map((com, index) => (
        <React.Fragment key={index}>
          {widthLength > com.break && widthLength < com.maxBreak ? (
            <>{com.component()} </>
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
      {defaultTrue && get("SSR") && <>{components[0].component()}</>}
      {widthLength < 1 && get("SSR") && defaultComponent && (
        <>{defaultComponent()}</>
      )}
    </div>
  );
};
export default Resizer;
