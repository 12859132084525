import React from "react";
import styled from "@emotion/styled";

const MastContainerLargeImage = styled.img`
  position: relative;
  @media (min-width: 1901px) {
    width: 100%;
    height: auto;
  }
  @media (max-width: 1900px) {
    height: 100%;
    width: auto;
  }
  @media (max-width: 1400px) {
    width: 100%;
    height: auto;
  }
  @media (max-width: 1275px) {
    border-radius: 0px 0px 367px 0px;
  }
  @media (max-width: 1200px) {
    border-radius: 0px;
  }
`;

const ImageContainer = styled.div`
  height: 800px;
  overflow: hidden;
  border-radius: 0px 0px 431px 0px;
  @media (max-width: 1400px) {
    height: 697px;
  }
  @media (max-width: 1300px) {
    height: 682px;
  }
  @media (max-width: 1275px) {
    border-radius: 0px 0px 0px 0px;
  }
  @media (max-width: 700px) {
    height: auto;
  }
  @media (max-width: 575px) {
    display: none;
  }
`;

const MastImage = () => {
  return (
    <ImageContainer>
      <picture>
        <source
          media="(min-width:1401px)"
          srcSet="https://cdn.cardcash.com/website/ui/Home/Hero-3.webp"
          type="image/webp"
        />
        <source
          media="(min-width:1401px)"
          srcSet="https://cdn.cardcash.com/website/ui/Home/Hero.jpg"
          type="image/jpg"
        />
        <source
          media="(min-width:1200px)"
          srcSet="https://cdn.cardcash.com/website/ui/Home/hero-med-2.webp"
          type="image/webp"
        />
        <source
          media="(min-width:1200px)"
          srcSet="https://cdn.cardcash.com/website/ui/Home/hero-med@2x.jpg"
          type="image/jpg"
        />
        <source
          media="(min-width:575px)"
          srcSet="https://cdn.cardcash.com/website/ui/Home/hero-sm-1.webp"
          type="image/webp"
        />
        <source
          media="(min-width:575px)"
          srcSet="https://cdn.cardcash.com/website/ui/Home/hero-sm.jpg"
          type="image/jpg"
        />
        <source
          media="(min-width:5px)"
          srcSet="https://cdn.cardcash.com/website/ui/Home/hero-mobile.png"
        />
        <MastContainerLargeImage
          src="https://cdn.cardcash.com/website/ui/Home/Hero-3.webp"
          alt="home card image"
        />
      </picture>
    </ImageContainer>
  );
};

export default MastImage;
