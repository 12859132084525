import React from "react";
import styled from "@emotion/styled";
import {
  tinySmallPhone,
  reallyTinySmallPhone
} from "@modules/Components/break";
import PreloadLink from "../../../components/PreloadLink";
import { Link as ReactLink } from "react-router-dom";
import HomeLogo from "@components/Logo";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";

const ContinueShoppingButton = loadable(() =>
  import(
    /* webpackChunkName: "ContinueShoppingButton" */ "./ContinueShoppingButton"
  )
);
const LargeNavSearch = loadable(() =>
  import(/* webpackChunkName: "LargeNavSearch" */ "./LargeNavSearch")
);

const LogoLinkContainer = styled.span`
  height: 2.85rem;
  cursor: pointer;
  margin-right: 20px;
  min-width: 173px;

  @media (max-width: 415px) {
    min-width: 150px;
    width: 150px;
  }
  @media (max-width: 330px) {
    min-width: 120px;
    width: 120px;
  }
  @media ${tinySmallPhone} {
    height: 2.5rem;
  }
  @media ${reallyTinySmallPhone} {
    height: 2.2rem;
  }
  @media (max-width: 575px) {
    display: ${props => (props.payoutView ? "none" : "")};
  }
`;

const CardCashLink = styled(ReactLink)`
  margin-right: 18px;

  height: 2.85rem;
  cursor: pointer;
  @media ${tinySmallPhone} {
    height: 2.5rem;
  }
  @media ${reallyTinySmallPhone} {
    height: 2.2rem;
  }
`;

const LeftSection = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-right: 0;
  }
`;

const Logo = styled.img`
  height: 2.85rem;
  width: 155px;
  cursor: pointer;
  @media ${tinySmallPhone} {
    height: 2.5rem;
    width: 135px;
  }
  @media ${reallyTinySmallPhone} {
    height: 2.2rem;
    width: 120px;
  }
`;

const NavLeftSection = ({ payoutView, cartView }) => {
  const mainLogo = useSelector(state => state.logos.mainLogo);
  const userMobile = useSelector(state => state.config.userAgent.isMobile);

  return (
    <LeftSection>
      <PreloadLink page={"Home"}>
        <LogoLinkContainer payoutView={payoutView}>
          <CardCashLink to="/" aria-label="Home page">
            {mainLogo ? <Logo src={mainLogo} alt="cardcash" /> : <HomeLogo />}
          </CardCashLink>
        </LogoLinkContainer>
      </PreloadLink>

      {!cartView && !payoutView && !userMobile && <LargeNavSearch />}
      {cartView && <ContinueShoppingButton />}
    </LeftSection>
  );
};

export default NavLeftSection;
