import React, { useState } from "react";
import styled from "@emotion/styled";
import { signal } from "@modules/Signal/action";
import { preloadLinks } from "@actions/preload";
import MenuItem from "./MenuItem";

const compact =
  (a, b) =>
  ({ compact }) =>
    compact ? a : b;

const Menu = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  margin: ${compact("8px", "20px")} 0;
  background: white;
  width: 187px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  box-shadow: 0 0 5px rgba(31, 32, 33, 0.15);
  z-index: 1;

  @media (max-width: 950px) {
    width: 166px;
  }
`;

const Image = styled.img`
  opacity: ${props => props.opacity};
`;

const MenuSpan = styled.span`
  display: block;
  width: 147px;
  margin: 0 auto;
  padding-bottom: 8px;
  padding-top: 8px;
  cursor: pointer;

  @media (max-width: 950px) {
    width: 138px;
  }
`;

const TextSpan = styled.span`
  margin-left: 10px;
`;

const Divider = styled.div`
  height: 1px;
  opacity: 0.15;
  background-color: #777984;
  width: 147px;
  margin: 0 auto;
  margin-top: 7px;
  margin-bottom: 7px;
`;

const AccountGroupMenu = ({ compact }) => {
  const [hover, setHover] = useState("");

  return (
    <Menu compact={compact} onClick={e => e.stopPropagation()}>
      <MenuSpan
        onMouseOver={() => setHover("account")}
        onMouseOut={() => setHover("")}
      >
        <MenuItem to="/account/" mouseOverActions={[preloadLinks("Account")]}>
          <Image
            opacity={hover === "account" ? "1" : "0.2"}
            src={
              "https://cdn.cardcash.com/website/ui/elements/id-card-dark.svg"
            }
            alt=""
          />
          <TextSpan>My account</TextSpan>
        </MenuItem>
      </MenuSpan>
      <MenuSpan
        onMouseOver={() => setHover("wallet")}
        onMouseOut={() => setHover("")}
      >
        <MenuItem to="/wallet/" mouseOverActions={[preloadLinks("Wallet")]}>
          <Image
            opacity={hover === "wallet" ? "1" : "0.2"}
            src={"https://cdn.cardcash.com/website/ui/wallet/wallet-icon.svg"}
            alt=""
          />
          <TextSpan>My cards</TextSpan>
        </MenuItem>
      </MenuSpan>
      <Divider />
      <MenuSpan
        onMouseOver={() => setHover("logout")}
        onMouseOut={() => setHover("")}
      >
        <MenuItem
          to="/"
          actions={[signal("logout")]}
          mouseOverActions={[preloadLinks("Home")]}
        >
          <Image
            opacity={hover === "logout" ? "1" : "0.2"}
            src={"https://cdn.cardcash.com/website/ui/elements/login-dark.svg"}
            alt=""
          />
          <TextSpan>Logout</TextSpan>
        </MenuItem>
      </MenuSpan>
    </Menu>
  );
};

export default AccountGroupMenu;
