import React from "react";
import styled from "@emotion/styled";
import { mediaSMDown } from "@modules/Components/break";
import SmallButtonSpinner from "@components/SmallButtonSpinner";

const LoadingContainer = styled.div`
  min-width: 132px;

  @media (max-width: 1029px) {
    min-width: 40px;
  }
  ${mediaSMDown} {
    display: none;
  }
`;

const AccountGroupNotAttempted = ({}) => {
  return (
    <LoadingContainer>
      <SmallButtonSpinner img="https://cdn.cardcash.com/website/ui/elements/spinner-orange.png" />
    </LoadingContainer>
  );
};

export default AccountGroupNotAttempted;
