import { combineReducers } from "redux";
import accountGroupReducer from "./AccountGroup/state";
import {
  CART_BUY_SHOW_POPUP_CART,
  CART_BUY_HIDE_POPUP_CART,
  CLOSE_ALL_SEARCH_DROPDOWN,
  SET_SELECT_SEARCH_VALUES,
  SET_NAV_SEARCH_OPEN,
  SET_HOME_SEARCH_OPEN,
  SET_QUICK_SEARCH_OPEN
} from "@constants/actionTypes";

const initialState = {
  showPopupCart: false,
  selectSearchValues: [],
  navSearchOpen: false,
  homeSearchOpen: false,
  quickSearchOpen: false
};

const initNav = (state = initialState, action) => {
  switch (action.type) {
    case CART_BUY_SHOW_POPUP_CART:
      return {
        ...state,
        showPopupCart: true
      };
    case CART_BUY_HIDE_POPUP_CART:
      return {
        ...state,
        showPopupCart: false
      };
    case SET_NAV_SEARCH_OPEN:
      return {
        ...state,
        navSearchOpen: action.payload
      };
    case SET_HOME_SEARCH_OPEN:
      return {
        ...state,
        homeSearchOpen: action.payload
      };

    case SET_QUICK_SEARCH_OPEN:
      return {
        ...state,
        quickSearchOpen: action.payload
      };

    case CLOSE_ALL_SEARCH_DROPDOWN:
      return {
        ...state,
        selectSearchValues: [
          ...state.selectSearchValues.map(item => ({
            ...item,
            displayDropdown: false
          }))
        ]
      };
    case SET_SELECT_SEARCH_VALUES:
      return {
        ...state,
        selectSearchValues: [
          ...state.selectSearchValues.filter(
            item => item.name !== action.payload.name
          ),
          {
            ...state.selectSearchValues.find(
              item => item.name === action.payload.name
            ),
            ...action.payload
          }
        ]
      };
    default:
      return state;
  }
};

export default combineReducers({
  accountGroup: accountGroupReducer,
  initNav
});
