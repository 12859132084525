import React, { useState, useCallback, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { defaultAction } from "../../actions/defaultAction";
import { FaSearch, FaDollarSign } from "react-icons/fa";
import { ClearedStyleInput } from "@modules/Components";
import { getBuyMerchants } from "@actions/buyMerchants";
import { debounce } from "lodash";
import DefaultCustomButton from "@modules/Components/DefaultCustomButton";
import { quickBuySearch } from "@actions/quickBuy";
import { addResult, addErrorMessage } from "./reducer";
import { showQuickBuySearchResults } from "../../actions/search";
import { useHistory } from "react-router-dom";
import SmallButtonSpinner from "@components/SmallButtonSpinner";
import { filter, filterSell } from "@utils/fuzzySearch";
import {
  CARD_PARTIAL_MERCHANT,
  CARD_PARTIAL_BALANCE,
  SET_HOME_SEARCH_OPEN
} from "@constants/actionTypes";
import { addSellCard } from "../../actions/sellActions";
import { fireOneOff } from "@modules/Emission/action";
import { getHomeSearchState } from "@actions/search";
import SliderHeaderButtons from "./SliderHeaderButtons";
import HomeSearchMenu from "./HomeSearchMenu";

const SearchForm = styled.form`
  width: 300px;
  margin: 0 auto;

  @media (max-width: 575px) {
    width: 355px;
  }
  @media (max-width: 500px) {
    width: 315px;
  }
  @media (max-width: 350px) {
    width: 280px;
  }
  @media (max-width: 305px) {
    width: 90%;
  }
`;

const MerchantContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: ${props => props.zIndex};
  margin-top: ${({ marginTop }) => marginTop};
`;

const IconDiv = styled.div`
  position: absolute;
  left: 27px;
  pointer-events: none;
`;

const SearchInput = styled(ClearedStyleInput)`
  border-radius: 5px;
  border: ${props => props.setBorder};
  cursor: text;
  width: 100%;
  height: 64px;
  padding-left: 56px;
  box-shadow: rgb(31 32 33 / 12%) 0px 20px 70px 0px;
  &:focus {
    box-shadow: 0 20px 70px 0 rgba(31, 32, 33, 0.12);
    border: solid 2px #1f2021;
  }

  @media (max-width: 575px) {
    border: 0;

    &:focus {
      box-shadow: 0 20px 70px 0 rgba(31, 32, 33, 0.12);
      border: 0;
    }
  }
`;

const AmountInput = styled(ClearedStyleInput)`
  width: 100%;
  border-radius: 5px;
  height: 64px;
  padding-left: 56px;
  cursor: text;
  box-shadow: rgb(31 32 33 / 12%) 0px 20px 70px 0px;
  &:focus {
    border: solid 2px #1f2021;
  }

  @media (max-width: 575px) {
    &:focus {
      border: 0;
    }
  }
`;

const SelectedValue = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  pointer-events: none;
  padding-left: 60px;
  grid-gap: 10px;
  padding-right: 6px;
`;

const InnerSingleValue = styled.div`
  height: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
`;

const ValueImageContainer = styled.div`
  position: relative;
  margin-right: 8px;
`;

const ValueImage = styled.img`
  width: 40px;
  height: 29px;
`;

const ButtonDiv = styled.button`
  all: unset;
  width: 100%;
  height: 72px;
  z-index: 2;
  margin-top: 25px;
  outline: none !important;

  @media (max-width: 575px) {
    margin-bottom: 20px;
  }
`;

const ValueSpan = styled.div`
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: nowrap;
`;

const Absolutecontainer = styled.div`
  top: 6px;
  position: absolute;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  left: 30px;
  text-transform: uppercase;

  @media (max-width: 530px) {
    font-size: 9px;
  }
  @media (max-width: 500px) {
    left: ${props => props.tabletLeft};
  }
  @media (max-width: 465px) {
    font-size: 8px;
  }
  @media (max-width: 375px) {
    left: 20px;
  }
`;

const ErrorContainer = styled(Absolutecontainer)`
  color: #ee5050;
`;

const HomeSearchForm = () => {
  const amountRef = useRef(null);
  const history = useHistory();

  const dispatch = useDispatch();
  const sortedByName = useSelector(
    state => state.merchantsBuy?.sortedByName || []
  );
  const sortedByNameSell = useSelector(
    state => state.merchantsSell?.sortedByName
  );
  const homeSearchOpen = useSelector(state => state.nav.initNav.homeSearchOpen);

  const [sortedMerchants, setSortedMerchants] = useState(
    sortedByName.filter(item => item.cardsAvailable > 0)
  );
  const [sortedMerchantsSell, setSortedMerchantsSell] =
    useState(sortedByNameSell);

  const [buyAction, setBuyAction] = useState(true);
  const [selectedMerchant, setSelectedMerchant] = useState();
  const [merchantValue, changeInputValue] = useState("");
  const [merchantFocused, setMerchantFocused] = useState(false);
  const [pattern, setPattern] = useState("");
  const [amountFocused, setAmountFocused] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMerchants, setLoadingMerchants] = useState(true);
  const [buyMerchantsLoaded, setBuyMerchantsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    window.addEventListener("blur", handleHomeSearchWindowBlur);

    return () => window.removeEventListener("blur", handleHomeSearchWindowBlur);
  }, []);

  useEffect(() => {
    if (buyAction) {
      if (pattern && sortedByName.length > 0) {
        setSortedMerchants(
          filter({ merchants: sortedByName, pattern, threshold: -1500 })
        );
      } else {
        setSortedMerchants(
          sortedByName.filter(item => item.cardsAvailable > 0)
        );
      }
    } else {
      if (pattern && sortedByNameSell.length > 0) {
        setSortedMerchantsSell(
          filterSell({ merchants: sortedByNameSell, pattern, threshold: -1500 })
        );
      } else {
        setSortedMerchantsSell(sortedByNameSell);
      }
    }
  }, [pattern, sortedByName]);

  const handleHomeSearchWindowBlur = async () => {
    const res = await dispatch(getHomeSearchState());
    if (res) {
      dispatch(defaultAction(SET_HOME_SEARCH_OPEN, false));
    }
  };

  const handleMerchantFocus = e => {
    initFocus();
  };

  const initFocus = async () => {
    setMerchantFocused(true);
    if (buyAction) {
      if (sortedByName.length < 1 && !buyMerchantsLoaded) {
        setBuyMerchantsLoaded(true);
        await dispatch(getBuyMerchants());
        setLoadingMerchants(false);
      } else {
        if (loadingMerchants) {
          setLoadingMerchants(false);
        }
      }
    } else {
      setLoadingMerchants(false);
    }
  };

  const merchantBlur = e => {
    e.preventDefault();
    setMerchantFocused(false);
  };
  function setFunction(value) {
    setPattern(value);
  }

  const debounceSetSearch = useCallback(debounce(setFunction, 100), []);

  const merchantChange = e => {
    if (!merchantFocused || sortedByName.length < 1) {
      initFocus();
    }
    changeInputValue(e.target.value);
    debounceSetSearch(e.target.value);
    if (e.target.value && !homeSearchOpen) {
      dispatch(defaultAction(SET_HOME_SEARCH_OPEN, true));
    }
  };

  const handleButtonClick = async () => {
    if (!selectedMerchant?.id || loading) {
      if (!selectedMerchant?.id) {
        setErrorMessage(true);
      }
      return;
    } else {
      if (buyAction) {
        setLoading(true);
        if (
          selectedMerchant?.id &&
          selectedMerchant?.cardType &&
          selectedAmount
        ) {
          const result = await dispatch(
            quickBuySearch(
              selectedMerchant.id,
              selectedMerchant.cardType,
              selectedAmount
            )
          );
          if (result) {
            dispatch(addResult(result));
            dispatch(showQuickBuySearchResults());
          } else {
            dispatch(
              addErrorMessage({
                error:
                  "Sorry, we could not find a package of cards that meets your search.",
                brand: selectedMerchant
              })
            );
          }
          reset();
          history.push(
            `/buy-gift-cards/${
              selectedMerchant?.slug ? `${selectedMerchant?.slug}/` : ""
            }`
          );
          setLoading(false);
        } else {
          setSelectedMerchant();
          reset();
          history.push(
            `/buy-gift-cards/${
              selectedMerchant?.slug ? `${selectedMerchant?.slug}/` : ""
            }`
          );
          setLoading(false);
        }
      } else {
        await dispatch(defaultAction(CARD_PARTIAL_MERCHANT, selectedMerchant));

        const sellData = {
          merchant_id: selectedMerchant.id
        };
        if (selectedAmount) {
          await dispatch(defaultAction(CARD_PARTIAL_BALANCE, selectedAmount));
          await dispatch(addSellCard("quickSell"));
          sellData.face_value = selectedAmount;
        }

        await dispatch(fireOneOff("HOME_SELL_SEARCH", sellData));
        reset();
        history.push("/sell-gift-cards/");
      }
    }
  };

  const reset = () => {
    setMerchantFocused(false);
    setAmountFocused(false);
    setSelectedMerchant();
    setSelectedAmount("");
    setLoading(false);
    setPattern("");
    changeInputValue("");
    setErrorMessage(false);
    dispatch(defaultAction(SET_HOME_SEARCH_OPEN, false));
  };

  const handleMerchantClick = mer => {
    setErrorMessage(false);
    setSelectedMerchant(mer);
    dispatch(defaultAction(SET_HOME_SEARCH_OPEN, false));

    changeInputValue("");
    if (mer.cardsAvailable > 0) {
      amountRef.current.focus();
    }
  };

  const handleSellClick = mer => {
    setErrorMessage(false);
    setSelectedMerchant(mer);
    dispatch(defaultAction(SET_HOME_SEARCH_OPEN, false));
    changeInputValue("");
    amountRef.current.focus();
  };

  return (
    <>
      <SliderHeaderButtons
        reset={reset}
        buyAction={buyAction}
        changeBuyAction={action => {
          setBuyAction(action);
        }}
      />

      <SearchForm
        onSubmit={e => {
          e.preventDefault();
          if (!loading) handleButtonClick();
        }}
      >
        <MerchantContainer onClick={e => e.stopPropagation()}>
          <InputContainer zIndex={2} marginTop={""}>
            <IconDiv>
              <FaSearch
                style={{
                  color: merchantFocused ? "#1f2021" : "rgba(31, 32, 33, 0.3)",
                  fontSize: "16px"
                }}
              />
            </IconDiv>
            <SearchInput
              setBorder={homeSearchOpen ? "solid 2px #1f2021" : ""}
              className="home-search-merchant-input"
              placeholder={selectedMerchant?.id ? "" : "Store name..."}
              onFocus={handleMerchantFocus}
              onBlur={merchantBlur}
              onChange={merchantChange}
              value={merchantValue}
              aria-label="Store name"
              aria-autocomplete="both"
            />
            <ErrorContainer>
              {errorMessage && <div>Please enter a valid merchant</div>}
            </ErrorContainer>
            {selectedMerchant?.id && !merchantValue && (
              <SelectedValue>
                <InnerSingleValue>
                  <ValueSpan>{selectedMerchant?.name}</ValueSpan>
                  <ValueImageContainer>
                    <ValueImage src={selectedMerchant?.image} alt="merchant" />
                  </ValueImageContainer>
                </InnerSingleValue>
              </SelectedValue>
            )}
          </InputContainer>
          {homeSearchOpen && (
            <HomeSearchMenu
              buyAction={buyAction}
              merchantsToRender={
                buyAction ? sortedMerchants : sortedMerchantsSell
              }
              handleMerchantClick={handleMerchantClick}
              handleSellClick={handleSellClick}
              loadingMerchants={loadingMerchants}
            />
          )}
        </MerchantContainer>
        <InputContainer
          className="remove-number-input-arrows"
          zIndex={0}
          marginTop={"25px"}
        >
          <IconDiv>
            <FaDollarSign
              style={{
                color: amountFocused ? "#1f2021" : "rgba(31, 32, 33, 0.3)",
                fontSize: "17px"
              }}
            />
          </IconDiv>

          <AmountInput
            className="home-search-amount-input"
            ref={amountRef}
            onChange={e => setSelectedAmount(e.target.value)}
            value={selectedAmount}
            type="number"
            placeholder={"Amount (optional)"}
            onFocus={() => {
              setAmountFocused(true);
              if (homeSearchOpen) {
                dispatch(defaultAction(SET_HOME_SEARCH_OPEN, false));
              }
            }}
            onBlur={() => {
              setAmountFocused(false);
            }}
            aria-label="amount"
          />
        </InputContainer>
        <ButtonDiv>
          <DefaultCustomButton
            buttonType={!buyAction ? "GREEN_BUTTON" : "ORANGE_BUTTON"}
            disabled={false}
            classes={"main-default-orange-button"}
          >
            {loading ? (
              <SmallButtonSpinner img="https://cdn.cardcash.com/website/ui/elements/spinner-white.png" />
            ) : (
              <span>{!buyAction ? "How much can I get?" : "Search cards"}</span>
            )}
          </DefaultCustomButton>
        </ButtonDiv>
      </SearchForm>
    </>
  );
};

export default HomeSearchForm;
