import {
  TOGGLE_VERIFICATION_BOX,
  TOGGLE_ACCOUNT_HELP_BOX,
  OPEN_ADD_A_CREDIT_CARD,
  SHOW_TWILIO_POPUP,
  RESET,
  RESET_EXPIRE,
  RESET_SELL,
  SET_CARDS_UNDER_CONSIDERATION,
  HIDE_CARDS_UNDER_CONSIDERATION_POPUP,
  SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID,
  HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID,
  OPEN_EDIT_PROFILE_MODAL,
  OPEN_CANCEL_SELL_ORDER_MODAL,
  OPEN_PAYMENT_DETAILS_MODAL,
  CLOSE_SHARE_A_SALE_MODAL,
  INITIAL_CART_LOADING,
  INITIAL_SELL_CART_LOADING,
  SHOW_YOU_TUBE_POPUP
} from "@constants/actionTypes";

import {
  DISPLAY_CUSTOMER_ALREADY_EXISTS,
  SHOW_ACCOUNT_LOCKED
} from "@constants/accountTypes";

const initialState = {
  verificationBox: false,
  showYouTubePopUp: false,
  accountHelpBox: false,
  addACreditCard: false,
  showChoosePhoneOrTextBox: false,
  showAccountLockedBox: false,
  showCardsUnderConsideration: false,
  showHowWouldYouLikeToGetPaid: false,
  howWouldYouGetPaidRedirect: null,
  showCancelSellOrderModal: false,
  showPaymentDetailsModal: false,
  showShareASaleModal: true,
  customerExists: false,
  initialCartLoading: true,
  initialSellCartLoading: true
};

const popupsActions = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_SELL_CART_LOADING:
      return {
        ...state,
        initialSellCartLoading: action.payload
      };
    case SHOW_YOU_TUBE_POPUP:
      return {
        ...state,
        showYouTubePopUp: action.payload
      };

    case INITIAL_CART_LOADING:
      return {
        ...state,
        initialCartLoading: action.payload
      };
    case RESET:
      return {
        ...initialState,
        initialCartLoading: state.initialCartLoading,
        initialSellCartLoading: state.initialSellCartLoading
      };

    case DISPLAY_CUSTOMER_ALREADY_EXISTS:
      return {
        ...state,
        customerExists: action.payload
      };

    case RESET_SELL:
    case RESET_EXPIRE:
      return {
        ...initialState,
        initialCartLoading: state.initialCartLoading,
        initialSellCartLoading: state.initialSellCartLoading,
        showChoosePhoneOrTextBox: state.showChoosePhoneOrTextBox
      };
    case "orderVerification.reset":
      return {
        ...state,
        showChoosePhoneOrTextBox: false
      };

    case SHOW_ACCOUNT_LOCKED:
      return {
        ...state,
        showAccountLockedBox: action.payload
      };

    case TOGGLE_VERIFICATION_BOX:
      return {
        ...state,
        verificationBox: !state.verificationBox
      };

    case TOGGLE_ACCOUNT_HELP_BOX:
      return {
        ...state,
        accountHelpBox: !state.accountHelpBox
      };

    case OPEN_ADD_A_CREDIT_CARD:
      return {
        ...state,
        addACreditCard: action.payload
      };

    case SHOW_TWILIO_POPUP:
      return {
        ...state,
        showChoosePhoneOrTextBox: action.payload
      };

    case SET_CARDS_UNDER_CONSIDERATION:
      return {
        ...state,
        showCardsUnderConsideration: true
      };

    case HIDE_CARDS_UNDER_CONSIDERATION_POPUP:
      return {
        ...state,
        showCardsUnderConsideration: false
      };

    case SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID:
      return {
        ...state,
        showHowWouldYouLikeToGetPaid: true,
        howWouldYouGetPaidRedirect: action.payload ? action.payload : null
      };

    case HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID:
      return {
        ...state,
        showHowWouldYouLikeToGetPaid: false,
        howWouldYouGetPaidRedirect: null
      };

    case OPEN_EDIT_PROFILE_MODAL:
      return {
        ...state,
        showEditProfileModal: action.payload
      };

    case OPEN_CANCEL_SELL_ORDER_MODAL:
      return {
        ...state,
        showCancelSellOrderModal: action.payload
      };

    case OPEN_PAYMENT_DETAILS_MODAL:
      return {
        ...state,
        showPaymentDetailsModal: action.payload
      };

    case CLOSE_SHARE_A_SALE_MODAL:
      return {
        ...state,
        showShareASaleModal: false
      };

    default:
      return state;
  }
};

export default popupsActions;
