import {
  EMAILREGEX,
  PHONEREGEX,
  CITYREGEX,
  PASSREGEX,
  STREETREGEX,
  ZIPREGEX,
  NAMEREGEX,
  ACCOUNT_NUMBER,
  ROUTING_NUMBER,
  NUMSREGEX,
  FULLNAMEREGEX
} from "@constants/regex";
import { capitalizeFirstLetter, checkLuhn } from "@utils";
import { getCardType } from "@utils/validators/card";
import cardValidator from "card-validator";

const validateField = (fieldName, regex) => value => {
  if (!value) {
    return `${capitalizeFirstLetter(fieldName)} is required`;
  }
  if (!regex.test(value.trim())) {
    return `Invalid ${fieldName}`;
  }
  return;
};

export const fullName = validateField("name", FULLNAMEREGEX);
export const firstName = validateField("first name", NAMEREGEX);
export const lastName = validateField("last name", NAMEREGEX);
export const street = validateField("street", STREETREGEX);
export const city = validateField("city", CITYREGEX);
export const zip = validateField("zip code", ZIPREGEX);
export const email = validateField("email", EMAILREGEX);
export const validatePassword = validateField("password", PASSREGEX);
export const accountNumber = validateField("account number", ACCOUNT_NUMBER);

export const validateAccountAndReEnterNumber = (value, formValues) => {
  const accountNumber = formValues?.enterACH?.accountNumber;
  const accountNumberConfirm = formValues?.enterACH?.accountNumberConfirm;
  if (accountNumber === accountNumberConfirm) {
    const val = validateField("account number", ACCOUNT_NUMBER);
    return val(value);
  } else {
    return "Must match account numbers";
  }
};

const checkIfRoutingCouldBeValid = routing => {
  if (routing.length !== 9) {
    return false;
  }

  // http://en.wikipedia.org/wiki/Routing_transit_number#MICR_Routing_number_format
  var checksumTotal =
    7 *
      (parseInt(routing.charAt(0), 10) +
        parseInt(routing.charAt(3), 10) +
        parseInt(routing.charAt(6), 10)) +
    3 *
      (parseInt(routing.charAt(1), 10) +
        parseInt(routing.charAt(4), 10) +
        parseInt(routing.charAt(7), 10)) +
    9 *
      (parseInt(routing.charAt(2), 10) +
        parseInt(routing.charAt(5), 10) +
        parseInt(routing.charAt(8), 10));

  var checksumMod = checksumTotal % 10;
  if (checksumMod !== 0) {
    return false;
  } else {
    return true;
  }
};

export const routingNumber = value => {
  if (!value) {
    return `${capitalizeFirstLetter("routing number")} is required`;
  }
  if (
    !ROUTING_NUMBER.test(value.trim()) ||
    !checkIfRoutingCouldBeValid(value)
  ) {
    return "Invalid routing number";
  }

  return;
};

export const confirmPassword = (confirmPassword, { password }) => {
  if (!password || !confirmPassword) {
    return "Passwords must match";
  }
  if (password !== confirmPassword) {
    return "Passwords must match";
  }
  return;
};

export const creditCard = value => {
  const trimmedValue = value && value.replace(/\s+/g, "");
  if (!trimmedValue) {
    return "Credit card is required";
  }
  if (!checkLuhn(trimmedValue.trim())) {
    return "Invalid credit card";
  }
};

export const cvvValidate = value => {
  const isNotNum = validateField("CVV", NUMSREGEX)(value);
  if (isNotNum) {
    return isNotNum;
  }
  if (!value) {
    return "CVV is required";
  }
  if (value.length < 3 || value.length > 4) {
    return "Invalid CVV";
  }
};

export const cvv = value => {
  if (!value) {
    return "CVV is required";
  }
  if (value.length < 3) {
    return "Invalid CVV";
  }
};

export const expirationDate = value => {
  const isValidExpirationDate = cardValidator.expirationDate(value).isValid;

  if (!isValidExpirationDate) {
    return "Enter a valid expiration";
  }
};

export const month = value => {
  if (!value) {
    return "Month is required";
  }
};

export const year = value => {
  if (!value) {
    return "Year is required";
  }
};

export const state = value => {
  if (!value) {
    return "State is required";
  }
};

export const acceptTerms = value => {
  if (!value) {
    return "You must accept the terms & conditions";
  }
};

export const accountType = value => {
  const values = ["business", "personal"];
  if (!value || !values.includes(value.toLowerCase())) {
    return "Account type is required";
  }
};

export const creditCardType = value => {
  const creditCardType = getCardType(value);
  if (!creditCardType.length) {
    return "Please enter a valid credit card";
  }
  return;
};

export const phone = value => {
  if (!value) {
    return "Phone is required";
  }
  if (!PHONEREGEX.test(value.trim())) {
    return "Invalid phone";
  }
  if (value.length < 10) {
    return "Invalid phone";
  }
  return;
};

export const getPasswordFieldsPassed = (password = "") => {
  let fieldsPassed = {
    lowerCasePass: false,
    upperCasePass: false,
    lengthPass: false,
    numberPass: false,
    specialCharacterPass: false
  };
  if (/[a-z]/.test(password)) {
    fieldsPassed = { ...fieldsPassed, lowerCasePass: true };
  }
  if (/[A-Z]/.test(password)) {
    fieldsPassed = { ...fieldsPassed, upperCasePass: true };
  }
  if (/[0-9]/.test(password)) {
    fieldsPassed = { ...fieldsPassed, numberPass: true };
  }
  if (/[$@$!%*?&]/.test(password)) {
    fieldsPassed = { ...fieldsPassed, specialCharacterPass: true };
  }
  if (password.length >= 8) {
    fieldsPassed = { ...fieldsPassed, lengthPass: true };
  }
  return fieldsPassed;
};

export const anyValue = value => {
  if (!value) {
    return "Old Password is required.";
  }
};

export const message = value => {
  if (!value) {
    return "A message is required.";
  }
};
