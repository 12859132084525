import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_NAV_SEARCH_OPEN } from "@constants/actionTypes";
import { defaultAction } from "@actions/defaultAction";
import { getBuyMerchants } from "@actions/buyMerchants";
import { debounce } from "lodash";
import NavSearchSelectedValue from "./NavSearchSelectedValue";
import SearchCards from "@components/SearchCards";

const NavSearchInputContainer = ({
  selectedMerchant,
  inputValue,
  changeInputValue,
  merchantFocused,
  setMerchantFocused,
  mobileView,
  reset,
  setPattern,
  showMenu,
  setShowMenu,
  showButtons,
  setShowButtons
}) => {
  const navSearchOpen = useSelector(state => state.nav.initNav.navSearchOpen);
  const sortedByName = useSelector(
    state => state.merchantsBuy?.sortedByName || []
  );
  const dispatch = useDispatch();

  const handleMerchantBlur = e => {
    e.preventDefault();
    setMerchantFocused(false);
    if (inputValue && selectedMerchant?.id) {
      changeInputValue("");
    }
    if (!inputValue && !selectedMerchant?.id && !showButtons) {
      reset();
    }
  };
  const debounceSetSearch = useCallback(debounce(setFunction, 100), []);
  function setFunction(value) {
    setPattern(value);
  }
  const handleChange = e => {
    changeInputValue(e.target.value);
    debounceSetSearch(e.target.value);
    if (e.target.value && !showMenu) {
      setShowMenu(true);
    }
    setShowButtons(false);
  };

  const handleMerchantFocus = e => {
    setMerchantFocused(true);
    if (sortedByName.length < 1 && !mobileView) {
      dispatch(getBuyMerchants());
    }
    if (!mobileView) {
      dispatch(defaultAction(SET_NAV_SEARCH_OPEN, true));
    }
  };

  return (
    <SearchCards
      selectedMerchant={selectedMerchant}
      inputValue={inputValue}
      merchantFocused={merchantFocused}
      mobileView={mobileView}
      handleMerchantFocus={handleMerchantFocus}
      handleMerchantBlur={handleMerchantBlur}
      searchOpen={navSearchOpen}
      handleChange={handleChange}
      navSearch={true}
    >
      <>
        {selectedMerchant?.id && !inputValue && (
          <NavSearchSelectedValue selectedMerchant={selectedMerchant} />
        )}
      </>
    </SearchCards>
  );
};

export default NavSearchInputContainer;
