import {
  SET_FACEBOOK_SDK_LOADED,
  SET_GOOGLE_SDK_LOADED,
  SET_GLOBAL_WIDTH,
  LOGOUT,
  SET_OPEN_ORDERS,
  SELL_ORDER_PROCESS_RUNNING,
  SET_SIGNED_IN,
  HIDE_LOADING,
  SHOW_LOADING,
  OPEN_ERROR_BOX,
  CLOSE_ERROR_BOX,
  RESET_EXPIRE,
  RESET,
  SET_SIGN_IN_ATTEMPTED,
  SET_INITIAL_LOAD,
  SET_CUSTOMER_PROFILE
} from "@constants/actionTypes";

const initialState = {
  faceSDKLoaded: false,
  googleSDKLoaded: false,
  globalWidth: "",
  openOrders: {},
  sellOrderInProcess: false,
  signedIn: false,
  displayLoading: false,
  loadingDisplayCaller: "",
  errorMsg: "",
  errorBox: false,
  title: "",
  errorDirectLink: "",
  signInAttempted: false,
  initialLoad: true,
  firstname: "",
  achAuthorized: false
};

const initAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FACEBOOK_SDK_LOADED:
      return {
        ...state,
        faceSDKLoaded: true
      };
    case SET_SIGNED_IN:
      return {
        ...state,
        signedIn: true
      };
    case SET_GOOGLE_SDK_LOADED:
      return {
        ...state,
        googleSDKLoaded: true
      };

    case SET_GLOBAL_WIDTH:
      return {
        ...state,
        globalWidth: action.payload
      };
    case SET_OPEN_ORDERS:
      return {
        ...state,
        openOrders: action.payload
      };
    case SELL_ORDER_PROCESS_RUNNING:
      return {
        ...state,
        sellOrderInProcess: action.payload
      };
    case HIDE_LOADING:
      return {
        ...state,
        displayLoading: false,
        loadingDisplayCaller: ""
      };
    case SHOW_LOADING:
      return {
        ...state,
        displayLoading: true,
        loadingDisplayCaller: action.payload || ""
      };
    case OPEN_ERROR_BOX:
      return {
        ...state,
        errorMsg: action.payload.message,
        errorBox: true,
        title: action.payload.title,
        errorDirectLink: action.payload.errorDirectLink
          ? action.payload.errorDirectLink
          : ""
      };
    case CLOSE_ERROR_BOX:
      return {
        ...state,
        errorBox: false
      };
    case SET_SIGN_IN_ATTEMPTED:
      return {
        ...state,
        signInAttempted: true
      };
    case SET_INITIAL_LOAD:
      return {
        ...state,
        initialLoad: action.payload
      };
    case SET_CUSTOMER_PROFILE:
      return {
        ...state,
        firstname: action.payload.customer.firstname,
        achAuthorized: action.payload.customer.achAuthorized
      };

    case RESET:
    case RESET_EXPIRE:
    case LOGOUT:
      return {
        ...state,
        openOrders: {},
        signedIn: false,
        signInAttempted: state.signInAttempted,
        firstname: "",
        achAuthorized: false
      };

    default:
      return state;
  }
};
export default initAppReducer;
