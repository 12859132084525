import React from "react";
import LazyLoad from "react-lazyload";

const ReactLazy = ({ children, height, offset }) => {
  return (
    <LazyLoad height={height} offset={offset}>
      {children}
    </LazyLoad>
  );
};

export default ReactLazy;
