import React from "react";
import styled from "@emotion/styled";
import { mediaSMDDown } from "@modules/Components/break";
import Resizer from "@hocs/Resizer";
import MobileNavSearchContainer from "./MobileNavSearchContainer";
import { useSelector } from "react-redux";

const Mobile = styled.div`
  display: none;
  ${mediaSMDDown} {
    display: initial;
  }
`;

const MobileSearch = () => {
  const userMobile = useSelector(state => state.config.userAgent.isMobile);

  return (
    <Mobile>
      <Resizer
        components={[
          {
            component: () => <MobileNavSearchContainer />,
            break: 1,
            maxBreak: 1000
          }
        ]}
        defaultTrue={userMobile ? true : false}
      />
    </Mobile>
  );
};

export default MobileSearch;
