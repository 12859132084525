import {
  FILL_BUY_ORDERS,
  FILL_BUY_ORDER,
  RESET,
  RESET_EXPIRE,
  BUY_ORDERS_THUNK_RUNNING
} from "@constants/actionTypes";

const initialState = {
  buyOrders: [],
  buyOrder: {},
  _thunkRunning: false,
  buyOrdersByYear: {}
};

const ordersBuy = (state = initialState, action) => {
  switch (action.type) {
    case FILL_BUY_ORDERS:
      const buyOrderYear = {};
      action.payload.forEach(order => {
        const year = new Date(order.datePurchased).getFullYear();
        if (buyOrderYear[year]) {
          buyOrderYear[year].push(order);
        } else {
          buyOrderYear[year] = [order];
        }
      });
      return {
        ...state,
        buyOrders: action.payload,
        buyOrdersByYear: buyOrderYear
      };

    case FILL_BUY_ORDER:
      return {
        ...state,
        buyOrder: action.payload
      };

    case BUY_ORDERS_THUNK_RUNNING:
      return {
        ...state,
        _thunkRunning: action.payload
      };

    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};

export default ordersBuy;
