import { PAYMENT_OPTIONS_RECIEVED } from "@constants/actionTypes";

const initialState = {};

const offers = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_OPTIONS_RECIEVED:
      return action.payload;

    default:
      return state;
  }
};

export default offers;
