import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import "../../styles/reactSelect.scss";
import { SET_NAV_SEARCH_OPEN } from "@constants/actionTypes";
import { defaultAction } from "@actions/defaultAction";
import PreloadLink from "@components/PreloadLink";
import { Link } from "react-router-dom";
import { verySmallPhone } from "@modules/Components/break";

const StyledLink = styled(Link)`
  height: 100%;
  width: fit-content;
  display: block;
`;

const MainButtonContainer = styled.div`
  border-radius: 0px 0px 30px 30px;
  z-index: -1;
  top: ${props => props.top};
  width: 566px;
  background-color: #fff;
  position: absolute;
  margin-top: 20px;

  @media (max-width: 999px) {
    position: static;
    width: 500px;
    z-index: 0;
    margin-top: 0px;
  }
  @media (max-width: 600px) {
    width: 100%;
    grid-column: 1/4;
  }
`;

const ButtonLinksDiv = styled.div`
  border-radius: 0px 0px 30px 30px;
  box-shadow: 0 5px 20px 0 rgba(38, 38, 38, 0.05);
  padding-top: 62px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  padding-bottom: 20px;

  @media (max-width: 999px) {
    display: block;
    padding-top: 23px;
  }
`;

const NavHeaderP = styled.p`
  text-align: center;
  color: #777984;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupLinkButtons = styled.div`
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto;
  grid-gap: 40px;

  @media (max-width: 400px) {
    grid-gap: 20px;
  }
  @media (max-width: 300px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Button = styled.button`
  width: 143px;
  height: 60px;
  border-radius: 36px;
  font-size: 16px;
  font-weight: bold;
  background-color: #ffffff;
  &:hover,
  &:focus {
    outline: none;
  }
  @media ${verySmallPhone} {
    width: 118px;
    height: 50px;
  }

  @media (max-width: 300px) {
    width: 100%;
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const PopUpBuyButton = styled(Button)`
  border: solid 1px rgba(255, 156, 27, 0.2);
  color: #ff9c1b;
  cursor: pointer;
`;

const PopUpSellButton = styled(Button)`
  border: solid 1px rgba(57, 200, 118, 0.2);
  color: #39c876;
  cursor: pointer;
`;

const NavSearchMainButtonContainer = () => {
  const dispatch = useDispatch();
  const bannersList = useSelector(state => state.banners.bannersList);
  const closeAll = e => {
    e.stopPropagation();
    dispatch(defaultAction(SET_NAV_SEARCH_OPEN, false));
  };
  return (
    <MainButtonContainer top={bannersList.length > 0 ? "32%" : "32%"}>
      <ButtonLinksDiv>
        <NavHeaderP>Explore CardCash:</NavHeaderP>
        <PopupLinkButtons>
          <PreloadLink page={"DiscountBuy"}>
            <StyledLink to="/buy-gift-cards/" onClick={closeAll}>
              <PopUpBuyButton>BUY & SAVE</PopUpBuyButton>
            </StyledLink>
          </PreloadLink>
          <PreloadLink page={"StepOneSell"}>
            <StyledLink to="/sell-gift-cards/" onClick={closeAll}>
              <PopUpSellButton>SELL & EARN</PopUpSellButton>
            </StyledLink>
          </PreloadLink>
        </PopupLinkButtons>
      </ButtonLinksDiv>
    </MainButtonContainer>
  );
};

export default NavSearchMainButtonContainer;
