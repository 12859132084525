import Service, { globalLinker } from "../Service";
import dlog from "../../../utils/dlog";

export default class AnyCardService extends Service {
  constructor() {
    super("CardCash");
    this.forceLoad = true;
  }

  get variables() {
    return ["CardCash"].reduce(globalLinker, {});
  }

  afterScript({ config }) {
    dlog("Setting up anycard.");

    // Change this to production when ready to release.
    this.script.src = config.anycardURL;

    this.script.onload = () => {
      dlog("Done loading anycard.");
    };
  }
}
