import React from "react";
import loadable from "@loadable/component";
import { ADD_TO_PRELOAD } from "@constants/actionTypes";
import { defaultAction } from "@actions/defaultAction";
import { useDispatch, useSelector } from "react-redux";

const PreloadLink = ({ children, page }) => {
  const dispatch = useDispatch();
  const preloadArray = useSelector(state => state.preload.preloadArray);

  const Page = loadable(() => import(`../pages/${page}`));
  const handlePreload = () => {
    if (!preloadArray.includes(page)) {
      dispatch(defaultAction(ADD_TO_PRELOAD, page));
      Page.preload();
    }
  };

  return React.cloneElement(children, {
    onMouseOver: handlePreload
  });
};

export default PreloadLink;
