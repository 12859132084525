import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { sendBugsnagError } from "@utils/Bugsnag";
import { withRouter } from "react-router-dom";

const Main = styled.main`
  margin-top: 200px;
  min-height: 600px;
  text-align: center;
  font-size: 18px;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    sendBugsnagError(error, "errorBoundary", errorInfo);
    // console.log("error", error);
    // console.log("errorInfo", errorInfo);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Main>
          <h1>We are sorry, something went wrong.</h1>
          <p>
            Please refresh the page.{" "}
            <a href={`${this.props.siteEnv}${this.props.location.pathname}`}>
              Click here to refresh
            </a>
            .
          </p>
        </Main>
      );
    }

    return this.props.children;
  }
}

export default withRouter(
  connect(state => ({ siteEnv: state.config.siteEnv }))(ErrorBoundary)
);
