import {
  VALID_TOKEN,
  INVALID_TOKEN,
  PASSWORD_TOKEN_RUNNING,
  RESET,
  RESET_EXPIRE,
  SET_SLICK_DEALS_SDTID
} from "@constants/actionTypes";
import {
  SET_CUSTOMER_VERIFICATION_EMAIL,
  SET_CUSTOMER_VERIFICATION_ID
} from "@constants/accountTypes";

const initialState = {
  validToken: "",
  token: null,
  pathHistory: [],
  slickDealsSdtId: null,
  customerVerificationEmail: "",
  customerVerificationId: null,
  passwordTokenFinished: false
};

const userInfo = (state = initialState, action) => {
  switch (action.type) {
    case VALID_TOKEN:
      return {
        ...state,
        validToken: "ACCEPT",
        token: action.payload
      };

    case INVALID_TOKEN:
      return {
        ...state,
        validToken: "DENY"
      };

    case PASSWORD_TOKEN_RUNNING:
      return {
        ...state,
        passwordTokenFinished: action.payload
      };

    case SET_SLICK_DEALS_SDTID:
      return {
        ...state,
        slickDealsSdtId: action.payload
      };

    case SET_CUSTOMER_VERIFICATION_EMAIL:
      return {
        ...state,
        customerVerificationEmail: action.payload
      };

    case SET_CUSTOMER_VERIFICATION_ID:
      return {
        ...state,
        customerVerificationId: action.payload
      };

    case RESET:
    case RESET_EXPIRE:
      return {
        ...initialState,
        passwordTokenFinished: state.passwordTokenFinished
      };

    default:
      return state;
  }
};

export default userInfo;
