import { path, mergeDeepWith, reduce } from "ramda";

const LOCAL_NETWORK = new RegExp(
  "(" +
    [
      "192\\.168\\.\\d{1,3}\\.\\d{1,3}",
      "127\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}",
      "localhost",
      ".+\\.local"
    ].join("|") +
    ")"
);

// Helpers
const HOSTNAME = () => path(["location", "hostname"], global) || "";
const PATH_NAME = () => path(["location", "pathname"], global) || "";
const testInRoot = () => /^\/test/.test(PATH_NAME());

const inIntegration = (integration, options = {}) => {
  let currentHost = HOSTNAME() || options.host || "";
  if (Array.isArray(integration.whenHosts)) {
    for (const host of integration.whenHosts) {
      if (currentHost.includes(host)) return true;
    }
  }
  return currentHost.includes("test") || testInRoot();
};

const inDevelopment = (development, options = {}) => {
  let currentHost = HOSTNAME() || options.host || "";
  if (Array.isArray(development.whenHosts)) {
    for (const host of development.whenHosts) {
      if (currentHost.includes(host)) return true;
    }
  }
  return LOCAL_NETWORK.test(currentHost);
};

// NOTE: This code is pretty dangerous.
function mergeLayers(a, b) {
  return mergeDeepWith(
    // This code is basically mergeDeepRight... with one exception.
    (a, b) => {
      // NOTE: Dave save this. This is what makes this code dangerous.
      if (Array.isArray(a) && Array.isArray(b)) {
        return a.concat(b);
      }
      return b;
    },
    a,
    b
  );
}

const configBuilder = (name, layers, options = {}) => {
  layers.production.name = name;

  const selectedLayers = [layers.production];

  if (
    options.env === "development" ||
    inIntegration(layers.integration, options)
  ) {
    console.info("ENV: integration");
    selectedLayers.push(layers.integration);
  }

  if (inDevelopment(layers.development, options)) {
    console.info("ENV: development");
    selectedLayers.push(layers.development);
  }

  return reduce(mergeLayers, {}, selectedLayers);
};

export default configBuilder;
