/**
 * @typedef {import("./service").default} Service
 */

import { isNil, complement, isEmpty, both } from "ramda";
import dlog from "../../utils/dlog";

// import GoogleAnalyticsService from "./Services/GoogleAnalyticsService";
import MaxMindService from "./Services/MaxMindService";
import RiskifiedService from "./Services/RiskifiedService";
// import ReCaptchaService from "./Services/ReCaptchaService";
import pendingGet from "../../utils/pendingGet";
import AnyCardService from "./Services/AnyCardService";
import SiftScienceService from "./Services/SiftScienceService";
// import IPQualityScoreService from "./Services/IPQualityScoreService";

export default class ServiceManager {
  constructor() {
    this.loaded = false;
    this.scripts = {};
    this.store = null;
    this.services = [
      // new GoogleAnalyticsService(), // now loads from tag manager
      new MaxMindService(),
      new RiskifiedService(),
      // new ReCaptchaService(), // moved to on needed pages only - pass-assist and sign-in component
      new AnyCardService(),
      new SiftScienceService()
      // new IPQualityScoreService() - turning off 1/9/20 as per Michael
    ];

    this.variables = {};
  }

  /**
   * getGlobals
   * returns an object containing the globals that are created by the verious services.
   * @params {...String} variables
   * @returns {Object}
   */
  getGlobals(...variables) {
    const interval = 10;
    const timeout = Date.now() + 10000;

    return new Promise((resolve, reject) => {
      let output = {};

      const handle = setInterval(() => {
        if (!this.loaded) {
          dlog("Waiting for services to be initialized");
          return;
        }
        if (Date.now() > timeout) {
          clearInterval(handle);
          reject(output);
        }
        if (variables.length === 0) {
          clearInterval(handle);
          resolve(output);
        }

        variables = variables.filter(variable => {
          try {
            if (!this.variables.hasOwnProperty(variable)) return false;

            // By design, this statement will throw is the varaible is not ready
            output[variable] = this.variables[variable]();

            return false;
          } catch (error) {
            // polling for variable
            return true;
          }
        });
      }, interval);
    });
  }

  use(Service) {
    for (const service of this.services)
      if (service instanceof Service) {
        return new Promise(async resolve => {
          resolve({
            service,
            dependencies: {
              session: await this.session,
              config: await this.config,
              getState: this.store.getState.bind(this.store),
              dispatch: this.store.dispatch.bind(this.store)
            }
          });
        });
      }

    return Promise.reject(`Failed to get service ${Service}`);
  }

  setupStore(store) {
    this.store = store;
  }

  async addServiceToDocument(service, deps) {
    dlog("Appending service tags");
    const name = service.name,
      script = service.setup(deps);

    this.scripts[name] = script;
    document.scripts[0].parentNode.insertBefore(script, document.scripts[0]);
  }

  async promiseAddServiceToDocument(service, deps) {
    dlog("Appending service tags");
    const name = service.name;
    let script;
    const prom = new Promise(resolve => {
      script = service.setup(deps, resolve);
    });
    this.scripts[name] = script;
    document.scripts[0].parentNode.insertBefore(script, document.scripts[0]);
    return prom;
  }

  mergeVariables(service) {
    this.variables = {
      ...this.variables,
      ...service.variables
    };
  }

  get session() {
    // NOTE: This code is gross. 🤢
    const condition = complement(isNil);

    return Promise.all([
      pendingGet(this.store, ["session", "renderAssets"], condition),
      pendingGet(this.store, ["session", "renewalTime"], condition),
      pendingGet(this.store, ["session", "sessionId"], condition)
    ]).then(([renderAssets, renewalTime, sessionId]) => ({
      renderAssets,
      renewalTime,
      sessionId
    }));
  }

  get config() {
    return pendingGet(
      this.store,
      ["config"],
      both(complement(isEmpty), complement(isNil))
    );
  }

  async initializeServices() {
    dlog("initializingServices");

    const session = await this.session;
    const config = await this.config;

    // NOTE: renderAssets is a flag set by the post to session that tells the client
    //       whether or not to setup the analytic script (e.g. maxmind, riskified, ga, ...);
    for (const service of this.services) {
      if (session.renderAssets || service.forceLoad) {
        this.addServiceToDocument(service, { session, config });
        this.mergeVariables(service);
      } else {
        console.warn(`${service.name} not loaded.`);
      }
    }
    this.loaded = true;
  }

  async initializeRecaptcha(service) {
    const session = await this.session;
    const config = await this.config;

    await this.promiseAddServiceToDocument(service, {
      session,
      config
    });
    this.mergeVariables(service);
  }
}
