import {
  COUPON_LOADING,
  ENABLE_COUPON,
  RESET,
  RESET_COUPON,
  RESET_EXPIRE
} from "@constants/actionTypes";

const initialState = {
  active: false,
  coupon: "",
  discount: 0,
  description: "",
  expires: "",
  loading: false
};

const coupon = (state = initialState, action) => {
  switch (action.type) {
    case ENABLE_COUPON:
      return {
        ...state,
        active: true,
        coupon: action.payload.coupon,
        discount: action.payload.discount || 0,
        description: action.payload.description,
        expires: action.payload.expires
      };

    case COUPON_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case RESET:
    case RESET_COUPON:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};

export default coupon;
