import React from "react";
import styled from "@emotion/styled";
import { CUSTOM_BUTTONS } from "./colors";
import classNames from "classnames";

const CustomStyled = styled.div`
  width: 100%;
  height: 72px;
  border-radius: 36px;
  box-shadow: ${props =>
    props.disabled ? "none" : props.buttonType.default.boxShadow};
  background-color: ${props =>
    props.disabled ? "#f2f2f3" : props.buttonType.default.backgroundColor};
  font-size: 16px;
  font-weight: bold;
  color: ${props =>
    props.disabled
      ? "rgba(119, 121, 132, 0.5)"
      : props.buttonType.default.color};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    box-shadow: ${props =>
      props.disabled ? "none" : props.buttonType.hover.boxShadow};
    background-color: ${props =>
      props.disabled ? "#f2f2f3" : props.buttonType.hover.backgroundColor};
  }
  &:active {
    box-shadow: ${props =>
      props.disabled ? "none" : props.buttonType.active.boxShadow};
    background-color: ${props =>
      props.disabled ? "#f2f2f3" : props.buttonType.active.backgroundColor};
  }
`;

const DefaultCustomButton = ({
  children,
  disabled,
  handleClick = f => f,
  classes,
  setId = "",
  type = "",
  buttonType
}) => (
  <CustomStyled
    buttonType={CUSTOM_BUTTONS[buttonType]}
    onClick={e => handleClick(disabled, e)}
    disabled={disabled}
    type={type}
    className={classNames(
      disabled ? "" : "button-transition-hover-bgColor-boxShadow",
      classes
    )}
    id={setId}
  >
    {children}
  </CustomStyled>
);

export default DefaultCustomButton;
