const SET_IP_INFO = "set_ip_info";

const initialState = {
  country: ""
};

const ipInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_IP_INFO:
      return {
        ...state,
        country: action.payload
      };
    default:
      return state;
  }
};

export default ipInfo;
