import React from "react";
import Resizer from "@hocs/Resizer";
import AccountGroup from "./AccountGroup";
import { useSelector } from "react-redux";

const AccountMain = ({ scrolled, payoutView, cartView }) => {
  const userMobile = useSelector(state => state.config.userAgent.isMobile);
  return (
    <Resizer
      components={[
        {
          component: () => (
            <AccountGroup
              compact={scrolled}
              cartView={cartView}
              payoutView={payoutView}
            />
          ),
          break: 999,
          maxBreak: Number.MAX_SAFE_INTEGER
        }
      ]}
      defaultTrue={userMobile ? false : true}
    />
  );
};

export default AccountMain;
