import { createAction, createReducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { registerPostHook } from "@modules/ReduxHook";
import { signal } from "@modules/Signal/action";

const toggle = createAction("accountGroup/toggle");
const close = createAction("accountGroup/close");
const open = createAction("accountGroup/open");

const isOpen = createReducer(false, {
  [toggle](state) {
    return !state;
  },
  [close]() {
    return false;
  },
  [open]() {
    return true;
  }
});

export default combineReducers({
  isOpen
});

registerPostHook([signal("page-change").type], [close()]);

export { toggle, close, open };
