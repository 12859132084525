import React from "react";
import styled from "@emotion/styled";

const MerchantOptions = styled.div`
  position: absolute;
  width: 100%;
  max-height: 326px;
  overflow: scroll;
  top: 36px;
  background-color: #ffffff;
  border-radius: 0px 0px 30px 30px;
  padding-top: 26px;
  box-shadow: 0 5px 20px 0 rgb(38 38 38 / 5%);
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const InnerOption = styled.div`
  width: 95%;
  margin: 0 auto;
  border-bottom: ${props => props.borderBottom};
  display: grid;
  grid-template-columns: auto 1fr 60px;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: pointer;
`;

const OptionImage = styled.img`
  width: 40px;
  height: 29px;
  margin-right: 10px;
  margin-left: 10px;
  opacity: ${props => props.setOpacity};
`;

const Label = styled.span`
  opacity: ${props => props.setOpacity};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: nowrap;

  grid-column: ${props => props.gridColumn};
`;

const AvailableContainer = styled.div`
  @media (max-width: 370px) {
    justify-self: flex-end;
  }
`;

const SoldOutSpan = styled.span`
  width: 50px;
  height: 27px;
  opacity: 0.5;
  border-radius: 5px;
  background-color: rgba(119, 121, 132, 0.1);
  color: #777984;
  font-weight: bold;
  font-size: 9px;
  text-align: center;
  display: grid;
  align-items: center;
`;

const NoResults = styled.div`
  text-align: center;
  color: #777984;
  padding-top: 25px;
  padding-bottom: 10px;
`;

const HomeSearchMenu = ({
  buyAction,
  merchantsToRender,
  handleMerchantClick,
  handleSellClick,
  loadingMerchants
}) => {
  const optionClick = mer => {
    if (buyAction) {
      handleMerchantClick(mer);
    } else {
      handleSellClick(mer);
    }
  };

  return (
    <MerchantOptions className="nav-merchant-options">
      <div>
        {merchantsToRender.map((mer, index) => (
          <InnerOption
            borderBottom={
              merchantsToRender.length - 1 === index
                ? ""
                : "1px rgba(119, 121, 132, 0.15) solid"
            }
            onClick={() => {
              optionClick(mer);
            }}
            key={`mer${mer.id}`}
          >
            <OptionImage
              src={mer.image}
              setOpacity={mer.cardsAvailable || !buyAction ? 1 : 0.5}
              alt="merchant"
            />
            <Label
              setOpacity={mer.cardsAvailable || !buyAction ? 1 : 0.5}
              gridColumn={mer.cardsAvailable || !buyAction ? "2/4" : "2/3"}
            >
              {mer.name}
            </Label>
            <AvailableContainer>
              {!mer.cardsAvailable && buyAction && (
                <SoldOutSpan>Sold out</SoldOutSpan>
              )}
            </AvailableContainer>
          </InnerOption>
        ))}

        {merchantsToRender.length < 1 && !loadingMerchants && (
          <NoResults>No results found</NoResults>
        )}
      </div>
    </MerchantOptions>
  );
};

export default HomeSearchMenu;
