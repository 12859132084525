import {
  GET_ALL_CATEGORIES,
  SET_HOME_CATEGORIES,
  RECEIVE_SELL_MERCHANTS,
  SET_SELL_CART_ID,
  ADD_CARD_TO_CART,
  ADD_CARD,
  UPDATE_NUMBER_OF_CARDS_IN_BUY,
  RECEIVE_BUY_CART,
  ADD_BUY_CARD,
  RESET_BUY,
  RESET_SELL,
  INITIAL_CART_LOADING,
  INITIAL_SELL_CART_LOADING,
  ENABLE_COUPON,
  OPEN_ERROR_BOX,
  ADD_MESSAGES,
  ADD_BANNERS,
  ADD_LOGOS,
  ADD_BUY_LOGOS,
  ADD_BUY_NEW_LOGOS,
  SET_COOKIES_ENABLED,
  UPDATE_COUPON_ENTER,
  SET_INITIAL_LOAD,
  ADD_EPIC_DISCOUNTS_BANNER
} from "@constants/actionTypes";
import { pick, isEmpty } from "ramda";
import { fireOneOff } from "@modules/Emission/action";
import { getCustomerProfile } from "./getCustomerProfile";
import { removeEmpty } from "@utils";
import { defaultAction } from "./defaultAction";
import { getSellCards } from "@utils/validators/card";
import { getPaymentMethods } from "./getPaymentMethods";
import { sendBugsnagError } from "@utils/Bugsnag";
import { getCoupon } from "./coupon";
import { setUpBuyCardsForRedux, getCartIds } from "./buyMerchants";
import { handleError } from "./initAppError";
import { getBuyMerchants, getNewMerch } from "./buyMerchants";

export const initAppBrowser = actions => async (dispatch, getState) => {
  try {
    const {
      merchantsBuy,
      merchantsSell,
      categories,
      merchantsNew,
      popups,
      initAppReducer
    } = getState();
    let buyMerchants = merchantsBuy.sortedByName;
    if (actions.includes("buy") && merchantsBuy.sortedByName.length < 1) {
      buyMerchants = await dispatch(getBuyMerchants());
    }
    if (actions.includes("sell") && merchantsSell.sortedByName.length < 1) {
      dispatch(getSellMerchants());
    }

    if (actions.includes("messages") && !popups.topMsg) {
      dispatch(getMessages());
    }
    if (actions.includes("statistics") && buyMerchants.length < 1) {
      await dispatch(getBuyMerchants());
    }
    if (
      actions.includes("home-categories") &&
      categories.homeCategories.length < 1
    ) {
      if (buyMerchants.length < 1) {
        buyMerchants = await dispatch(getBuyMerchants());
      }
      dispatch(getAllCategories(buyMerchants));
    }
    if (actions.includes("categories") && categories.allCategories.length < 1) {
      dispatch(getAllCategories(buyMerchants));
    }
    if (actions.includes("new") && merchantsNew.sortedByName.length < 1) {
      dispatch(getNewMerch());
    }

    if (initAppReducer.initialLoad) {
      const { marketing } = getState();
      const queryParams = pick(
        [
          "utm_campaign",
          "utm_medium",
          "utm_source",
          "utm_term",
          "utm_content",
          "gclid",
          "ranSiteID"
        ],
        marketing?.queryParams
      );
      if (!isEmpty(queryParams)) {
        dispatch(fireOneOff("CAPTURE_UTM_DATA", removeEmpty(queryParams)));
      }
      const customerCallStatus = await dispatch(getCustomerProfile());
      dispatch(getCarts());
      const couponCode = marketing?.queryParams?.discount;
      if (couponCode) {
        dispatch(defaultAction(UPDATE_COUPON_ENTER, couponCode));
        dispatch(getCoupon(couponCode));
      }
      if (customerCallStatus?.status !== 401) {
        dispatch(getPaymentMethods());
      }
      dispatch(defaultAction(SET_INITIAL_LOAD, false));
    }
  } catch (error) {
    const { cookies, config } = getState();
    const { x_cc_app } = config;
    let iterations = 0;
    const itrvl = setInterval(() => {
      if (
        iterations++ === 60 &&
        !globalThis?.document?.cookie?.includes(x_cc_app)
      ) {
        dispatch(defaultAction(SET_COOKIES_ENABLED, false));
        sendBugsnagError(
          new Error("x_cc_app cookie not found"),
          "initApp/initAppBrowser",
          {
            cookie: globalThis?.document?.cookie,
            cookieEnabled: globalThis?.navigator?.cookieEnabled
          }
        );
        clearInterval(itrvl);
      } else if (globalThis?.document?.cookie?.includes(x_cc_app)) {
        clearInterval(itrvl);
        if (!cookies.cookiesEnabled) {
          dispatch(defaultAction(SET_COOKIES_ENABLED, true));
        }
      }
    }, 400);
    sendBugsnagError(error, "initApp/initAppBrowser", {
      cookie: globalThis?.document?.cookie,
      cookieEnabled: globalThis?.navigator?.cookieEnabled
    });
  }
};

export const getSellMerchants = () => async (dispatch, getState, api) => {
  try {
    const sellMerchants = await api.get("v3/merchants/sell?cache=bust");
    dispatch({ type: RECEIVE_SELL_MERCHANTS, payload: sellMerchants.data });
  } catch (error) {
    dispatch(handleError(error, "initApp/getSellMerchants"));
  }
};

export const getMessages = () => async (dispatch, getState, api) => {
  try {
    const testParams = getState().marketing?.queryParams?.testTime;
    const getUrl = `v3/messages${
      testParams ? `/?testTime=${testParams}` : "?cache=bust"
    }`;
    const messages = await api.get(getUrl);
    const messageList = messages.data.messages;
    const reviewList = messages.data.reviews;
    let messagesObj = {
      top: messageList.top
        ? messageList.top
        : "Saving money shouldn't be this easy.",
      buy: messageList.buy ? messageList.buy : "",
      sell: messageList.sell ? messageList.sell : "",
      mobile: messageList.mobile
        ? messageList.mobile
        : "Saving money shouldn't be this easy.",
      customerReviews: reviewList
    };
    dispatch(defaultAction(ADD_MESSAGES, messagesObj));
    dispatch(defaultAction(ADD_BANNERS, messages.data.banners));
    if (messages.data.overrideImgs?.main_logo) {
      dispatch(defaultAction(ADD_LOGOS, messages.data.overrideImgs.main_logo));
    }
    if (messages.data.overrideImgs?.buy_page_banner) {
      dispatch(
        defaultAction(ADD_BUY_LOGOS, messages.data.overrideImgs.buy_page_banner)
      );
    }
    if (messages.data.overrideImgs?.buy_new_card_page_banner) {
      dispatch(
        defaultAction(
          ADD_BUY_NEW_LOGOS,
          messages.data.overrideImgs.buy_new_card_page_banner
        )
      );
    }
    if (messages.data.overrideImgs?.epic_discounts_page_banner) {
      dispatch(
        defaultAction(
          ADD_EPIC_DISCOUNTS_BANNER,
          messages.data.overrideImgs.epic_discounts_page_banner
        )
      );
    }
  } catch (error) {
    dispatch(handleError(error, "initApp/getMessages"));
  }
};

const getMerchantsData = (merchants, buyMerchants) =>
  buyMerchants
    .filter(merchant => merchants.includes(merchant.id.toString()))
    .sort((a, b) => b.popular - a.popular);

export const getAllCategories =
  buyMerchants => async (dispatch, getState, api) => {
    try {
      const categories = await api.get("v3/merchants/categories?cache=bust");
      dispatch(defaultAction(GET_ALL_CATEGORIES, categories.data));
      if (buyMerchants) {
        // we are getting the website categories (listId 1) and filtering the ones that are featured, sorting them by priority and getting the first 3
        const homePageCategories =
          categories.data
            ?.find(category => category.listId === 1)
            ?.categories?.filter(category => category.featuredPriority)
            ?.sort((a, b) => a.featuredPriority - b.featuredPriority)
            ?.slice(0, 3) || [];

        // we are filtering the buy merchants that have cards available and are popular
        const availableBuyMerchants = buyMerchants.filter(
          merchant => merchant.cardsAvailable > 0 && merchant.popular > 0
        );

        // we are adding the merchants data to the categories
        const homePageCategoriesWithMerchantData = homePageCategories.map(
          category => ({
            ...category,
            merchants: getMerchantsData(
              category.merchants,
              availableBuyMerchants
            )
          })
        );

        dispatch(
          defaultAction(SET_HOME_CATEGORIES, homePageCategoriesWithMerchantData)
        );
      }
    } catch (error) {
      dispatch(handleError(error, "initApp/getAllCategories"));
    }
  };

export const getCarts = actionType => async (dispatch, getState, api) => {
  if (actionType !== "no-actions") {
    dispatch(defaultAction(INITIAL_CART_LOADING, true));
  }
  dispatch(defaultAction(INITIAL_SELL_CART_LOADING, true));

  try {
    const results = await api.get("v3/carts");
    const buyCartApi = results.data.cart.buyCart;
    if (Object.keys(buyCartApi).length) {
      let allCardsInCart = getCartIds(buyCartApi);
      let currentCards = setUpBuyCardsForRedux(buyCartApi);
      let qty = 0;
      buyCartApi.cards.forEach(card => {
        qty += card.quantity;
      });
      dispatch(defaultAction(UPDATE_NUMBER_OF_CARDS_IN_BUY, qty));
      dispatch(
        defaultAction(RECEIVE_BUY_CART, {
          cartId: buyCartApi.cartId,
          cards: allCardsInCart
        })
      );
      dispatch(defaultAction(ADD_BUY_CARD, currentCards));

      if (buyCartApi.couponData) {
        if (buyCartApi.couponData.errorMsg) {
          await api.delete(
            `v3/carts/${buyCartApi.cartId}/coupons/${buyCartApi.couponData.couponCode}`
          );
        } else {
          dispatch(defaultAction(ENABLE_COUPON, buyCartApi.couponData));
          if (buyCartApi.couponData.discount > 0) {
            dispatch(
              defaultAction(UPDATE_COUPON_ENTER, buyCartApi.couponData.coupon)
            );
          }
        }
      }
    } else {
      dispatch(defaultAction(RESET_BUY, null));
      dispatch(defaultAction(UPDATE_NUMBER_OF_CARDS_IN_BUY, 0));
    }
    if (Object.keys(results.data.cart.sellCart).length) {
      let sellCart = getSellCards(results.data, {});
      dispatch(defaultAction(ADD_CARD, sellCart[0]));
      dispatch(defaultAction(ADD_CARD_TO_CART, sellCart[1]));
      dispatch(defaultAction(SET_SELL_CART_ID, sellCart[2]));
    } else {
      dispatch(defaultAction(RESET_SELL, null));
    }
  } catch (error) {
    sendBugsnagError(error, "initApp/getCarts");

    dispatch(
      defaultAction(OPEN_ERROR_BOX, {
        title: "Sorry, something went wrong.",
        message:
          "We are sorry. Please refresh the page and try again. Thank you."
      })
    );
  } finally {
    if (actionType !== "no-actions") {
      dispatch(defaultAction(INITIAL_CART_LOADING, false));
    }
    dispatch(defaultAction(INITIAL_SELL_CART_LOADING, false));
  }
};
